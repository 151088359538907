import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Component({
  selector: 'app-loginredirect',
  templateUrl: './loginredirect.component.html',
  styleUrls: ['./loginredirect.component.css']
})
export class LoginredirectComponent {

  constructor(private router: Router,public appSer : AppService){
    
  }
  ngOnInit(){
    // window.location.href = environment.loginUrl;
    if( this.appSer.isPlatformBrowser()){
    window.open( environment.loginUrl,"_self");
    }
    // this.router.navigateByUrl(environment.loginUrl)

  }
}
