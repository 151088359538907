<footer class="footer-section bg-footer" *ngIf="!urlContainsRegistration && !urlContainsThankyou && footerContent">
    <div class="container">
        <div class="footer-content pt-4 pb-4 pt-md-5 pb-md-5">
            <div class="row">
                <div class="col-xl-4  col-md-4 col-lg-4">
                    <div class="footer-widget">
                        <div class="footer-logo mb-2">
                            <a routerLink=""><img loading="lazy" src="{{footerContent?.fileDir}}/{{footerContent?.TPSLogo?.content}}"
                                    class="img-fluid" alt="{{footerContent?.TPSLogo?.alt}}"></a>
                            <p style="font-size: 14px;" id="logoSlogan"
                                [innerHtml]="footerContent?.logoSlogan?.content | safehtml"></p>
                        </div>
                        <div class="footer-social-icon ms-1 mt-3">
                            <span id="connectWithUs"
                                [innerHtml]="footerContent?.connectWithUs?.content | safehtml"></span>
                            <ng-container *ngFor="let item of socialLinkDetails?.sociallinks">
                                <img loading="lazy" class="social-links" src="{{socialLinkDetails.storagePath}}/{{item.filePath}}"
                                    alt="{{item.alt}}" (click)="appSer.openLink(item.link)">
                            </ng-container>

                            <!-- <a href="#"><i class="fab fa-instagram insta-bg"></i></a> 
                            <a href="#"><i class="fab fa-facebook-f facebook-bg"></i></a> 
                            <a href="#"><i class="fab fa-twitter twitter-bg"></i></a> 
                            <a href="#"><i class="fab fa-youtube youtube-bg"></i></a>
                            <a href="#"><i class="fab fa-linkedin linkedin-bg"></i></a>  -->
                        </div>
                        <!-- <div class="footer-social-icon mt-4 ms-1" style="width: 100%;"><span id="reviewsRedirection" [innerHtml]="footerContent?.reviewsRedirection?.content | safehtml"></span> </div> -->
                        <!-- TrustBox widget - Micro Review Count -->
                        <!-- <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad"
                            data-businessunit-id="5d2f6d831467690001526272" data-style-height="60px"
                            data-style-width="66.5%" data-theme="light" data-style-alignment="center"> <a
                                href="https://www.trustpilot.com/review/thepanelstation.com" target="_blank"
                                rel="noopener" id="trustPilot" [innerHtml]="footerContent?.trustPilot?.content | safehtml"> >Trust Pilot</a>
                        </div> -->
                        <!-- End TrustBox widget -->
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12 col-md-8 mb-50">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3 class="mt-2" id="thePanelStation"
                                        [innerHtml]="footerContent?.thePanelStation?.content | safehtml"></h3>
                                </div>
                                <ul (click)="handleFooterMenuClick($event)">
                                    <li><a [routerLink]="appSer.getLink(aboutUs?.link,country)" [queryParams]="qparams">{{aboutUs?.label}}</a></li>
                                    <li class="d-none"><a [routerLink]="appSer.getLink('paid-surveys',country)" [queryParams]="qparams" id="allAboutPaidSurveys" [innerHtml]="footerContent?.allAboutPaidSurveys?.content | safehtml">All About Paid Surveys</a></li>
                                    <!-- <li><a href="aboutus.html" id="aboutus">About Us</a></li>
                                    <li class="d-none"><a href="allaboutpaidsurveys.html">All About Paid Surveys</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3 class="mt-2" id="onlinePaidSurveys"
                                        [innerHtml]="footerContent?.onlinePaidSurveys?.content | safehtml"></h3>
                                </div>
                                <ul (click)="handleFooterMenuClick($event)">
                                    <li><a [routerLink]="appSer.getLink('register',country)" [queryParams]="qparams" id="joinUsForFree" routerLink="Register" [innerHtml]="footerContent?.joinUsForFree?.content | safehtml">{{joinUsForFree?.label}}</a></li>
                                    <li> <a [routerLink]="appSer.getLink(paidSurvey?.link,country)" [queryParams]="qparams">{{paidSurvey?.label}}</a> </li>
                                    <li> <a [routerLink]="appSer.getLink(howItWorks?.link,country)" [queryParams]="qparams">{{howItWorks?.label}}</a> </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 mb-md-3">
                            <div class="footer-widget">
                                <div class="footer-widget-heading">
                                    <h3 class="mt-2" id="exploreConnect"
                                        [innerHtml]="footerContent?.exploreConnect?.content | safehtml">Explore &amp;
                                        Connect</h3>
                                </div>
                                <ul (click)="handleFooterMenuClick($event)">
                                    <li> <a [routerLink]="appSer.getLink(rewardZone?.link,country)" [queryParams]="qparams">{{rewardZone?.label}}</a> </li>
                                    <li><a id="tpsHubStories" target="_blank" href="https://www.thepanelstation.com/blog" [innerHtml]="footerContent?.tpsHubStories?.content | safehtml"></a></li>
                                    <!-- <li><a id="needHelp" [routerLink]="" (click)="needhelp()" [innerHtml]="footerContent?.needHelp?.content | safehtml"></a></li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-12 col-12 mb-50">
                            <div class="footer-widget">
                                <div class="footer-text mb-25">
                                    <p id="signUpNewletters"
                                        [innerHtml]="footerContent?.signUpNewletters?.content | safehtml"></p>
                                </div>
                                <div class="subscribe-form w-75">
                                    <form action="#" [formGroup]="subscribeForm" (ngSubmit)="onSubscription(footerModal)">
                                        <input style="color: black;" class="rounded-pill bg-light" type="email" formControlName="email" [placeholder]="(footerContent?.['Email Address']) ? (footerContent?.['Email Address']):'Email Address'" required>
                                        <button type="submit" class="text-white rounded-pill" id="subscribe" [innerHtml]="footerContent?.subscribe?.content | safehtml"></button>
                                    </form>
                                    <div *ngIf="isSubmittedEmail && subscribeForm.controls['email']?.invalid">
                                        <small class="text-danger">{{ (footerContent?.['Please enter a valid email']) ? (footerContent?.['Please enter a valid email']) : 'Please enter a valid email'}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 mb-50">
                            <div class="text-left w-75 mt-3">
                                <select id="langSelection" [(ngModel)]="langSelection" class="selectpicker form-select rounded-pill" data-width="fit" (change)="setCountryURLLang($event);">
                                    <option data-content="" id="chooseYourLangauge" value="">{{footerContent?.['Choose your language']}}</option>
                                    <ng-container *ngFor="let lang of languageList">
                                        <option [ngValue]="lang.id" [attr.data-content]="lang.code">{{lang?.name}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-md-8 mb-2 mb-md-0 text-right">
                    <div class="footer-menu">
                        <ul (click)="handleFooterMenuClick($event)">
                            <li><a [routerLink]="appSer.getLink('faqs',country)" [queryParams]="qparams" id="faqFooter" [innerHtml]="footerContent?.faqFooter?.content | safehtml">FAQs</a></li>
                            <li><a [routerLink]="appSer.getLink('privacy-policy',country)" [queryParams]="qparams" id="privacyPolicyFooter" [innerHtml]="footerContent?.privacyPolicyFooter?.content | safehtml">Privacy Policy</a></li>
                            <li><a [routerLink]="appSer.getLink('terms-and-conditions',country)" [queryParams]="qparams" id="termsOfServiceFooter" [innerHtml]="footerContent?.termsOfServiceFooter?.content | safehtml">Terms of Service</a></li>
                            <li><a [routerLink]="appSer.getLink('cookie-policy',country)" [queryParams]="qparams" id="cookiePolicyFooter" [innerHtml]="footerContent?.cookiePolicyFooter?.content | safehtml">Cookie Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 text-end">
                    <div class="copyright-text">
                        <p id="allRightsReserved"> {{year}} © <span
                                [innerHtml]="footerContent?.allRightsReserved?.content | safehtml">The Panel Station |
                                All Rights Reserved</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Subscription popo up start  -->
     <!-- <ng-template #footerModal>
    <div class="modal fade" id="footerModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0 px-3 pt-2 pb-0">
                    <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;"
                        data-bs-dismiss="modal" aria-label="Close" (click)="closeReset()"></button>
                </div>
                <div class="modal-body">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-6 text-center py-3 px-0">
                            <img loading="lazy" src="assets/resources/images/subscribe/subscribe.svg" class="img-fluid"
                                id="subscribeBanner" alt="image">
                        </div>
                        <div class="col-lg-6 py-3">
                            <h4 class="fs-2 pb-0 text-left p-md-0 fw-bold" id="joinTheList"
                              [innerHtml]="footerContent?.joinTheList?.content | safehtml"></h4>
                            <h5 class="pt-0 pb-0 fs-6 mt-4 mb-4 text-left"  id="subscribeToNewsLetterFooter"
                              [innerHtml]="footerContent?.subscribeToNewsLetterFooter?.content | safehtml"></h5>
                          
                            <form [formGroup]="subscribeFormDetails" (ngSubmit)="onSubmit()">
                              Full name
                              <div class="input-group mb-3 w-75">
                                <input type="name" class="form-control rounded-3" [placeholder]="(footerContent?.['Full name']) ? (footerContent?.['Full name']):'Full Name'"
                                  aria-describedby="emailHelp" formControlName="fullName">
                                <div *ngIf="isSubmitted && subscribeFormDetails.controls['fullName']?.invalid">
                                  <small class="text-danger">{{(footerContent?.['Please enter a valid name.'])? (footerContent?.['Please enter a valid name.']) : 'Please enter a valid name.'}}</small>
                                </div>
                              </div>
                              Email
                              <div class="input-group mb-3 w-75">
                                <input formControlName="EmailId" type="name" class="form-control rounded-3"
                                  id="exampleInputEmail1" [placeholder]="(footerContent?.['Email Address']) ? (footerContent?.['Email Address']):'Email Address'"
                                  aria-describedby="emailHelp">
                                <div *ngIf="isSubmitted && subscribeFormDetails.controls['EmailId']?.invalid">
                                  <small class="text-danger">Please enter a email.</small>
                                </div>
                              </div>

                               Country
                               <div class="form-group w-75  mb-3">
                                <select formControlName="Market" (change)="onMarketSelection()"
                                  class="selectpicker form-control form-select rounded-3" data-width="fit" [attr.disabled]=disableCountrySelect>
                                  <option data-content="">{{(footerContent?.['Choose Country']) ? (footerContent?.['Choose Country']):'Choose Country'}}</option>
                                  <ng-container *ngFor="let country of countryList">
                                    <option data-content="English">{{country?.name}}</option>
                                  </ng-container>
                                </select>
                                <div *ngIf="countryErrorMessage !== ''">
                                    <small class="text-danger">{{ countryErrorMessage }}</small>
                                  </div>
                              </div>
                          
                              Generate OTP Button
                              <button id="generateOtp" type="button" class="btn-primary btn-sm form-control w-75 mb-3"
                                style="height: 36px;background-color: #e9ecef;border-radius: 0.5rem;"
                                (click)="generateOTP()" [disabled]="disableGenerateOTP"> {{(footerContent?.['Generate OTP']) ? (footerContent?.['Generate OTP']):'Generate OTP'}}</button>
                                <p class="my-2" *ngIf="showMessage" 
                                  [ngStyle]="{'font-size': '14px', 'line-height': '1.2', 'color': messageColor}">
                                  {{ otpMessage }}
                                </p>

                          
                              OTP Input Field
                              <div class="form-group mb-3 input-group w-75" id="VerifyOtp" #verifyOtp style="display: none;">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <div class="mb-lg-0 mb-3">
                                          <input formControlName="otp" type="text" class="form-control"
                                          [placeholder]="(footerContent?.['Enter OTP']) ? (footerContent?.['Enter OTP']):'Enter OTP'"
                                          required pattern="[0-9]*" maxlength="6"
                                          (input)="onOtpInput($event)">
                                            <p class="my-2" *ngIf="showInvalidMessage" style="font-size : 14px; line-height : 1.2; color : red;">
                                                {{ invalidOtpMessage }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 VerifyBtn">
                                        <div class="m-0">
                                            <button type="button" id="VerifyBtn" class="btn-primary btn-sm form-control" [disabled]="disableVerifyOTP"
                                                    (click)="verifySubscriberOTP()">{{(footerContent?.['Verify']) ? (footerContent?.['Verify']):'Verify'}}
                                            </button>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="resendTimerOn; else resendOTPbutton" class="col-lg-9 m-0">
                                        <button class="resendbtn" style="color: #0194a8;">
                                           {{(registerContent?.regFormResentOTP?.content) ? (registerContent?.regFormResentOTP?.content):'Resend OTP'}} <span class="counterdiv"> {{(footerContent?.['in']) ? (footerContent?.['in']):'in'}} <span id="countdowntimer">{{ resendTimer }}</span> S </span>
                                        </button>  
                                        
                                    </div>
                                    <ng-template #resendOTPbutton>
                                        <div class="col-lg-9 m-0">
                                          <button class="resendbtn" style="color: #0194a8;" [disabled]="disableResendOTP" (click)="resendOTP()">{{(footerContent?.['Resend OTP']) ? (footerContent?.['Resend OTP']):'Resend OTP'}}</button>  
                                        </div>
                                    </ng-template>
                                      
                                    
                                </div>
                              </div>
                          
                             
                              Checkbox conditions
                              <div class="col-lg-12 mt-2">
                                <div class="row d-flex align-items-center">
                                  <div class="col-lg-12 text-start">
                                    <div class="form-group" style="line-height: 1;">
                                      <input type="checkbox" name="terms" style="width: 12px;height: 12px;"
                                        formControlName="termsTnC">
                                      <span style="font-size: 10px;color: #000;margin-left: 5px;"
                                        id="termsTnC"
                                        [innerHtml]="footerContent?.termsTnC?.content | safehtml"> </span>
                                      <div
                                        *ngIf="isSubmitted && subscribeFormDetails.controls['termsTnC']?.invalid">
                                        <small class="text-danger">{{(footerContent?.['Please select'])? (footerContent?.['Please select']) : 'Please select'}}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 text-start mt-2">
                                    <div class="form-group" style="line-height: 1;">
                                      <input type="checkbox" id="terms" name="terms"
                                        formControlName="marketingConsent"
                                        style="width: 12px;height: 12px;">
                                      <span style="font-size: 10px;color: #000;margin-left: 5px;"
                                        id="marketingConsent"
                                        [innerHtml]="footerContent?.marketingConsent?.content | safehtml"></span>
                                      <div
                                        *ngIf="isSubmitted && subscribeFormDetails.controls['marketingConsent']?.invalid">
                                        <small class="text-danger">{{(footerContent?.['Please select'])? (footerContent?.['Please select']) : 'Please select'}}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 text-center mt-2">
                                    <div class="row">
                                      <div class="col-12 col-md-8 mb-2 mb-md-0">
                                        <button type="submit"
                                          class="btn btn-outline-light rounded-pill w-100 but"
                                          style="padding: 5px 0px;margin-top: 7px;" id="signMeUp"
                                          [innerHtml]="footerContent?.signMeUp?.content | safehtml"></button>
                                      </div>
                                    </div>
                                  </div>
                                  <div [ngClass]="subClass + ' mt-2'" *ngIf="subscriptionValid">
                                    {{subscriptionDetails?.Message}}
                                    You have been subscribed successfully!
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template> -->
  <ng-template #footerModal>
    <div class="modal-content">
      <div class="modal-header border-0 px-3 pt-2 pb-0">
          <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;"
              data-bs-dismiss="modal" aria-label="Close" (click)="closeReset()"></button>
      </div>
      <div class="modal-body">
          <div class="row d-flex align-items-center">
              <div class="col-lg-6 text-center py-3 px-0">
                  <img loading="lazy" src="assets/resources/images/subscribe/subscribe.svg" class="img-fluid"
                      id="subscribeBanner" alt="image">
              </div>
              <div class="col-lg-6 py-3">
                  <h4 class="fs-2 pb-0 text-left p-md-0 fw-bold" id="joinTheList"
                    [innerHtml]="footerContent?.joinTheList?.content | safehtml"></h4>
                  <h5 class="pt-0 pb-0 fs-6 mt-4 mb-4 text-left"  id="subscribeToNewsLetterFooter"
                    [innerHtml]="footerContent?.subscribeToNewsLetterFooter?.content | safehtml"></h5>
                
                  <form [formGroup]="subscribeFormDetails" (ngSubmit)="onSubmit()">
                    <!-- Full name -->
                    <div class="input-group mb-3 w-75">
                      <input type="name" class="form-control rounded-3" [placeholder]="(footerContent?.['Full name']) ? (footerContent?.['Full name']):'Full Name'"
                        aria-describedby="emailHelp" formControlName="fullName">
                      <div *ngIf="isSubmitted && subscribeFormDetails.controls['fullName']?.invalid">
                        <small class="text-danger">{{(footerContent?.['Please enter a valid name.'])? (footerContent?.['Please enter a valid name.']) : 'Please enter a valid name.'}}</small>
                      </div>
                    </div>
                    <!-- Email -->
                    <div class="input-group mb-3 w-75">
                      <input formControlName="EmailId" type="name" class="form-control rounded-3"
                        id="exampleInputEmail1" [placeholder]="(footerContent?.['Email Address']) ? (footerContent?.['Email Address']):'Email Address'"
                        aria-describedby="emailHelp">
                      <div *ngIf="isSubmitted && subscribeFormDetails.controls['EmailId']?.invalid">
                        <small class="text-danger">Please enter a email.</small>
                      </div>
                    </div>

                     <!-- Country -->
                     <div class="form-group w-75  mb-3">
                      <select formControlName="Market" (change)="onMarketSelection()"
                        class="selectpicker form-control form-select rounded-3" data-width="fit" [attr.disabled]=disableCountrySelect>
                        <option data-content="">{{(footerContent?.['Choose Country']) ? (footerContent?.['Choose Country']):'Choose Country'}}</option>
                        <ng-container *ngFor="let country of countryList">
                          <option data-content="English">{{country?.name}}</option>
                        </ng-container>
                      </select>
                      <div *ngIf="countryErrorMessage !== ''">
                          <small class="text-danger">{{ countryErrorMessage }}</small>
                        </div>
                    </div>
                
                    <!-- Generate OTP Button -->
                    <button id="generateOtp" type="button" class="btn-primary btn-sm form-control w-75 mb-3"
                      style="height: 36px;background-color: #e9ecef;border-radius: 0.5rem;"
                      (click)="generateOTP()" [disabled]="disableGenerateOTP"> {{(footerContent?.['Generate OTP']) ? (footerContent?.['Generate OTP']):'Generate OTP'}}</button>
                      <p class="my-2" *ngIf="showMessage" 
                        [ngStyle]="{'font-size': '14px', 'line-height': '1.2', 'color': messageColor}">
                        {{ otpMessage }}
                      </p>

                
                    <!-- OTP Input Field -->
                    <div class="form-group mb-3 input-group w-75" id="VerifyOtp" *ngIf="verifyOtp">
                      <div class="row">
                          <div class="col-lg-7">
                              <div class="mb-lg-0 mb-3">
                                <input formControlName="otp" type="text" class="form-control"
                                [placeholder]="(footerContent?.['Enter OTP']) ? (footerContent?.['Enter OTP']):'Enter OTP'"
                                required pattern="[0-9]*" maxlength="6"
                                (input)="onOtpInput($event)">
                                  <p class="my-2" *ngIf="showInvalidMessage" style="font-size : 14px; line-height : 1.2; color : red;">
                                      {{ invalidOtpMessage }}
                                  </p>
                              </div>
                          </div>
                          <div class="col-lg-5 VerifyBtn">
                              <div class="m-0">
                                  <button type="button" id="VerifyBtn" class="btn-primary btn-sm form-control" [disabled]="disableVerifyOTP"
                                          (click)="verifySubscriberOTP()">{{(footerContent?.['Verify']) ? (footerContent?.['Verify']):'Verify'}}
                                  </button>
                              </div>
                          </div>
                          
                          <div *ngIf="resendTimerOn; else resendOTPbutton" class="col-lg-9 m-0">
                              <button class="resendbtn" style="color: #0194a8;">
                                 {{(registerContent?.regFormResentOTP?.content) ? (registerContent?.regFormResentOTP?.content):'Resend OTP'}} <span class="counterdiv"> {{(footerContent?.['in']) ? (footerContent?.['in']):'in'}} <span id="countdowntimer">{{ resendTimer }}</span> S </span>
                              </button>  
                              
                          </div>
                          <ng-template #resendOTPbutton>
                              <div class="col-lg-9 m-0">
                                <button class="resendbtn" style="color: #0194a8;" [disabled]="disableResendOTP" (click)="resendOTP()">{{(footerContent?.['Resend OTP']) ? (footerContent?.['Resend OTP']):'Resend OTP'}}</button>  
                              </div>
                          </ng-template>
                            
                          
                      </div>
                    </div>
                
                   
                    <!-- Checkbox conditions -->
                    <div class="col-lg-12 mt-2">
                      <div class="row d-flex align-items-center">
                        <div class="col-lg-12 text-start">
                          <div class="form-group" style="line-height: 1;">
                            <input type="checkbox" name="terms" style="width: 12px;height: 12px;"
                              formControlName="termsTnC">
                            <span style="font-size: 10px;color: #000;margin-left: 5px;"
                              id="termsTnC"
                              [innerHtml]="footerContent?.termsTnC?.content | safehtml"> </span>
                            <div
                              *ngIf="isSubmitted && subscribeFormDetails.controls['termsTnC']?.invalid">
                              <small class="text-danger">{{(footerContent?.['Please select'])? (footerContent?.['Please select']) : 'Please select'}}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 text-start mt-2">
                          <div class="form-group" style="line-height: 1;">
                            <input type="checkbox" id="terms" name="terms"
                              formControlName="marketingConsent"
                              style="width: 12px;height: 12px;">
                            <span style="font-size: 10px;color: #000;margin-left: 5px;"
                              id="marketingConsent"
                              [innerHtml]="footerContent?.marketingConsent?.content | safehtml"></span>
                            <div
                              *ngIf="isSubmitted && subscribeFormDetails.controls['marketingConsent']?.invalid">
                              <small class="text-danger">{{(footerContent?.['Please select'])? (footerContent?.['Please select']) : 'Please select'}}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 text-center mt-2">
                          <div class="row">
                            <div class="col-12 col-md-8 mb-2 mb-md-0">
                              <button type="submit"
                                class="btn btn-outline-light rounded-pill w-100 but"
                                style="padding: 5px 0px;margin-top: 7px;" id="signMeUp"
                                [innerHtml]="footerContent?.signMeUp?.content | safehtml"></button>
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="subClass + ' mt-2'" *ngIf="subscriptionValid">
                          {{subscriptionDetails?.Message}}
                          <!-- You have been subscribed successfully! -->
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                
          </div>
      </div>
  </div>
  </ng-template>
  
    <!-- Subscription popo up end  -->

</footer>