import { Component, ElementRef, HostListener, Inject, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '../app.service';
// import '../../assets/resources/js/casrousel.js';
import { SafehtmlPipe } from '../safehtml.pipe';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent {
  rewards: any;
  testimonials: any;
  languageList:any;
  langSelection!: string;
  @ViewChildren('testimonialElement') testimonialElements!: QueryList<any>;
  @ViewChild('locationElement', { static: false }) locationElement!: ElementRef;
  private observer!: MutationObserver;
  private hasElementBeenAdded = false;
  langCode: any;
  constructor(  public appSer: AppService, private _activatedRoute: ActivatedRoute, private router: Router,private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document, private metaService: Meta, private el:ElementRef) { }
  public content?:any = {};
  country = this.appSer.getLocalStorage("country");
  closeLangSelection : boolean = false;
  private markeLoadedSubscription: any;
  public includeTestmonials:boolean = false
  ngOnInit(){
    // this.appSer.setmarket(this._activatedRoute);
    // console.log( this.appSer.market );
    // this.content['locSpec'] = "sd";
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
      //alert("markeLoaded");
      this.getPageContent();

      this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
        this.rewards = res.data;
      });
     
    });
       
    this.appSer.getLanguageList().subscribe( (res:any)=>{// To get the language details :- 
      this.languageList = Object.values(res.data);
      let urlLang = this._activatedRoute.snapshot.queryParams['lang'];
      if (urlLang) {
        let lang = this.appSer.matchURLToCountryLang(urlLang,this.languageList);
        this.selectLanguage(lang);
      }
    });
    // // setTimeout(() => {
    //   this.langSelecion();
    // }, 10000);
    this.appSer.elementAdded.subscribe(() => {
      if (!this.hasElementBeenAdded) {
        this.hasElementBeenAdded = true;
        this.langSelecion();
        setTimeout(() => {
          this.closeLangSelection = true;
        }, 10000); // 10 seconds
      }
    });

    if(this.appSer.isPlatformBrowser()){
      this.includeTestmonials = true;
    }
  }
  handleJoinForFreeClick(buttonName: string): void {
    // console.log(`handleJoinForFreeClick called for ${buttonName}`);
    // console.log('handleJoinForFreeClick called');
    
    // Log the user action directly in the same function
    console.log('Sending data to backend:', { action: 'visited the site' });
    const caid = null;
    const langID = null;
    const message = `visited the ${buttonName} section`;
    this.appSer.loguser({caid, langID}, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }
  


  handleVideoControl(event:any) { // to handle play and pause the video on hover 
    let videoElement = event.target;
    if (event.type === 'mouseenter') {
      videoElement?.play()
    } else if (event.type === 'mouseleave') {
      videoElement?.pause();
    }
  }

  langSelecion() {
  
    /*
    // document.addEventListener('DOMContentLoaded', (event) => {
      // let location = document.querySelector(".location-set") as HTMLElement | null; //docchange
      let location = this.el.nativeElement.querySelector('.location-set');
      console.log("location", location);
      let myTimeout = setTimeout(function () {
          if (location) {
              location.style.display = 'none';
          }
      }, 10000);
      location?.addEventListener("mouseleave", () => {
          myTimeout = setTimeout(function () {
              if (location) {
                  location.style.display = 'none';
              }
          }, 10000);
      });
      location?.addEventListener("mouseenter", () => {
          clearTimeout(myTimeout);
      });
  // });
  */
  }
  // To update the URL depending on language selection old function
  setCountryURLLang(langID:any,langCode?:any){
    this.appSer.setLanguage(langID);
    if(langID != ""){
    
      this.appSer.getFooterlang(langID).subscribe ( (data:any)=>{
        this.appSer.marketStyles(data);
        if(langCode){
          let url = this.router.url.split('?')[0] 
          console.log(url)
          //this.router.navigate([url], {queryParams: {lid: this.langSelection}});
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            // this.router.navigate([url], {queryParams: {lc: data.data.country_lang_id}});
            this.appSer.addToLocalStorage('country_lang_id',data.data.country_lang_id);
            this.router.navigate([url], {queryParams: {lang: langCode.split('-')[0]}});
          });
          //this we are refreshing menu, footer on lang select
          this.appSer.langChanged();
        }
      
      })
    }
    // this.langSelection= (<HTMLInputElement>event.target).value;
    //let url = this.router.url.split('?')[0] 
    // console.log(url)
    // if(this.langSelection !="Choose Your Language"){
    //   //this.router.navigate([url], {queryParams: {lid: this.langSelection}});
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //     this.router.navigate([url], {queryParams: {lid: this.langSelection}});
    //   });
    // }
    this.appSer.langChangedEvent.subscribe((data:string) => {
      // alert("lang change");
      // setTimeout( ()=>{
        this.getPageContent();
      // },1000);
    });

  }
  selectLanguage(lang:any){
    this.appSer.setLanguage(lang.id);
    if(lang.id != ""){
      this.appSer.getFooterlang(lang.id).subscribe((data:any)=>{
        this.appSer.marketStyles(data);
        if(lang.code){
          let url = this.router.url.split('?')[0];
          let urlLang = this._activatedRoute.snapshot.queryParams['lang'];
          if (urlLang !== lang.langCode) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.appSer.addToLocalStorage('country_lang_id', lang.langCode);
              this.router.navigate([url], { queryParams: { lang: lang.langCode } });
            });
          }
          //this we are refreshing menu, footer on lang select
          this.appSer.langChanged();
        }
      })
    }
    this.appSer.langChangedEvent.subscribe((data:string) => {
        this.getPageContent();
    });
  }
  ngAfterViewInit() {
    this.testimonialElements.changes.subscribe((element:Element) => {
      console.log("testimonialElements len",this.testimonialElements.length);
      console.log("testimonialElements len-",this.testimonials?.testmonials?.length);
      if (this.testimonialElements.length === this.testimonials?.testmonials?.length) {
        // this.initializeCarouselScript();
      }
    });

    // Initialize MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Check if the element has been added
          if (this.locationElement && this.locationElement.nativeElement) {
            this.appSer.notifyElementAdded();
          }
        }
      }
    });
    // Start observing the document body
    observer.observe(this.document.body, { childList: true, subtree: true });
  }

  getPageContent(){
    let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    this.appSer.getPageContent(page).subscribe( (res:any)=>{
      // console.log(res.data);
      this.content = res.data;
      // console.log(this.content);
      this.appSer.setTitle(res.data.title);
      this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService,1, res.data.title);
      // this.addMetaTagsFromString("aaa");
      // console.log("res.title", res.data.title)
      // this.appSer.getTestmonialsDetails().subscribe((res:any) => { // To get the testimonials details 
      //   this.testimonials = res.data;
      //   // this.initializeCarouselScript();
      //   // setTimeout(() => {
      //   //   const script = this.renderer2.createElement('script');
      //   //   script.type = 'text/javascript';
      //   //   script.src  = "assets/resources/js/casrousel.js";
      //   //   this.renderer2.appendChild(document.body, script);
      //   // }, 5000);
      // });
      if( typeof this.content != 'undefined'){
        if(typeof this.content?.answerWithEaseVideo_thumbnails == 'undefined') {
          this.content.answerWithEaseVideo_thumbnails = {};
        } this.content.answerWithEaseVideo_thumbnails.content = "";
        if(typeof this.content?.voiceTakeRootVideo_thumbnails == 'undefined') {
          this.content.voiceTakeRootVideo_thumbnails = {} ;
          this.content.voiceTakeRootVideo_thumbnails.content = "" ;
        } 
      }
      // console.log("schema_script", JSON.parse(this.content.schema_script.content));
      if(this.content.schema_script){
        this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
      }
    });
   
  }
// To set the carousel javascript file post view is ready
  initializeCarouselScript() {
    // setTimeout(() => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = "assets/resources/js/casrousel.js";
      // this.renderer2.appendChild(document.body, script); //docchange
      this.renderer2.appendChild(this.document.body, script); 
      
    // }, 5000);
  }

  private addMetaTagsFromString(metaTagsString: string): void {
    console.log(this.metaService.getTag('description'))
    if(this.metaService.getTag('description')){
      this.metaService.updateTag({name: 'description', content: metaTagsString})
    }else{
      this.metaService.addTag({name: 'description', content: metaTagsString})
    }
    
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(metaTagsString, 'text/html');
    // const head = doc.head;

    // // Append all meta tags from parsed head to the actual document head
    // for (let i = 0; i < head.children.length; i++) {
    //   const node = head.children[i];
    //   if (node.tagName === 'META') {
    //     this.renderer2.appendChild(this.document.head, node);
    //   }
    // }
    
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
}
