<div class="prodiler-wrapper">
<div class="row no-gutter">
    <div class="col-lg-12 col-xl-12">
        <nav class="navbar-light p-3" style="position: absolute;background-color: transparent;">
            <a><img loading="lazy" src="assets/resources/images/TPSLogo.svg" class="d-inline-block align-top navbar-brand w-50 bg-light p-2 rounded-pill" style="box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);" alt=""></a>
        </nav>
    </div>
</div>
<div class="container-fluid">
    <div class="row no-gutter" id="questionastart">
        <!-- The image half -->
        <div class="col-md-6 d-flex align-items-center text-center">
            <div class="container position-unset">
                <div class="row" *ngIf="content">
                    <div class="col-lg-12 col-xl-12 p-5 mx-auto div-profiler" id="Div5" *ngIf="currentPage ==0  && !this.formSubmitSuccess">
                        <h4 class="fs-1 fw-bold text-white" style="font-size: 60px;"><span id='welcomeMsg' [innerHtml]="content?.welcomeMsg?.content | safehtml">Welcome to your preliminary survey</span>, {{panelistInfo?.FirstName}} </h4>
                        <h4 class="text-white fs-5 mt-4"><span id="fillOutSurvey" [innerHtml]="content?.fillOutSurvey?.content | safehtml">Fill out this simple survey and earn</span> {{panelistInfo?.Points}}  {{panelistInfo?.PointText}}.</h4>
                        <h4 class="text-white fs-6 mt-2"> ({{panelistInfo?.PanelistMarketThreshold}} {{panelistInfo?.PointText}} = {{panelistInfo?.TrendingVoucherMinThreshold}} {{panelistInfo?.TrendingVoucher}})</h4> <img loading="lazy" src="assets/resources/images/profiler/banner.svg" class="w-30 mt-2" style="position: absolute;padding-right: 40px;"> 
                    </div>
                    <div class="col-lg-12 col-xl-12 p-2 mx-auto div-profiler" id="Div4" *ngIf="this.formSubmitSuccess">
                        <div class="col-lg-12 col-md-12 rounded text-center mob-hide"> <img loading="lazy" src="assets/resources/images/profiler/banner-1.svg" class="w-50">
                            <h1 class="fs-1 px-5 mt-md-0 mt-5 lh-sm text-white" id="kudosEarnedPoints" [innerHtml]="content?.kudosEarnedPoints?.content | safehtml"> Kudos! You have earned {{panelistInfo?.Points}}  {{panelistInfo?.PointText}} for taking the survey.</h1> </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 p-5 mx-auto div-profiler" id="Div7" *ngIf="currentPage > 0  && !this.formSubmitSuccess">
                        <h4 class="fs-1 fw-bold text-white lh-1" style="font-size: 60px;" id="hereYouGo" [innerHtml]="content?.hereYouGo?.content | safehtml">Here you go!</h4><img loading="lazy" src="assets/resources/images/profiler/banner.svg" class="w-30 mt-5 pt-5" style="position: absolute;padding-right: 40px;"> </div>
                </div>
            </div>
        </div>
        <!-- The content half -->
        <div class="col-md-6 bg-light" id="DivStart">
            <div class="login d-flex align-items-center text-left">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <p class="fs-4 text-center mb-4 px-lg-3 mx-lg-3"> To receive updates on points and new survey opportunities, share your mobile number below. </p>
                        </div>
                        <div class="col-md-10 mx-auto py-3 glass">
                            <form class="appoinment-form px-lg-4 p-2 rounded">
                                <div class="row py-1 text-center register-form">
                                    <div class="col-lg-12 d-flex mb-2">
                                        <p style="font-size: 13px;color: #000">Where do you want to receive OTP?</p>&nbsp;&nbsp;&nbsp;
                                        <!-- Default inline 1-->
                                        <div class="custom-control custom-radio d-flex">
                                            <input type="radio" class="custom-control-input" id="defaultInline1" name="inlineDefaultRadiosExample" (click)="show2();">&nbsp;
                                            <label class="custom-control-label" for="defaultInline1" style="font-size: 13px;margin-top: 1px;color: #000">Whatsapp</label>
                                        </div>&nbsp;&nbsp;&nbsp;
                                        <!-- Default inline 2-->
                                        <div class="custom-control custom-radio d-flex">
                                            <input type="radio" class="custom-control-input radioshow" id="defaultInline2" name="inlineDefaultRadiosExample" (click)="show1();">&nbsp;
                                            <label class="custom-control-label" for="defaultInline2" style="font-size: 13px;margin-top: 1px;color: #000">SMS</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mx-auto">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="form-group">
                                                    <div class="input-group"> <span class="input-group-text rounded" id="basic-addon1" style="padding: 0.3rem 0.75rem;height: 35px;width: 70px;background-color: #fff;"><img loading="lazy" src="assets/resources/images/icons/whatsapp.svg" class="w-50 img-fluid cart" style="display: none;"> &nbsp;+91</span>&nbsp;
                                                        <input name="name" type="text" class="form-control rounded required" style="height: 35px;" placeholder="Enter your mobile number" value=""> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <button type="button" class="btn btn-primary btn-sm rounded-pill toggle-disabled" data-bs-toggle="modal" data-bs-target="#mymodal3" style="padding: 3px 10px;height: 35px;font-size: 14px;width: 100%;" disabled>Get OTP</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="col-lg-11 px-2 mx-auto text-start">
                                <div class="form-group" style="line-height: 0.9;">
                                    <input type="checkbox" id="terms" name="terms" style="width: 12px;height: 12px;"> <span style="font-size: 11px;color: #000;margin-left: 5px;"> By signing up, I consent to receive updates via SMS, Whatsapp, and App notifications, but can change my preferences anytime. </span> </div>
                            </div>
                            <div class="col-md-6 mb-3 mt-4 mx-auto">
                                <div class="form-group">
                                    <button type="submit" id="button5" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;margin-top: 7px;">Submit &amp; Explore Vouchers</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6 bg-light p-0" id="Div1" *ngIf="currentPage == 0">
            <div style="height: 5px;"> </div>
            <div class="login-1 d-flex align-items-center text-left">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-xl-9 p-2 px-4 mx-auto">
                            <h4 class="fs-5 mb-4" style="line-height: 1.2;">This survey collects basic demographic information, which we will use to send you surveys that match your profile.</h4>
                            <h4 class="fs-5 mb-3" style="line-height: 1.2;">Please read each question carefully and provide honest answers to help us understand your preferences and interests accurately.</h4>
                            <h4 class="fs-5 mb-4" style="line-height: 1.2;">{{profilerQuestions[currentPage]?.questions[currentPage]?.QuestionText}}</h4>
                            <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" (click)="button1(); ctaAtion('next',profilerQuestions[currentPage]);" id="letstartbtn">Let’s Start</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-md-6 bg-light p-0" id="Div2" *ngIf="!this.formSubmitSuccess">
            <div class="row mt-4" [ngStyle]="{'display': currentPage === 0 ? 'none' : 'block'}">
                <div class="col-10 mx-auto">
                    <div class="progress" style="height: 15px;">
                        <div class="progress-bar progress-bar-striped" id="progressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="custom-percentage"><b id="percentValue"> </b> <span style="color: #0194a8;" id="completed" [innerHtml]="content?.completed?.content | safehtml">Completed</span> 
                        <!-- <span style="color: #0194a8; float: right;">{{currentPage}} / {{(profilerQuestions?.length-3)}}</span>  -->
                    </div>
                </div>
            </div>
            <div class="login d-flex align-items-center" >
                <!-- profiler start -->
                <div class="container" [formGroup]="profilerQuestionsForm">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12 mx-auto">
                            <div class="row q-width p-0 px-md-5">
                                <ng-container *ngIf="profilerDetails?.response.Status == false">
                                    <div  [innerHtml]="profilerDetails?.response.Message | safehtml"></div>
                                </ng-container>
                                <div class="col-md-12" *ngIf="profilerDetails?.response.Status == true">
                                    <!-- render the page start-->
                                    <ng-container *ngFor="let page of profilerQuestions;let i = index">
                                        <ng-container *ngIf="currentPage === (i)">
                                        <div class="step well" [formGroupName]="page.ID.toString()" data-id="{{page.ID}}">
                                            <!-- <h4>This is dynamic section {{i}}, {{page.Name}}</h4> -->
                                            <!-- Render the questions start -->
                                            <ng-container *ngFor="let question of page.questions; let j=index;">
                                                <div class="mb-4 formgroup">
                                                    <!-- <i class="bi bi-gender-ambiguous fs-1"></i> -->
                                                    <h5 class="mb-3">{{question.QuestionText}}</h5>
                                                    <!-- 
                                                        * Type of question :-
                                                            1. Dropdown
                                                            2. Date
                                                            3. Radio
                                                    -->
                                    
                                                    <!-- 1.Dropdown -->
                                                    <!-- <ng-container *ngIf="question.QuestionType.includes('Drop Down')">
                                                        <select class="form-select form-select-sm w-75" aria-label=".form-select-sm example" [formControlName]="question.ID.toString()">
                                                            <ng-container *ngFor="let answers of question.AnswerChoice;let k = index">
                                                                <option [selected] disabled value="" *ngIf="k==0" [innerHtml]="content?.selectfromdropdown?.content | safehtml"></option>
                                                                <option [value]="answers.ID">{{answers.AnswerChoiceText}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </ng-container> -->
                                                    <!-- <ng-container *ngIf="question.QuestionType.includes('Drop Down')">
                                                        <div *ngIf="question.AnswerChoice.length > 7">
                                                          <input type="text" class="form-control mb-2 search-profiler" placeholder="Search" [formControl]="searchTerms[question.ID]">
                                                        </div>
                                                        <select class="form-select form-select-sm w-75" aria-label=".form-select-sm example" [formControlName]="question.ID.toString()">
                                                          <ng-container *ngFor="let answers of (question.AnswerChoice | filterOptions:searchTerms[question.ID].value); let k = index">
                                                            <option [selected] disabled value="" *ngIf="k == 0" [innerHtml]="content?.selectfromdropdown?.content | safehtml"></option>
                                                            <option [value]="answers.ID">{{answers.AnswerChoiceText}}</option>
                                                          </ng-container>
                                                        </select>
                                                      </ng-container> -->
                                                    <!-- <ng-container *ngIf="question.QuestionType.includes('Drop Down')">
                                                        <div class="custom-dropdown" tabindex="-1">
                                                            <input type="text" class="dropdown-search" [formControl]="searchTerms[question.ID]" placeholder="Search" />
                                                            <input type="text" [formControlName]="question.ID" style="display: none;">
                                                            <div class="dropdown-menu scroll-box">
                                                                <div *ngFor="let answers of (question.AnswerChoice | filterOptions:searchTerms[question.ID].value); let k = index"
                                                                    class="dropdown-item" data-id="{{answers.ID}}" (click)="onSelect(answers,question,page)">
                                                                    {{answers.AnswerChoiceText}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container> -->
                                                    <ng-container *ngIf="question.QuestionType.includes('Drop Down')">
                                                    <!-- <ngx-select-dropdown [config]="config" [options]="question.AnswerChoice" (change)="onSelectionChange($event,page)"></ngx-select-dropdown> -->
                                                    <ngx-select-dropdown 
                                                        [config]="getConfig(question.ID)" 
                                                        [options]="question.AnswerChoice" 
                                                        (change)="onSelectionChange($event, question,page)">
                                                    </ngx-select-dropdown>
                                                        <input type="hidden" style="display: none;" type="text" [formControlName]="question.ID">
                                                    </ng-container>
                                                    <!-- 2.Date -->
                                                    <ng-container *ngIf="question.QuestionType.includes('Date')">
                                                        <div class="form-group">
                                                            <input type="date" id="datepicker" class="form-control rounded w-75"
                                                                placeholder="Tell us when you were born" style="padding: 3px 10px;height: 35px;" value="" [formControlName]="question.ID.toString()">
                                                        </div>
                                                    </ng-container>
                                                    <!-- 3.Radio -->
                                                    <div *ngIf="question.QuestionType.includes('Radio Button')" class="mb-4">
                                                        <!-- <div *ngIf="question.AnswerChoice.length > 7">
                                                            <input type="text" class="form-control mb-2 search-profiler" placeholder="Search" [formControl]="searchTerms[question.ID]">
                                                          </div> -->
                                                        <div class="scroll-box">
                                                            <ng-container
                                                                *ngFor="let answers of question.AnswerChoice;let k = index">
                                                                <!-- <div class="mb-1 scroll-box"> -->
                                                                <div class="radiobtn">
                                                                    <input type="radio" (change)="otherOptionSelection(page,question,answers);" id="{{answers.ID}}" [formControlName]="question.ID.toString()" [value]="answers.ID" />
                                                                    <label for="{{answers.ID}}">{{answers.AnswerChoiceText}}</label>
                                                                </div>
                                                                <!-- </div> -->
                                                                <div class="is-other-textbox" *ngIf="answers.IsOthers && otherSelected[answers.ID]">
                                                                    <textarea [formControlName]="'other-' + answers.ID" placeholder="Type your answer here"></textarea>
                                                                  </div>
                                                            </ng-container>
                                                        </div>

                                                    </div>
                                                    <!-- 4.Multiple selection -->
                                                    <ng-container *ngIf="question.QuestionType.includes('Multi Choice')">
                                                        <!-- <div *ngIf="question.AnswerChoice.length > 7">
                                                            <input type="text" class="form-control mb-2 search-profiler" placeholder="Search" [formControl]="searchTerms[question.ID]">
                                                          </div> -->
                                                        <div [formArrayName]="question.ID.toString()" class="scroll-box">
                                                          <ng-container *ngFor="let answer of (question.AnswerChoice | filterOptions:searchTerms[question.ID].value); let k = index">
                                                            <!-- <div class="mb-4"> -->
                                                              <div class="radiobtn">
                                                                <input type="checkbox" (change)="noOfTheAbove(page,question,answer);otherOptionSelection(page,question,answer);" [id]="answer.ID" [formControlName]="answer.ID" [value]="answer.ID" />
                                                                <label [for]="answer.ID">{{answer.AnswerChoiceText}}</label>
                                                              </div>
                                                            <!-- </div> -->
                                                            <div class="is-other-textbox" *ngIf="answer.IsOthers && otherSelected[answer.ID]">
                                                                <textarea [formControlName]="'other-' + answer.ID" placeholder="Type your answer here"></textarea>
                                                              </div>
                                                          </ng-container>
                                                        </div>
                                                      </ng-container>
                                                </div>
                                            </ng-container>
                                            <!-- Render the questions end -->
                                        </div>
                                        </ng-container>
                                    </ng-container>
                                    <!-- render the page end-->
                                     <div  class="alert alert-danger" role="alert" *ngIf="formGroupSubmit && !currentFormGroupValid">
                                        <!-- Above questions are mandatory, Please select answer -->
                                        {{(content?.['Above questions are mandatory, Please select answer'])? (content?.['Above questions are mandatory, Please select answer']):'Above questions are mandatory, Please select answer'}}
                                      </div>
                                    <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" (click)="ctaAtion('next',profilerQuestions[currentPage+1]);" id="letstartbtn" *ngIf="currentPage == 0" id="letsstart" [innerHtml]="content?.letsstart?.content | safehtml">Let’s Start</button>
                                    <button class="btn btn-secondary rounded-pill action back mt-2" *ngIf="currentPage >1 && currentPage<profilerQuestions?.length" (click)="ctaAtion('back',profilerQuestions[currentPage]);" id="back" [innerHtml]="content?.back?.content | safehtml">Back</button>
                                    <button class="btn btn-dark rounded-pill action next mt-2" id="Btnnext" (click)="ctaAtion('next',profilerQuestions[currentPage+1]);" *ngIf="currentPage > 0 && currentPage<profilerQuestions?.length-1" id="next" [innerHtml]="content?.next?.content | safehtml">Next</button>
                                    <button class="btn action btn-dark rounded-pill submit btn btn-success mt-2" type="submit" value="Click" (click)="onSubmit();validateCurrentPageForm('next');ctaAtion('Submit');"  *ngIf="currentPage == profilerQuestions?.length-1" id="submit" [innerHtml]="content?.submit?.content | safehtml">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End -->
            </div>
        </div>
        <div class="col-md-6 bg-light p-0" id="Div3" *ngIf="this.formSubmitSuccess">
            <div style="height: 5px;"> </div>
            <div class="login-2 d-flex align-items-center text-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-11 col-xl-11 p-4 mx-auto">
                            <div id="hideme" *ngIf="!claimYourVoucher">
                                <div class="mb-4 svg1">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path1 circle" fill="none" stroke="#0194a8" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline class="path1 check" fill="none" stroke="#0194a8" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " /> </svg>
                                </div>
                                <h4 class="fw-normal fs-2 mb-4" id="chooseVoucher" [innerHtml]="content?.chooseVoucher?.content | safehtml">Choose from the list below to redeem your voucher now!</h4>
                                <div class="row mt-3 mx-auto mb-4">
                                    <!-- <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;" (click)="claimVoicher();claimYourVoucher=true;">
                                        <p class="mb-1 fw-bold" style="color: #000">Claim your</p>
                                        <div class="rounded-pill bg-primary border-l">
                                            <a class="test brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/1.webp" alt="image"></a>
                                        </div>
                                        <p class="mb-3 mb-md-0 fw-bold" style="color: #000">Voucher</p>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;" (click)="claimVoicher();claimYourVoucher=true;">
                                        <p class="mb-1 fw-bold" style="color: #000">Claim your</p>
                                        <div class="rounded-pill bg-primary border-l">
                                            <a class="test brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/2.webp" alt="image"></a>
                                        </div>
                                        <p class="mb-3 mb-md-0 fw-bold" style="color: #000">Voucher</p>
                                    </div>
                                    <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;" (click)="claimVoicher();claimYourVoucher=true;">
                                        <p class="mb-1 fw-bold" style="color: #000">Claim your</p>
                                        <div class="rounded-pill bg-primary border-l">
                                            <a class="test brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/8.webp" alt="image"></a>
                                        </div>
                                        <p class="mb-0 fw-bold" style="color: #000">Voucher</p>
                                    </div> -->
                                    <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                                        <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;display: ruby;" (click)="claimVoicher();claimYourVoucher=true;">
                                            <!-- <div>
                                                <p class="mb-1 fw-bold" style="color: #000" id="claimYour" [innerHtml]="content?.claimYour?.content | safehtml">Claim your</p>
                                                <div class="w-75">
                                                    <a class="test brand-img"> <img loading="lazy" class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}"></a>
                                                </div>
                                                <p class="mb-0 fw-bold" style="color: #000">Voucher</p>
                                            </div> -->
                                            <a class="test brand-img">
                                                <div class="claim-voucher mb-2">
                                                    <img loading="lazy" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="image" class="image">
                                                    <div class="overlay">
                                                        <div class="text"> <span id="claimYour" [innerHtml]="content?.claimYour?.content | safehtml">Claim your</span></div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-11 mx-auto" id="showme" *ngIf="claimYourVoucher"> <!--*ngIf="claimYourVoucher" -->
                                <div class="card border-0 mb-3 glass">
                                    <div class="row d-flex align-items-center p-2">
                                        <div class="col-12 col-md-12 final text-start">
                                            <div class="row d-flex align-items-center">
                                                <div class="col-12 col-md-5">
                                                    <!-- <div class="progress" [attr.data-percentage]="redeemPerc"> <span class="progress-left">
                <span class="progress-bar"></span> </span> <span class="progress-right">
                <span class="progress-bar"></span> </span>
                                                        <div class="progress-value">
                                                            <div> {{redeemPerc}}% </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="progress-wrapper" *ngIf="redeemPerc || redeemPerc == 0">
                                                        <div class="current progress-value" [ngStyle]="getOverlayStyle()">{{redeemPerc}}%</div>
                                                        <round-progress
                                                          [current]="redeemPerc"
                                                          [max]="100"
                                                          [color]="gradient ? 'url(#gradient)' : color"
                                                          [radius]="radius"
                                                          [stroke]="stroke"
                                                          [duration]="duration"
                                                          [animation]="animation"
                                                          />
                                                      </div>
                                                </div>
                                                <div class="col-12 col-md-7 mt-3 mt-md-0">
                                                    <div class="boxes bg-light glass">
                                                        <input id="checkbox1" type="checkbox" checked>
                                                        <label for="checkbox1" id="pointsEarned"> <span  [innerHtml]="content?.pointsEarned?.content | safehtml"></span> {{panelistInfo?.Points}} {{panelistInfo?.PointText}}</label>
                                                        <input id="checkbox2" type="checkbox">
                                                        <label for="checkbox2" id="pointstobeEarned" [innerHtml]="content?.pointstobeEarned?.content | safehtml">Earn #PointsRequired# more {{panelistInfo?.PointText}} to redeem your voucher</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            <h4 class="fs-5 p-3 pb-0 mt-2" style="font-size: 16px;" id="redeem" [innerHtml]="content?.redeem?.content | safehtml">To redeem, you need #PointsRequired# {{panelistInfo?.PointText}} and must complete 1 consumer survey.</h4></div>
                                    </div>
                                </div>
                                <h4 class="fs-3 mb-3 mt-4 mx-4" id="startTakingSurvey" [innerHtml]="content?.startTakingSurvey?.content | safehtml">Sign in to your account and start taking surveys to earn more points.</h4>
                                <a [href]="loginLink.LoginLink">
                                    <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" id="loginNow" [innerHtml]="content?.loginNow?.content | safehtml">Login Now</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End -->
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="mymodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center py-4">
            <div class="modal-body">
                <div class="col-lg-10 mt-3 mt-lg-0 mx-auto">
                    <label for="fname" class=""><b>Verify Your Mobile Number</b></label>
                    <h6 class="mb-3">Please enter the otp received via SMS</h6>
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" /> </div>
            </div>
            <div class="modal-footer border-0 d-block pt-0">
                <button type="button" class="btn1 btn-outline-light rounded" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#mymodal4" style="padding: 3px 20px;margin-top: 7px;">Continue</button>
                <p id="time">Time remaining <span id="timer" style="color: green;"></span></p>
                <h6 id="resend">Didn't receive OTP?<a href="#" style="color: #0194a8;">Click here</a></h6> </div>
        </div>
    </div>
</div>
<div class="modal fade" id="mymodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center py-4">
            <div class="modal-body">
                <div class="col-lg-12 text-center">
                    <div class="svg1">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path1 circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path1 check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " /> </svg>
                    </div>
                    <h4 class="pt-4 fs-4 text-center">Mobile Number Verified Successfully</h4> </div>
            </div>
        </div>
    </div>
</div>
<!--OTP modal -->