import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent {
  content: any;
  contentOrder: string[] = [
    'privacyPolicyAbout',
    'personalInformation',
    'detailsTheyCollect',
    'personalInformationSources',
    'panelInformationUse',
    'sharePersonalInformation',
    'marketingCommunications',
    'dataretention',
    'dataUse',
    'deletingAccount',
    'dataTransfer',
    'privacyRights',
    'legalBasis',
    'californiaPrivacyRights',
    'informationSecurity',
    'externalLinks',
    'childrensPrivacy',
    'privacyNoticeChanges',
    'contactUs'
  ];
  private markeLoadedSubscription: any;
  constructor(private appSer: AppService, private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
     this.getPageContent();
    });
  }

  getPageContent() {
    let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    this.appSer.getPageContent(page).subscribe((res: any) => {
      this.content = res.data;
      this.appSer.setTitle(res.data.title);
    });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
}
