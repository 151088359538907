<section class="wf-section bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6 ps-3 ps-md-4 pe-3 pb-4 mt-3 h1textsize">
                <h1 class="text-center mb-4 lh-sm" [innerHtml]="content?.beginSurveyJourney?.content | safehtml" id="beginSurveyJourney"></h1>
                <h5 class="fs-4 text-center mt-2" [innerHtml]="content?.surveyJourneySubheader?.content | safehtml" id="surveyJourneySubheader"></h5></div>
            <div class="col-md-6 col-lg-6 text-center p-0"> <img loading="lazy" class="img-fluid" src="{{content?.fileDir}}/{{content?.beginSurveyJourneyImg?.content}}" alt="{{content?.beginSurveyJourneyImg?.alt}}" (click) = "appSer.openLink(content?.beginSurveyJourneyImg?.link)"> </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <!-- The image half -->
            <div class="col-md-6 order-1 order-md-1 bg-light p-0"><img loading="lazy" src="{{content?.fileDir}}/{{content?.simpleRegisterationProcessImg?.content}}" class="img-fluid" alt="{{content?.simpleRegisterationProcessImg?.alt}}" (click) = "appSer.openLink(content?.simpleRegisterationProcessImg?.link)"> </div>
            <!-- The content half -->
            <div class="col-md-6 order-2 order-md-2 d-md-flex">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 howitwork-text">
                        <div class="align-items-center">
                            <h3 class="headline mb-3" [innerHtml]="content?.registrationProcessHeader?.content | safehtml" id="registrationProcessHeader"></h3>
                            <h5 class="text-left mb-3 fs-5" style="text-align: justify;" [innerHtml]="content?.registrationProcessText?.content | safehtml" id="registrationProcessText"></h5> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row no-gutter d-flex align-items-center">
            <!-- The image half -->
            <div class="col-md-6 order-2 order-md-1 d-md-flex">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 howitwork-text">
                        <div class="align-items-center">
                            <h3 class="headline mb-3" [innerHtml]="content?.completeProfileHeader?.content | safehtml" id="completeProfileHeader"></h3>
                            <h5 class="text-left mb-3 fs-5" style="text-align: justify;" [innerHtml]="content?.completeProfileText?.content | safehtml" id="completeProfileText"></h5> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-1 order-md-2 bg-light p-0"><img loading="lazy" src="{{content?.fileDir}}/{{content?.completeProfileImg?.content}}" class="img-fluid" alt="{{content?.completeProfileImg?.alt}}" (click) = "appSer.openLink(content?.completeProfileImg?.link)"> </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 order-1 order-md-1 bg-light p-0"><img loading="lazy" src="{{content?.fileDir}}/{{content?.readyForSurvey?.content}}" class="img-fluid" alt="{{content?.readyForSurvey?.alt}}" (click) = "appSer.openLink(content?.readyForSurvey?.link)"> </div>
            <!-- The image half -->
            <div class="col-md-6 order-2 order-md-2 d-md-flex">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 howitwork-text">
                        <div class="align-items-center">
                            <h3 class="headline mb-3" [innerHtml]="content?.startTakingSurveysHeader?.content | safehtml" id="startTakingSurveysHeader"></h3>
                            <h5 class="text-left mb-3 fs-5" style="text-align: justify;" [innerHtml]="content?.startTakingSurveysText?.content | safehtml" id="startTakingSurveysText"></h5> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <!-- The image half -->
            <div class="col-md-6 order-2 order-md-1 d-md-flex">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 howitwork-text">
                        <div class="align-items-center">
                            <h3 class="headline mb-3" [innerHtml]="content?.pointsAndVouchersHeader?.content | safehtml" id="pointsAndVouchersHeader"></h3>
                            <h5 class="text-left mb-3 fs-5" style="text-align: justify;" [innerHtml]="content?.pointsAndVouchersText?.content | safehtml" id="pointsAndVouchersText"></h5> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-1 order-md-2 bg-light p-0"><img loading="lazy" src="{{content?.fileDir}}/{{content?.pointsNVoucher?.content}}" class="img-fluid" alt="{{content?.pointsNVoucher?.alt}}" (click) = "appSer.openLink(content?.pointsNVoucher?.link)"> </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-primary-dark">
    <div class="container text-center">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <h4 class="text-white lh-sm" [innerHtml]="content?.happySurveyingHeader?.content | safehtml" id="happySurveyingHeader">Happy Surveying, Happy Earning <br>with The Panel Station.
            </h4>
                <h5 class="fs-4 text-center mt-3 text-white" [innerHtml]="content?.joinUsText?.content | safehtml" id="joinUsText">If you haven’t joined us already, Join us for Free here.</h5>
                <a [routerLink]="appSer.getLink('register',country)">
                    <button type="button" class="btn btn-outline-light mt-4 rounded-pill" [innerHtml]="content?.joinUsButton?.content | safehtml" id="joinUsButton" (click)="handleJoinForFreeClick('Join Us')">Join Us</button>
                </a>
            </div>
        </div>
    </div>
</section>