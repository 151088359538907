import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.css']
})
export class HowitworksComponent {
  content: any;
  country = this.appSer.getLocalStorage('country');
  constructor(public appSer : AppService, private _activatedRoute: ActivatedRoute, private metaService:Meta, private renderer2:Renderer2){}

  ngOnInit(): void {
    this.appSer.markeLoaded.subscribe((data:string) => {
      let page = this.appSer.getlastRouteSegment(this._activatedRoute);
      this.appSer.getPageContent(page).subscribe( (res:any)=>{
        this.content = res.data;
        this.appSer.setTitle(res.data.title);
        this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService);
        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }
      });
    });
  }
  handleJoinForFreeClick(buttonName: string): void {
    // console.log(`handleJoinForFreeClick called for ${buttonName}`);
    // console.log('handleJoinForFreeClick called');
    
    // Log the user action directly in the same function
    const currentPage = this._activatedRoute.snapshot.url.map(segment => segment.path).join('/') || 'unknown';
    const message = `${currentPage}: user visited ${buttonName} button`;
    console.log('message',message);
    // Log user action with the constructed message
    this.appSer.loguser({ caid: null, langID: null }, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }
  
}
