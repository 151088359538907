import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { AuthService } from './auth.service';
import { LoaderService } from './loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private loadSer: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isAuthenticate()) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${this.authService.getToken()}` } });
    }


    const requiresLoader = request.url.includes("/saveProfiler") ||
                           request.url.includes("/saveRegDetails") ||
                           request.url.includes("/signinWithGoogle") ||
                           request.url.includes("/checkmail") ||
                           request.url.includes("/verifyOTP");

    if (requiresLoader) {
      this.loadSer.showLoader();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (requiresLoader) {
          this.loadSer.hideLoader();
        }
      }),
    );
  }
}
