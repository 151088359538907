<!-- <section [formGroup]="profileForm" id="profileForm">
    <div class="container p-lg-0">
        <div class="row w-100 d-flex align-items-center"> -->
            <!-- <div class="col-lg-7 bg-light p-lg-0 mobile-hide" style="height: 88vh;">
                <div class="row">
                    <div class="col-md-12 reg-video">
                        <video class="homepagevideo" autoplay src="assets/resources/images/Brand%20Video.webm" loop></video>
                    </div>
                    <div class="col-md-12 mt-3 text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div id="hiddenDiv1">
                                        <h5 class="fs-4">Turn your opinions into rewards! Choose from a range of amazing gift vouchers from top brands across the world!</h5>
                                        <div class="row mt-3">
                                            <div class="col-12 col-lg-1 col-md-1 col-xs-4 m-2"></div>
                                            <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill m-2 bg-primary brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/1.webp" alt="image"> </div>
                                            <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill  m-2 bg-primary brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/2.webp" alt="image"> </div>
                                            <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill  m-2 bg-primary brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/3.webp" alt="image"> </div>
                                            <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill  m-2 bg-primary brand-img"> <img class="img-fluid img-fluid-mob" src="assets/resources/images/partnerlogo/4.webp" alt="image"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <ng-container *ngIf="isVPN; else showContent">
                <div [innerHTML]="isVPN" ></div>
            </ng-container>
            
<ng-template #showContent>
    <ng-container *ngIf="showForm">

        <div class="text-end" *ngIf="isClose"><button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button></div>
        <form [formGroup]="profileForm" id="profileForm">
            <div #formDIV *ngIf="formDIVFlag && showForm">
                <div class="appoinment-form mt-1 ps-lg-4 pe-lg-4 p-2 rounded direction-rtl">
                    <div class="row py-1 text-center register-form">
                        <div class="col-12 col-md-12">
                            <h4 class="fs-2 mt-2 mb-md-3 mb-2 mt-md-5 mt-md-0 fw-bold" id="regFormHello" [innerHTML]="content?.regFormHello?.content"></h4>
                            <h4 class="fs-5 mt-2 mb-md-4 mb-2 lh-sm" id="regFormBannerText" [innerHTML]="content?.regFormBannerText?.content"></h4> </div>
                        <div class="col-md-8 mb-2 mx-auto">
                            <div class=" text-center">
                                <div class="form-group">
                                    <input type="checkbox" #terms id="terms" name="terms" formControlName="terms" style="width: 12px;height: 12px;"> <span style="font-size: 13px;color: #000;margin-left: 5px;"> <a [routerLink]="appSer.getLink('terms-and-conditions',country)" target="_blank" style="color: #0194a8;" id="regFormTnC" [innerHTML]="content?.regFormTnC?.content"></a> {{content?.and}} <a [routerLink]="appSer.getLink('privacy-policy',country)" target="_blank" style="color: #0194a8;" id="regFormPrivacyPolicy" [innerHTML]="content?.regFormPrivacyPolicy?.content"></a></span>
                                    <div *ngIf="isSignupClicked && !isTermsAccepted()" 
                                         class="ps-4 ps-md-5 alert alert-danger form-danger" role="alert" id="regFormErrorMsgTnc" > {{ (content?.['Please accept Terms and conditions']) ? (content?.['Please accept Terms and conditions']) : 'Please accept Terms and conditions'}}
                                    </div> <!-- [innerHTML]="content?.regFormErrorMsgTnc?.content" -->
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" (click)="signupWithEmail()" #button4 id="button4" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;margin-top: 7px;"  id="regFormSignUpWithEmail" [innerHTML]="content?.regFormSignUpWithEmail?.content"></button>
                            </div>
                        </div>
                        <div class="col-md-8 mx-auto">
                            <p style="text-transform: uppercase;">{{content?.or}}</p>
                        </div>
                        <div class="col-md-8 mb-1 mx-auto">
                            <div class="form-group">
                                <!-- <asl-google-signin-button type='standard' size='medium' shape="pill"></asl-google-signin-button> -->
                                <button (click)="handleGoogleLogin()" class="btn1 btn-outline-light rounded-pill w-100 badge" style="padding: 8px 0px;margin-top: 7px;" id="regFormSignUpWithGoogle" [innerHTML]="content?.regFormSignUpWithGoogle?.content"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="form" #form *ngIf="formFlag"> 
                <div action="#" method="POST" class="appoinment-form px-lg-5 p-2 rounded">
                    <div class="row text-center register-form">
                        <div class="col-12 col-md-12 mx-auto">
                            <h4 class="fs-2 mt-2 mb-md-4 mb-3 mt-md-0  fw-bold" id="regFormLetsGetStarted" [innerHTML]="content?.regFormLetsGetStarted?.content"></h4>
                         </div>
                        <div *ngIf="Message === 'paused'" class="alert alert-danger form-danger" role="alert">
                            Please provide valid Inputs
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="firstName" [formControlName]="'camp_'+defaultQues['fname']" type="text" class="form-control rounded" [placeholder]="placeholderInfo[defaultQues['fname']]" style="padding: 7px 10px;height: 35px;" value="" required (input)="checkSpace('camp_'+defaultQues['lname'])" >
                            </div>
                            <div *ngIf="profileFormControls['camp_'+defaultQues['fname']].invalid && (profileFormControls['camp_'+defaultQues['fname']].dirty || profileFormControls['camp_'+defaultQues['fname']].touched || isFormSubmitted)" class="alert alert-danger form-danger" role="alert">
                                <ng-container *ngIf="profileFormControls['camp_'+defaultQues['fname']].errors.required">
                                    {{ (content?.['First Name is required']) ? (content?.['First Name is required']) : 'First Name is required'}}
                                </ng-container>
                                <!-- <ng-container  *ngIf="profileFormControls['camp_'+defaultQues['fname']].errors?.firstNameValidator">
                                    No Special characters are allowed
                                </ng-container> -->
                                <ng-container  *ngIf="profileFormControls['camp_'+defaultQues['fname']].errors?.firstNameLength">
                                    {{ (content?.['First Name should be minimum of three characters']) ? (content?.['First Name should be minimum of three characters']) : 'First Name should be minimum of three characters'}}
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="lastName" [formControlName]="'camp_'+defaultQues['lname']" type="text" class="form-control rounded" [placeholder]="placeholderInfo[defaultQues['lname']]" style="padding: 7px 10px;height: 35px;" value="" required (input)="checkSpace('camp_'+defaultQues['lname'])">
                            </div>
                            <div *ngIf="profileFormControls['camp_'+defaultQues['lname']].invalid && (profileFormControls['camp_'+defaultQues['lname']].dirty || profileFormControls['camp_'+defaultQues['lname']].touched || isFormSubmitted)" class="alert alert-danger form-danger" role="alert">
                                <ng-container *ngIf="profileFormControls['camp_'+defaultQues['lname']].errors.required">
                                    {{ (content?.['Last Name is required']) ? (content?.['Last Name is required']) : 'Last Name is required'}}
                                </ng-container>
                                <!-- <ng-container  *ngIf="profileFormControls['camp_'+defaultQues['lname']].errors?.lastNameValidator">
                                    No Special characters are allowed
                                </ng-container> -->
                                <ng-container  *ngIf="profileFormControls['camp_'+defaultQues['lname']].errors?.lastNameLength">
                                    {{ (content?.['Last Name should be minimum of three characters']) ? (content?.['Last Name should be minimum of three characters']) : 'Last Name should be minimum of three characters'}}
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="email" [formControlName]="'camp_'+defaultQues['email']" type="email" class="form-control rounded" [placeholder]="placeholderInfo[defaultQues['email']]"style="padding: 7px 10px;height: 35px;" value="" required (input)="preventEmoji($event)"> 
                            </div>
                            <div *ngIf="profileFormControls['camp_'+defaultQues['email']].invalid && (profileFormControls['camp_'+defaultQues['email']].dirty || profileFormControls['camp_'+defaultQues['email']].touched || isFormSubmitted)" class="alert alert-danger form-danger" role="alert">
                                <div *ngIf="profileFormControls['camp_'+defaultQues['email']].errors.required" id="regFormErrorMsgEmail">
                                    {{ (content?.['Email is Required']) ? (content?.['Email is Required']) : 'Email is Required'}}
                                </div>
                                <div *ngIf="profileFormControls['camp_'+defaultQues['email']].errors.invalidEmail" id="regFormErrorMsgEmailValid">
                                    
                                    {{ (content?.['Please enter a valid email']) ? (content?.['Please enter a valid email']) : 'Please enter a valid email'}}
                                </div>   
                            </div>
                             
                        </div>
                       
                        <div class="col-md-6 mb-3  mx-auto password-container" >
                            <div class="form-group password-wrapper">
                                <!-- [ngClass]="(profileFormControls['camp_'+defaultQues['password']].invalid && (profileFormControls['camp_'+defaultQues['password']].dirty || profileFormControls['camp_'+defaultQues['password']].touched || isFormSubmitted))?'error-psw':(profileFormControls['camp_'+defaultQues['password']].valid)?'success-psw':''"  -->
                                <input id="password" name="password" [formControlName]="'camp_'+defaultQues['password']"  [type]="pwPrivacy ? 'password' : 'text'" class="form-control rounded" [placeholder]="placeholderInfo[defaultQues['password']]" style="padding: 7px 10px;height: 35px;" value="" required (focus)="onPasswordFocus()" 
                                (blur)="onPasswordBlur()" [ngClass]="applyValidationClasses()" (input)="preventEmoji($event)" (keydown)="restrictSpaces($event)" maxlength="40" /> 
                                <img loading="lazy" class="show-hide hidden-eye" *ngIf="!pwPrivacy" src="../../assets/resources/images/eye.png" (click)="passwordPrivacy('password',$event)">
                                <img loading="lazy" class="show-hide eye" *ngIf="pwPrivacy" src="../../assets/resources/images/hidden.png" (click)="passwordPrivacy('password',$event)">
                            </div>
                            <div>
                                <div class="tooltipp" *ngIf="showTooltip">
                                {{ (content?.['passwordError']) ? (content?.['passwordError']) : 'To keep a strong new password make sure to add 8-12 characters containing at least one numeric value, one upper case letter and one special character.'}}
                                </div>
                            </div>
                            <div *ngIf="profileFormControls['camp_'+defaultQues['password']].invalid && (profileFormControls['camp_'+defaultQues['password']].dirty || profileFormControls['camp_'+defaultQues['password']].touched || isFormSubmitted)"  class="alert alert-danger form-danger" role="alert">
                                
                                <ng-container *ngIf="profileFormControls['camp_'+defaultQues['password']].errors.required">
                                    {{ (content?.['Password is required']) ? (content?.['Password is required']) : 'Password is required'}}
                                </ng-container>
                                <ng-container *ngIf="profileFormControls['camp_'+defaultQues['password']].errors?.passwordStrength"  class="alert alert-danger form-danger" role="alert">
                                        {{ (content?.['passwordStrength']) ? (content?.['passwordStrength']) : 
                                        'Please set a password as per guidelines.' }}
                                </ng-container>
                                
                            </div>
                           
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group password-wrapper">
                                <!-- [ngClass]="{'error-psw': (profileForm.get('camp_'+defaultQues['confirm_password'])?.touched || profileForm.get('camp_'+defaultQues['confirm_password'])?.dirty) && (profileFormControls['camp_'+defaultQues['confirm_password']].invalid || profileForm.hasError('passwordNotMatch')),
                                    'success-psw': (profileForm.get('camp_'+defaultQues['confirm_password'])?.touched || profileForm.get('camp_'+defaultQues['confirm_password'])?.dirty) && profileFormControls['camp_'+defaultQues['confirm_password']].valid && !profileForm.hasError('passwordNotMatch')
                                  }" -->
                                <input name="confirm_password" [formControlName]="'camp_'+defaultQues['confirm_password']" [ngClass]="applyConfirmValidationClasses()" [type]="cpwPrivacy ? 'password' : 'text'" class="form-control rounded" [placeholder]="placeholderInfo[defaultQues['confirm_password']]" style="padding: 7px 10px;height: 35px;" value="" (focus)="onConfirmPasswordFocus()"
                                (blur)="onConfirmPasswordBlur()" (keydown)="restrictSpaces($event)" (input)="preventEmoji($event)" maxlength="40" required> 
                                <img loading="lazy" class="show-hide hidden-eye" *ngIf="!cpwPrivacy" src="../../assets/resources/images/eye.png" (click)="passwordPrivacy('cpassword',$event)">
                                <img loading="lazy" class="show-hide eye" *ngIf="cpwPrivacy" src="../../assets/resources/images/hidden.png" (click)="passwordPrivacy('cpassword',$event)">
                            </div>
                            <div *ngIf="profileFormControls['camp_'+defaultQues['confirm_password']].invalid && (profileFormControls['camp_'+defaultQues['confirm_password']].dirty || profileFormControls['camp_'+defaultQues['confirm_password']].touched || isFormSubmitted)" class="alert alert-danger form-danger" role="alert">
                                <ng-container *ngIf="profileFormControls['camp_'+defaultQues['confirm_password']].errors.required">
                                    {{ (content?.['Confirm Password is required']) ? (content?.['Confirm Password is required']) : 'Confirm Password is required'}}
                                </ng-container>
                            </div>
                            <div *ngIf="profileForm.errors?.['passwordNotMatch'] && (profileFormControls['camp_'+defaultQues['confirm_password']].dirty || profileFormControls['camp_'+defaultQues['confirm_password']].touched)" class="alert alert-danger form-danger" role="alert">
                                
                                {{ (content?.['passwordNotMatching']) ? (content?.['passwordNotMatching']) : 'Password  and  Confirm password are not matching'}}
                            </div>
                        </div>
                        
                        <!-- New OTP design start -->
                        <div class="col-md-12 mb-3  mx-auto" *ngIf="otpFlag">
                            <div class="text-center" id="otp" #otp [ngClass]="(otpFlag) ? '' : 'd-none'">
                                <input #otpNew (input)="onInputNew($event)" (focus)="isOtpInputFocused = true;otpMsg=''" 
                                (blur)="isOtpInputFocused = false" name="otp" pattern="[0-9]*" inputmode="numeric"
                                    formControlName="otp" class="otp-input m-md-2 form-control rounded"
                                    type="text" maxlength="4" style="height: 35px; margin: 0 !important;" [placeholder]="placeholderInfo[defaultQues['enter_OTP']] ? placeholderInfo[defaultQues['enter_OTP']] : 'Enter OTP'"/>
                                <div class="alert alert-success form-danger col-md-6 mb-3 mt-3 mx-auto text-center" style="text-align: center !important;color: green !important;" *ngIf="otpSuccess">{{otpSuccess}}</div>
                                <div class="alert alert-danger form-danger col-md-6 mb-3 mt-3 mx-auto text-center" style="text-align: center !important;" *ngIf="otpMsg">{{otpMsg}}</div>
                                <div class="otp-wrapper mt-3">
                                    <span>{{(content?.["Didn't get your OTP?"]) ? content?.["Didn't get your OTP?"]:"Didn't get your OTP?"}}</span>
                                    <ng-container *ngIf="!resendOTPFlag">
                                        <div><span id="regFormResentOTP" [innerHTML]="content?.regFormResentOTP?.content">Resend OTP </span> in  {{timeLeft}} S</div>
                                    </ng-container>
                                    <ng-container *ngIf="resendOTPFlag">
                                        <a (click)="resendOTP()" style="color: #0194a8;text-decoration: underline;" id="regFormResentOTP" [innerHTML]="content?.regFormResentOTP?.content">Resend OTP</a> 
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- New OTP design end -->
                       
                         <!-- extraQues from campaign-->
                        <ng-container *ngFor="let q of extraQues">
                            <div class="col-md-12 mb-3  mx-auto">
                                <div class="form-group">
                                    <ng-container  *ngIf="q.type=='input'; else elseBlock">
                                        <input [name]="q.name" [formControlName]="q.name" type="text" class="form-control rounded" [placeholder]="q.placeholder" style="padding: 7px 10px;height: 35px;" value="" > 
                                    </ng-container>
                                    <ng-template #elseBlock>
                                       <select [name]="q.name" [formControlName]="q.name" type="text" class="form-control rounded"  style="padding: 7px 10px;height: 35px;" value="">
                                            <option value="">{{q.placeholder}}</option>
                                            <option *ngFor="let o of q.options" [value]="o.ID">{{o.AnswerChoiceText}}</option>
                                        </select>
                                    </ng-template>
                                   
                                </div>
                                <div *ngIf="profileForm.get(q.name)?.invalid && (profileForm.get(q.name)?.dirty || profileForm.get(q.name)?.touched || isFormSubmitted)" class="alert alert-danger form-danger" role="alert">
                                    <div>
                                        {{q.placeholder}} is required
                                    </div>
                                </div>
                                
                            </div>
                        </ng-container>

                        <div class="col-lg-12 mx-auto">
                            <div class="row d-flex align-items-center">
                                <div class="col-md-12 mx-auto">
                                    <div class="form-group">
                                        <button *ngIf="!otpFlag" 
                                        [disabled]="(profileFormControls['camp_'+defaultQues['fname']].invalid || 
                                        profileFormControls['camp_'+defaultQues['lname']].invalid || 
                                        profileFormControls['camp_'+defaultQues['email']].invalid || 
                                        profileFormControls['camp_'+defaultQues['password']].invalid || 
                                        profileFormControls['camp_'+defaultQues['confirm_password']].invalid || 
                                        isFormSubmitted)" 
 
                                        type="submit" #button5 (click)="showNextForm()" id="button5" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;margin-top: 7px;">
                                            {{ (content?.['Next']) ? (content?.['Next']) : 'Next'}}
                                        </button>
                                        <button *ngIf="otpFlag" [disabled]="registrationSubmit" type="button" id="button7" #button7 (click)="submitProceed()" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;">{{(content?.['Submit and proceed']) ? content?.['Submit and proceed']:'Submit and proceed'}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Old otp - 6 digit -->
            <div class="text-center" id="otp" *ngIf="false" #otp [ngClass]="(otpFlag)?'':'d-none'">
                <h4 class="p-4 fs-3 pb-0 text-center mt-5 mt-md-0 fw-bold" id="regFormJustOneStep" [innerHTML]="content?.regFormJustOneStep?.content">Just one more step before we dive in!</h4>
                <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center" >
                    <span id="regFormOTP" [innerHTML]="content?.regFormOTP?.content"></span>
                    <span>{{profileForm.get('camp_'+defaultQues['email'])?.value}}.</span>
                </h5>
                <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center" id="regFormTPSMembership" [innerHTML]="content?.regFormTPSMembership?.content">To finish creating The Panel Station membership, <br>enter your OTP below and activate your account</h5>
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 0)" (input)="onInput($event, 0)" name="otp1" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp1" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 1)" (input)="onInput($event, 1)" name="otp2" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp2" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 2)" (input)="onInput($event, 2)" name="otp3" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp3" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 3)" (input)="onInput($event, 3)" name="otp4" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp4" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 4)" (input)="onInput($event, 4)" name="otp5" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp5" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <input (focus)="focus($event);" (keydown)="onKeyDown($event, 5)" (input)="onInput($event, 5)" name="otp6" pattern="[0-9]*" inputmode="numeric"
                    formControlName="otp6" #otpInputs class="otp-input m-md-2 text-center form-control rounded i-width inputs"
                    type="text" maxlength="1" />
                <br>
                <div class="alert alert-danger form-danger col-md-6 mb-3  mx-auto text-center" style="text-align: center !important;" *ngIf="otpMsg">{{otpMsg}}</div>
                <button type="button" id="button7" #button7 (click)="submitProceed()" class="btn btn-outline-light rounded-pill w-50 mt-4" style="padding: 7px 0px;margin-top: 7px;">{{(content?.['Submit and proceed']) ? content?.['Submit and proceed']:'Submit and proceed'}}</button>
                <ng-container *ngIf="!resendOTPFlag"><br> <div><span id="regFormResentOTP" [innerHTML]="content?.regFormResentOTP?.content">Resend OTP </span> in  {{timeLeft}} S</div></ng-container>
                <ng-container *ngIf="resendOTPFlag"><br> <a (click)="resendOTP()" class="mt-5" style="color: #0194a8;" id="regFormResentOTP" [innerHTML]="content?.regFormResentOTP?.content">Resend OTP</a> </ng-container>
            </div>
            <div id="thankyou" #thankyou *ngIf="thankyouFlag">
                <!-- <div class="container pt-4">
                    <h5 class="fs-3 my-3 text-center fw-bold" id="regFormFirstName">#FirstName#, your journey starts here. </h5>
                    <div class="row">
                        <div class="col-md-10 mx-auto py-2 glass">
                            <h5 class="fs-4 mt-3 text-center px-5 mb-3" id="regFormEarnPpoints" [innerHTML]="content?.regFormEarnPpoints?.content"> Dive into the survey and earn <span class="fw-bold">#Points# points</span>. </h5>
                            <h5 class="fs-6 text-center" id="regFormMarketThreshold" [innerHTML]="content?.regFormMarketThreshold?.content"> (#MarketThreshold# Points = #Minthreshold# #Voucher#) </h5>
                            <div class="mx-auto">
                                <div class="form-group">
                                    <a href="profiler.html">
                                        <button type="button" class="btn btn-outline-light rounded-pill w-75 mt-4 mb-4" style="padding: 7px 0px;margin-top: 7px;" id="regFormStartNow" [innerHTML]="content?.regFormStartNow?.content"></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </ng-container>
</ng-template>

            <!-- End -->
        <!-- </div>
    </div>
</section> -->
