import { Component, Inject } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-terminate',
  templateUrl: './terminate.component.html',
  styleUrls: ['./terminate.component.css']
})
export class TerminateComponent {

  message:any ;
  sorryText:any;
  environment =environment;
  loginTxt:any = "";
  PanalistStatus = "";
  content:any;
  private markeLoadedSubscription: any;
  constructor(private router: Router, private authSer:AuthService, private modalService:BsModalService, @Inject(DOCUMENT) private document: Document,public appSer : AppService) {
    if(this.router.getCurrentNavigation()?.extras){
      console.log(this.router.getCurrentNavigation()?.extras); 
      this.message = this.router.getCurrentNavigation()?.extras?.state?.['message'];
      this.sorryText = this.router.getCurrentNavigation()?.extras?.state?.['wearesorry'];
      this.PanalistStatus = this.router.getCurrentNavigation()?.extras?.state?.['PanalistStatus'];
      const sessionId = this.router.getCurrentNavigation()?.extras?.state?.['session']; // Get session ID here
      console.log('Session ID from state:', sessionId);
    }else{
      if( this.appSer.isPlatformBrowser()){
        this.message = window.history.state.message;
        this.sorryText = window.history.state.wearesorry;
        this.PanalistStatus = window.history.state.PanalistStatus;
      }
    }
    this.authSer.logout();
  }

  ngAfterViewInit(){
    this.closeAllModals();
    setTimeout( ()=>{
      if(this.document.getElementById("HeaderFormLogin")){
        this.loginTxt = this.document.getElementById("HeaderFormLogin")?.innerText;
      }else{
        this.loginTxt = "Login"
      }
      
    },4000) 
  }
 
  closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnInit(){
    // console.log(this.message);
    if( typeof this.message == 'undefined'){ //if this coponent is opened from mail
      this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
          this.appSer.getPageContent('register').subscribe( (res:any)=>{
          this.content = res.data;
          // console.log(this.content)
          this.PanalistStatus = "Intake";
          this.sorryText = (this.content['wearesorry'] )? this.content['wearesorry'] : "We are Sorry";
          this.message = (this.content['emailexiststext'] )? this.content['emailexiststext'] : "Your Email Id is already registered";
        });
      });
    }
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
  onLoginClick() {
    const formvals: any = {
      session: null  // Initialize to null
    };

    
    const sessionId = this.router.getCurrentNavigation()?.extras?.state?.['session'] || window.history.state.session;

    if (sessionId) {
      formvals.session = sessionId;  
    } else {
      formvals.session = null;  
    }

    console.log("Session during login click:", formvals.session);
    this.updateLoginClickStatus(formvals);
  }
  
  

  updateLoginClickStatus(formvals: any) {
      this.appSer.updateLoginStatus(formvals).subscribe({
          next: (response) => {
              console.log('Login click status updated', response);
          },
          error: (err) => {
              console.error('Error updating login click status:', err);
          }
      });
  }

 
}
