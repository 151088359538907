<!-- <header>
    <nav class="navbar navbar-expand-lg sticky-top" style="box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);">
        <div class="container-fluid">
            <a class="navbar-brand p-0" href="index.html"><img src="assets/resources/images/TPSLogo.svg" class="img-fluid logo"></a>
            <div class="MobileView">
                <a href="register.html">
                    <button type="button" class="btn btn-dark rounded-pill">Register</button>
                </a>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
                    <div class="hamburger-toggle">
                        <div class="hamburger"> <span></span> <span></span> <span></span> </div>
                    </div>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="navbar-content">
                <ul class="navbar-nav mr-auto mb-2 mb-lg-0 navtopactive">
                    <ng-container *ngFor="let navOption of navigationOptions">
                        <li class="nav-item"> <a class="nav-link darklink" aria-current="page" routerLink="{{navOption.path}}">{{navOption.label}}</a> </li>
                    </ng-container>
                    <li class="nav-item dropdown dropdown-mega position-static d-none"> <a class="nav-link dropdown-toggle darklink" data-bs-toggle="dropdown" data-bs-auto-close="true">TPS Hub &amp; Stories <i
                                class="fas fa-angle-right arrow-mob"></i> </a>
                        <div class="dropdown-menu shadow " id="mega-dropdown">
                            <div class="mega-content">
                                <div class="container-fluid">
                                    <div class="row py-4">
                                        <div class="col-12 col-sm-4 col-md-4 p-3 border-end border-2 px-4">
                                            <h4 class="fs-3 fw-bold">Hub &amp; Stories</h4>
                                            <p class="lh-base mt-4" style="font-size: 14px;">Lorem ipsum dolor sit amet consectetur. Congue id ante aliquam mattis sit a urna arcu. Aenean duis vivamus lorem ipsum lectus leo aenean. Vel leo lectus tincidunt purus. Aliquet mattis pharetra accumsan est ultricies volutpat cras in sit. Habitant accumsan dignissim sed id vulputate. </p>
                                            <a href="register.html">
                                                <button type="button" class="btn btn-lg btn-dark rounded-pill w-75 mt-4">Explore</button>
                                            </a>
                                        </div>
                                        <div class="col-12 col-sm-3 col-md-3 border-end border-2">
                                            <ul class="nav nav-pills" id="pills-tab" role="tablist" data-mouse="hover">
                                                <li class="nav-item"> <a class="nav-link active" id="pills-blog-tab" data-toggle="pill" href="#pills-blog" role="tab" aria-controls="pills-blog" aria-selected="true"><span
                                                            onclick="window.location='Hub-Stories/blogs.html'">Blogs</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-camp-tab" data-toggle="pill" href="#pills-camp" role="tab" aria-controls="pills-camp" aria-selected="false"><span
                                                            onclick="window.location='Hub-Stories/campaigns.html'">Campaigns</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-cust-tab" data-toggle="pill" href="#pills-cust" role="tab" aria-controls="pills-cust" aria-selected="false"><span
                                                            onclick="window.location='Hub-Stories/customer-stories.html'">Customer
                                                            Stories</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-insight-tab" data-toggle="pill" href="#pills-insight" role="tab" aria-controls="pills-insight" aria-selected="false"><span
                                                            onclick="window.location='Hub-Stories/insights.html'">Insights</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-video-tab" data-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" aria-selected="false"><span
                                                            onclick="window.location='Hub-Stories/videos.html'">Videos</span></a> </li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-sm-5 col-md-5 px-5">
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="pills-blog" role="tabpanel" aria-labelledby="pills-blog-tab">
                                                    <div class="card"> <img src="assets/resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-camp" role="tabpanel" aria-labelledby="pills-camp-tab">
                                                    <div class="card"> <img src="assets/resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-cust" role="tabpanel" aria-labelledby="pills-cust-tab">
                                                    <div class="card"> <img src="assets/resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-insight" role="tabpanel" aria-labelledby="pills-insight-tab">
                                                    <div class="card"> <img src="assets/resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                                                    <div class="card"> <img src="assets/resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <form class="formbox">
                    <a href="register.html">
                        <button type="button" class="btn btn-dark rounded-pill">Register</button>
                    </a>
                    <a href="Login.html">
                        <button type="button" class="btn1 btn-outline-dark  ms-2 rounded-pill">Login</button>
                    </a>
                </form>
            </div>
        </div>
    </nav>
</header> -->
<header>
    <nav class="navbar navbar-expand-lg sticky-top" style="box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);">
        <div class="container-fluid">
            <a *ngIf="navigationOptions?.[0]?.link !=null" class="navbar-brand p-0" [routerLink]="appSer.getLink(navigationOptions?.[0]?.link,country)" [queryParams]="qparams" aria-label="The Panel Station" (click)="toggleHandbergegr(('Home'));"><img id="TPSLogo" src="assets/resources/images/TPSLogo.svg" style="width: 180px;height: 60px;" class="img-fluid logo img-responsive" alt="The Panel Station"></a>
            <div class="MobileView">
                <!-- <a routerLink="/register">
                    <button type="button" class="btn btn-dark rounded-pill" id="registerButtonHeader">Register</button>
                </a> -->
                <ng-container *ngFor="let navOption of navigationOptions">
                    <a [routerLink]="appSer.getLink(navOption.link,country)" [queryParams]="qparams" *ngIf="navOption.link == 'register' && !requiredUrl">
                        <button  id="HeaderFormRegister" type="button" class="btn btn-dark rounded-pill">{{navOption.label}}</button>
                    </a>
                </ng-container>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="true" data-bs-target="#navbar-content" aria-expanded="false" (click)="toggleHandbergegr('register');">
                    <div class="hamburger-toggle">
                        <div class="hamburger"> <span></span> <span></span> <span></span> </div>
                    </div>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="navbar-content">
                <ul class="navbar-nav mr-auto mb-2 mb-lg-0 navtopactive">
                    <ng-container *ngFor="let navOption of navigationOptions">
                        <ng-container *ngIf="(navOption.name != 'Home' && navOption.link != 'register' && navOption.link != '') && navOption.mtype =='menu' && !requiredUrl">
                            <li class="nav-item" (click)="toggleHandbergegr(navOption.label)"> 
                                <a class="nav-link darklink" aria-current="page" [routerLink]="appSer.getLink(navOption.link,country)" routerLinkActive="activepage"  [queryParams]="qparams">{{navOption.label}}</a> 
                            </li>
                        </ng-container>
                    </ng-container>
                    <li id="tpsHubStoriesMenu" class="nav-item dropdown dropdown-mega position-static d-none"> <a class="nav-link dropdown-toggle darklink" data-bs-toggle="dropdown" data-bs-auto-close="true">TPS Hub &amp; Stories <i class="fas fa-angle-right arrow-mob"></i> </a>
                        <div class="dropdown-menu shadow " id="mega-dropdown">
                            <div class="mega-content">
                                <div class="container-fluid">
                                    <div class="row py-4">
                                        <div class="col-12 col-sm-4 col-md-4 p-3 border-end border-2 px-4">
                                            <h4 id="headerHubStories" class="fs-3 fw-bold">Hub & Stories</h4>
                                            <p id="headerHubStoriesDetails" class="lh-base mt-4" style="font-size: 14px;">Lorem ipsum dolor sit amet consectetur. Congue id ante aliquam mattis sit a urna arcu. Aenean duis vivamus lorem ipsum lectus leo aenean. Vel leo lectus tincidunt purus. Aliquet mattis pharetra accumsan est ultricies volutpat cras in sit. Habitant accumsan dignissim sed id vulputate.</p>
                                            <a  [routerLink]="appSer.getLink('register', country)">
                                                <button type="button" class="btn btn-lg btn-dark rounded-pill w-75 mt-4">Explore</button>
                                            </a>
                                        </div>
                                        <div class="col-12 col-sm-3 col-md-3 border-end border-2">
                                            <ul class="nav nav-pills" id="pills-tab" role="tablist" data-mouse="hover">
                                                <li class="nav-item"> <a class="nav-link active" id="pills-blog-tab" data-toggle="pill" href="#pills-blog" role="tab" aria-controls="pills-blog" aria-selected="true"><span onclick="window.location='Hub-Stories/blogs.html'" id="headernavPillsBlogs">Blogs</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-camp-tab" data-toggle="pill" href="#pills-camp" role="tab" aria-controls="pills-camp" aria-selected="false"><span onclick="window.location='Hub-Stories/campaigns.html'" id="headernavPillsCampaigns">Campaigns</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-cust-tab" data-toggle="pill" href="#pills-cust" role="tab" aria-controls="pills-cust" aria-selected="false"><span onclick="window.location='Hub-Stories/customer-stories.html'" id="headernavPillsCustomerStories">Customer Stories</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-insight-tab" data-toggle="pill" href="#pills-insight" role="tab" aria-controls="pills-insight" aria-selected="false"><span onclick="window.location='Hub-Stories/insights.html'" id="headernavPillsInsights">Insights</span></a> </li>
                                                <li class="nav-item"> <a class="nav-link" id="pills-video-tab" data-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" aria-selected="false"><span onclick="window.location='Hub-Stories/videos.html'" id="headernavPillsVideos">Videos</span></a> </li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-sm-5 col-md-5 px-5">
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="pills-blog" role="tabpanel" aria-labelledby="pills-blog-tab">
                                                    <div class="card"> <img loading="lazy" src="resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p id="headerCardText" class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-camp" role="tabpanel" aria-labelledby="pills-camp-tab">
                                                    <div class="card"> <img loading="lazy" src="resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p id="headerCardText1" class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-cust" role="tabpanel" aria-labelledby="pills-cust-tab">
                                                    <div class="card"> <img loading="lazy" src="resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p id="headerCardText2" class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-insight" role="tabpanel" aria-labelledby="pills-insight-tab">
                                                    <div class="card"> <img loading="lazy" src="resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p id="headerCardText3" class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                                                    <div class="card"> <img loading="lazy" src="resources/images/blog.webp" class="img-fluid" alt="image">
                                                        <div class="card-body">
                                                            <p id="headerCardText4" class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <form class="formbox">
                    <p class="mt-2 pe-3" *ngIf="requiredUrl" id="alreadyHaveAnAccount">{{this.alreadyHaveAnAccount}}</p>
                    <ng-container *ngFor="let navOption of navigationOptions">
                        <a  [routerLink]="appSer.getLink(navOption.link,country)" [queryParams]="qparams" *ngIf="navOption.link == 'register' && !requiredUrl" (click)="toggleHandbergegr('register');">
                            <button  id="HeaderFormRegister" type="button" class="btn btn-dark rounded-pill">{{navOption.label}}</button>
                        </a>
                        <a href="{{environment.loginUrl}}" *ngIf="navOption.name == 'Login'" (click)="toggleHandbergegr('login');">
                            <button id="HeaderFormLogin" type="button" class="btn1 btn-outline-dark  ms-2 rounded-pill">{{navOption.label}}</button>
                        </a>
                    </ng-container>
                    <div class="form-item ms-2 mt-2 border-start border-2 d-none">
                        <input id="country_selector" type="text" readonly> </div>
                </form>
            </div>
        </div>
    </nav>
</header>
