import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import {environment} from '../../environments/environment'
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RegformComponent } from '../regform/regform.component';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent {
  surveyForm!: FormGroup;
  isSubmitted!: boolean;
  questionsForm!: FormGroup;
  currentQuestion: number = 0;
  pollData: any;
  formGroupValid: boolean = true;
  pollSurveyComplete: boolean = false;
  environment =environment;
  // content: any;
  @Input() content:any;
  formProgress:any[]=[];
  bsModalRef?: BsModalRef;
  campaignData: any;
  constructor(private fb: FormBuilder,private appSer:AppService, private _activatedRoute: ActivatedRoute, private modalService: BsModalService){}
  ngOnInit(): void {
  
    this.questionsForm = this.fb.group({
      isPanelist: new FormControl('', Validators.required)
    });
    // this.appSer.saveSurveyDetails(this.questionsForm.value).subscribe( (res:any)=>{});

    this.appSer.getPolls().subscribe((res:any)=>{
      if (res.success) {
        this.pollData = res.data.response;
        const questionGroup = this.fb.group({});
        questionGroup.addControl(this.pollData[0]?.QuestionID.toString(), this.fb.control('', Validators.required));
      } else {
        console.warn("GetPolls api failed",res);
      }
    });
    this.appSer.getMarketCampaign().subscribe((res:any)=>{
      this.campaignData = res;
    });
    // let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    // this.appSer.getPageContent(page).subscribe( (res:any)=>{
    //   this.content = res.data;
    // });
  }
  nextQuestion(id?:any,index?:any): void {
    const questionGroup = this.fb.group({});
    if (index < this.pollData?.length) {
      questionGroup.addControl(this.pollData[index]?.QuestionID.toString(), this.fb.control('', Validators.required));
      this.questionsForm.addControl(this.pollData[index]?.QuestionID.toString(), questionGroup);
    }
    if(index != 0){
      this.formGroupValid = false;
      this.formGroupValid = this.validateForm(id);
    }
    if ((this.currentQuestion < this.pollData?.length+1) && this.formGroupValid) {
      this.currentQuestion++;
    }
  }


  // Validate the formgroup of each question form
  // If the formgroup is valid save the data
  validateForm(id?:any){
    let fg :string = id;
    const specificQuestionGroup = this.questionsForm?.get(''+fg+'') as FormGroup;
    let formValue:any = [];
    let currentPage= this.pollData[(this.currentQuestion-1)];
    formValue.push (
      {
        'QuestionId' : (id=='isPanelist')?'isPanelist':currentPage.QuestionID.toString(), 
        'QuestionTypeId' : (id=='isPanelist')?'isPanelist': currentPage.QuestionTypeID.toString(),
        'AnswerChoice' :  (id=='isPanelist')?specificQuestionGroup.value : specificQuestionGroup.controls[fg].value,
        'CampaignId': this.campaignData?.message?.toString()
      });
    if (specificQuestionGroup?.valid) {
      // this.formProgress.push(specificQuestionGroup);
        this.pollSurveyComplete = (id=='isPanelist');
      this.appSer.savePollsDetails(formValue).subscribe( (res:any)=>{});//to submit form details till current page
    }
    return specificQuestionGroup?.valid;
  }
  // onSubmit(): void {
  //   console.log('questionsForm onsubmit:- ',this.questionsForm);
    // if (this.questionsForm.valid) {
    //   this.pollSurveyComplete = true;
    //   console.log('Details',this.questionsForm);
    //   this.appSer.savePollsDetails(this.questionsForm.value).subscribe( (res:any)=>{});
    // }
  // }

  openModalWithComponent() {
    const initialState: ModalOptions = {
      initialState: {
        // SpinID: SpinID
        isClose:true
      },
      class:'modal-regform'
    };
    this.bsModalRef = this.modalService.show(RegformComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
