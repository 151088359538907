
<section>
    <div class="container pt-3 h1textsize">
        <div class="row text-center">
            <!-- <div class="col-lg-12"> <img id="peopleFirstBanner" src="assets/resources/images/People-First-Banner.webp" alt="About Us" class="w-75 about-us"></div> -->
            <div class="col-lg-12"> <img loading="lazy" id="peopleFirstBanner" src="{{content?.fileDir}}/{{content?.peopleFirstBanner?.content}}"  class="w-75 about-us" alt="{{content?.peopleFirstBanner?.alt}}" (click) = "appSer.openLink(content?.peopleFirstBanner?.link)" ></div>
            <div class="col-lg-12 bg-primary-dark rounded">
                <h1 class="mt-0 text-left pt-3 pb-4 text-white" [innerHtml]="content?.peopleFirstBrand?.content | safehtml" id="peopleFirstBrand">We are a people-first brand, helping voice take root.</h1></div>
        </div>
    </div>
</section>
<section class="section-sm">
    <div class="container">
        <div class="row d-flex align-items-center">
            <!-- The image half -->
            <div class="col-md-6 order-md-2 p-0">
                <section class="outter hero-video"> 
                    <section class="video-container-1">
                        <!-- <video id="ourMissionVideo" src="{{content?.fileDir}}/{{content?.ourMissionVideo?.content}}" [autoplay]="true" [muted]="true" [loop]="true" playsinline ></video> -->
                        <img loading="lazy" id="ourMissionVideo" src="{{content?.fileDir}}/{{content?.ourMissionVideo?.content}}" class="img-rsponsive img-fluid" alt="{{content?.ourMissionVideo?.alt}}"> 
                    </section>
                </section>
            </div>
            <div class="col-md-6 d-md-flex order-md-1">
                <div class="row">
                    <div class="col-12 col-lg-10 mx-auto aboutus-text">
                        <div class="align-items-center">
                            <h4 class="headline mb-3" [innerHtml]="content?.missionTitle?.content | safehtml" id="missionTitle"></h4>
                            <h5 class="text-left lh-sm" style="text-align: justify;font-size: 1.25rem;" [innerHtml]="content?.missionDetails?.content | safehtml" id="missionDetails"></h5>
                            <h5 class="text-left lh-sm fs-5 mt-3" [innerHtml]="content?.letVoiceBeHeard?.content | safehtml" id="letVoiceBeHeard"></h5>
                            <a [routerLink]="appSer.getLink('register',country)">
                                <button type="button" class="btn btn-outline-dark my-2 my-md-5 rounded-pill" [innerHtml]="content?.jtpsButton?.content | safehtml" id="jtpsButton" (click)="handleButtonClick('jointhepanelstation')"></button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- The content half -->
            
            <!-- End -->
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 mx-auto text-center ps-5 pe-5">
                <h4 class="text-center lh-sm" [innerHtml]="content?.honestAnswers?.content | safehtml" id="honestAnswers"></h4>
                <h4 class="text-center fs-4 mt-3 lh-sm" [innerHtml]="content?.dataSecurityText?.content | safehtml" id="dataSecurityText"></h4> 
            </div>
        </div>
        <div class="row text-center mt-4">
            <div class="col-lg-8 mx-auto align-itmes-center justify-content-center"> 
                <!-- <img id="EUGDPRCompliant" src="assets/resources/images/compliant.svg" alt="GDPR Compliant" class="w-25">  -->
                <img loading="lazy" id="EUGDPRCompliant" src="{{content?.fileDir}}/{{content?.EUGDPRCompliant?.content}}" class="w-25" alt="{{content?.EUGDPRCompliant?.alt}}" (click) = "appSer.openLink(content?.EUGDPRCompliant?.link)"> 
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section mb-md-5 mb-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
                <h4 class="lh-base fs-2 ps-3"></h4>
            </div>
            <div class="col-md-12 col-lg-12">
                <div class="row text-center">
                    <div class="col-lg-11 mx-auto text-center ps-5 pe-5">
                        <h4 class="text-center lh-sm" [innerHtml]="content?.userTrust?.content | safehtml" id="userTrust"></h4>
                    </div>
                    <div class="col-lg-8 mx-auto text-center ps-5 pe-5">
                        <h4 class="text-center fs-4 mt-3 mb-4 lh-sm" [innerHtml]="content?.trustedUsers?.content | safehtml" id="trustedUsers">{{content?.trustedUsers?.content}}</h4> 
                    </div>
                    <div class="col-12 col-lg-12 col-md-12 col-xs-4 rounded-pill mt-3"> 
                        <!-- <img id="aboutBanner" class="img-fluid img-fluid-mob w-75 about-us" src="assets/resources/images/about-banner.webp" alt="image">  -->
                        <img loading="lazy" id="aboutBanner" class="img-fluid img-fluid-mob w-75 about-us" src="{{content?.fileDir}}/{{content?.aboutBanner?.content}}" alt="{{content?.aboutBanner?.alt}}" (click) = "appSer.openLink(content?.aboutBanner?.link)"> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="wf-section bg-light">
    <div class="container-sc">
        <div class="section-sc" id="section1">
            <h2 class="text-center section1-h2 px-4" style="font-size: 100px;line-height: 1;" [innerHtml]="content?.decadeGrowth?.content | safehtml" id="decadeGrowth"></h2>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-01">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small">
                            <h4 class="fs-3 text-white" [innerHtml]="content?.timelineYear1?.content | safehtml" id="timelineYear1"></h4>
                        </div>
                        <div class="reason-text" [innerHtml]="content?.timelineYear1Reason?.content | safehtml" id="timelineYear1Reason"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-04">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small">
                            <h4 class="fs-3 text-white" [innerHtml]="content?.timelineYear2?.content | safehtml" id="timelineYear2"></h4>
                        </div>
                        <div class="reason-text" [innerHtml]="content?.timelineYear2Reason?.content | safehtml" id="timelineYear2Reason"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-03">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small">
                            <h4 class="fs-3 text-white" [innerHtml]="content?.timelineYear3?.content | safehtml" id="timelineYear3"></h4>
                        </div>
                        <div class="reason-text" [innerHtml]="content?.timelineYear3Reason?.content | safehtml" id="timelineYear3Reason"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-05">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small">
                            <h4 class="fs-3 text-white" [innerHtml]="content?.timelineYear4?.content | safehtml" id="timelineYear4"></h4>
                        </div>
                        <div class="reason-text" [innerHtml]="content?.timelineYear4Reason?.content | safehtml" id="timelineYear4Reason"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-primary-dark">
    <div class="container text-center">
        <div class="col-md-12 col-lg-12">
            <h4 class="text-white lh-sm ps-4 pe-4" [innerHtml]="content?.joinCommunityPitch?.content | safehtml" id="joinCommunityPitch"> </h4>
            <a [routerLink]="appSer.getLink('register',country)">
                <button type="button" class="btn btn-outline-light mt-5 rounded-pill" [innerHtml]="content?.signUpTodayButton?.content | safehtml" id="signUpTodayButton" (click)="handleButtonClick('signUpTodayButton')"></button>
            </a>
        </div>
    </div>
</section>