import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-profilerterminate',
  templateUrl: './profilerterminate.component.html',
  styleUrls: ['./profilerterminate.component.css']
})
export class ProfilerterminateComponent {
  message:any ;
  constructor(private router: Router, private authSer:AuthService,public appSer : AppService) {
    if(this.router.getCurrentNavigation()?.extras){
      //console.log(this.router.getCurrentNavigation()?.extras); 
      this.message = this.router.getCurrentNavigation()?.extras?.state?.['message'];
    }else{
      if( this.appSer.isPlatformBrowser()){
      this.message = window.history.state.message;
      }
    }
  }
}
