export const environment = {
  production: false,
  // host:'http://10.1.1.54:8004/api/',
  // host:'https://onetps-tpsphp.azurewebsites.net/admin/api/',
  host:'https://www.cms.staging-tps.brdrlsxs.in/admin/api/',// old cms portal
  blogHost: 'https://www.cms.thepanelstation.com/blog',
  login:"",
  recaptcha: {
    siteKey: '6LeLO44hAAAAAJ8bywRuDB2AKbUVRPhHM5-tf8zy',
  },
  loginUrl:'https://qa.thepanelstation.com/', //Login url to qa
  // loginUrl:'https://stg-stps.thepanellstation.net/', //Login url to qa
  pwQID:22274,
  cpwQID:22275
};