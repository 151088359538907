<section>
    <div class="container p-lg-0">
        <div class="row w-100 d-flex align-items-center">
            <div class="col-lg-7 bg-light p-lg-0 mobile-hide" style="height: 88vh;">
                <div class="row">
                    <div class="col-md-12 reg-video">
                        <!-- <video loading="lazy" class="homepagevideo" id="regBrandVideo" paused src="{{content?.fileDir}}/{{content?.regBrandVideo?.content}}" loop (mouseenter)="handleVideoControl($event)"
                        (mouseleave)="handleVideoControl($event)"></video> -->
                        <div class="div1">
                            <a (click)="openModal(youtube)" href=# data-bs-toggle="modal" data-bs-target="#mymodal6"> <img loading="lazy" src="assets/resources/images/brand-img.webp" alt="The Panel Station" class="img-fluid" /> </a>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 mt-3 text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div id="hiddenDiv1">
                                        <h5 class="fs-4" id="registrationBannerAdd" [innerHTML]="content?.registrationBannerAdd?.content"></h5>
                                        <div class="row mt-3"> -->
                                            <!-- <div class="col-12 col-lg-1 col-md-1 col-xs-4 m-2"></div> -->
                                            <!-- <ng-container *ngFor="let reward of rewards?.rewards;">
                                                <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill  m-2 bg-primary brand-img">
                                                    <img class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="image">
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-12 mt-3 text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div id="hiddenDiv1">
                                        <h5 class="fs-4" id="registrationBannerAdd" [innerHTML]="content?.registrationBannerAdd?.content">
                                            Turn your opinions into rewards! Choose from a range of amazing gift vouchers from top brands across the world!
                                        </h5>
                                        <div class="row mt-3">
                                            <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                                                <ng-container *ngIf="i < 4 ">
                                                    <div class="col-12 col-lg-3 col-md-2 col-xs-4 mb-4 brand-img">
                                                        <img loading="lazy" class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}">
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<div class="col-lg-5 col-10 mx-auto text-center" id="thankyou" #thankyou *ngIf="profilerInfo">
    <div class="container pt-4">
        <h5 class="fs-3 my-3 text-center fw-bold" >Yay! {{profilerInfo.FirstName}}, <span id="yourJourneyStartsHereThankYou" [innerHTML]="content?.yourJourneyStartsHereThankYou?.content">your journey starts here.</span> </h5>
        <div class="row">
            <div class="col-md-10 mx-auto py-2 glass">
                <h5 class="fs-4 mt-3 text-center px-5 mb-3" id="diveIntoTheSurveyThankYou" > 
                 <span [innerHTML]="content?.diveIntoTheSurveyThankYou?.content"></span>
                 <span class="fw-bold"> {{profilerInfo.Points}} {{profilerInfo.PointText}}</span>.
                 </h5>
                <!-- <h5 class="fs-6 text-center" id="pointsDetailsThankYou" [innerHTML]="content?.pointsDetailsThankYou?.content"> (#MarketThreshold# Points = #Minthreshold# #Voucher#) </h5> -->
                <h5 class="fs-6 text-center"> ({{profilerInfo.PanelistMarketThreshold}} {{profilerInfo.PointText}} = {{profilerInfo.TrendingVoucherMinThreshold}} {{profilerInfo.TrendingVoucher}}) </h5>
                <div class="mx-auto">
                    <div class="form-group">
                        <a routerLink="/{{profilerInfo.profilerLink}}" >
                            <button type="button" class="btn btn-outline-light rounded-pill w-75 mt-4 mb-4" style="padding: 7px 0px;margin-top: 7px;" id="startNowThankYou" [innerHTML]="content?.startNowThankYou?.content">Start Now</button>
                            <!-- <button type="button" (click)="cleverTapTrack()" class="btn btn-outline-light rounded-pill w-75 mt-4 mb-4" style="padding: 7px 0px;margin-top: 7px;" id="startNowThankYou" [innerHTML]="content?.startNowThankYou?.content">Start Now</button> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</section>

<ng-template #youtube>
       
    <div class="modal-body">
        <div id="video-container" class="video-container">
            <iframe
            width="100%" 
            height="400"
            [src]="sanitizedVideoUrl"
            frameborder="0"
            allowfullscreen
        ></iframe>
        </div>
    </div>
        
    <div class="modal-footer">
        <button (click)="modalRef?.hide()" id="close-video" type="button" class="button btn-default" data-bs-dismiss="modal"><span aria-hidden="true" class="fs-1 fw-bold" style="margin-left: -3px;">&times;</span> </button>
    </div>
      
</ng-template>
