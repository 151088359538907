<ng-container *ngIf="(content | json) != '{}'">
<section class="bg-primary-dark">
    <!-- languageList --{{languageList | json}} -->
    <div class="bg-colorloc location-set" #locationElement [ngClass]="((languageList && languageList !='undefined') && !closeLangSelection)?'':'d-none'">
        <div class="container" style="z-index: 10;">
            <div class="row">
                <div class="col-md-12 heightbox text-center">
                    <div class="text-center d-flex justify-center">
                        <p [innerHtml]="content?.preferredLangTxt?.content | safehtml">To see the content in your preferred language &nbsp;</p>
                        <div class="dropdown d-md-inline-flex" dropdown>
                            <span dropdownToggle id="drop-loc" aria-controls="drop-loc">
                                {{content?.['click here']}}
                            </span>
                            <button type="button" class="btn-close closeBtn" (click)="closeLangSelection = true"> X</button>
                            <ul class="dropdown-menu max-height" id="dropdown-basic" *dropdownMenu aria-labelledby="drop-loc">
                                <ng-container *ngFor="let lang of languageList">
                                    <li><a class="dropdown-item" (click)="selectLanguage(lang);">{{lang?.name}}</a></li>
                                    <!-- <li><a class="dropdown-item" (click)="setCountryURLLang(lang.id,lang.code);">{{lang?.name}}</a></li> -->
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container py-5 p-md-5 p-lg-5">
        <div>
            <div class="row mobile-hide">
                <div class="col-12 col-lg-8 mx-auto mb-3 text-left text-center">
                <!-- <img id="bannerTitleDesktop" src="{{content?.fileDir}}/{{content?.bannerTitleDesktop?.content}}" alt="{{content?.bannerTitleDesktop?.alt}}" class="img-fluid home-banner"> -->
                <img fetchpriority="high" id="bannerTitleDesktop"  src="{{content?.fileDir}}/{{content?.bannerTitleDesktop?.content}}" alt="{{content?.bannerTitleDesktop?.alt}}"  class="img-fluid home-banner" style="width: 776px;height: 383px;">
                </div>
                
            </div>

            <div class="row desktop-hide">
                <div class="col-12 col-md-8 col-lg-8 offset-md-2 mt-2 mb-3 text-left">
                    <!-- <img id="bannerTitleMobile" src="{{content?.fileDir}}/{{content?.bannerTitleMobile?.content}}" alt="{{content?.bannerTitleMobile?.alt}}" class="img-fluid"> -->
                    
                    <img fetchpriority="high" src="{{content?.fileDir}}/{{content?.bannerTitleMobile?.content}}" alt="{{content?.bannerTitleMobile?.alt}}" class="img-fluid">
                    </div>
            </div>
        </div>
        
    </div>

</section>
<section class="section wf-section pb-0 pb-md-5">
    <div class="container-sc">
        <div class="section-sc" id="section1">
            <h1 class="text-center section1-h2 px-3" style="font-size: 120px;line-height: 1.2;" id="scrollBannerText" [innerHtml]="content?.scrollBannerText?.content | safehtml"></h1>
        </div>
        <!-- Bellow is the trial code -->
        <!-- <ng-container *ngFor="let content of Object.keys(content); let i = index"> -->
        <!-- <ng-container *ngFor="let item of content | keyvalue;let i = index">
            <ng-container *ngIf="item.key.startsWith('reason')">
            
            Key: {{ item.key }}, Value: {{ item.value }}
            <div class="section-sc">
                <div class="reason-placement reason-0{{i+1}}">
                    <div class="reason">
                        <div class="reason-content">
                            <div class="reason-number body-small" id="reason{{i+1}}" [innerHtml]="content?.['reason' + (i + 1)]?.content | safehtml"></div>
                            <div class="reason-text" id="reason{{i+1}}detail" [innerHtml]="content?.['reason' + (i + 1)+'detail']?.content | safehtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        </ng-container> -->
        
        <div class="section-sc">
            <div class="reason-placement reason-01">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" id="reason1" [innerHtml]="content?.reason1?.content | safehtml"></div>
                        <div class="reason-text" id="reason1detail" [innerHtml]="content?.reason1detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-02">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" id="reason2" [innerHtml]="content?.reason2?.content | safehtml"></div>
                        <div class="reason-text" id="reason2detail" [innerHtml]="content?.reason2detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-03">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" id="reason3" [innerHtml]="content?.reason3?.content | safehtml"></div>
                        <div class="reason-text" id="reason3detail"  [innerHtml]="content?.reason3detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-04">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" aid="reason4"  [innerHtml]="content?.reason4?.content | safehtml"></div>
                        <div class="reason-text" id="reason4detail"  [innerHtml]="content?.reason4detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-05">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" id="reason5"  [innerHtml]="content?.reason5?.content | safehtml"></div>
                        <div class="reason-text" id="reason5detail"  [innerHtml]="content?.reason5detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-sc">
            <div class="reason-placement reason-06">
                <div class="reason">
                    <div class="reason-content">
                        <div class="reason-number body-small" id="reason6"  [innerHtml]="content?.reason6?.content | safehtml"></div>
                        <div class="reason-text" id="reason6detail"  [innerHtml]="content?.reason6detail?.content | safehtml"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-light">
    <div class="container">
        <div class="section-content">
            <div class="section-content-heading"> </div>
            <div class="section-content-text text-center">
                <h3 class="text-center" style="line-height: 1.1;" id="notBeingHeard"  [innerHtml]="content?.notBeingHeard?.content | safehtml"></h3>
            </div>
        </div>
    </div>
</section>

<section class="section-sm wf-section">
    <div class="container">
        <div class="section-content">
            <div class="section-content-heading"> </div>
            <div class="section-content-text text-center">
                <h3 class="text-center" id="panelStationComesIn" [innerHtml]="content?.panelStationComesIn?.content | safehtml"></h3>
                <h5 class="text-center fs-4 mt-3" id="talkRewards" [innerHtml]="content?.talkRewards?.content | safehtml"></h5>
                <a  [routerLink]="appSer.getLink('register',country)">
                    <button type="button" class="btn btn-outline-dark mt-4 rounded-pill" id="joinForFree" [innerHtml]="content?.joinForFree?.content | safehtml" (click)="handleJoinForFreeClick('Join for Free')"></button>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-light">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-5">
                <h4 class="text-left ps-lg-4 ms-md-4 lh-sm" id="answerWithEase" [innerHtml]="content?.answerWithEase?.content | safehtml"></h4>
            </div>
            <div class="col-md-6 col-lg-7 text-left">
                <!-- <video [autoplay]="true" [muted]="true" [loop]="true"  playsinline preload="metadata" id="answerWithEaseVideo" class="w-100" src="{{content?.fileDir}}/{{content?.answerWithEaseVideo?.content}}">
                    <source type="video/mp4">
                </video> -->
                <video *ngIf="content?.answerWithEaseVideo_thumbnails" poster="{{content?.fileDir}}/{{content?.answerWithEaseVideo_thumbnails.content}}" [autoplay]="true" [muted]="true" [loop]="true" playsinline = "true" class="w-100" [preload]="'none'">
                    <source *ngIf="content?.answerWithEaseVideo_webm?.content" src="{{content?.fileDir}}/{{content?.answerWithEaseVideo_webm?.content}}"  type="video/webm">
                    <source *ngIf="content?.answerWithEaseVideo?.content" src="{{content?.fileDir}}/{{content?.answerWithEaseVideo?.content}}" type="video/mp4">
                     Your browser does not support the video tag.
                </video>

            </div>
        </div>  
    </div>
</section>
<section class="section-sm wf-section">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6">
                <h4 class="text-md-left ms-md-4 ps-md-3 mb-4 mb-md-0" id="chooseYourRewards" [innerHtml]="content?.chooseYourRewards?.content | safehtml">
                    Feel valued more often, choose your rewards
                </h4>
            </div>
            <div class="col-md-6 col-lg-6 text-center">
                <div class="row text-center w-100 mx-auto">
                    <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                        <div class="col-6 col-lg-4 col-md-4 col-xs-4 p-1">
                            <img loading="lazy" id="rewardBrand{{i+1}}" class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}" style="box-shadow: 5px 5px 20px rgba(0,0,0, 0.15);border-radius: 12px;">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 text-center">
                <a [routerLink]="appSer.getLink('rewards',country)">
                    <button type="button" class="btn btn-outline-dark mt-3 mt-md-5 rounded-pill" id="howRewardsWork" [innerHtml]="content?.howRewardsWork?.content | safehtml" (click)="handleJoinForFreeClick('How Rewards Work')">
                        How Rewards Work
                    </button>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-light">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6 text-center">
                <!-- <video [autoplay]="true" [muted]="true" [loop]="true" playsinline preload="metadata" class="w-75" src="{{content?.fileDir}}/{{content?.voiceTakeRootVideo?.content}}">
                    <source id="voiceTakeRootVideo" type="video/mp4">
                </video> -->
                <video loading="lazy" *ngIf="content?.voiceTakeRootVideo_thumbnails" poster="{{content?.fileDir}}/{{content?.voiceTakeRootVideo_thumbnails.content}}" [autoplay]="true" [muted]="true" [loop]="true" playsinline = "true" class="w-75" [preload]="'none'">
                    <source *ngIf="content?.voiceTakeRootVideo_webm?.content" src="{{content?.fileDir}}/{{content?.voiceTakeRootVideo_webm?.content}}"  type="video/webm">
                    <source *ngIf="content?.voiceTakeRootVideo?.content" src="{{content?.fileDir}}/{{content?.voiceTakeRootVideo?.content}}" type="video/mp4">
                     Your browser does not support the video tag.
                </video>
            </div>
            <div class="col-md-6 col-lg-6 text-center">
                <h4 class="text-center" id="voiceTakeRoot"  [innerHtml]="content?.voiceTakeRoot?.content | safehtml"></h4>
                <h5 class="fs-4 mt-2 p-4 pt-2 pb-0" id="exploreProcessDetails"  [innerHtml]="content?.exploreProcessDetails?.content | safehtml"></h5>
                <a [routerLink]="appSer.getLink('how-it-works',country)">
                    <button type="button" class="btn btn-outline-dark mt-4 rounded-pill"
                        id="exploreProcessButton"  [innerHtml]="content?.exploreProcessButton?.content | safehtml" (click)="handleJoinForFreeClick('Explore Process')"></button>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-sm testimonial">
    <div class="container pe-lg-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-5 col-lg-5 pb-3 pb-md-0">
                <h4 id="storiesOfSatisfaction"  [innerHtml]="content?.storiesOfSatisfaction?.content | safehtml"></h4>
                <h5 class="fs-5 mt-4" id="storiesOfSatisfactionDetails"  [innerHtml]="content?.storiesOfSatisfactionDetails?.content | safehtml"></h5>
            </div>
            <div class="col-md-7 col-lg-7 ps-4 ps-md-0 pe-4 text-center pe-md-5">
                <app-testimonials *ngIf="includeTestmonials" ></app-testimonials>
                <!-- <div class="carousel-container">
                    <button class="slider-nav prev" id="prev">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left"
                            width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M15 6l-6 6l6 6"></path>
                        </svg>
                    </button>
                    <button class="slider-nav next" id="next">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right"
                            width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </button>
                    <div class="carousel-wrapper">
                        <div class="carousel">
                            <ng-container *ngFor="let testimonial of testimonials?.testmonials; let i = index;">
                                <div class="carousel-slide" tabindex="0" #testimonialElement>
                                    <div>
                                        <video class="homepagevideo"
                                            src="{{testimonials.storagePath}}/{{testimonial.filePath}}" height="380"
                                            width="auto" loop (mouseenter)="handleVideoControl($event)"
                                            (mouseleave)="handleVideoControl($event)"></video>
                                        <div class="slide-content">
                                            <h3 id="story{{i+1}}Name"  [innerHtml]="testimonial?.name | safehtml"></h3>
                                            <hr>
                                            <p id="story{{i+1}}Country"  [innerHtml]="testimonial?.subtitle | safehtml"></p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="carousel-scrollbar">
                        <div class="scrollbar-track">
                            <div class="scrollbar-thumb"></div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-primary-dark">
    <div class="container text-center">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <h4 class="text-white" id="communtiyTitle" [innerHtml]="content?.communtiyTitle?.content | safehtml"></h4>
                <h5 class="fs-4 text-center mt-3 text-white" id="communityDetails" [innerHtml]="content?.communityDetails?.content | safehtml"></h5>
            </div>
            <a  [routerLink]="appSer.getLink('register',country)">
                <button type="button" class="btn btn-outline-light mt-4 rounded-pill" id="communityJoinUs" [innerHtml]="content?.communityJoinUs?.content | safehtml" (click)="handleJoinForFreeClick('Join Us')"></button>
            </a>
        </div>
    </div>
</section>

</ng-container>