import { Component } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent {
  ngOnInit(){
    this.appSer.setTitle("Not Found");
  }
  constructor(  public appSer: AppService){}
}
