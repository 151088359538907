import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(html:any, element?: any) {
    // alert(this.sanitizer.bypassSecurityTrustHtml(html))
    // console.log( html);
    if (html === undefined || html === null ) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
