import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { tap, catchError } from 'rxjs';
import { AppService } from './app.service';
import { jwtDecode } from 'jwt-decode';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Auth Laravel Passport: Password Grant Client
   *
   * @private
   */
  private clientSecret: string = 'YOUR client secret token from oauth_clients table()' // <-- add enviroments
  private clientId: number = 2 // <-- id client from oauth_clients table() | add enviroments
  private isBrowser: boolean;

  constructor (private http: HttpClient, public appSer:AppService, @Inject(PLATFORM_ID) private platformId: Object ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  /**
   * Login in cabinet.
   *
   * @param email
   * @param password
   */
  login () {
    this.appSer.authenticateApp().subscribe ( (response:any)=>{
      // console.log(response);
      this.setToken(response.access_token)
      // this.setRefreshToken(refresh_token)
    });
  }

  getToken (): string {
    if (this.isBrowser) {
      return sessionStorage.getItem('token') ?? ''
    }

    return "";
  }

  getRefreshToken (): string {
    if (this.isBrowser) {

      return sessionStorage.getItem('token_refresh') ?? ''
    }
    return ""
  }

  setToken (token: string): void {
    if (this.isBrowser) {
      sessionStorage.setItem('token', token) // OR use 
    }
    let session:any = this.getTokenId()?.toString();
    if( this.appSer.isPlatformBrowser()){
      localStorage.setItem("session", session);
    }
  }

  protected setRefreshToken (refresh_token: string): void {
    // sessionStorage.setItem('token_refresh', refresh_token)
  }

  isAuthenticate (): boolean {
    if (this.isBrowser) {
      return !!sessionStorage.getItem('token')
    }
    return false
  }

  logout (): void {
    if (this.isBrowser) {

    sessionStorage.removeItem('token')
    }
    // sessionStorage.removeItem('token_refresh')
  }

  isValidToken(): boolean {
    let token = this.getToken();
    console.log('token', token)
    if(token ){
      if(this.tokenExpired(token)){
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  getTokenId(){
    let token = this.getToken();
    try {
      let decodedToken =  jwtDecode(token);
      return decodedToken['jti'];
    } catch(Error) {
      return null;
    }
  }
}