import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { FooterComponent } from './footer/footer.component';
import { MarketComponent } from './market/market.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegformComponent } from './regform/regform.component';
import { SafehtmlPipe } from './safehtml.pipe';
import { RewardzoneComponent } from './rewardzone/rewardzone.component';
import { PaidsurveysComponent } from './paidsurveys/paidsurveys.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TerminateComponent } from './terminate/terminate.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { SurveysComponent } from './surveys/surveys.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { CookiespolicyComponent } from './cookiespolicy/cookiespolicy.component';
import { RegistrationComponent } from './registration/registration.component';
import { TokenInterceptor } from './token.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { ProfilerComponent } from './profiler/profiler.component';
import { ProfilerterminateComponent } from './profilerterminate/profilerterminate.component';
import { VerifyPanelistComponent } from './verify-panelist/verify-panelist.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
// import { CookieService } from 'ngx-cookie-service';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { FilterOptionsPipe } from './filter-options.pipe';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LowerCaseUrlSerializer } from './urlserializer';
import { UrlSerializer } from '@angular/router';
// import { LoginredirectComponent } from './loginredirect/loginredirect.component';
// import { CarouselModule } from 'ngx-bootstrap/carousel';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    AboutComponent,
    NotfoundComponent,
    FooterComponent,
    MarketComponent,
    RegformComponent,
    SafehtmlPipe,
    RewardzoneComponent,
    PaidsurveysComponent,
    TerminateComponent,
    ThankyouComponent,
    HowitworksComponent,
    SurveysComponent,
    FaqsComponent,
    PrivacypolicyComponent,
    TermsofserviceComponent,
    CookiespolicyComponent,
    RegistrationComponent,
    LoaderComponent,
    ProfilerComponent,
    ProfilerterminateComponent,
    VerifyPanelistComponent,
    TestimonialsComponent,
    FilterOptionsPipe,
    // LoginredirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    FormsModule,
    // // NgbModule,
    // // GoogleLoginProvider,
    // GoogleSigninButtonModule,
    // SocialLoginModule,
    RoundProgressModule,
    SelectDropDownModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule
    // // CarouselModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, // Automatically sign in the user on application load if true
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('454939559607-g22f24e1ue9ai20rpkrpcv5q6mrgrcgd.apps.googleusercontent.com', {
              scopes: 'openid profile email',
              oneTapEnabled:false
            }) // Replace with your Google Client ID
          },
          
        ]
      } as SocialAuthServiceConfig,
    },
    // CookieService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
