import { Component, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cookiespolicy',
  templateUrl: './cookiespolicy.component.html',
  styleUrls: ['./cookiespolicy.component.css']
})
export class CookiespolicyComponent {
  content: any;
  markeLoadedSubscription:any;
  constructor(private appSer :AppService,private _activatedRoute: ActivatedRoute, private renderer:Renderer2, @Inject(DOCUMENT) private document: Document){}
  ngOnInit(): void {
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
      this.getPageContent();
      this.addCookieScript();
    });
  }

  getPageContent(){
    let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    this.appSer.getPageContent(page).subscribe( (res:any)=>{
      this.content = res.data;
      this.appSer.setTitle(res.data.title);
    });
  }

  addCookieScript(){
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src  = "assets/resources/js/cookiepolicy.js";
    this.renderer.appendChild(this.document.body, script);
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
 
}
