<!-- 
<div class="col-lg-5 col-10 mx-auto text-center" >
    <div class="container pt-4">
        <div class="row">
            <div class="col-md-10 mx-auto py-2 glass">
                <h5 class="fs-4 mt-3 text-center px-5 mb-3">{{message}}</h5>
            </div>
        </div>
    </div>
</div> -->
<section class="wf-section my-3">
    <div class="">
        <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-xs-4 rounded-pill"> <img  loading="lazy" class="img-fluid img-fluid-mob w-100" src="assets/resources/images/subscribe/terminate.svg" alt="image"> </div>
        </div>
    </div>
    <div class="container all-img ">
        <div class="row align-items-center w-100">
            <div class="col-md-6 col-lg-6">
                <h4 class="lh-base fs-2 ps-3"> </h4> </div>
            <div class="col-md-12 col-lg-12 ">
                <div class="row text-center">
                    <div class="col-lg-12 mx-auto text-center">
                        <h4 class="text-center lh-sm" [innerHTML]="sorryText | safehtml">We're Sorry!</h4>
                    </div>
                    <div class="col-lg-7 mx-auto text-center">
                        <h4 class="text-center px-4 mx-2 fs-5 mt-3 mb-4 lh-sm" [innerHTML]="message | safehtml"></h4>
                    </div>
                    <ng-container *ngIf="PanalistStatus  && PanalistStatus != 'Lead' && PanalistStatus != '' ">
                        <div class="col-lg-7 mx-auto text-center">
                            <a target="_blank" href="{{environment.loginUrl}}">
                                <button type="button" class="btn btn-dark w-25 ms-2 rounded-pill" (click)="onLoginClick()">{{loginTxt}}</button>
                            </a>
                        </div>
                    </ng-container>
                    <!-- <div class="col-lg-12 mx-auto text-center">
                        <button type="button" class="btn btn-dark w-25 ms-2 rounded-pill">Yes, I agree</button>
                        <button type="button" class="btn1 btn-outline-dark w-25  ms-2 rounded-pill">No, I don't agree</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
