import { Component, Input, Renderer2, AfterViewInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import Swiper from 'swiper';
import { DOCUMENT } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { AppService } from '../app.service';
register();

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements AfterViewInit, OnDestroy {
  // @Input() testimonailsData: any;
  private swiper: Swiper | undefined;
  public testimonailsData:any;
  constructor(
    private renderer2: Renderer2,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  ngOnInit():void{
    this.appService.getTestmonialsDetails().subscribe((res:any) => { // To get the testimonials details 
      this.testimonailsData = res.data;
      setTimeout(() => {
        this.initializeSwiper();
      }, 0);
    });
  }

  ngAfterViewInit(): void {
    // Ensure the view is fully rendered
    // this.cdr.detectChanges();
    
    // Delay Swiper initialization to allow for complete DOM rendering
    // setTimeout(() => {
      // this.initializeSwiper();
    // }, 0);
  }

  appendVideoControlScript(): void {
    const script = this.renderer2.createElement('script');
    script.src = '../../assets/resources/js/video-control.js';
    script.type = 'text/javascript';
    script.async = true;

    this.renderer2.appendChild(this.document.head, script); 
  }

  handleVideoControl(event: any) {
    let videoElement = event.target;
    if (event.type === 'mouseenter') {
      videoElement.play();
    } else if (event.type === 'mouseleave') {
      videoElement.pause();
    }
  }

  initializeSwiper() {
    if (this.appService.isPlatformBrowser() && typeof window !== 'undefined') {
      console.log("swiper initialized");
      this.swiper = new Swiper('.mySwiper', {
        spaceBetween: 20,
        slidesPerView: 1, // Ensure this is set correctly
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          580: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
          },
          1150: {
            slidesPerView: 3,
          },
        },
      });

      if (this.appService.isPlatformBrowser()) {
        this.appendVideoControlScript();
         // Update Swiper on window resize
          window.addEventListener('resize', () => {
            this.swiper?.update();
          });
      }
    }
  }

  ngOnDestroy() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }
}
