<section class="pb-0">
    <div class="container ps-md-0 p-lg-0">
        <div class="row no-gutter">
            <!-- The image half -->
            <div class="col-md-6 order-2 order-md-1 d-md-flex mt-4 mt-md-4">
                <div class="row">
                    <div class="col-lg-10 mx-auto ps-md-4 mt-md-4">
                        <div class="align-items-center h1textsize">
                            <h1 [innerHtml]="content?.takePaidSurvey?.content | safehtml" id="takePaidSurvey" class="headline mb-3"></h1>
                            <h5 [innerHtml]="content?.takePaidSurveyDetails1?.content | safehtml" id="takePaidSurveyDetails1" class="text-left mb-3 fs-5"></h5>
                            <h5 [innerHtml]="content?.takePaidSurveyDetails2?.content | safehtml" id="takePaidSurveyDetails2" class="text-left mb-3 fs-5"></h5>
                            <h5 [innerHtml]="content?.takePaidSurveyDetails3?.content | safehtml" id="takePaidSurveyDetails3" class="text-left mb-3 fs-5"></h5>
                            <div class="textbtn">
                                <a  [routerLink]="appSer.getLink('register',country)">
                                    <button type="button" class="btn btn-outline-dark mt-2 mb-4 rounded-pill" id="registerNSeeForYourself" [innerHtml]="content?.registerNSeeForYourself?.content | safehtml" (click)="handleButtonClick('register')"></button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- The content half -->
            <div class="col-md-6 order-1 order-md-2 bg-light p-0">
                <section class="outter hero-video">
                    <section id="takePaidSurveryVideo" class="video-container">
                        <div class="callout">
                            <h4 class="text-white" id="onlinePaidSurvey" [innerHtml]="content?.onlinePaidSurvey?.content | safehtml"></h4>
                            <h4 class="text-white mt-2"><span id="dynamicContent" class="fw-bold" style="background-color: rgba(1, 148, 168, 0.6);padding: 0px 10px 10px 10px;margin-top: 10px;border-radius: 5px;text-decoration: underline;" [innerHTML] = "dynamicStr | safehtml"></span> </h4>
                            <!-- <div class="desc">An optional description</div> --></div>
                    </section>
                </section>
            </div>
            <!-- End -->
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-light">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6 text-center">
                <video loading="lazy" [autoplay]="true" [muted]="true" [loop]="true" playsinline preload="metadata" class="w-100" id="missoutEmailVideo" src="{{content?.fileDir}}/{{content?.missoutEmailVideo?.content}}">
                    <!-- <source id="missoutEmailVideo" src="assets/resources/images/takesurvey/Take%20Survey.mp4" type="video/mp4">  -->
                </video>
            </div>
            <div class="col-md-6 col-lg-6 text-center">
                <h4 [innerHtml]="content?.missoutEmail?.content | safehtml" id="missoutEmail" class="text-center"></h4>
                <h5 [innerHtml]="content?.missoutEmailDetails?.content | safehtml" id="missoutEmailDetails" class="fs-4 mt-4 mt-md-2"></h5> 
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-primary-dark">
    <div class="container text-center">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <h4 [innerHtml]="content?.letEarningBegin?.content | safehtml" id="letEarningBegin" class="text-white lh-sm ps-lg-5 pe-lg-5"></h4> 
            </div>
            <div class="col-md-12 col-lg-12 mt-3 mt-md-5">
                <a href="{{content?.playStoreBadge?.link}}" target="_blank"><img id="playStoreBadge" src="{{content?.fileDir}}/{{content?.playStoreBadge?.content}}" alt="{{content?.playStoreBadge?.alt}}"  class="me-lg-3 mt-2" style="height: 50px;width: auto;"></a>
                <a href="{{content?.appStoreBadge?.link}}" target="_blank"><img id="appStoreBadge" src="{{content?.fileDir}}/{{content?.appStoreBadge?.content}}" alt="{{content?.appStoreBadge?.alt}}"  style="height: 50px;width: auto;" class="mt-2"></a>
            </div>
        </div>
    </div>
</section>