import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {environment} from '../../environments/environment'
// import * as bootstrap from 'bootstrap';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
declare var bootstrap: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
navigationOptions: any;
@Output() navigationOptionsChange = new EventEmitter<any[]>();
qparams = {};
environment =environment;
url: any;
requiredUrl: boolean=false;
@Input() country:any;
alreadyHaveAnAccount: any;
langSelection: any = '';
languageList: any = [];
langCode: any;
constructor(  public appSer: AppService, private actRoute:ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: Document,@Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(){
    
    this.appSer.markeLoaded.subscribe((data:string) => {
      // console.log('country----', this.country)
      this.getMenu();
    });
    
    // Bellow logic is to render the header colors depending on the scroll 
    let _this = this;
    this.document.addEventListener('scroll', () => {
      let header:any = _this.document.querySelector('header');
      // (window.scrollY > 0)? header.classList.add('scrolled'): header.classList.remove('scrolled');
      let value :any;
      if( this.appSer.isPlatformBrowser()){
        value = window.scrollY;
      }
      if (value > 0) {
        header?.classList?.add('scrolled')
        _this.document.querySelector('.navbar-brand img')?.setAttribute('src', 'assets/resources/images/TPSLogo_white.svg');
      } else {
        header?.classList?.remove('scrolled');
        _this.document.querySelector('.navbar-brand img')?.setAttribute('src', 'assets/resources/images/TPSLogo.svg');
      }
    });

    this.actRoute.queryParams.subscribe((params: any) => {
      (params['lang']) ? this.qparams = {lang : params['lang']} : "";
    });
  
    this.appSer.langChangedEvent.subscribe((data:string) => {
      // alert("lang change");
      setTimeout( ()=>{
        this.getMenu();
      },1000);
    });

    // To disable the menu options for register component 
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/register') ||this.router.url.includes('/thankyou')) {
          this.requiredUrl = true;
        } else {
          this.requiredUrl = false;
        }
      // }
    });
    let urlParams:any;
    if( this.appSer.isPlatformBrowser()){
      urlParams = new URLSearchParams(window.location.search);
    }
    (urlParams && urlParams.get('lang')) ? this.langSelection = urlParams.get('lang') : "";
    // To set the language selected URL as query params
    this.actRoute.queryParams.subscribe((params: any) => {
      (params['lang']) ? this.qparams = {lang : params['lang']} : "";
    });
    let lang:any;
    this.appSer.getLanguageList().subscribe( (res:any)=>{// To get the language details 
      this.languageList = Object.values(res.data);
      let urlLang = this.actRoute.snapshot.queryParams['lang'];
      if (urlLang) {
        lang = this.appSer.matchURLToCountryLang(urlLang,this.languageList);
      }
      if ( urlParams && urlParams.get('lang') ) {
        this.appSer.isArabic(lang.country_lang_id).subscribe((data: any) => {
          this.langSelection = data?.langID;
          if( data.isArabic == 1){ //apply arabic styles on refresh
            let dataP = {"data" : data}
            this.appSer.marketStyles(dataP);
          }
        });
      }

    });
    // this.actRoute.queryParams.subscribe((params) => {
    //   let a = this.appSer.matchURLToCountryLang(params['lang'],this.languageList);
    // });
    // TO check if the language selected is Arabic or non arabic on refresh
    // if ( urlParams && urlParams.get('lang') ) {
    //   this.appSer.isArabic(lc).subscribe((data: any) => {
    //     this.langSelection = data?.langID;
    //     if( data.isArabic == 1){ //apply arabic styles on refresh
    //       let dataP = {"data" : data}
    //   console.log("menu  1",data);
    //       this.appSer.marketStyles(dataP);
    //     }
    //   });
    // }
    if (isPlatformBrowser(this.platformId)) {
      import('bootstrap').then((bs) => {
        bootstrap = bs;
      });
    }
  }

  getMenu(){
    this.appSer.getMenu().subscribe( (res:any)=>{
      let sortedData = res.data.sort((a:any, b:any) => a.seq - b.seq);
      this.navigationOptions = sortedData;
      this.navigationOptionsChange.emit(sortedData);
      this.alreadyHaveAnAccount = this.appSer.getLocalStorage('Already have an account?');
    });
  }
  // To toggle the handbergegr menu in mobile view
  toggleHandbergegr(menuName: string): void {
    this.document.querySelector('.hamburger')?.classList.toggle('active');
    var collapseElementList = [].slice.call(this.document.querySelectorAll('.collapse'))
    if (isPlatformBrowser(this.platformId)) {
      var collapseList = collapseElementList.map(function (collapseEl) {
      return new bootstrap.Collapse(collapseEl)
    })
  }
  const caid = null;
  const langID = null;
  const message = `User visited the ${menuName} menu`;
  // console.log('menu clicked:', menuName);

  // Call the service to log user action
  this.appSer.loguser({ caid, langID }, message).subscribe({
    next: (data) => {
      console.log('User action logged successfully:', data);
    },
    error: (error) => {
      console.error('Error logging user action:', error);
    }
  });
  }
}
