import { Component, Renderer2 } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  content: any;
  // country = localStorage.getItem("country")ww;
  country = this.appSer.getLocalStorage("country");
  private markeLoadedSubscription: any;
  constructor(public appSer : AppService, private _activatedRoute: ActivatedRoute, private metaService:Meta, private renderer2:Renderer2){}

  ngOnInit(): void {

    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
      let page = this.appSer.getlastRouteSegment(this._activatedRoute);
      this.appSer.getPageContent(page).subscribe( (res:any)=>{
        this.content = res.data;
        this.appSer.setTitle(res.data.title);
        this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService);
        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }
      });
    });
  }

  handleButtonClick(buttonId: string): void {
    // Use ActivatedRoute to get the last part of the URL path
    const currentPage = this._activatedRoute.snapshot.url.map(segment => segment.path).join('/') || 'unknown';
    const message = `${currentPage}: user visited ${buttonId} button`;
    console.log('message',message);
    // Log user action with the constructed message
    this.appSer.loguser({ caid: null, langID: null }, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
}
