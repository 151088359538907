import { Component, ElementRef, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RegformComponent } from '../regform/regform.component';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-rewardzone',
  templateUrl: './rewardzone.component.html',
  styleUrls: ['./rewardzone.component.css']
})
export class RewardzoneComponent {
  bsModalRef?: BsModalRef;
  @ViewChild('claimreward', { static: false }) claimreward?: ElementRef;
  content: any;
  rewards:any;
  socialLinkDetails: any;
  environment =environment;
  country = this.appSer.getLocalStorage('country');
  constructor(private renderer: Renderer2, private modalService: BsModalService,public appSer : AppService, private _activatedRoute: ActivatedRoute, private metaService:Meta, @Inject(DOCUMENT) private document: Document, private renderer2:Renderer2) { }
  ngOnInit() {

    this.appSer.markeLoaded.subscribe((data:string) => {
    
      let page = this.appSer.getlastRouteSegment(this._activatedRoute);
      this.appSer.getPageContent(page).subscribe( (res:any)=>{
        this.content = res.data;
        this.appSer.setTitle(res.data.title);
        this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService);

        this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
          this.rewards = res.data;
        });

        this.appSer.getSocialLinks().subscribe( (res:any)=>{
          this.socialLinkDetails = res.data;
        });

        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }

      });
    });

    //spin
    this.getSpinInfo();
  }

  openModalWithComponent() {
    var SpinID ;
    if( this.document.getElementById("spinVal") ){
      let spinVal = this.document.getElementById("spinVal") as HTMLInputElement;
      SpinID = spinVal?.value;
      // console.log(spinVal?.value)
    }
    const initialState: ModalOptions = {
      initialState: {
        SpinID: SpinID,isClose:true
      }
    };
    this.bsModalRef = this.modalService.show(RegformComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }


  @HostListener('window:customEvent', ['$event'])
  onCustomEvent($event: Event) {
    const customEvent = $event as CustomEvent;
    const spinID = customEvent.detail;
    console.log('SpinID:', spinID);

    this.openModalWithComponent();
  }

  getSpinInfo(){
    this.appSer.getSpinInfo().subscribe( (response:any)=>{
      if( this.appSer.isPlatformBrowser()){
        localStorage.setItem("spin", JSON.stringify(response.data.response));
      }
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src  = "assets/resources/js/script.js";
      this.renderer.appendChild(this.document.body, script);
    });
  }
  handleButtonClick(buttonId: string): void {
    // Use ActivatedRoute to get the last part of the URL path
    const currentPage = this._activatedRoute.snapshot.url.map(segment => segment.path).join('/') || 'unknown';
    const message = `${currentPage}: user visited ${buttonId} button`;
    console.log('message',message);
    // Log user action with the constructed message
    this.appSer.loguser({ caid: null, langID: null }, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }


}
