import { ElementRef, EventEmitter, Inject, Injectable, Output, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppService {
  public globalMarket: string | null = null;
  private readonly _countryStateObj: BehaviorSubject<any> = new BehaviorSubject<any>('US');
  public countryStateObj: Observable<any> = this._countryStateObj.asObservable();
  @Output() langChangedEvent = new EventEmitter<string>();
  @Output() markeLoaded = new EventEmitter<string>();
  private languageSubject = new Subject<string>();
  selectedLanguage = this.languageSubject.asObservable();
  private state: any = {};
  private renderer: Renderer2;
  private markets = [
    'es-ar', 'pt-br', 'es-cl', 'zh-cn', 'es-co', 'es-ec', 
    'ar-eg', 'fr-fr', 'de-de', 'zh-hk', 'id-id', 'it-it', 
    'ar-kw', 'ms-my', 'es-mx', 'ar-ma', 'nl-nl', 'es-pe', 
    'tl-ph', 'pl-pl', 'ar-qa', 'ru-ru', 'ar-sa', 'ko-kr', 
    'es-es', 'zh-tw', 'th-th', 'tr-tr', 'ar-ae', 'vi-vn', 
    'en-au', 'en-gh', 'en-in', 'en-ke', 'en-nz', 'en-ng', 
    'en-sg', 'en-za', 'en-gb', 'en-us'
  ];
  private elementAddedSubject = new Subject<void>();
  elementAdded = this.elementAddedSubject.asObservable();
  constructor(
    private http:HttpClient, 
    private titleService:Title, 
    private meta:Meta, 
    private router: Router,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document, 
    @Inject(PLATFORM_ID) private platformId: Object) { 
      this.renderer = rendererFactory.createRenderer(null, null);

      this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.updateHreflangTags());
  }

  //Functions to make a GLOBAL VARIABLE for CURRENT MARKET CODE!
  setGlobalMarket(value: string | null) {
    this.globalMarket = value;
  }

  getGlobalMarket(): string | null {
    return this.globalMarket;
  }
  

  //Function to update hrefLang on Page Change
  private updateHreflangTags(): void {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    const urlWithoutCountry = segments.length > 2 ? '/' + segments.slice(2).join('/') : '/';

    // Remove existing hreflang tags
    this.removeExistingHreflangTags();
    
    const head = this._document.head;
    const firstChild = head.firstElementChild;

    // Create x-default hreflang tag without country code in URL
    let def = this.renderer.createElement('link');
    this.renderer.setAttribute(def, 'rel', 'alternate');
    this.renderer.setAttribute(def, 'hreflang', 'x-default');
    this.renderer.setAttribute(def, 'href', `https://www.thepanelstation.com${urlWithoutCountry}`);
    // this.renderer.insertBefore(head, def, firstChild);  
    this.renderer.appendChild(this._document.head, def);

    // Creating market-specific hreflang tags
    this.markets.forEach(market => {
      
      let segments = market.split('-');
      let country = segments[1];
      //console.log(`https://www.thepanelstation.com/${country}${urlWithoutCountry}`);
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'alternate');
      this.renderer.setAttribute(link, 'hreflang', market);
      this.renderer.setAttribute(link, 'href', `https://www.thepanelstation.com/${country}${urlWithoutCountry}`);
      // this.renderer.insertBefore(head, link, firstChild);  
      this.renderer.appendChild(this._document.head, link);
    });

    

    // Add canonical tag
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', `https://www.thepanelstation.com${currentUrl}`);
    // this.renderer.insertBefore(head, canonicalLink, firstChild);  
    this.renderer.appendChild(this._document.head, canonicalLink);
  }


  

  private removeExistingHreflangTags(): void {
    const head = this._document.head;
    const linkTags = head.getElementsByTagName('link');

    for (let i = linkTags.length - 1; i >= 0; i--) {
      const linkTag = linkTags[i];
      if (linkTag.getAttribute('rel') === 'alternate') {
        this.renderer.removeChild(head, linkTag);
      }
    }
  }

  get market(): any {
    return this._countryStateObj.value;
  }

  // sendEvent(formData: any,eventType: any, eventName: any, countryCode: any, payload: any){
  //   return this.http.post(environment.host +"clevarTap", {"formData": formData, "event": eventType, "eventName": eventName, "countryCode": countryCode, "eventData": payload}).pipe(map(data=>{
  //     return data;
  //   }));
  // }
  
  setdefaultmarket(){
    return 'us';
  }

  setmarket(_activatedRoute:any, market:any){
    _activatedRoute.paramMap.subscribe(({ params }: any) => {
      console.log("Params", _activatedRoute.snapshot.queryParams);
      // let market = '';
      if( params.market ){
        this._countryStateObj.next(params.market);
        // market = params.market;
      }else{
        this._countryStateObj.next( this.setdefaultmarket() );
        // market = this.setdefaultmarket();
      }
      
      if(!market){
        return;
      }
      this.getCountryDetails(market).subscribe( (res:any)=>{
        let  info:any =  Object.values(res.data)[0];
        console.log('MARKET DATA : ', res.data);
        console.log('MARKET INFO : ', Object.values(res.data)[0]);
        // localStorage.setItem("marketInfo", JSON.stringify(info) );
        this.addToLocalStorage("marketInfo", JSON.stringify(info));
        this.marketDetailsLoaded(); //just emitting thevent
        // this.marketStyles();
        let funParam = {data:info.defaultlang};
        // const urlParams = new URLSearchParams(window.location.search);
        const urlParams = _activatedRoute.snapshot.queryParams;
        if( !urlParams['lang'] && !this._document.location.pathname.includes('/profiler')){//if no lang url is there and not profiler
          this.marketStyles(funParam); 
        }
        
      })
    });

  }

  currentMarketCode() {
    const marketInfo = localStorage.getItem("marketInfo");
  
    if (marketInfo) {
      const marketData = JSON.parse(marketInfo);
      console.log("Market Data: ", marketData);
      const iso2 = marketData.iso2;
      console.log("ISO2: ", iso2);
    } else {
      console.log("No marketInfo found in localStorage.");
    }
  }
  

  getCountryDetails(market:any){
    // console.log(environment.host);
    return this.http.post(environment.host+"getCountryDetails", {market:market}).pipe(map(data=>{
      return data;
    }));
  }

  getCountryLangID(){

    // const urlParams = new URLSearchParams(window.location.search);
    const urlParams:any = new URLSearchParams(this._document.location.search);
    let country_lang_id = this.getLocalStorage('country_lang_id');
    if( country_lang_id){
      return country_lang_id;
    }else{
      // let info:any = localStorage.getItem("marketInfo");
      let info:any = this.getLocalStorage("marketInfo");
      info = JSON.parse(info);
      //defaultlang
      return info.defaultlang.id;
    }
   
  }

  getMenu(){
    return this.http.post(environment.host+"getMenu", {market:this.market, country_lang_id:this.getCountryLangID()}).pipe(map(data=>{
      return data;
    }));
  }

  getContent(menuID:any){
    return this.http.post(environment.host+"getContent", {menuID:menuID, country_lang:this.getCountryLangID()}).pipe(map(data=>{
      return data;
    }));
  }

  getlastRouteSegment (_activatedRoute:any){
    let urlSegments = _activatedRoute.snapshot.url;
    if (urlSegments.length > 0) {
      urlSegments = urlSegments[urlSegments.length - 1].path;
    }else{
      urlSegments = '';
    }
    return urlSegments;
  }
  
  getPageContent(page:string, countryLangID?:any){
    // console.log( "countryLangID,",countryLangID);
    let country_lang;
    if(countryLangID){
      country_lang = countryLangID;
    }else{
      country_lang = this.getCountryLangID()
    }
    return this.http.post(environment.host+"getContentByPage", {page:page, country_lang:country_lang}).pipe(map(data=>{
      return data;
    }));
  }

  showerror(msg:any){
    alert(msg)
  }
  
  getGeoDetails(){
    return this.http.get(environment.host+"getGeoDetails").pipe(map(data=>{
      return data;
    }));
  }

  getLanguageID(){
    // let info:any = localStorage.getItem("marketInfo");
    let info:any = this.getLocalStorage("marketInfo");
    info = JSON.parse(info);
    //defaultlang
    return info.defaultlang.langID;
  }

  getCountryCampaignDetails(langID:any){
    return this.http.post(environment.host+"getCountryCampaignDetails/"+langID,{}).pipe(map(data=>{
      return data;
    }));
  }
  getAwardsDetails(){
    return this.http.get(environment.host+"getRewards?country_lang="+this.getCountryLangID()).pipe(map(data=>{
      return data;
    }));
  }

  getTestmonialsDetails(){
    return this.http.get(environment.host+"getTestmonials?country_lang="+this.getCountryLangID()).pipe(map(data=>{
      return data;
    }));
  }

  getSocialLinks(){
    return this.http.get(environment.host+"getsociallinks?country_lang="+this.getCountryLangID()).pipe(map(data=>{
      return data;
    }));
  }

  getCountryList(){
    return this.http.get(environment.host+"getCountryList").pipe(map(data=>{
      return data;
    }));
  }

  saveSubscriptionDetails(subsccriberDetails:string){
    return this.http.post(environment.host+"saveSubscriptionDetails", {subsccriberDetails:subsccriberDetails, country_lang:this.getCountryLangID()}).pipe(map(data=>{
      return data;
    }));
  }

  register(formvals:any){
    return this.http.post(environment.host+"register",{formvals}).pipe(map(data=>{
      return data;
    }));
  }

  sendOTP(formvals:any){
    return this.http.post(environment.host+"sendOTP",{formvals}).pipe(map(data=>{
      return data;
    }));
  }

  verifyOTP(formvals:any){
    return this.http.post(environment.host+"verifyOTP",{formvals}).pipe(map(data=>{
      return data;
    }));
  }

  checkmail(formvals:any){
    return this.http.post(environment.host+"checkmail",{formvals}).pipe(map(data=>{
      return data;
    }));
  }
  getLanguageList(){
    return this.http.post(environment.host+"getFooterLanguages",'').pipe(map(data=>{
      return data;
    }));
  }

  authenticateApp(){
    let host = environment.host.replace("api/", "");
    // console.log(host);
    return this.http.post(host+"oauth/token",{grant_type:'client_credentials', client_id: 4, client_secret: 'zjRMlSZmPt4A6RUqjifNlcRcV0xMKs1nQsV01Rq8'}).pipe(map(data=>{
      return data;
    }));
  }

  getFooterlang(lid:any){
    return this.http.post(environment.host+"getFooterLang",{lid:lid}).pipe(map(data=>{
      return data;
    }));
  }

  langChanged(){
    this.langChangedEvent.emit();
  }

  getSpinInfo(){
    return this.http.post(environment.host+"getSpin",{}).pipe(map(data=>{
      return data;
    }));
  }
  getPolls(){
    return this.http.get(environment.host+"getPoll").pipe(map(data=>{
      return data;
    }));
  }
  savePollsDetails(pollDetails:any){
    return this.http.post(environment.host+"savePollsDetails", {pollDetails:pollDetails, country_lang:this.getCountryLangID()}).pipe(map(data=>{
      return data;
    }));
  }

  marketDetailsLoaded(){
    // setTimeout(() => {
      this.markeLoaded.emit();
    // }, 5000);
  
  }
  
  getProfilerQuestions(id:any){
    return this.http.get(environment.host+"getPriorityProfiler/"+id).pipe(map(data=>{
      return data;
    }));
  }
  submitPage(ID: any, formValue: any) {
    return this.http.post(environment.host+"savePollsDetails", {pageDetails:ID,formValue:formValue }).pipe(map(data=>{
      return data;
    }));
  }

  setTitle(title:any){
    // console.log('title', title)
    this.titleService.setTitle(title);
    // this.meta.removeTag("property='twitter:title'");
    // this.meta.removeTag("property='og:title'");
    // this.meta.addTag({ property: 'twitter:title', content: title });
    // this.meta.addTag({ property: 'og:title', content: title });
  }

  openLink(link:string){
    if(link){
      // console.log(link)
      // window.open(link, '_blank');
      const element = this._document.createElement('a');
      element.href = link;
      element.target = '_blank';
      element.click();
    }
  }

  saveRegDetails(formvals:any){
    return this.http.post(environment.host+"saveRegDetails",{formvals}).pipe(map(data=>{
      return data;
    }));
  }

  signinWithGoogle(user:any, formvals:any){
    return this.http.post(environment.host+"signinWithGoogle",{formvals:formvals, user:user}).pipe(map(data=>{
      return data;
    }));
  }

  loguser(formvals:any, action:string){
    // formvals['session'] = this.getLocalStorage('session');
    formvals['session'] = "null";
    if( this.isPlatformBrowser()){
      formvals['session'] = localStorage.getItem("session");
    }
    // console.log("Session:", formvals['session']);
    return this.http.post(environment.host+"log",{formvals,action}).pipe(map(data=>{
      return data;
    }));
  }

  addMetaTagsFromString(metaTagsString: string, meta_:any, isHome?:any, title?:any): void {
      this.removeMetaTags();
      if(isHome && isHome == 1){
        this.meta.addTag({ property: 'og:type', content: 'website' });
        this.meta.addTag({ property: 'og:image', content: 'https://www.thepanelstation.com/public/cms/20240630023456_20240626123602_TPSLogo.svg' });
        this.meta.addTag({ property: 'og:site_name', content: 'The Panel Station' });
        this.meta.addTag({ property: 'og:title', content: title });
        this.meta.updateTag({ property: 'og:description', content: metaTagsString });
        this.meta.addTag({ property: 'og:url', content: `https://www.thepanelstation.com${this.router.url}` });
        
        this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.addTag({ property: 'twitter:domain', content: 'thepanelstation' });
        this.meta.addTag({ name: 'twitter:image', content: 'https://www.thepanelstation.com/public/cms/20240630023456_20240626123602_TPSLogo.svg' });
        this.meta.updateTag({ property: 'twitter:description', content: metaTagsString });
        this.meta.addTag({ name: 'twitter:title', content: title });
        this.meta.addTag({ property: 'twitter:url', content: `https://www.thepanelstation.com${this.router.url}` }); 
        this.meta.addTag({name: 'description', content: metaTagsString})

      } else {
          this.removeMetaTags();
          this.meta.addTag({name: 'description', content: metaTagsString})
      }
  }

  removeMetaTags(){
    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:type'");
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:site_name'");
    
    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("property='twitter:domain'");
    this.meta.removeTag("property='twitter:url'");
    this.meta.removeTag("name='twitter:title'");
    this.meta.removeTag("name='twitter:description'");
    this.meta.removeTag("name='twitter:image'");
  }

  resendOTP(formvals:any){
    return this.http.post(environment.host+"resendOTP",{formvals}).pipe(map(data=>{
      return data;
    }));
  }

  getProfilerInfo(encryptedStr:any){
    return this.http.post(environment.host+"getProfilerInfo/"+encryptedStr,{}).pipe(map(data=>{
      return data;
    }));
  }

  saveProfiler(data:any, id:any, isSubmmitted:any){
    return this.http.post(environment.host+"saveProfiler",{data:data, id:id, isSubmitted:isSubmmitted}).pipe(map(data=>{
      return data;
    }));
  }
  getMarketCampaign(){
    return this.http.get(environment.host+"getMarketCampaign").pipe(map(data=>{
      return data;
    }));
  }

  getLink(routename:any, country:any){
    // console.log("routename", routename)
    let geo = (this.getLocalStorage('geo')) ? this.getLocalStorage('geo')?.toLowerCase() : "";
    country = (routename == 'register' && geo)? geo : country;
    // console.log("country", country)
    if( country.toLowerCase() == 'us' ){
      return "/"+routename;
    }else{
      return "/"+country+"/"+routename;
    }
  }

  isMarketExists(market:any){
    return this.http.get(environment.host+"isMarketExists/"+market).pipe(map(data=>{
      return data;
    }));
  }

  getLoginLink(id:any){
    return this.http.post(environment.host+"getLoginLink",{id:id}).pipe(map(data=>{
      return data;
    }));
  }

  //To set the styles depending on the arabic markets 
  // marketStyles() {
  //   // console.log(localStorage.getItem('marketInfo'))
  //   let info:any = localStorage.getItem("marketInfo");
  //   info = JSON.parse(info);
  //   // console.log(info.defaultlang)
  //   if ( info.defaultlang.isArabic == 1 ) {
  //     // alert("arabic")
  //     const linkElement = document.createElement('link');
  //     linkElement.rel = 'stylesheet';
  //     linkElement.href = '../assets/resources/css/style-arabic.css';
  //     document.head.appendChild(linkElement);
  //   }
  // }

  marketStyles(data: any) {
    // console.log(data)
    let arabicStyleId = 'arabic-style';
    let existingLinkElement = this._document.getElementById(arabicStyleId) as HTMLLinkElement;
    if (data?.data?.isArabic =='1') {
      if (!existingLinkElement) {
        let linkElement = this._document.createElement('link');
        linkElement.id = arabicStyleId; 
        linkElement.rel = 'stylesheet';
        linkElement.href = '../assets/resources/css/style-arabic.css';
        this._document.head.appendChild(linkElement);
      }
    } else {
      if (existingLinkElement) {
        this._document.head.removeChild(existingLinkElement);
      }
    }
  }
  
  // USING POST METHOD
  sendSubscribeOTP(email: string, country: string) {
    const url = `${environment.host}sendSubscribeOTP`;
    console.log('Request URL:', url, 'Email:', email, 'Country:', country);
  
    return this.http.post(url, { email, country }).pipe(
      tap(data => {
        console.log('Response data:', data);
      }),
      catchError(error => {
        console.error('Failed to send OTP', error);
        return throwError(() => error);
      })
    );
  }


  //USING GET METHOD
  // sendSubscribeOTP(email: string, country: string) {
  //   const url = `${environment.host}sendSubscribeOTP`;
  //   console.log('Request URL:', url, 'Email:', email, 'Country:', country);
  
  //   return this.http.get(url, { params: { email, country } }).pipe(
  //     tap(data => {
  //       console.log('Response data:', data);
  //     }),
  //     catchError(error => {
  //       console.error('Failed to send OTP', error);
  //       return throwError(() => error);
  //     })
  //   );
  // }
  

  

  verifySubscribeOTP(email: string, otp: string) {
    return this.http.post(environment.host + 'verifySubscribeOTP', { email, OTP: otp }).pipe(
      tap((response: any) => {
        console.log('OTP verification successful', response);
          }),
      catchError((error) => {
        console.error('Failed to verify OTP', error);
        return throwError(() => new Error('Failed to verify OTP'));
      })
    );
  }
  isArabic(lc:any) {
    return this.http.post(environment.host+'countryLangDetails', {lc:lc}).pipe(map(data=>{
      return data;
    }));
  }
  setLanguage(lang: string) {
    this.languageSubject.next(lang);
  }

  getCountryLangIDFromlangCulture(langCulture:any){
    return this.http.post(environment.host+'getCountryLangIDFromlangCulture', {lc:langCulture}).pipe(map(data=>{
      return data;
    }));
  }

  getPixelsCode(caid: number){
    return this.http.post(environment.host+'getpixel', {caid: caid}).pipe(map(data=>{
      return data;
    }))
  }

  getRDScore(session:any,caid:any){
    let rdPublishableKey = '17e35a70-ff61-44b2-a33f-c5757f587bf5';
    let rdSearchEndpoint = "https://prod.rtymgt.com/api/v2/respondents/search/" + rdPublishableKey + "?sn_ud=" +
    session + "&sy_nr=" + caid + "&coordinates=1&vpt=check";
    return this.http.get(rdSearchEndpoint).pipe(map(data=>{
      return data;
    }));
  }
  addCanonicalLink(render: any) {
    // let linkElement = document.querySelector('link[rel="canonical"]'); //docchange
    let linkElement = this._document.querySelector('link[rel="canonical"]');
    if (linkElement) {
      linkElement.parentNode?.removeChild(linkElement);
    }

    let link: HTMLLinkElement = render.createElement('link');
    link.setAttribute('rel', 'canonical');
    // let currentUrl = window.location.href;
    // let urlWithoutParams = currentUrl.split('?')[0];
    // let canonicalLink = (window.location.search)? (urlWithoutParams) : (window.location.href);
    // link.setAttribute('href', canonicalLink);
    // render.appendChild(this._document.head, link);
  }

  public addSchema(renderer2: Renderer2, data: any): void {
   
    //this.removeScriptById('schematag');
    // console.log("Checking for the Device ID : ", this.platformId);
    if (isPlatformServer(this.platformId)) {
      // console.log("Inside isPlatformServer with device : ", this.platformId);
      const existingScript = this._document.getElementById('schematag');
      
      if (existingScript) {
        // console.log('Schema tag already exists, skipping addition.');
        return;
      }
      // console.log("Starting Schema Script Addition with device : ", this.platformId);

      let script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `${data}`;
      this.renderer.setAttribute(script, 'id', 'schematag');
     
      const titleElement = this._document.querySelector('title');
      if (titleElement) {
        // console.log("Appending Script Before Head Tag with device : ", this.platformId);
        this.renderer.insertBefore(this._document.head, script, titleElement);
      }else{
        // console.log("Appending Script After Head Tag with device : ", this.platformId);
        this.renderer.appendChild(this._document.head, script);
      }
    }
  }

  // removeScriptById(id: string) {
  //   this._document.getElementById(id)?.remove();
  // }

  addHreflangLink(renderer: any, marketInfo: any) {
    // let linkElement = document.querySelector('link[rel="alternate"][hreflang]');
    let linkElement = this._document.querySelector('link[rel="alternate"][hreflang]');
    linkElement?.parentNode?.removeChild(linkElement);
    // let currentUrl = window.location.href.split('?')[0];//To remomve query params if any 
    // let alternateLinks = (window.location.search)? (currentUrl) : (window.location.href);
    let hreflangLink: HTMLLinkElement = renderer.createElement('link');
    hreflangLink.setAttribute('rel', 'alternate');
    hreflangLink.setAttribute('hreflang', marketInfo.lang[0].code);
    // hreflangLink.setAttribute('href', alternateLinks);
    renderer.appendChild(this._document.head, hreflangLink);
  }

  isPlatformBrowser(){
    return isPlatformBrowser(this.platformId);
  }

  addToLocalStorage(key:string, value:any){
    if( this.isPlatformBrowser() ){
     localStorage.setItem(key, value);
    }
    this.setState(key, value)
  }

  getLocalStorage(key:string) :any{
    if( this.isPlatformBrowser() ){
      // return localStorage.getItem(key);
    }
    // console.log(this.state)
    return this.getState(key)
  }

  setState(key: string, value: any): void {
    this.state[key] = value;
  }

  getState(key: string): any {
    return this.state[key];
  }

  clearState(key: string): void {
    delete this.state[key];
  }

  verifyPanelist(id:string){
    return this.http.get(environment.host+'verify/'+id).pipe(map(data=>{
      return data;
    }));
  }
  saveUrl(url: string): Observable<any> {
    return this.http.post(environment.host + 'save-url', { url }).pipe(map(data => {
        console.log('Saving URL:', url); // Debug line
        return data;
    }));
  }
  notifyElementAdded() {
    this.elementAddedSubject.next();
  }
  matchURLToCountryLang(langParam: any,languageList:any) {

    // console.log("MATCH URL TO COUNTRY EXECUTED!");
    // console.log("LangParam", langParam);
    // console.log("languageList", languageList);
    
    if (langParam) {
      // console.log("INSIDE IF LANGPARAM : " , langParam );
      let matchingLang = languageList?.find((lang: any) => lang.langCode === langParam);
      if (matchingLang) {
        // console.log("INSIDE IF matchingLang : " , matchingLang );
        let langCode = matchingLang.country_lang_id;
        this.setState('country_lang_id', langCode);
        // console.log("Matching Lang : ", matchingLang)
        // console.log("LANG CODE : ", langCode)
        return matchingLang;
      }
    }
  }
  
  VPNValidation(){
    return this.http.get(environment.host+'vpnValidation').pipe(map(data=>{
      console.log("vpn data", data);
      return data;
    }));
  }

  updateLoginStatus(formvals: any) {
    return this.http.post(environment.host + "update-login-status", formvals)
       .pipe(map(data => {
          return data;
       }));
  }
  getOtpConfiguration(countryCode: string): Observable<any> {
    return this.http
      .get(`${environment.host}otp-configuration?country_code=${countryCode}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  sendWhatsAppOtp(payload: { PhoneNumber: string}): Observable<any> {

    // Make the POST request to the backend'
    return this.http.post(environment.host + "send-whatsapp-otp", payload).pipe(
      map((response) => {
        console.log('OTP API Response:', response);
        return response;
      })
    );
  }
  sendOtp(data: any): Observable<any> {
    return this.http.post(environment.host + "send-otp", data).pipe(
        map((response: any) => response)
    );
  }
  verifyOtp(payload: { PhoneNumber: string; OTP: string }): Observable<any> {
    return this.http.post(environment.host + "verify-otp", payload).pipe(
        map(response => {
            console.log('OTP Verification Response:', response);
            return response;
        })
    );
  }
  verifySMSotp(data: any): Observable<any> {
    return this.http.post(environment.host + "verify-sms-otp", data).pipe(
        map((response: any) => response)
    );
  }
  checkMobileNumberExistence(data: any): Observable<any> {
    return this.http.post(environment.host + "verify-number", data).pipe(
        map((response: any) => response)
    );
  }
  assignMobileVerificationPoints(payload: any): Observable<any> {
    return this.http.post(environment.host + "assign-mobile-points", payload).pipe(
      map((response: any) => response)
  );
  }
  

 
}
