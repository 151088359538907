import { Component, Renderer2, Inject ,PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { filter, pairwise, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tpssite';
  subscription: any;
  navigationOptionsData: any[] = [];
  routeUpdated: any;
  countryDetails: any;
  arabicmarkets: any = ["eg", "kw", "ma", "qa", "sa", "ae"];
  isProfilerRoute: boolean = false;
  isRegisterRoute: boolean = false;
  marketLoaded: any = '';
  marketInfo: any;
  previousUrl: string | undefined;
  currentUrl: string | undefined;
  hasSavedUrl: boolean = false; // Flag to prevent multiple saves

  constructor(
    public appSer: AppService, 
    private _activatedRoute: ActivatedRoute,
    private router: Router, 
    private authSer: AuthService, 
    private rendered: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  

  ngOnInit() {
   
    // Track current and previous URLs
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
        tap(event => {
          if (event instanceof NavigationStart && this.appSer.isPlatformBrowser()) {
            // console.log("this.router.url", this.router);
            this.currentUrl = window.location.href;
          } else if (event instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
          }
        }),
        pairwise(),
        tap(([previous, current]) => {
          if (current instanceof NavigationEnd && current.urlAfterRedirects === '/not-found' && this.previousUrl && !this.hasSavedUrl) {
            this.saveUrl(this.previousUrl); // Save the URL that led to the not-found page
            this.hasSavedUrl = true; // Set flag to true after saving
          }
        })
      )
      .subscribe({
        error: (err) => console.error('Error tracking URL:', err)
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.marketLoaded = this.getCurrentRoute(this.router.routerState.root);
      // localStorage.setItem("country", this.marketLoaded)
        this.appSer.addToLocalStorage("country", this.marketLoaded);
      // let market = (routeLoaded?.snapshot.paramMap.get('market')) ? (routeLoaded?.snapshot.paramMap.get('market')) : this.appSer.setdefaultmarket();
      // console.log('marketLoaded',this.marketLoaded);
        this.appSer.setmarket(this._activatedRoute, this.marketLoaded);

        this.appSer.getGeoDetails().subscribe((res: any) => {
          this.countryDetails = res;
        // localStorage.setItem("geo", this.countryDetails.code)
          this.appSer.addToLocalStorage("geo", this.countryDetails.code);
          this.setCountryURL(res);
        });
      // this.marketInfo = localStorage.getItem('marketInfo');
      // this.marketInfo = this.appSer.getLocalStorage('marketInfo');
      // console.log("marketInfo :- ",this.marketInfo);

      // this.appSer.addCanonicalLink(this.rendered);
      // this.appSer.addHreflangLink(this.rendered,JSON.parse(this.marketInfo));
    });
    
    // this.appSer.getMenu().subscribe( (res)=>{
    //   console.log(res)
    // });
    // this.appSer.getCountryDetails().subscribe( (data)=>{
      // console.log(data)
    // });
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.appSer.isPlatformBrowser()) {
        window.scrollTo(0, 0); // Scroll to top
      }

      let url = this.router.url;
      if( url.includes('/register') || url.includes('/terminate') || url.includes('/thankyou')  || url.includes('/profiler') || url.includes('/admin')){
        this.isRegisterRoute = true;
      }else{
        this.isRegisterRoute = false;
      }
      
    });
   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { // Check for NavigationEnd event
        // (this.countryDetails != undefined) ? this.setCountryURL(this.countryDetails):'';
      }
    });

    // this.authSer.login();

    this.authSer.login();

    // this.marketStyles();
  }
  // private getCurrentRoute(route: any): ActivatedRoute | null {
    // if (route.firstChild) {
    //   console.log("has first child", route.firstChild)
    //   return this.getCurrentRoute(route.firstChild);
    // } else {
    //   return route;
    // }
  // }
  private getCurrentRoute(route: any) {
    if (route.firstChild && route.firstChild.snapshot.params['market']) {
      console.log('Yes!!!', route.firstChild.snapshot.params['market'])
      console.log("TPS-V1");
      this.appendChatbotScript(route.firstChild.snapshot.params['market']);
      return route.firstChild.snapshot.params['market'];
      
    } else {
      // this.appendChatbotScript('US');
      return this.appSer.setdefaultmarket();
    }
  }

  appendChatbotScript(market: string) {
    if (!isPlatformBrowser(this.platformId)) {
      console.log('Skipping on the server');
      return;
    }
  
    const scriptId = 'chatbot-script';
  
    const scripts = Array.from(document.getElementsByTagName('script'));
    scripts.forEach((script) => {
      if (script.id === scriptId || script.src.includes('https://xbotic.cbots.live/static/js/widget.js')) {
        script.remove();
        // console.log('Removed existing chatbot script:', script);
      }
    });
  
    const script = document.createElement('script');
    script.id = scriptId;
    script.innerHTML = `
      !function(e,t,a){
        var c=e.head||e.getElementsByTagName("head")[0],
            n=e.createElement("script");
        n.async=!0;
        n.defer=!0;
        n.type="text/javascript";
        n.src=t+"/static/js/widget.js?config="+JSON.stringify(a);
        c.appendChild(n);
      }(document,"https://xbotic.cbots.live",{bot_key:"1ee57cb7a58d4556",launch_flow:"E51943B6CE1942F6843BDD99DEC8328C",welcome_msg:true,"user_attributes":[{"name":"C_code","value":"${market.toUpperCase()}"}],branding_key:"xbotic",server:"https://xbotic.cbots.live",e:"p" });
    `;
  
    document.body.appendChild(script);
    // console.log('New chatbot script appended:', script);
  }
  

  updateNavigationOptions(options: any[]) {
    this.navigationOptionsData = options;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setCountryURL(res:any){
    let url = this.router.url;
    console.log('url', url)
    if (url != '/not-found' && url == "/" && res?.code?.toLowerCase() != 'us') {//if country is not us, redirect to repective country
      // if(url.startsWith('/'+res?.code?.toLowerCase())){
      //   url = url.slice(3); // Start slicing from the 3rd character (index 2)
      // };
      // this.routeUpdated = (url == "/") ? ('/'+ res?.code?.toLowerCase()) : ('/'+ res?.code?.toLowerCase() +url);
      // this.router.navigateByUrl(this.routeUpdated);
      let route: any = '/' + res.code?.toLowerCase();
      this.router.navigateByUrl(route);
    }
    this.isProfilerRoute =  (url.includes('/profiler') || url.includes('/not-found') || url.includes('/admin') )? true :false;
    // this.isRegisterRoute = (url.includes('/register') )? true :false;
    // if( url.includes('/register') || url.includes('/terminate') || url.includes('/thankyou')  || url.includes('/profiler')){
    //   this.isRegisterRoute = true;
    // }else{
    //   this.isRegisterRoute = false;
    // }
  }







  private saveUrl(url: string | undefined) {
    if (url && !this.hasSavedUrl) {
      this.appSer.saveUrl(url).subscribe({
        next: (response) => {
          console.log('URL logged successfully:', response);
          this.hasSavedUrl = true;
        },
        error: (err) => console.error('Error logging URL:', err)
      });
    }
  }
}
