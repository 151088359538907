import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../auth.service';
import { AppService } from '../app.service';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent {

  name:any;
  public content?:any = {'locSpec' : 'ss'};
  profilerInfo:any;
  rewards: any;
  country = this.appSer.getLocalStorage('country');
  modalRef?: BsModalRef;
  sanitizedVideoUrl!: SafeResourceUrl;
  // payload:any = {};
  constructor(private router: Router,public appSer: AppService, private _activatedRoute: ActivatedRoute, private modalService:BsModalService, private authSer:AuthService, private cookieService:CookieService, private sanitizer:DomSanitizer) {
   
  }

  closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnInit(): void {

    if(this.router.getCurrentNavigation()?.extras){
      console.log(this.router.getCurrentNavigation()?.extras); // should log out 'bar'
      this.profilerInfo = this.router.getCurrentNavigation()?.extras?.state?.['profilerInfo'];
    }else if(window.history.state.profilerInfo && this.appSer.isPlatformBrowser()){
      this.profilerInfo = window.history.state.profilerInfo;
    }else{
      this.getProfilerInfo();
    }
    if(this.profilerInfo){
      this.cookieService.set('isProfiler', this.profilerInfo.profilerLink , { expires: 1, sameSite: 'Lax' });
    }
    
    this.closeAllModals()
   
    this.appSer.markeLoaded.subscribe((data:string) => {
      // To load the content on page load
      this.appSer.getPageContent('register').subscribe( (res:any)=>{
        this.content = res.data;
        this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.youtubeURL.content);
      });

      this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
        this.rewards = res.data;
      });
    });

    this.authSer.logout();
  }

  handleVideoControl(event:any) { // to handle play and pause the video on hover 
    let videoElement = event.target;
    if (event.type === 'mouseenter') {
      videoElement?.play()
    } else if (event.type === 'mouseleave') {
      videoElement?.pause();
    }
  }

  getProfilerInfo(){
    let urlParams:any;
    if( this.appSer.isPlatformBrowser()){
      urlParams = new URLSearchParams(window.location.search);
    }
    if( urlParams.get('id')){
     this.appSer.getProfilerInfo(urlParams.get('id')).subscribe( (response:any)=>{
      this.profilerInfo = response.response;
      this.profilerInfo.profilerLink = urlParams.get('redirect');
      console.log('panelistInfo', this.profilerInfo);
    })
    }
  }
  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template,{ class: 'modal-lg youtube-video modal-dialog-centered' });
  }

  // cleverTapTrack(){
  //   console.log("this.profilerInfo : ", this.profilerInfo);
  //   const currentDate = new Date(); // Get the current date
  //   const userReadableDate: string = currentDate.toLocaleString();
  //   // this.payload["Campion ID"] = this.profilerInfo.CampaignID;
  //   this.payload["Product name"] =  "The Panel Station";
  //   this.payload["Email ID"] = this.profilerInfo.EmailID;
  //   this.payload["First Name"] = this.profilerInfo.FirstName;
  //   this.payload["Language Culture"] = this.profilerInfo.LanguageCulture;
  //   this.payload["Market"] = this.appSer.getLocalStorage("country");
  //   this.payload["Date of Joining"] = userReadableDate;
  //   if(this.appSer.getLocalStorage("country").toUpperCase() == "IN"){
  //     this.appSer.sendEvent(this.profilerInfo,"event","Profiler_Start_Now",this.appSer.getLocalStorage("country"),JSON.stringify(this.payload)).subscribe( (response:any)=>{
  //       console.log('Event Users clicked on Profiler "start now" button:', response)
  //     });
  //   }
  // }
}
