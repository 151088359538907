import { Component, Inject, Renderer2, PLATFORM_ID  } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { atLeastOneRadioSelectedValidator } from '../customValidators/customValidators';
import { CookieService } from 'ngx-cookie-service';
import { NgxDropdownConfig } from 'ngx-select-dropdown';
import { DOCUMENT, formatDate } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { log } from 'node:console';


@Component({
  selector: 'app-profiler',
  templateUrl: './profiler.component.html',
  styleUrls: ['./profiler.component.css']
})
export class ProfilerComponent {
  showErrorBorder: boolean = false;
  timerOn: boolean = false;
  currentStep = 1;
  widgetElements: any[] = [];
  profilerQuestionsForm!: FormGroup;
  typeOfQuestion:any;
  profilerDetails:any;
  presentPage:any;
  totalPage: any;
  profilerQuestions:  any = [];
  currentPage: number = 0;
  questionGroup!: FormGroup<{}>;
  formGroupSubmit: boolean = false;
  currentFormGroupValid: boolean = false;
  formSubmitSuccess: boolean=false; 
  formSubmitSuccess_disabled: boolean=false;
  otpVerified: boolean = false; 
  getOtpClicked: boolean = false;
  isInvalid: boolean = false;
  isConsentChecked: boolean = false;
  isOtpSent: boolean = false;
  mobileNumber: string = '';
  selectedOtpMode: string = '';
  otp: string = '';
  mobileBonusPoints: boolean = false;
  skipOtpVerification: boolean = false;
  timeLeft: number = 60; 
  resendOTPFlag: boolean = false; 
  interval: any; 
  claimYourVoucher: boolean =false;
  panelistInfo:any;
  mobileInfo:any;
  rewards:any;
  terminatedTxt:any;
  profilerCompleted: boolean = false;
  content:any;
  loginLink:any;
  lastAnsweredPage: any=[];
  redeemPerc:any ;
  DOB: any;
  current = 0;
  max = 100;
  stroke = 15;
  radius = 125;
  color = '#0194a8';
  duration = 800;
  animation = 'easeOutCubic';
  gradient = false;
  realCurrent = 0;
  onRefresh: boolean = false;
  searchTerms: { [key: string]: FormControl } = {};
  dropdownSelected: { [key: string]: FormControl } = {};
  searchControl = new FormControl('');
  selectedOption: any;
  filteredOptions: any = [];
  isOpen: boolean=false;
  selectedValue:any;
  selectedDropdown: any = {};
  panelistId:any;
  CampaignID:any;
  sampleid:any;
  panelistEmail:any;
  storedPixelCodeScreened: string | null = null;
  otherSelected: { [key: string]: boolean } = {};
  questionControl: any;
  showWhatsApp: boolean = false;
  showSms: boolean = false;
  countryCode: string = '';
  selectedOtpModes: string = '';
  countryCodes: string = '';
  errorMessage: boolean = false;
  otperrorMessage: boolean = false;
  otpexpiredMessage: boolean = false;
  limitexpiredMessage: boolean = false;
  succesMessage: boolean = false;
  isLoading: boolean = false;
  isFormDisabled = false;
  otpTimeout: any;
  // payload:any = {};
  MobileNumberMaxLength: number | null = null;
  showError: boolean = false;
  mobileVerified : boolean = false;
  showProfilerDiv : boolean = true;
  showMobileVerification : boolean = false;
  isAgeRestricted : any;
  otpConfiguration : boolean = true;
  private markeLoadedSubscription: any;

  constructor(
  
    private fb: FormBuilder,private appSer:AppService, 
    private route:ActivatedRoute, 
    private router:Router, 
    private renderer: Renderer2, 
    private cookieService:CookieService, 
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ){}

  ngOnDestroy() {
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
  ngOnInit(): void {

    this.profilerQuestionsForm = new FormGroup({});
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
    
      this.getProfilerInfo();
      this.cookieService.set('isProfiler', this.router.url , { expires: 1, sameSite: 'Lax' });
      this.appendClarityScript();
      this.appendGtmScript();
      //API Call to Fetch OTP Configuration
      const countryCode = window.location.href.split('/')[3];
      if (countryCode) {
          this.appSer.getOtpConfiguration(countryCode).subscribe(
              (response) => {
                  // console.log("OTP CONFIG RES : ", response);
                  if (response && response.response && response.response.length > 0) {
                    this.mobileInfo = response.response[0];
                      const primaryChannel = response.response[0]?.PrimaryChannel;
                      const secondaryChannel = response.response[0]?.SecondaryChannel;

                      this.showWhatsApp = primaryChannel === 'WhatsApp' || secondaryChannel === 'WhatsApp';
                      this.showSms = primaryChannel === 'SMS' || secondaryChannel === 'SMS';
                      this.selectedOtpModes = primaryChannel?.toLowerCase() || null;
                      // console.log('showWhatsApp:', this.showWhatsApp);
                      // console.log('showSms:', this.showSms);

                  } else {
                      console.warn('No OTP configuration found for the provided country code.');
                      this.showWhatsApp = false;
                      this.showSms = false;
                      this.otpConfiguration = false;
                      console.log("OTP Configured : ", this.otpConfiguration);
                  }
              },
              (error) => {
                  console.error('Failed to fetch OTP configuration', error);
                  this.showWhatsApp = false;
                  this.showSms = false;
              }
          );
      } else {
          console.error('Country code is not provided.');
      }

    });
  }

skipOtp(): void {
  this.getLoginLink();
  this.getRewards();
  this.skipOtpVerification = true;
  this.showMobileVerification = false;
  console.log("skipOtpVerification : ", this.skipOtpVerification);
  console.log("otpVerified : ", this.otpVerified);
  console.log("showMobileVerification : ", this.showMobileVerification);
  //UPDATE URL PARAM to THANKYOU
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  params.set('s', 'Screened-thankyou');
  const newUrl = `${currentUrl.pathname}?${params.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
}  

openOtp(): void {
  this.getRewards();
  // this.getLoginLink();
  this.mobileBonusPoints = true;
}

onKeyDown(event: KeyboardEvent): void {
  // Allow navigation keys and clipboard shortcuts
  const allowedKeys = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Enter',
  ];
  
  const isCtrlOrCmd = event.ctrlKey || event.metaKey;
  const isClipboardShortcut = isCtrlOrCmd && (event.key === 'v' || event.key === 'c' || event.key === 'x');

  if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key) && !isClipboardShortcut) {
    event.preventDefault();
  }
}


// Method to handle input
onPhoneNumberInput(): void {
  // Allow only numeric input by replacing non-numeric characters
  this.mobileNumber = this.mobileNumber.replace(/[^0-9]/g, '');

  // this.isInvalid = !(mobileNumber.length > this.mobileInfo.MobileNumberMaxLength || mobileNumber.length < this.mobileInfo.MobileNumberMinLength  && /^[0-9]+$/.test(this.mobileNumber));
  if (!this.mobileNumber) {
    this.errorMessage = false;
  }
  const length = this.mobileNumber.length;
    if (length > 0 && length < this.mobileInfo.MobileNumberMinLength) {
      this.showError = true; // Show error if input is less than max length
    } else if (length === this.mobileInfo.MobileNumberMinLength) {
      this.showError = false; // Hide error when max length is reached
    }
}
onOtpInput(): void {
  this.otp = this.otp.replace(/[^0-9]/g, '');
  if (!this.otp) {
    this.otperrorMessage = false;
  }
}

removeSymbol(event: Event): void {
  const inputElement = event.target as HTMLTextAreaElement;
  let value = inputElement.value;

  value = value.replace(/[^a-zA-Z\s]/g, '');

  if (value.startsWith(' ')) {
      value = value.trimStart();
  }

  value = value.replace(/\s{2,}/g, ' ');

  inputElement.value = value;
}


validatePhoneNumber(): void {
    if (this.mobileNumber?.length >= this.mobileInfo.MobileNumberMinLength && this.mobileNumber?.length <= this.mobileInfo.MobileNumberMaxLength  ) {
      this.isLoading = true; 
      console.log('Phone number:', this.mobileNumber);
  
      // Construct the payload for the mobile number existence check
      const formattedCountryCode = this.mobileInfo?.ISDCode || '';
      const encryptedUrl = window.location.href.split('/profiler/')[1]?.split('?')[0];
      const payload = {
        PhoneNumber: this.mobileNumber,
        EncryptedUrl: encryptedUrl,
        IsWhatsapp: this.selectedOtpModes === 'whatsapp',
      };
  
      // Call API to check if the mobile number exists
      this.appSer.checkMobileNumberExistence(payload).subscribe({
        next: (response: any) => {
          console.log('Response from checkMobileNumberExistence:', response);
          
          
  
          // Only proceed with OTP sending if Status is true
          if (response.Status === true) {
            if (this.selectedOtpModes === 'whatsapp') {
              const otpPayload = {
                PhoneNumber: `${formattedCountryCode}${this.mobileNumber}`,
                EncryptedUrl: encryptedUrl,
              };
              this.appSer.sendWhatsAppOtp(otpPayload).subscribe({
                next: (otpResponse: any) => {
                  console.log('WhatsApp OTP sent successfully:', otpResponse);
                  console.log(otpResponse.message);
                  if (otpResponse === "OTP sent successfully."){
                  this.isOtpSent = true;
                  console.log("isOtpSent" , this.isOtpSent);
                  this.startTimer();
                  //this.getOtpClicked = true;
                  this.isLoading = false; 
                  this.errorMessage = false;
                  this.isFormDisabled = true;
                  this.showsuccessMessage('succesMessage');
                  }else {
                    this.isLoading = false; 
                    this.showErrorMessage('limitexpiredMessage');
                  }
                },
                error: (otpError: any) => {
                  console.error('Failed to send WhatsApp OTP:', otpError);
                  alert('Failed to send WhatsApp OTP. Please try again.');
                  this.isLoading = false; 
                },
              });
            } else if (this.selectedOtpModes === 'sms') {
              const otpPayload = {
                MobileNumber: this.mobileNumber,
                ResendOTP: false, // Default for "Get OTP"
                EncryptedUrl: encryptedUrl,
              };
              this.appSer.sendOtp(otpPayload).subscribe({
                next: (otpResponse: any) => {
                  console.log('SMS OTP sent successfully:', otpResponse);
                  if (otpResponse.Status === true) {
                  this.isOtpSent = true;
                  console.log("isOtpSent" , this.isOtpSent)
                  //this.getOtpClicked = true;
                  this.startTimer();
                  this.isLoading = false; 
                  this.errorMessage = false;
                  this.isFormDisabled = true;
                  this.showsuccessMessage('succesMessage');
                  }else if(otpResponse.Message === 'You have reached the maximum limit of OTP attempts. Please try to generate the OTP after 24 hours'){
                    this.showErrorMessage('limitexpiredMessage');
                    this.isLoading = false; 
                  }else{
                    this.showErrorMessage('errorMessage');
                    this.isLoading = false; 
                  }
                },
                error: (otpError: any) => {
                  console.error('Failed to send SMS OTP:', otpError);
                  alert('Failed to send SMS OTP. Please try again.');
                  this.isLoading = false; 
                },
              });
            }
          } else {
            // Handle cases where Status is false
            //alert(response.Message || 'Mobile number is not eligible for OTP.');
            this.showErrorMessage('errorMessage');
            this.isLoading = false; 
          }
        },
        error: (error: any) => {
          console.error('Error checking mobile number existence:', error);
          alert('Failed to check mobile number existence. Please try again.');
        },
      });
    } else {
      alert('Please enter a valid 10-digit mobile number.');
    }
  

  console.log('validatePhoneNumber triggered');
  // this.getOtpClicked = true;

  // if (!this.selectedOtpModes) {
  //   return;
  // }

  // if (this.isConsentChecked && !this.isInvalid) {
  //   this.isOtpSent = true;
  // } else {
  //   this.isOtpSent = false;
  // }
}



validateOtp(): void {
 // this.getOtpClicked = true;
  this.isLoading = true; 
  if (this.otp.length !== 4) {
    return;
  }

  if (this.selectedOtpModes === 'whatsapp') {
    const formattedCountryCode = this.mobileInfo?.ISDCode || '';
    const encryptedUrl = window.location.href.split('/profiler/')[1]?.split('?')[0];
    const payload = {
      PhoneNumber: `${formattedCountryCode}${this.mobileNumber}`,
      OTP: this.otp,
      EncryptedUrl: encryptedUrl,
    };
    console.log('Payload sent to backend:', payload);

    this.appSer.verifyOtp(payload).subscribe(
      (response: any) => {
        console.log('Response:', response);

        // Check for the "Login Successfully" response
        if (response === "Mobile Number Saved Successfully") {
          this.isLoading = false; 
          this.otpVerified = true;
          this.showMobileVerification = false;
          console.log('OTP Verified Successfully:', response);
          this.MobileVerificationPoints();
          // Update URL parameters
          const currentUrl = new URL(window.location.href);
          const params = new URLSearchParams(currentUrl.search);
          params.set('s', 'Screened-thankyou');
          const newUrl = `${currentUrl.pathname}?${params.toString()}`;
          window.history.pushState({ path: newUrl }, '', newUrl);
        } else if(response === "OTP Expired"){
          console.error('Unexpected response:', response);
          this.isLoading = false; 
          // this.otperrorMessage = true;
          this.showErrorMessage('otpexpiredMessage');
        }else{
          console.error('Unexpected response:', response);
          this.isLoading = false; 
          // this.otperrorMessage = true;
          this.showErrorMessage('otperrorMessage');

        }
      },
      (error) => {
        console.error('OTP Verification Failed:', error);
      }
    );

  } else if (this.selectedOtpModes === 'sms') {
    const encryptedUrl = window.location.href.split('/profiler/')[1]?.split('?')[0];
    const payload = {
      PhoneNumber: this.mobileNumber,
      EncryptedUrl: encryptedUrl,
      OTP: this.otp,
    };
    console.log('Payload sent to backend:', payload);

    this.appSer.verifySMSotp(payload).subscribe({
      next: (response: any) => {
        // Check for the "Mobile Number Saved Successfully" message
        if (response.Message === 'Mobile Number Saved Successfully') {
          this.isLoading = false; 
          this.otpVerified = true;
          this.showMobileVerification = false;
          console.log('OTP saved successfully:', response);
          // Update URL parameters
          this.MobileVerificationPoints();
          const currentUrl = new URL(window.location.href);
          const params = new URLSearchParams(currentUrl.search);
          params.set('s', 'Screened-thankyou');
          const newUrl = `${currentUrl.pathname}?${params.toString()}`;
          window.history.pushState({ path: newUrl }, '', newUrl);

        } else if(response.Message === 'OTP Expired') {
          console.error('Unexpected response:', response);
          this.isLoading = false; 
          // this.otperrorMessage = true;
          this.showErrorMessage('otpexpiredMessage');
        }else{
          console.error('Unexpected response:', response);
          this.isLoading = false; 
          // this.otperrorMessage = true;
          this.showErrorMessage('otperrorMessage');
        }
      },
      error: (error: any) => {
        console.error('Failed to send OTP:', error);
        alert('Failed to send OTP. Please try again.');
      },
    });

  } else {
    alert('Please enter a valid 10-digit mobile number.');
  }

  console.log('OTP verification process completed!');
}
// showOtpErrorMessage(): void {
//   this.otperrorMessage = true;
//   clearTimeout(this.otpTimeout);
//   this.otpTimeout = setTimeout(() => {
//     this.otperrorMessage = false;
//   }, 5000);
// }
// showmobileErrorMessage(): void {
//   this.errorMessage = true;
//   clearTimeout(this.otpTimeout);
//   this.otpTimeout = setTimeout(() => {
//     this.errorMessage = false;
//   }, 5000);
// }


// showErrorMessage(property: 'otperrorMessage' | 'errorMessage' | 'otpexpiredMessage' | 'limitexpiredMessage'|'succesMessage'): void {
//   this[property] = true;
//   console.log(this[property]); // Set the specified property to true
//   clearTimeout(this.otpTimeout); // Clear any existing timeout
//   this.otpTimeout = setTimeout(() => {
//     this[property] = false; // Reset the property to false after 5 seconds
//   }, 5000); // Timeout duration
// }

showErrorMessage(property: 'otperrorMessage' | 'errorMessage' | 'otpexpiredMessage' | 'limitexpiredMessage'): void {
  this[property] = true; // Set the specified property to true
  this.showErrorBorder = true; // Turn the border red
  console.log(`Property: ${property}, Value: ${this[property]}`)

  // Clear any existing timeout for border or error message
  clearTimeout(this.otpTimeout);

  this.otpTimeout = setTimeout(() => {
    this[property] = false; // Reset the property to false after 5 seconds
    this.showErrorBorder = false; // Remove the red border after 5 seconds
  }, 5000); // Timeout duration
}
showsuccessMessage(property: 'succesMessage'): void {
  this[property] = true; // Set the specified property to true
  console.log(`Property: ${property}, Value: ${this[property]}`)

  // Clear any existing timeout for border or error message
  clearTimeout(this.otpTimeout);

  this.otpTimeout = setTimeout(() => {
    this[property] = false; // Reset the property to false after 5 seconds
  }, 5000); // Timeout duration
}
MobileVerificationPoints(): void {
  const countryCode = window.location.href.split('/')[3];
  const encryptedUrl = window.location.href.split('/profiler/')[1]?.split('?')[0];
  const payload = {
    "MarketId" : countryCode,
    "PanelistId" : encryptedUrl,
  };

  console.log('Calling AssignMobileVerificationPoints API with payload:', payload);
  this.appSer.assignMobileVerificationPoints(payload).subscribe(
    (response: any) => {
      console.log('n API response:', response);
    },
    (error: any) => {
      console.error('Error calling AssignMobileVerificationPoints API:', error);
    }
  );
}


  resendOtp(): void {
    if (this.mobileNumber?.length >=this.mobileInfo.MobileNumberMinLength  && this.mobileNumber?.length <= this.mobileInfo.MobileNumberMaxLength ) {
      this.isLoading = true; 
      console.log('Phone number:', this.mobileNumber);

      // Construct the payload for the mobile number existence check
      const formattedCountryCode = this.mobileInfo?.ISDCode || '';
      const encryptedUrl = window.location.href.split('/profiler/')[1]?.split('?')[0];
      const payload = {
        PhoneNumber: this.mobileNumber,
        EncryptedUrl: encryptedUrl,
        IsWhatsapp: this.selectedOtpModes === 'whatsapp',
      };

    // Call API to check if the mobile number exists
    this.appSer.checkMobileNumberExistence(payload).subscribe({
      next: (response: any) => {
        console.log('Response from checkMobileNumberExistence:', response);
        
        

        // Only proceed with OTP sending if Status is true
        if (response.Status === true) {
          if (this.selectedOtpModes === 'whatsapp') {
            const otpPayload = {
              PhoneNumber: `${formattedCountryCode}${this.mobileNumber}`,
              EncryptedUrl: encryptedUrl,
            };
            this.appSer.sendWhatsAppOtp(otpPayload).subscribe({
              next: (otpResponse: any) => {
                console.log('WhatsApp OTP sent successfully:', otpResponse);
                if (otpResponse === "OTP sent successfully."){
                  this.isOtpSent = true;
                  this.resendOTPFlag=false;
                  console.log("isOtpSent" , this.isOtpSent)
                  this.startTimer();
                  console.log("Resend OTP STart Timeleft", this.timeLeft);
                  //this.getOtpClicked = true;
                  this.isLoading = false; 
                  this.errorMessage = false;
                  this.isFormDisabled = true;
                  // this.showErrorMessage('succesMessage');
                  }else {
                    this.isLoading = false; 
                    this.showErrorMessage('limitexpiredMessage');
                  }
              },
              error: (otpError: any) => {
                console.error('Failed to send WhatsApp OTP:', otpError);
                alert('Failed to send WhatsApp OTP. Please try again.');
                this.isLoading = false; 
              },
            });
          } else if (this.selectedOtpModes === 'sms') {
            const otpPayload = {
              MobileNumber: this.mobileNumber,
              ResendOTP: false, // Default for "Get OTP"
              EncryptedUrl: encryptedUrl,
            };
            this.appSer.sendOtp(otpPayload).subscribe({
              next: (otpResponse: any) => {
                console.log('SMS OTP sent successfully:', otpResponse);
                if (otpResponse.Status === true) {
                this.isOtpSent = true;
                this.resendOTPFlag=false;
                console.log("isOtpSent" , this.isOtpSent)
                //this.getOtpClicked = true;
                this.startTimer();
                this.isLoading = false; 
                this.errorMessage = false;
                this.isFormDisabled = true;
                // this.showErrorMessage('succesMessage');
              }else if(otpResponse.Message === 'You have reached the maximum limit of OTP attempts. Please try to generate the OTP after 24 hours'){
                this.showErrorMessage('limitexpiredMessage');
                this.isLoading = false; 
              }else{
                this.showErrorMessage('errorMessage');
                this.isLoading = false; 
              }
              },
              error: (otpError: any) => {
                console.error('Failed to send SMS OTP:', otpError);
                alert('Failed to send SMS OTP. Please try again.');
                this.isLoading = false; 
              },
            });
          }
        } else {
          // Handle cases where Status is false
          //alert(response.Message || 'Mobile number is not eligible for OTP.');
          this.showErrorMessage('errorMessage');
          this.isLoading = false; 
        }
      },
      error: (error: any) => {
        console.error('Error checking mobile number existence:', error);
        alert('Failed to check mobile number existence. Please try again.');
      },
    });
  } else {
    alert('Please enter a valid 10-digit mobile number.');
  }


console.log('validatePhoneNumber triggered');
// this.getOtpClicked = true;

// if (!this.selectedOtpModes) {
//   return;
// }

// if (this.isConsentChecked && !this.isInvalid) {
//   this.isOtpSent = true;
// } else {
//   this.isOtpSent = false;
// }
}
// startTimer() {
//   this.interval = setInterval(() => {
//     if (this.timeLeft > 0) {
//       this.timeLeft--;
//     } else {
//       alert('Please enter a valid 10-digit mobile number.');
//     }


//     console.log('validatePhoneNumber triggered');

//   }
  startTimer() {
    this.timeLeft = 60;
    console.log('RESEND OTP TIMELEFT : ', this.timeLeft);
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.resendOTPFlag = true;
        clearInterval(this.interval);
      }
    }, 1000); 
  }


  private appendClarityScript(): void {
    const scriptId = 'clarity-script';

    if (this.document.getElementById(scriptId)) {
      return;
    }

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;

    script.text = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "cncszpjlpw");
    `;

    this.document.head.appendChild(script);
  }

  private appendGtmScript(): void {
    const scriptId = 'gtm-script';
    const noscriptId = 'gtm-noscript';
 
    // Check if GTM script is already appended
    if (this.document.getElementById(scriptId)) {
        console.log('GTM script already appended.');
        return;
    }
 
    // Append the GTM script to the <head>
    const gtmScript = this.document.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.id = scriptId;
 
    gtmScript.text = `
      (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KFQLWJ38');
    `;
    this.document.head.appendChild(gtmScript);

    // Check if <noscript> GTM iframe is already appended
    if (this.document.getElementById(noscriptId)) {
        console.log('GTM <noscript> iframe already appended.');
        return;
    }

    // Append the GTM <noscript> iframe to the <body>
    const noscriptElement = this.document.createElement('noscript');
    noscriptElement.id = noscriptId;

    noscriptElement.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KFQLWJ38"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

    this.document.body.insertBefore(noscriptElement, this.document.body.firstChild);
  }
  executeTrackingScript(): void {
    const scriptId = 'form-submit-tracking-script';
 
    const existingScript = this.document.getElementById(scriptId);
    if (existingScript) {
      console.log('Tracking script already exists.');
      return;
    }
 
    const script = this.renderer.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.text = `
      dataLayer.push({
        'event':'form_submit',
        'enhanced_conversion_data': {
          "email": '${this.panelistEmail}'
        }
      });
    `;
 
    this.renderer.appendChild(this.document.body, script);
  }

  voicherSelection(){

  }
  onSelectionChange(event: any,question:any,page:any) {
    this.searchTerms[question.ID]?.setValue("");
    let pageGroup = this.profilerQuestionsForm.get(page.ID.toString());
    pageGroup?.get(question.ID.toString())?.patchValue(event.value.ID);
    this.selectedDropdown[question.ID] = event.value.AnswerChoiceText;
  }
  getProfilerQuestions() {
    this.appSer.getProfilerQuestions(this.id).subscribe( (res:any)=>{
      // res.data.response.xcampaign.pages?.sort((a:any, b:any) => a.ID - b.ID);
      this.profilerDetails = res.data;
      this.terminatedTxt = res.data.response.TerminatedMessage;
      console.log("formSubmit : ", this.formSubmitSuccess);
      if( res.data.response.Status == false ){ //profiler complted
        
        console.log("PROFILER ALREADY COMPLETED BY USER");
        console.log('====================================');
        console.log("Mobile Verified = ", this.mobileVerified);
        this.formSubmitSuccess = true;

        if(this.isAgeRestricted === "Age Restricted"){
          console.log("Terminated Message : " , this.terminatedTxt);
          this.router.navigate(['/profiler-terminate'], { state: { message: this.terminatedTxt } });
        }


        if(this.otpConfiguration){
          this.getRewards();
          console.log("OTP IS CONFIGURED : ", this.otpConfiguration);
          if(this.mobileVerified){
            this.showProfilerDiv = true;
            this.showMobileVerification = false;
            this.skipOtpVerification = true;
            console.log("Skipping Mobile Verification as Number Already Verified.");
          }
          
          if(!this.mobileVerified){
            this.showProfilerDiv = false;
            this.showMobileVerification = true;
            console.log("Mobile Number not already verified, taking to verification screen");
          }
        }

        if(!this.otpConfiguration){
          this.getRewards();
          this.showProfilerDiv = false;
          this.skipOtpVerification = true;
          this.otpVerified = false;
        }

      }else{
        this.getLastAnsweredPage(res.data.response.xcampaign.pages);
        (this.profilerDetails.response.xcampaign.pages).forEach( (v:any,i:any)=>{
          console.log(v)
          if( v.Name != 'Thank You' && v.Name != 'Terminated' ){
            this.profilerQuestions.push(v);
          }
        });

        
        if (this.lastAnsweredPage.length > 0) {
          this.profilerQuestions.some((page: any) => {
            if (page.Name == "Default") {
              this.ctaAction('initial',this.profilerQuestions[this.currentPage]);
            }
            if (page.Name != "Default") {
              setTimeout(() => {
                this.ctaAction('next',this.profilerQuestions[this.currentPage+1],'onRefresh');
              }, 100);
            }
          });
        }else this.ctaAction('initial',this.profilerQuestions[this.currentPage]);
      }
    });
  }
  // To check which of the page is last visited
  // Logic :- to keep the track of last answered page
  getLastAnsweredPage(pages: any): any {
    pages.forEach((page: any) => {
      let hasActiveAnswer = false;
      if (page.Name != 'Default' && page.Name != 'Thank You' && page.Name != 'Terminated') {
        page.questions.forEach((question: any) => {
          let isActive = question.AnswerChoice.some((choice: any) => choice.IsActive);
          if (isActive) {
            hasActiveAnswer = true;
            let latest = page;
            this.lastAnsweredPage=[latest];
          }
        });
      }
    });
    this.onRefresh=true;
  }

 
  //whatsapp Logo
  show1() {
    let x: any = this.document.getElementsByClassName('cart');
    x[0].style.display = 'none';
  }

  show2() {
    let x: any = this.document.getElementsByClassName('cart')
    x[0].style.display = 'block';
  }



  // To switch between the pages i.e. to show profiler questions page one by one
  ctaAction(action?: any, page?: any,onRefresh?:any) {
    // console.log("");
    
    this.formGroupSubmit = true;

    if (isPlatformBrowser(this.platformId)) {
        const scrollableDiv = document.getElementById('Div2');
        if (scrollableDiv) {
            scrollableDiv.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    
    if (action == 'initial') {
      this.addFormGroup(page);
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
    }else if (action == 'next' || action =="Submit") {
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
      if (this.currentFormGroupValid) {
        //save answered ques
       
        if(action =="Submit"){
          this.profilerCompleted = true;
          this.saveAnswers( this.currentPage);//calling after page submit
        }else if (action == 'next') {
          if (!onRefresh) {
            this.saveAnswers( this.currentPage,action,page);//calling before page increment
          }else{// Only on refresh scenario 
            this.currentPage++;
            this.completionPercentage(this.currentPage);
            this.addFormGroup(page);
            this.formGroupSubmit = false;
          }
        }
       

        
      }
    } else {
      this.formGroupSubmit = false;
      this.currentPage--;
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
      this.completionPercentage(this.currentPage);
    }

  }

  // To show the completion percentage of the profiler
  // completionPercentage(step: any) {
  //   let steps: any = (100 / (this.profilerQuestions.length-1)) * (step);
  //   let percent = parseInt(steps);
  //   (<HTMLElement>this.document.getElementById("percentValue")).textContent = `${percent}%`;
  //   (<HTMLElement>this.document.querySelector(".progress-bar")).style.width = `${percent}%`;
  // }
  completionPercentage(step: any) {
    let steps: any = (100 / (this.profilerQuestions.length - 1)) * step;
    let percent = parseInt(steps);

    // Validation: If the percentage is already 100, do not update further
    if (percent >= 100) {
      percent = 100;
    }
  
    const percentValueElement = this.document.getElementById("percentValue");
    const progressBarElement = this.document.querySelector(".progress-bar") as HTMLElement;
  
    if (percentValueElement) {
      (<HTMLElement>percentValueElement).textContent = `${percent}%`;
    }
  
    if (progressBarElement) {
      progressBarElement.style.width = `${percent}%`;
    }
  }
  

  // To add the form group dynamicaly depending on the active page
  addFormGroup(page?: any) {
    let selectionDetails:any= [];
    this.questionGroup = this.fb.group({});
    page.questions.forEach((question: any) => {
      this.searchTerms[question.ID] = new FormControl('');
      let activeChoice = question.AnswerChoice?.find((choice:any) => choice.IsActive);
      let selectedAns;
      if (question.QuestionType === 'Date') {
        // selectedAns = new Date(question?.Answer);
        selectedAns =(question?.Answer)?(formatDate(question?.Answer, 'yyyy-MM-dd', 'en')):'';
      } else {
        selectedAns = activeChoice ? activeChoice.ID : '';
      }
      
      // let selectedAns = (question.QuestionType =='Date')?question.Answer:(activeChoice ? activeChoice.ID : '');
      if (!question?.QuestionType.includes('Multi Choice')) {
        this.questionGroup.addControl(
          question?.ID.toString(), 
          (question.Mandatory)
            ? this.fb.control(selectedAns, Validators.required)
            : this.fb.control(selectedAns)
        );
        question.AnswerChoice.forEach((choice: any) => {
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        question.AnswerChoice.forEach((choice: any) => {
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        if (activeChoice) {
          this.selectedDropdown[question.ID] =activeChoice.AnswerChoiceText;
        }
      } else {
        this.createFormArrays(page);
      }
    });
    this.profilerQuestionsForm.addControl(page?.ID.toString(), this.questionGroup);
    if (selectionDetails) {
      selectionDetails.forEach((selection:any) => {
        this.otherOptionSelection(selection.page, selection.question, selection.choice);
      });
    }
  }


  // To create an form arrays
  createFormArrays(page?: any) {
    const pageGroup = this.fb.group({});
    let selectionDetails:any= [];
    page.questions.forEach((question: any) => {
      if (question.QuestionType.includes('Multi Choice')) { // FormGroup creation for multi-selection questions
        const formGroup = this.fb.group({});
        question.AnswerChoice.forEach((choice: any) => {
          const slection = choice.IsActive ? choice.ID : false;
          formGroup.addControl(choice.ID.toString(), this.fb.control(slection));
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        if (question.Mandatory) formGroup.setValidators(atLeastOneRadioSelectedValidator());
        pageGroup.addControl(question.ID.toString(), formGroup);
      } else {
        let activeChoice = question.AnswerChoice?.find((choice:any) => choice.IsActive);
        const slection = (activeChoice?.IsActive) ? activeChoice.ID : '';
        pageGroup.addControl(question.ID.toString(), this.fb.control(slection,question.Mandatory ? Validators.required : []));
      }
    });
    this.profilerQuestionsForm.addControl(page.ID.toString(), pageGroup);
    if (selectionDetails) {
      selectionDetails.forEach((selection:any) => {
        this.otherOptionSelection(selection.page, selection.question, selection.choice);
      });
    }
  }

  getFormArray(pageId: number, questionId: number): FormArray {
    return this.profilerQuestionsForm.get([pageId.toString(), questionId.toString()]) as FormArray;
  }
  // To fetch the values of the selected anwers of multiple selection choice questions
  getSelectedValues(pageId: number, questionId: number): string[] {
    let selectedValues: string[] = [];
    let pageGroup = this.profilerQuestionsForm.get(pageId.toString()) as FormGroup;
    let questionControl = pageGroup.get(questionId.toString());
    if (questionControl) {
      let question = this.profilerQuestions.find((page: any) => page.ID === pageId)?.questions.find((q: any) => q.ID === questionId);
      if (question) {
        if (question.QuestionType.includes('Multi Choice')) {
          // Handle Multi Choice questions
          let questionGroup = questionControl as FormGroup;
          question.AnswerChoice.forEach((answer: any) => {
            let control = questionGroup.get(answer.ID.toString());
            if (control && control.value) {
              selectedValues.push(answer.ID);
            }
          });
        } else {
          // Handle Single Choice questions (radio buttons)
          if (questionControl.value) {
            selectedValues.push(questionControl.value);
          }
        }
      }
    }
    return selectedValues;
  }
  validateCurrentPageForm(action?:any,id?:any):any {
    if (action=='initial') {
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((this.currentPage+1).toString())?.valid)?true:false;
    } else if(action=='next') {
      let id:any = this.document.querySelector('.step')?.id;
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((id).toString())?.valid)?true:false;
    }else{
      let id:any = this.document.querySelector('.step')?.id;
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((id).toString())?.valid)?true:false;
    }
  }
  // Profiler form submission
  onSubmit() {
    // console.log(this.profilerQuestionsForm.value)
    // this.getLoginLink();
    // this.saveAnswers(this.currentPage);
    let formValue = {...this.profilerQuestionsForm.value};
    this.profilerQuestions.forEach((page: any) => {
      page.questions.forEach((question: any) => {
        if (question.QuestionType.includes('Multi Choice')) {
          formValue[page.ID.toString()][question.ID.toString()] = this.getSelectedValues(page.ID, question.ID);
        }
      });
    });
    
    // const currentDate = new Date(); // Get the current date
    // const userReadableDate: string = currentDate.toLocaleString();
    
    // if(formValue[15]){
    //   let genderByanswer: string = "other";
    //   let DateofBirth: any = formValue[15][1061];
    //   if(formValue[15][1063] == 104612){
    //     genderByanswer = "Male";
    //   }else if(formValue[15][1063] == 104613){
    //     genderByanswer = "Female";
    //   }else if(formValue[15][1063] == 104614){
    //     genderByanswer = "Non Binary";
    //   }else if(formValue[15][1063] == 104617){
    //     genderByanswer = "not to say";
    //   }else{
    //     genderByanswer = "Option not in condition";
    //   }
    //   this.payload["Date of Birth"] =  DateofBirth;
    //   this.payload["Gender"] =  genderByanswer;
    // }
    // this.payload["Product name"] =  "The Panel Station";
    // this.payload["Email ID"] = this.panelistEmail;
    // this.payload["Market"] = this.appSer.getLocalStorage("country");
    // this.payload["Date of Joining"] = userReadableDate;
    // if(this.appSer.getLocalStorage("country").toUpperCase() == "IN"){
    //   this.appSer.sendEvent({"EmailID":this.panelistEmail},"event","Profiler_Submit",this.appSer.getLocalStorage("country"),JSON.stringify(this.payload)).subscribe( (response:any)=>{
    //     console.log('Event User clicking on the submit after answering all the questions:', response)
    //   });
    // }

    console.log("OnSubmit Mobile Verification : " , this.mobileVerified);
    if(this.mobileVerified || !this.otpConfiguration){
      console.log("OnSubmit Inside If MobileVerified");
      this.skipOtpVerification = true;
      this.getRewards();
    }

    if (this.profilerQuestionsForm.valid) {
      //this.formSubmitSuccess = true;
    }
  }
  claimVoicher(){
    this.claimYourVoucher=true;
    this.getLoginLink();
  }

  saveAnswers(pageNum:any,action?:any,page?:any){
    // console.log("pageNum :", pageNum)
    // console.log("this.profilerQuestionsForm Value : ",this.profilerQuestionsForm.value)
    if(this.profilerQuestions[pageNum]){
      let ansPage = this.profilerQuestions[pageNum];
      // console.log("ansPage : ", ansPage);
      let formValue:any = [];
      
      ansPage.questions.forEach((question: any) => {
        // console.log( "Question Type Of: " , typeof question);
        formValue.push (
          {
            'pageID' : ansPage.ID.toString(), 
            'questionID' :question.ID.toString(),
            'selectedvalues' : this.getSelectedValues(ansPage.ID, question.ID),
            'Comment':this.getComment(ansPage.ID,question.ID),
            'qInfo': {QuestionType: question.QuestionType, MaxRange:question.MaxRange, MinRange:question.MinRange}
          });
          if (question.QuestionType ==="Date") {
            this.getDob(formValue[0]);
          }
          // Push the Date of birth object on submit
          // if (this.profilerCompleted) {
            // formValue.push(this.DOB);
            if (this.DOB && !formValue.some((element: any) => element.questionID === this.DOB.questionID)) {
              formValue.push(this.DOB);
            }
          // }
      });
    
      if (formValue) {
        // console.log(formValue);
        this.appSer.saveProfiler(formValue, this.id, this.profilerCompleted).subscribe((response: any) => {
          console.log("Answered Response", response);
          // Check the response success and profiler completion status
          if (response.success == true && this.profilerCompleted == true) {
            const surveyLink = response.message.response?.response?.SurveyLink;
            console.log("Survey Link :", surveyLink);
            if (surveyLink) {
              console.log("Survey Link Exist : ", surveyLink);
              window.location.href = surveyLink;
              return;
            } else {
              console.log("Survey Link Doesn't Exist : ", surveyLink);
              // this.getLoginLink();
              this.formSubmitSuccess = true;
              this.cookieService.delete('isProfiler');
    
              // Added Pixel Script to Body
              if (this.storedPixelCodeScreened) {
                this.appendPixelCodeToBody(this.storedPixelCodeScreened);
              } else {
                console.log('PixelCodeScreened is not available.');
              }
              this.injectPixelScripts(this.panelistEmail, this.panelistId, 'hashed_email_value', 'user_ip_address');
              this.executeTrackingScript();
    
              // Update URL param to THANKYOU
              const currentUrl = new URL(window.location.href);
              const params = new URLSearchParams(currentUrl.search);
              params.set('s', 'Screened-thankyou');
              const newUrl = `${currentUrl.pathname}?${params.toString()}`;
              window.history.pushState({ path: newUrl }, '', newUrl);
            }
          } else if (response.success == false) {
            this.router.navigate(['/profiler-terminate'], { state: { message: this.terminatedTxt } });
          }
    
          // Go to the next page only when there is a response from the API
          if (response.success == true) {
            this.currentPage++;
            this.completionPercentage(this.currentPage);
            this.addFormGroup(page);
            this.formGroupSubmit = false;
          }
        });
      }
     
    }
    
  }

  get id(){
    return this.route.snapshot.paramMap.get('id');
  }

  getProfilerInfo(){ 
    this.appSer.getProfilerInfo(this.id).subscribe( (response:any)=>{
      this.mobileVerified = response.profilerInfo.response.isMobileNumberVerified;
      this.isAgeRestricted = response.profilerInfo.response.ProfilerComment;
      console.log("Mobile Number Verified : ", this.mobileVerified);
      console.log("Age Restricted : ", this.isAgeRestricted);
      this.panelistInfo = response.profilerInfo.response;
      this.panelistId = response.profilerInfo.PID;
      this.CampaignID = response.profilerInfo.response.RegistrationCampaignID
      this.panelistEmail= response.profilerInfo.response.EmailID; 
      this.sampleid = response.SID;
      console.log("PANELIST ID : ", this.panelistId);
      console.log("CAMPAIGN ID: ", this.CampaignID);
      console.log("PANELIST EMAIL : ", this.panelistEmail);
      // console.log("PROFILER INFO : ", this.panelistInfo);
      console.log("sample id: ", this.sampleid);
      this.getPixelcodes(this.CampaignID);
      this.getProfilerQuestions();
      this.appSer.getCountryLangIDFromlangCulture(this.panelistInfo.LanguageCulture).subscribe( (countryLangInfo:any)=>{
        let funParam = {data:countryLangInfo.message};
        this.appSer.marketStyles(funParam); //applying market styles
        this.getPageContent(countryLangInfo.message.id);
      });
      // console.log('panelistInfo', this.panelistInfo);
     
    })
  }

  getPixelcodes(caid: number) {
    this.appSer.getPixelsCode(caid).subscribe(
      (data:any) => {
        if (data && data.response) {
          console.log("CID FOR GET PIXEL  ", caid);
          console.log("GET PIXEL RESPONSE", data);
          const pixelCodeScreened = data.response.PixelCodeScreened;
          this.storedPixelCodeScreened = pixelCodeScreened;
        }
      },
      error => {
        console.log('Error fetching pixel codes', error);
      }
    );
  }
  // appendPixelCodeToBody(pixelCode: string) {
  //   if (pixelCode) {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const subId:any = urlParams.get('subId');
  //     const transId = this.panelistId;
  //     console.log("SUB ID FROM URL" + subId);
  //     console.log("TRANS ID" + subId);
  //     console.log("TYPE OF SUBID URL " + typeof(subId));
  //     console.log("TYPE OF TRANS ID " + typeof(transId));
  //     // const subId = this.CampaignID;
  //     // console.log("PANELIST ID : ", this.panelistId);
  //     // console.log("PANELIST CID : ", this.caid);
  //     const updatedPixelCode = pixelCode
  //       .replace('[UNIQUE_TRANSACTION_ID]', transId)
  //       .replace('[UNIQUE_SUB_ID]', subId);
  //     const divContainer = document.createElement('div');
  //     divContainer.innerHTML = updatedPixelCode;
  //     //console.log("ADDING SCRIPT TO BODY:", divContainer);
  //     document.body.prepend(divContainer);
  //     console.log("SCRIPT ADDED TO BODY",divContainer);
  //   } else {
  //     console.log('No PixelCodeScreened script available to append.');
  //   }
  // }

  appendPixelCodeToBody(pixelCode: string) {
    if (pixelCode) {
      const urlParams = new URLSearchParams(window.location.search);
      const subId: any = urlParams.get('subid') || ""; 
      const transId:any = this.panelistId || "";

      console.log("SUB ID FROM URL : ", subId);
      console.log("TRANS ID : ", transId);
      console.log("TYPE OF SUBID URL : ", typeof subId);
      console.log("TYPE OF TRANS ID : ", typeof transId);
  
      const updatedPixelCode = pixelCode
        .replace('[UNIQUE_TRANSACTION_ID]', transId)
        .replace('[UNIQUE_SUB_ID]', subId);
  
      const divContainer = document.createElement('div');
      divContainer.innerHTML = updatedPixelCode;
      document.body.prepend(divContainer);
      console.log("SCRIPT ADDED TO BODY:", divContainer);
    } else {
      console.log('No PixelCodeScreened script available to append.');
    }
  }

  
  injectPixelScripts(email: string, panelistId: string, sha256Email: string, ipAddress: string) {
    // Tiktok Pixel Script
    const tiktokScript = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
        ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
        ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
        n=document.createElement("script");
        n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;
        e=document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n,e)};
        ttq.load('CNTE8CBC77UBM1BSGU70');
        ttq.page();
      }(window, document, 'ttq');
  
      ttq.identify({
        email: '${email}',
        'external_id': '${panelistId}'
      });
  
      ttq.track('CompleteRegistration');
      console.log("TIKTOK SCRIPT WORKING");
    `;
  
    // Meta Pixel Script
    const metaScript = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '3241517045981800', {
        em: '${email}',
      });
  
      fbq('track', 'PageView');
      fbq('track', 'CompleteRegistration');
      console.log("META SCRIPT WORKING");
    `;
  
    // Snapchat Pixel Script
    const snapchatScript = `
      (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
      {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
  
      snaptr('init', 'd83cb686-a1f2-4fde-8885-0dd7def53fb6', {
      user_hashed_phone_number: '${sha256Email}',
      ip_address: '${ipAddress}'
      });
  
      snaptr('track', 'PURCHASE');
      console.log("SNAPCHAT SCRIPT WORKING");
    `;
  
    // Inject the scripts into the DOM
    //console.log("Executing Pixel Completes Scripts.");
    const tiktokScriptElement = document.createElement('script');
    tiktokScriptElement.innerHTML = tiktokScript;
    document.body.appendChild(tiktokScriptElement);
    //console.log("Added Tiktok Script : ", tiktokScriptElement );
    
    const metaScriptElement = document.createElement('script');
    metaScriptElement.innerHTML = metaScript;
    document.body.appendChild(metaScriptElement);
    //console.log("Added Meta Script : ", metaScriptElement );
    
    const snapchatScriptElement = document.createElement('script');
    snapchatScriptElement.innerHTML = snapchatScript;
    document.body.appendChild(snapchatScriptElement);
    //console.log("Added Snapchat Script : ", snapchatScriptElement);
  
    //console.log("Pixel scripts injected successfully.");
  }

  getRewards(){
    this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
      this.rewards = res.data;
    });
  }

  getPageContent(countryLangID:any){
    this.appSer.getPageContent('Profiler',countryLangID).subscribe( (res:any)=>{
      this.content = res.data;
      (this.content.kudosEarnedPoints.content) ? this.content.kudosEarnedPoints.content = this.content.kudosEarnedPoints.content.replace("#EarnedPoints#", this.panelistInfo.Points) : "";
      (this.content.mobilepoints.content) ? this.content.mobilepoints.content = this.content.mobilepoints.content.replace("#ypoints#", `${this.mobileInfo.RewardValue} ${this.mobileInfo.RewardType}`) : "";
      (this.content.surveypoints.content) ? this.content.surveypoints.content = this.content.surveypoints.content.replace("#xpoints#", `${this.panelistInfo?.Points} ${this.panelistInfo?.PointText}`) : "";
      (this.content.collectedpoints.content) ? this.content.collectedpoints.content = this.content.collectedpoints.content.replace("#ypoints#", `${this.mobileInfo.RewardValue} ${this.mobileInfo.RewardType}`) : "";
      (this.content.totalpointsEarned.content) ? this.content.totalpointsEarned.content = this.content.totalpointsEarned.content.replace("#xpoints#", `${this.panelistInfo?.Points} ${this.panelistInfo?.PointText}`) : "";
      (this.content.totalpointsEarned.content) ? this.content.totalpointsEarned.content = this.content.totalpointsEarned.content.replace("#ypoints#", `${this.mobileInfo.RewardValue} ${this.mobileInfo.RewardType}`) : "";
      // this.selectedOption=this.content?.selectfromdropdown?.content

      if(this.content.selectfromdropdown){
        this.content.selectfromdropdown.content = this.content.selectfromdropdown.content.replace(/<[^>]*>/g, '');
      }
      

    });
  }

  getLoginLink(){
    this.appSer.getLoginLink(this.id).subscribe( (response:any)=>{
      // this.loginLinkInfo = response.response;
      // console.log(response)
      this.loginLink = response.data.response;
      this.redeemPerc = Math.floor((Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100);
      if( this.redeemPerc > 100){
        this.redeemPerc = 100;
      }
      // this.redeemPerc =Math.ceil((Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100 / 10) * 10;
      // let percentage = (Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100;
      // this.redeemPerc = Math.min(Math.ceil(percentage / 10) * 10, 100);
      console.log('redeemPerc', this.redeemPerc);
      let PointTobeRequired = Number(this.loginLink?.PointRequired) - Number(this.panelistInfo.Points);
      let PointTobeRequiredmobile = PointTobeRequired - Number(this.mobileInfo?.RewardValue || 0);

      if(PointTobeRequired < 0 || PointTobeRequiredmobile < 0){
        PointTobeRequired = 0;
        PointTobeRequiredmobile = 0;
      }
      let currentPointsRequired: number = 0; 
      console.log("OTP Verified:", this.otpVerified);
      console.log("Skip OTP Verification:", this.skipOtpVerification);
      console.log("PointTobeRequired:", PointTobeRequired);
      console.log("PointTobeRequiredmobile:", PointTobeRequiredmobile);
      if (this.otpVerified) {
          // If OTP is verified, use `PointTobeRequired`
          currentPointsRequired = PointTobeRequiredmobile;
          //currentPointsRequired = PointTobeRequired;
      } else if (this.skipOtpVerification) {
          // If OTP is skipped, use `PointTobeRequiredmobile`
          currentPointsRequired = PointTobeRequired;
      }
      
      console.log("Final Points Required:", currentPointsRequired);
      if (this.content.pointstobeEarned) {
        console.log("value", this.content.pointstobeEarned);
          this.content.pointstobeEarned.content = this.content.pointstobeEarned.content.replace("#PointsRequired#", currentPointsRequired);
      }
      console.log("Final Points Required:", currentPointsRequired);
      //(this.content.pointstobeEarned) ? this.content.pointstobeEarned.content = this.content.pointstobeEarned.content.replace("#PointsRequired#", PointTobeRequired) : "";
      (this.content.redeem) ? this.content.redeem.content = this.content.redeem.content.replace("#PointsRequired#", this.loginLink?.PointRequired) : "";
    })
  }
  // To get the Date of birth 
  getDob(form: any) {
    let dob =(this.profilerQuestionsForm.get((form.pageID).toString())?.get((form.questionID).toString()))?.value;
    this.DOB={
      'pageID' : (form?.pageID.toString()) ? form?.pageID.toString():'', 
      'questionID' :(form?.questionID.toString()) ? (form?.questionID.toString()):'',
      'selectedvalues' : (form?.selectedvalues) ? (form?.selectedvalues):'',
      'qInfo': form?.qInfo,
      'dob':dob,
      'min':form?.qInfo.MinRange.toString(),
      'max':form?.qInfo.MaxRange.toString()
    }
  }
// TO set the percentage of profiler completion
  getOverlayStyle() {
    const transform = ('translateY(-50%) ') + 'translateX(-50%)';

    return {
      top:  '50%',
      bottom: 'auto',
      left: '50%',
      transform,
      fontSize: this.radius / 3.5 + 'px',
    };
  }
  // Handle option selection
  onSelect(answer: any, question: any,page:any) {
    this.searchTerms[question.ID]?.setValue("");
    let pageGroup = this.profilerQuestionsForm.get(page.ID.toString());
    pageGroup?.get(question.ID.toString())?.patchValue(answer.ID);
    // this.searchTerms[question.ID]?.setValue(answer.AnswerChoiceText);
    this.dropdownSelected[question.ID] = answer.AnswerChoiceText;
  }
  getConfig(questionID: string) {
    let placeholderVal= "Select From dropdown";
    if(this.selectedDropdown[questionID]){
      placeholderVal = this.selectedDropdown[questionID];
    }else if( typeof this.content != 'undefined' && typeof this.content.selectfromdropdown !== 'undefined'){
      placeholderVal = this.content.selectfromdropdown.content;
    }else{
      placeholderVal = (this.selectedDropdown[questionID]) ? this.selectedDropdown[questionID] : "Select From dropdown";
    }
    return {
      displayFn: (item: any) => item.AnswerChoiceText,
      displayKey: 'AnswerChoiceText',
      // valueKey: 'ID',
      search: true,
      height: '210px',
      placeholder: placeholderVal,
      //customComparator: this.customComparator, // Ensure this returns a number
      // limitTo: 10,
      // moreText: 'More',
      noResultsFound: 'No results found',
      searchPlaceholder: 'Search',
      searchOnKey: 'AnswerChoiceText'
    };
  }
  noOfTheAbove(page: any, question: any, selection: any) {
    let pageControl = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl = pageControl?.get(question.ID.toString());
    if (selection.IsNoneoftheAbove) {
      // If "None of the Above" is selected, uncheck all other checkboxes
      question.AnswerChoice.forEach((answer: any) => {
        let otherControl = questionControl?.get(answer.ID.toString());
        if (answer.ID !== selection.ID) {
          otherControl?.setValue(false); // Uncheck all other checkboxes
        }
      });
    } else {
      // If any other checkbox is selected, uncheck "None of the Above"
      question.AnswerChoice.forEach((answer: any) => {
        if (answer.IsNoneoftheAbove) {
          let noneOfTheAboveControl = questionControl?.get(answer.ID.toString());
          noneOfTheAboveControl?.setValue(false); // Uncheck "None of the Above"
        }
      });
    }
  }
  // To select/unselect options when "Other" option selected 
  otherOptionSelection(page: any, question: any, selection: any) {
    let pageControl = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl = pageControl?.get(question.ID.toString());
    
    if (question.QuestionType.includes('Multi Choice')) {
      if (selection.IsOthers) { // If "Other option" is selected, uncheck all other checkboxes
        console.log("otherOptionSelection() selection.isOthers : ", selection.IsOthers);
        question.AnswerChoice.forEach((answer: any) => {
          let answerControl = questionControl?.get(answer.ID.toString());
          if (answer.ID !== selection.ID) {
            answerControl?.setValue(false); // Uncheck all other checkboxes
          }
        });
        this.showOtherOption(page, question, selection); // Show the other option textarea
      } else { // If any other checkbox is selected, uncheck "Other option"
        question.AnswerChoice.forEach((answer: any) => {
          if (answer.IsOthers) {
            let otherOptionControl = questionControl?.get(answer.ID.toString());
            otherOptionControl?.setValue(false); // Uncheck "Other option"
            this.hideOtherOption(page, question, answer); // Hide the other option textarea
          }
        });
      }
    } else {
      if (selection.IsOthers) {
        console.log("otherOptionSelection() selection.isOthers : ", selection.IsOthers);
        this.showOtherOption(page, question, selection); // Show the other option textarea
      } else {
        question.AnswerChoice.forEach((answer: any) => {
          if (answer.IsOthers) {
            let otherOptionControl = questionControl?.get(answer.ID.toString());
            otherOptionControl?.setValue(false); // Uncheck "Other option"
            this.hideOtherOption(page, question, answer); // Hide the other option textarea
          }
        });
      }
    }
  }
  // To show the Textarea when other option is selected 
  showOtherOption(page: any, question: any, answer: any) {
    let pageControl: any = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl: any = pageControl?.get(question.ID.toString());
    let otherOptionControlName = 'other-' + answer.ID;
    if (question.QuestionType.includes('Multi Choice')) { //Multi Choice buttons 
      if (questionControl.get(answer.ID.toString())?.value) { // If "Other" option is selected
        this.otherSelected[answer.ID] = true;
        if (!questionControl.get(otherOptionControlName)) {
          questionControl.addControl(otherOptionControlName, new FormControl((questionControl.get(otherOptionControlName)?.value) ? questionControl.get(otherOptionControlName)?.value : answer?.Comment, Validators.required));
        }
      } else { // If "Other" option is deselected
        this.otherSelected[answer.ID] = false;
        questionControl.removeControl(otherOptionControlName);
      }
    } else { //Radio buttons 
      if (pageControl.get(question.ID.toString())?.value) { // If "Other" option is selected
        this.otherSelected[answer.ID] = true;
        console.log("Answer ID : ",answer.ID);
        if (!pageControl.get(otherOptionControlName)) {
          pageControl.addControl(otherOptionControlName, new FormControl((pageControl.get(otherOptionControlName)?.value) ? pageControl.get(otherOptionControlName)?.value : answer?.Comment, Validators.required));
        }
      } else { // If "Other" option is deselected
        this.otherSelected[answer.ID] = false;
        pageControl.removeControl(otherOptionControlName);
      }
    }
  }
  // To hide the Textarea when other option is un-selected 
  hideOtherOption(page: any, question: any, answer: any) {
    let pageControl: any = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl: any = pageControl?.get(question.ID.toString());
    const otherOptionControlName = 'other-' + answer.ID;
    if (question.QuestionType.includes('Multi Choice')) { //Multi Choice buttons
      this.otherSelected[answer.ID] = false;
      questionControl.removeControl(otherOptionControlName);
    } else {
      this.otherSelected[answer.ID] = false;
      pageControl.removeControl(otherOptionControlName);
    }
  }
  // To get the other option content (Comment)
  getComment(pageId: number, questionId: number): string[] {
    // console.log("Inside GetComment");
    // console.log("PAGE ID : ", pageId);
    // console.log("QUESTION ID : ", questionId);
    let comment;
    let pageGroup = this.profilerQuestionsForm.get(pageId.toString()) as FormGroup;
    let questionControl = pageGroup.get(questionId.toString());
    if (questionControl) {
      // console.log("Question Control Exist!")
      let presentQuestion = this.profilerQuestions.find((page: any) => page.ID === pageId)?.questions.find((q: any) => q.ID === questionId);
      // console.log("Present Question : ", presentQuestion);

      if (presentQuestion) {
        let selectedAnswer = presentQuestion.AnswerChoice.find((answer: any) => answer.IsOthers && answer.IsActive);
        let selectedAnswer2 = presentQuestion.AnswerChoice.find((answer: any) => answer.IsOthers);

        // console.log("SELECTED ANSWER : ", selectedAnswer);
        // console.log("SELECTED ANSWER 2 : ", selectedAnswer2);
        if (presentQuestion.QuestionType.includes('Multi Choice')) {
          if (selectedAnswer || (selectedAnswer2 && this.otherSelected[selectedAnswer2.ID])) {
            comment = questionControl.get('other-' + selectedAnswer2.ID)?.value;
            console.log("SETTING COMMENT AS : " , comment);
            console.log("AnswerID : ", selectedAnswer2.ID)
          } else {
            comment = "Priority profiler";
          }
        } else {
          if (selectedAnswer || (selectedAnswer2 && this.otherSelected[selectedAnswer2.ID])) {
            comment = pageGroup.get('other-' + selectedAnswer2.ID)?.value;
            console.log("SETTING COMMENT AS : " , comment);
            console.log("AnswerID : ", selectedAnswer2.ID)
          } else {
            comment = "Priority profiler";
          }
        }
      }
    }
    return comment;
  }
}
