<section>
    <div class="container p-lg-0">
        <div class="row w-100 d-flex align-items-center">
            <div class="col-lg-7 order-2 order-lg-1 bg-light p-lg-0 reg-height">
                <div class="row">
                    <div class="col-md-12 reg-video">
                        <!-- <video loading="lazy" controls class="homepagevideo" id="regBrandVideo" paused src="{{content?.fileDir}}/{{content?.regBrandVideo?.content}}" loop (mouseenter)="handleVideoControl($event)"
                        (mouseleave)="handleVideoControl($event)"></video> -->
                        <div class="div1">
                            <a (click)="openModal(youtube)" href=# data-bs-toggle="modal" data-bs-target="#mymodal6"> <img loading="lazy" src="assets/resources/images/brand-img.webp" alt="The Panel Station" class="img-fluid" /> </a>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 mt-3 text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div id="hiddenDiv1">
                                        <h5 class="fs-4" id="registrationBannerAdd" [innerHTML]="content?.registrationBannerAdd?.content"></h5>
                                        <div class="row mt-3"> -->
                                            <!-- <div class="col-12 col-lg-1 col-md-1 col-xs-4 m-2"></div> -->
                                            <!-- <ng-container *ngFor="let reward of rewards?.rewards;">
                                                <div class="col-12 col-lg-2 col-md-2 col-xs-4 rounded-pill  m-2 bg-primary brand-img">
                                                    <img class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}" >
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <div class="col-md-12 mt-3 text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <div id="hiddenDiv1">
                                        <h5 class="fs-4" id="registrationBannerAdd" [innerHTML]="content?.registrationBannerAdd?.content">
                                            Turn your opinions into rewards! Choose from a range of amazing gift vouchers from top brands across the world!
                                        </h5>
                                        <div class="row mt-3">
                                            <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                                                <ng-container *ngIf="i < 4 ">
                                                    <div class="col-6 col-lg-3 col-md-2 col-xs-4 mb-4 brand-img">
                                                        <img loading="lazy" class="img-fluid img-fluid-mob" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}">
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-5 order-1 order-lg-2 bg-gray" id="formDIV">
                <ng-container *ngIf="content">
                    <app-regform [content]="content" ></app-regform>
                </ng-container>
            </div>
            <!-- <div class="col-lg-5 bg-gray" id="formDIV">
                <form action="#" method="" class="appoinment-form mt-1 ps-lg-4 pe-lg-4 p-2 rounded">
                    <div class="row py-1 text-center register-form">
                        <div class="col-12 col-md-12">
                            <h4 class="fs-2 mt-2 mb-md-3 mb-2 mt-5 mt-md-0 fw-bold">Hello!</h4>
                            <h4 class="fs-5 mt-2 mb-md-4 mb-2 lh-sm"> Become a part of our global community of 6.4
                                million survey takers! Share your opinions, influence brands and get rewarded. Let's
                                make your voice count!</h4>
                        </div>
                        <div class="col-md-8 mb-3 mx-auto">
                            <div class=" text-center">
                                <div class="form-group">
                                    <input type="checkbox" id="terms" name="terms" style="width: 12px;height: 12px;">
                                    <span style="font-size: 13px;color: #000;margin-left: 5px;"> <a href="#"
                                            target="_blank" style="color: #0194a8;"> Terms and Conditions</a> and <a
                                            href="#" target="_blank" style="color: #0194a8;">Privacy Policy</a></span>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" id="button4" class="btn btn-outline-light rounded-pill w-100"
                                    style="padding: 7px 0px;margin-top: 7px;">Sign up with email</button>
                            </div>
                        </div>
                        <div class="col-md-8 mx-auto">
                            <p>OR</p>
                        </div>
                        <div class="col-md-8 mb-1 mx-auto">
                            <div class="form-group">
                                <button class="btn1 btn-outline-light rounded-pill w-100 badge"
                                    style="padding: 8px 0px;margin-top: 7px;">Sign up with Google</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-5 col-sm-10 mx-auto text-center" id="form">
                <form action="#" method="POST" class="appoinment-form px-lg-5 p-2 rounded">
                    <div class="row text-center register-form">
                        <div class="col-12 col-md-12 mx-auto">
                            <h4 class="fs-2 mt-2 mb-md-4 mb-3 mt-5 mt-md-0  fw-bold">Let's get started!</h4>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="text" class="form-control rounded" placeholder="First Name"
                                    style="padding: 7px 10px;height: 35px;" value="" required>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="text" class="form-control rounded" placeholder="Last Name"
                                    style="padding: 7px 10px;height: 35px;" value="" required>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="email" class="form-control rounded" placeholder="Your Email"
                                    style="padding: 7px 10px;height: 35px;" value="" required>
                            </div>
                        </div>
                        
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <select class="form-select form-select-sm form-control" aria-label=".form-select-sm example" style="padding: 3px 10px;height: 35px;border-radius: 6px;padding-right: 35px;">
                                    <option selected disabled>Select Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                    <option value="3">Non-Binary / Gender Non-conforming / Another identity not listed</option>
                                    <option value="4"> Prefer not to say </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input type="text" id="datepicker" class="form-control rounded" placeholder="Share your date of birth" style="padding: 3px 10px;height: 35px;" value="" required> </div>
                        </div>

                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input type="password" name="password" class="form-control rounded"
                                    placeholder="Password" style="padding: 7px 10px;height: 35px;" value="" required />
                            </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="password" class="form-control rounded"
                                    placeholder="Confirm Password" style="padding: 7px 10px;height: 35px;" value=""
                                    required>
                            </div>
                        </div>
                        <div class="col-lg-12 mx-auto">
                            <div class="row d-flex align-items-center">
                                <div class="col-md-12 my-2 mx-auto">
                                    <div class="form-group">
                                        <button type="submit" id="button5"
                                            class="btn btn-outline-light rounded-pill w-100"
                                            style="padding: 7px 0px;margin-top: 7px;">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-5 col-10 mx-auto text-center" id="otp">
                <h4 class="p-4 fs-3 pb-0 text-center mt-5 mt-md-0 fw-bold">Just one more step before we dive in!</h4>
                <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center">We have sent an OTP to your email id at #email#.
                </h5>
                <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center">To finish creating The Panel Station membership,
                    <br>enter your OTP below and activate your account
                </h5>
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                <br>
                <button type="button" id="button7" class="btn btn-outline-light rounded-pill w-50 mt-4"
                    style="padding: 7px 0px;margin-top: 7px;">Submit &amp; Proceed</button>
                <br> <a href="#" class="mt-5" style="color: #0194a8;">Resend OTP</a>
            </div>
            <div class="col-lg-5 text-center" id="thankyou">
                <div class="container pt-4">
                    <h5 class="fs-3 my-3 text-center fw-bold">#FirstName#, your journey starts here. </h5>
                    <div class="row">
                        <div class="col-md-10 mx-auto py-2 glass">
                            <h5 class="fs-4 mt-3 text-center px-5 mb-3"> Dive into the survey and earn <span
                                    class="fw-bold">#Points# points</span>. </h5>
                            <h5 class="fs-6 text-center"> (#MarketThreshold# Points = #Minthreshold# #Voucher#) </h5>
                            <div class="mx-auto">
                                <div class="form-group">
                                    <a href="profiler.html">
                                        <button type="button" class="btn btn-outline-light rounded-pill w-75 mt-4 mb-4"
                                            style="padding: 7px 0px;margin-top: 7px;">Start Now</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- OTP modal -->
<!-- Modal -->
<!-- <div class="modal fade" id="mymodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">
            <div class="modal-body">
                <div class="col-lg-10 mt-3 mt-lg-0 mx-auto">
                    <label for="fname">Verify Your Mobile Number</label>
                    <h6 class="mb-3">Please enter the otp received via SMS</h6>
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                </div>
            </div>
            <div class="modal-footer border-0 d-block pt-0">
                <button type="button" class="btn btn-outline-light rounded" data-bs-dismiss="modal"
                    data-bs-toggle="modal" data-bs-target="#mymodal4"
                    style="padding: 3px 20px;margin-top: 7px;">Continue</button>
                <h6>Didn't receive OTP?<a href="#" style="color: #0194a8;">Click here</a></h6>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="modal fade" id="mymodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">
            <div class="modal-body">
                <div class="col-lg-12 text-center">
                    <div class="svg1">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path1 circle" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path1 check" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-linecap="round" stroke-miterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                    </div>
                    <h4 class="p-4 fs-4 text-center">Mobile Number Verified Successfully</h4>
                </div>
            </div>
        </div>
    </div>
</div> -->

<ng-template #youtube>
       
    <div class="modal-body">
        <div id="video-container" class="video-container">
            <iframe
            width="100%" 
            height="400"
            [src]="sanitizedVideoUrl"
            frameborder="0"
            allowfullscreen
        ></iframe>
        </div>
    </div>
        
    <div class="modal-footer">
        <button (click)="modalRef?.hide()" id="close-video" type="button" class="button btn-default" data-bs-dismiss="modal"><span aria-hidden="true" class="fs-1 fw-bold" style="margin-left: -3px;">&times;</span> </button>
    </div>
      
</ng-template>