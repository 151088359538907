import { Component, Renderer2, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { DomSanitizer, Meta, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
  public content?:any = {'locSpec' : 'ss'};
  rewards: any;
  modalRef?: BsModalRef;
  sanitizedVideoUrl!: SafeResourceUrl;
  constructor(  public appSer: AppService, private _activatedRoute: ActivatedRoute, private metaService:Meta, private modalService:BsModalService, private sanitizer:DomSanitizer, private renderer2:Renderer2){}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
    // To load the content on page load
    this.appSer.markeLoaded.subscribe((data:string) => {
      let page = this.appSer.getlastRouteSegment(this._activatedRoute);
      this.appSer.getPageContent(page).subscribe( (res:any)=>{
        this.content = res.data;
        this.appSer.setTitle(res.data.title);
        this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService);
        // this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/pEFq0tCWBDI");
        this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.youtubeURL.content);
        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }
      });

      this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
        this.rewards = res.data;
      });

    });

   
  }
  handleVideoControl(event:any) { // to handle play and pause the video on hover 
    let videoElement = event.target;
    if (event.type === 'mouseenter') {
      videoElement?.play()
    } else if (event.type === 'mouseleave') {
      videoElement?.pause();
    }
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template,{ class: 'modal-lg youtube-video modal-dialog-centered' });
  }
}
