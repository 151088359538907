import { Component, ElementRef, Inject, Input, PLATFORM_ID, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import * as bootstrap from 'bootstrap';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart} from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { interval, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
declare var bootstrap: any; 
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  private previousUrl: string = '';
  socialLinkDetails: any;
  subscribeForm!: FormGroup;
  isSubmitted: boolean = false; 
  isSubmittedEmail: boolean = false;
  subscribeFormDetails!: FormGroup;
  countryList: any;
  footerModalElement: any;
  @Input() navigationOptions: any[] = [];
  @ViewChild('footerModal') footerModal!: ElementRef;
  @ViewChild('marketSelect') marketSelect!: ElementRef<HTMLSelectElement>; 
  // @ViewChild('verifyOtp', { static: false }) verifyOtpElement: ElementRef | undefined;
  verifyOtp:boolean =false;
  aboutUs: any;
  paidSurvey: any;
  howItWorks: any;
  rewardZone: any;
  joinUsForFree: any;
  footerContent: any;
  registerContent:any;
  urlContainsRegistration: boolean = false;
  languageList: any;
  langCode: any;
  langSelection: any = '';
  year: any = new Date().getFullYear();
  subscriptionValid: boolean = false;
  @Input() country:any;

  otpMessage: string = '';
  invalidOtpMessage: string = '';
  showMessage: boolean = false;
  showInvalidMessage: boolean = false;
  countryErrorMessage: string = '';
  marketSelected: boolean = false;
  otpVerified: boolean = false; 
  resendTimer: number = 60;
  resendTimerOn: boolean = false;
  disableGenerateOTP: boolean = false;
  disableVerifyOTP: boolean = false;
  disableResendOTP: boolean = false;
  disableCountrySelect: boolean = false;
  qparams = {};
  messageColor:string = "red";
  subscriptionDetails: any;
  subClass: any;
  modalRef?: BsModalRef;
  constructor(public appSer: AppService, private fb: FormBuilder, private renderer: Renderer2, private router: Router, private _activatedRoute: ActivatedRoute, private renderer2:Renderer2,@Inject(PLATFORM_ID) private platformId: Object,private modalService:BsModalService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Store the current URL before the navigation happens
        this.previousUrl = this.router.url;
      }
    });
  }
  private timerSubscription: Subscription | undefined;
  urlContainsThankyou: boolean = false;
  
  

  ngOnInit(): void {

    this.appSer.markeLoaded.subscribe((data: string) => {
      this.appSer.getSocialLinks().subscribe((res: any) => {
        this.socialLinkDetails = res.data;
      });

      this.getPageContent();
    });

    this.subscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

    this.subscribeFormDetails = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      EmailId: ['', [Validators.required, Validators.email]],
      Market: ['Choose Country', Validators.compose([Validators.required, this.countryValidator])],
      termsTnC: [false, Validators.requiredTrue],
      marketingConsent: [false, Validators.requiredTrue],
      otp: [''],
      emailVerified: [false]
    });
  
    this.subscribeFormDetails.get('fullName')?.valueChanges.subscribe(value => {
      this.subscribeFormDetails.patchValue({
        fullName: this.cleanFullName(value)
      }, { emitEvent: false });
    });
    
    this.appSer.getCountryList().subscribe((res: any) => {
      this.countryList = Object.values(res.data)
    });

    let lang:any;
    this.appSer.getLanguageList().subscribe((res: any) => {
      // console.log("currentMarketCode Called!");
      // console.log('Current Market:', this.appSer.globalMarket);
      this.appSer.currentMarketCode();
      this.languageList = Object.values(res.data);
      // console.log("LANGUAGE LIST :  ", this.languageList);
      this._activatedRoute.queryParams.subscribe((params) => {
        // console.log('PARAM : ', params);
        // console.log('PARAM LANG: ', params['lang']);
        lang = this.appSer.matchURLToCountryLang(params['lang'], this.languageList);
        // console.log('CURRENT PARAM : ', lang);
      });

      let urlParams = this._activatedRoute.snapshot.queryParams;
      (lang) ? this.langSelection = lang.langID : "";
      if (urlParams['lang']) {
        this.appSer.isArabic(lang.country_lang_id).subscribe((data: any) => {
          this.appSer.setLanguage(lang.langID);
        });
      }
      this.appSer.selectedLanguage.subscribe((lang) => {
        this.langSelection = lang;
      });
    });
    this.checkURL();
    if (isPlatformBrowser(this.platformId)) {
      import('bootstrap').then((bs) => {
        bootstrap = bs;
      });
    }
  }
  checkMarket() {
    this.appSer.getFooterlang(this.langSelection).subscribe((data: any) => {
      this.appSer.marketStyles(data);
      if (data.data.country_lang_id) {
        let url = this.router.url.split('?')[0];
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([url], { queryParams: { lc: data.data.country_lang_id } });
        });
        this.appSer.langChanged();
      }
    });
  }

  private cleanFullName(value: string): string {
    const trimmedText = value.trimStart(); // Remove leading spaces
    const cleanedText = trimmedText
      .replace(/[^A-Za-zÀ-ÖØ-öø-ÿĀ-žḀ-ỿ ]/g, '') // Remove invalid characters
      .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      .trimStart();
    return cleanedText;
  }

  ngOnDestroy(): void {
    this.stopResendTimer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navigationOptions'] && changes['navigationOptions'].currentValue) {
      this.aboutUs = this.navigationOptions.find(item => item.name === "About Us");
      this.paidSurvey = this.navigationOptions.find(item => item.name === "Paid Surveys");
      this.howItWorks = this.navigationOptions.find(item => item.name === "How it Works");
      this.rewardZone = this.navigationOptions.find(item => item.name === "Reward Zone");
      this.joinUsForFree = this.navigationOptions.find(item => item.name === "Join Us for Free");
    }
  }

  countryValidator(country: FormControl): { [key: string]: boolean } | null {
    if (country.value === '' || country.value === null || country.value === 'Choose Country') {
      return { 'invalidCountry': true };
    }
    return null;
  }

  closeReset(){
        this.formReset();
  }

  onSubscription(footerModal: TemplateRef<void>) {
    this.isSubmittedEmail = true;
    this.subscribeFormDetails.patchValue({ EmailId: this.subscribeForm.value.email });
    this.subscribeFormDetails.get('EmailId')?.disable();
    if (this.subscribeForm.valid) {
      this.showModal(footerModal);
      this.subscribeForm?.reset();
      this.isSubmittedEmail = false;
    }
    // this.renderer.listen(this.footerModal?.nativeElement, 'hidden.bs.modal', () => {
    //   this.onModalClose();
    // });
    if (this.modalRef && this.modalRef.onHide) {
      this.modalRef.onHide.subscribe(() => {
        this.onModalClose();
      });
    }
    this.appSer.getPageContent('register').subscribe( (res:any)=>{
      this.registerContent = res.data;
    });
  }



  onSubmit() {
    this.isSubmitted = true;
    console.log(this.footerContent);

    if (!(this.disableGenerateOTP)) {
      this.otpMessage = this.footerContent?.['Email verification is required.'] || 'Email verification is required.';
      this.messageColor = 'red';
      this.showMessage = true;
      this.subscriptionValid = false;
      return;
    }

    if (!this.otpVerified) {
      this.invalidOtpMessage = this.footerContent?.['Please verify your OTP.'] || 'Please verify your OTP.';
      this.showInvalidMessage = true;
      console.log('OTP not verified, displaying message:', this.invalidOtpMessage);
      this.subscriptionValid = false;
      return;
    }
  
    if (this.subscribeFormDetails.valid) {
      this.subscribeFormDetails.get('EmailId')?.enable();
      this.appSer.saveSubscriptionDetails(this.subscribeFormDetails.value).subscribe((res: any) => {
        this.subscriptionDetails=res.response;
        this.subClass = (this.subscriptionDetails.Message.includes("Thank"))?'text-success':'text-danger'
        this.subscriptionValid = true;
        this.hideSubscriptionPopUp();
      });
      this.subscribeFormDetails.get('EmailId')?.disable();
    } else {
      console.log("Invalid", this.subscribeFormDetails.value);
    }

    if (!(this.subscribeFormDetails.valid)) {
      console.log("Invalid form details:", this.subscribeFormDetails.value);
      return; 
    } else{
        this.subscribeFormDetails.get('EmailId')?.enable();
        this.appSer.saveSubscriptionDetails(this.subscribeFormDetails.value).subscribe((res: any) => {
          // Handle subscription success
        });
        this.subscribeFormDetails.get('EmailId')?.disable();
        this.subscriptionValid = true;

        setTimeout(() => {
          this.formReset();
        }, 10000);
      }
  }

  formReset(){
    this.isSubmitted = false;
    this.subscribeFormDetails.get('otp')?.enable();
    this.modalRef?.hide();
    // this.footerModalElement.hide();
    this.subscribeFormDetails.reset({
      fullName: '',
      EmailId: '',
      Market: 'Choose Country',
      termsTnC: false,
      marketingConsent: false,
      otp: ''
    });

    // Resetting messages after successful form submission
    this.subscriptionValid = false;
    this.otpMessage = '';
    this.invalidOtpMessage = '';
    this.showMessage = false;
    this.showInvalidMessage = false;
    this.countryErrorMessage = '';
    this.marketSelected = false;
    this.otpVerified = false; 
    this.resendTimer = 60;
    this.resendTimerOn = false;
    this.disableGenerateOTP = false;
    this.disableVerifyOTP = false;
    this.disableResendOTP = false;
    this.disableCountrySelect = false;
    this.verifyOtp = false

    // if (this.verifyOtpElement) {
    //   this.verifyOtpElement.nativeElement.style.display = 'none';
    // }
  }
  
  hideSubscriptionPopUp(){
    setTimeout(() => {
      this.subscriptionValid = false;
      this.footerModalElement.hide();
      // this.subscribeFormDetails.reset();
      // this.subscribeFormDetails.reset({ Market: null })
      this.subscribeFormDetails.reset({
        fullName: '',
        EmailId: '',
        Market: 'Choose Country', // Set default value
        termsTnC: false,
        marketingConsent: false,
        otp: ''
      });

      this.subscriptionValid = false;
      this.otpMessage = '';
      this.invalidOtpMessage = '';
      this.showMessage = false;
      this.showInvalidMessage = false;
      this.countryErrorMessage = '';
      this.marketSelected = false;
      this.otpVerified = false; 
      this.resendTimer = 20;
      this.resendTimerOn = false;
      this.disableGenerateOTP = false;
      this.disableVerifyOTP = false;
      this.disableResendOTP = false;
      this.verifyOtp = false;
      // if (this.verifyOtpElement) {
      //   this.verifyOtpElement.nativeElement.style.display = 'none';
      // }
      this.subscriptionDetails='';
    }, 4000);
  }

  showModal(footerModal:TemplateRef<void>): void {
    this.verifyOtp = false
    if (this.footerModal) {
      // if (isPlatformBrowser(this.platformId)) {
      // this.footerModalElement = new bootstrap.Modal(this.footerModal.nativeElement);
      // }
      this.modalRef = this.modalService.show(footerModal, Object.assign({}, { class: 'modal-dialog modal-lg modal-dialog-centered' }));
      // this.footerModalElement.show();
      // let marketInfo:any = localStorage.getItem("marketInfo");
      let marketInfo:any = this.appSer.getLocalStorage("marketInfo");
      marketInfo = JSON.parse(marketInfo);
      console.log(marketInfo.name)
      setTimeout(() => {
        this.subscribeFormDetails.patchValue({ Market: marketInfo.name });
      },0);
    } else {
      console.error('footerModal is not defined');
    }
  }

  onModalClose(): void {
    this.formReset();
  }

  checkURL() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlContainsRegistration = event.urlAfterRedirects.includes('register');
        this.urlContainsThankyou = event.urlAfterRedirects.includes('thankyou');
      }
    });
  }

  setCountryURLLang(event: any) {
    // let dataContent = event.target.options[event.target.selectedIndex].getAttribute('data-content');
    this.appSer.setLanguage(this.langSelection);
    if (this.langSelection !== "") {
      this.appSer.getFooterlang(this.langSelection).subscribe((data: any) => {
        this.appSer.marketStyles(data);
        let selectedLang = this.languageList.find((lang: any) => lang.id === this.langSelection);
        if (selectedLang && selectedLang.country_lang_id) {
          let url = this.router.url.split('?')[0];
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.appSer.addToLocalStorage('country_lang_id', selectedLang.country_lang_id);
            this.router.navigate([url], { queryParams: { lang: selectedLang?.langCode } });
          });
          this.appSer.langChanged();
        }
      });
    }
    this.appSer.langChangedEvent.subscribe(() => {
      setTimeout(() => {
        this.getPageContent();
      }, 1000);
    });
  }

  getPageContent() {
    this.appSer.getPageContent("footer").subscribe((res: any) => {
      this.footerContent = res.data;
      let spinnContent: any = {
        yeah: (this.footerContent?.[" Yeahhhh!!!"]) ? (this.footerContent?.[" Yeahhhh!!!"]) : 'Yeahhhh!!!',
        YouJustWon: (this.footerContent?.["You Just Won"]) ? (this.footerContent?.["You Just Won"]) : 'You Just Won',
        clickHereToClaim: (this.footerContent?.["Click here to claim"]) ? (this.footerContent?.["Click here to claim"]) : 'Click here to claim'
      }
      if( this.appSer.isPlatformBrowser()){
        this.appSer.addToLocalStorage(`Already have an account?`, (this.footerContent?.["Already have an account?"]));
        this.appSer.addToLocalStorage('spinnContent',JSON.stringify(spinnContent));
      }
    });
  }

  generateOTP(): void {
    const email = this.subscribeFormDetails.get('EmailId')?.value;
    const country = this.subscribeFormDetails.get('Market')?.value;
    const countryTranslation = (this.footerContent?.['Choose Country'])? (this.footerContent?.['Choose Country']) : 'Choose Country';

    if (country === countryTranslation) {
      this.countryErrorMessage = (this.footerContent?.['Please select a country.'])? (this.footerContent?.['Please select a country.']) : 'Please select a country.';
      return;
    }
    
    this.disableCountrySelect = true;
    this.disableGenerateOTP = true;
    console.log('Sending OTP to email:', email, 'Country:', country);
  
    this.showMessage = false;
  
    this.appSer.sendSubscribeOTP(email, country).pipe(
      tap((response: any) => {
        console.log('OTP send response:', response);
  
        if (response.success && response.data.response.Status) {
          this.otpMessage = response.data.response.Message;
          this.messageColor = 'green';
          if(!this.resendTimerOn){
            this.startResendTimer();
          }
          this.verifyOtp = true;
          
          // if (this.verifyOtpElement) {
          //   this.verifyOtpElement.nativeElement.style.display = 'block';
          // }
        } else {
          this.otpMessage = response.data.response.Message;
          this.messageColor = 'green';
          this.disableGenerateOTP = true;
        }
  
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
          this.otpMessage = '';
        }, 5000);
      }),
      catchError((error) => {
        console.error('Failed to send OTP', error);
        this.otpMessage = 'Failed to send OTP. Please try again.';
        this.messageColor = 'red';
        this.showMessage = true;
        return throwError(() => error);
      })
    ).subscribe();
  }
  
  onOtpInput(event: any): void {
    const input = event.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, '');
  
    if (cleanedInput.length > 6) {
      this.subscribeFormDetails.get('otp')?.setValue(cleanedInput.slice(0, 6), { emitEvent: false });
    } else {
      this.subscribeFormDetails.get('otp')?.setValue(cleanedInput, { emitEvent: false });
    }

    if (cleanedInput.length > 0){
      this.showInvalidMessage = false;
    }
  }
  
  resendOTP(): void {
    if (!this.resendTimerOn) { 
      this.disableResendOTP = true;
      this.startResendTimer();
      this.generateOTP();
    }
  }

  startResendTimer(): void {
    this.resendTimer = 60;
    this.resendTimerOn = true;
    this.timerSubscription = interval(1000).subscribe(() => {
      this.resendTimer--;
      if (this.resendTimer === 0) {
        this.stopResendTimer();
      }
    });
  }

  stopResendTimer(): void {
    this.resendTimerOn = false;
    this.disableResendOTP = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
  
  verifySubscriberOTP() {
    const email = this.subscribeFormDetails.get('EmailId')?.value;
    const otp = this.subscribeFormDetails.get('otp')?.value;
    this.showMessage = true;
    this.showInvalidMessage = false;
    this.invalidOtpMessage = "";
    if(!otp){
      this.showInvalidMessage = true;
      this.invalidOtpMessage = (this.footerContent['Enter OTP']) ?  this.footerContent['Enter OTP'] : "Enter OTP";
      return;
    }

    if (!email || !otp) {
      return;
    }
  
    this.appSer.verifySubscribeOTP(email, otp).subscribe({
      next: (response: any) => {
        console.log('OTP Verification Response:', response);
        if (response.success) {
          const apiResponse = response.data.response; 
          if (apiResponse.Status === true) {
            this.subscribeFormDetails.patchValue({ emailVerified: true });
            console.log('OTP verification successful');
            this.disableResendOTP = true;
            this.resendTimerOn = false; 
            this.disableVerifyOTP = true;
            this.otpMessage = (this.footerContent?.['Email verification successful.'])? (this.footerContent?.['Email verification successful.']) : 'Email verification successful.';
            this.messageColor = 'green';
            this.showMessage = true;
            setTimeout(() => {
              this.showMessage = false;
            }, 5000);
            this.otpVerified = true; 
            this.invalidOtpMessage = '';
            this.showInvalidMessage= false;
            this.subscribeFormDetails.get('otp')?.disable();
          } else {
            this.invalidOtpMessage = apiResponse.Message;  
            this.showInvalidMessage = true;
            console.error('OTP verification failed:', apiResponse.Message);
          }
        } else {
          console.error('OTP verification failed');
        }
      },
      error: (error: any) => {
        console.error('Error verifying OTP:', error);
      }
    });
  }

  onMarketSelection(): void {
    const marketControl = this.subscribeFormDetails.get('Market');
    if (marketControl && marketControl.value !== 'Choose Country') {
      this.countryErrorMessage = ''; 
      this.marketSelected = true;
    }
  }

  needhelp(){
  }

  handleFooterMenuClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const clickedElement = target.closest('a');
    // const currentPage = this._activatedRoute.snapshot.url.map(segment => segment.path).join('/') || 'unknown';
    //const currentPage = this.router.url || 'unknown';
    const previousPage = this.previousUrl;
    const pageSegment = previousPage.split('/').pop() || 'unknown';
    const menuName = clickedElement ? clickedElement.innerText.trim() : 'unknown menu';
    
    // console.log('Footer menu clicked:', menuName);

    // Define caid and langID as null
    const caid = null;
    const langID = null;
    const message = `${pageSegment}:visited the ${menuName.toLowerCase()} menu`;
    // const message = `visited the ${menuName.toLowerCase()} menu`;
    console.log('message save as:',message);
    // Call the service to log user action and handle session internally
    this.appSer.loguser({ caid, langID }, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }
  
  
}

