import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { environment } from 'src/environments/environment';

export function passwordStrengthValidator(): ValidatorFn {

    // return (control:AbstractControl) : ValidationErrors | null => {
    //     const value = control.value;
        
    //     if (!value) {
    //         return null;
    //     }

    //     const hasUpperCase = /[A-Z]+/.test(value);
    //     const hasLowerCase = /[a-z]+/.test(value);
    //     const hasNumeric = /[0-9]+/.test(value);
    //     const hassplChar =/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    //     const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hassplChar && (value.length >= 8 && value.length <= 12);

    //     return !passwordValid ? {passwordStrength:true}: null;
    // }

    // new changes for password field
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const hasLetter = /[A-Za-z]+/.test(value);

        const hasNumeric = /[0-9]+/.test(value);

        const validLength = value.length >= 6;

        const passwordValid = hasLetter && hasNumeric && validLength;


        return !passwordValid ? { passwordStrength: true} : null;
     };
}

export function passwordMatch(formGroup: FormGroup) {
    const password = formGroup.get('camp_'+environment.pwQID)?.value;
    const confirmPassword = formGroup.get('camp_'+environment.cpwQID)?.value;
   
    if( typeof password == 'undefined' || typeof confirmPassword == 'undefined' || password == '' || confirmPassword == ''){
        return null;
    }
    return ( password === confirmPassword) ? null : { passwordNotMatch: true };
}

// export function firstNameValidator(): ValidatorFn {
//     return (control:AbstractControl) : ValidationErrors | null => {

//         const value = control.value;
//         if (!value) {
//             return null;
//         }
       
//         var specialcharchk = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
//         var digitchk = /\d/;
//         if (specialcharchk.test(value) || digitchk.test(value)) {
//             control.setValue(value.replace(/[^a-zA-Z]/g, ''));
 
//             // control.setValue("");
//            return {firstNameValidator:true}
 
//         }
//         return null;
//     }
// }

export function firstNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
  
      // Regex to check for invalid characters (special characters and digits)
      const invalidCharCheck = /[^a-zA-Z\s]/;
  
      if (invalidCharCheck.test(value)) {
        // Replace invalid characters with an empty string
        control.setValue(value.replace(/[^a-zA-Z\s]/g, ''));
  
        // Return validation error
        return { firstNameValidator: true };
      }
  
      return null;
    };
  }
  

  
export function emailPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;  
      }
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      if (!emailRegex.test(value)) {
        return { invalidEmail: true };
      }
      return null;
    };
  }

export function firstNameLength(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        if (value.length < 3) {
           return {firstNameLength:true}
        }
        return null;
    }
}

export function lastNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (!value) {
        return null;
      }
  
      // Regex to check for invalid characters (special characters and digits)
      const invalidCharCheck = /[^a-zA-Z\s]/;
  
      if (invalidCharCheck.test(value)) {
        // Replace invalid characters with an empty string (only allow A-Z, a-z, and spaces)
        control.setValue(value.replace(/[^a-zA-Z\s]/g, ''));
  
        // Return validation error
        return { lastNameValidator: true };
      }
  
      return null;
    };
  }
  

export function lastNameLength(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        if (value.length < 3) {
           return {lastNameLength:true}
        }
        return null;
    }
}

export function atLeastOneRadioSelectedValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
        const controls = (formGroup as FormGroup).controls;
        const valid = Object.keys(controls).some(key => controls[key].value);
        return valid ? null : { atLeastOneRequired: true };
    };
}