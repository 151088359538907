<section>
    <div class="pageBanner">
        <img loading="lazy" [src]="content?.fileDir + '/' + content?.termsofserviceImg?.content" class="w-100" />
        <div class="pageBannertext">
            <h3>TERMS AND CONDITIONS</h3>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row px-md-5 px-3 TextSize">
            <div class="Boxshadow" *ngFor="let key of contentOrder">
                <div [innerHtml]="content?.[key]?.content | safehtml"></div>
            </div>
        </div>
    </div>
</section>