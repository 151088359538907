import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent {
  content: any;
   private markeLoadedSubscription: any;
  constructor(private appSer :AppService,private _activatedRoute: ActivatedRoute){}
  ngOnInit(): void {
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
     this.getPageContent();
    });
  }

  getPageContent(){
    let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    this.appSer.getPageContent(page).subscribe( (res:any)=>{
      this.content = res.data;
    });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
}
