<div id="profilerContainer">
    <div id="profilerHeader" class="row d-flex">
        <div class="profilerHeaderLeft">
            <nav class="navbar-light p-3">
                <a><img loading="lazy" src="assets/resources/images/TPSLogo.svg" class="" width="158px" height="56px" alt=""></a>
            </nav>
        </div>
        <div class="profilerHeaderRight" *ngIf="(!this.formSubmitSuccess || this.formSubmitSuccess && !this.skipOtpVerification && !this.otpVerified && !this.mobileBonusPoints)">
            <div class="row mt-4" [ngStyle]="{'display': currentPage === 0 ? 'none' : 'block'}">
                <div class="col-10 mx-auto">
                    <div class="progress" style="height: 15px;">
                        <div class="progress-bar progress-bar-striped" id="progressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="custom-percentage"><b id="percentValue"> </b> <span style="color: #0194a8 !important;" id="completed" [innerHtml]="content?.completed?.content | safehtml">Completed</span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="prodiler-wrapper">
        <!-- <div class="row no-gutter">
            <div class="col-lg-12 col-xl-12">
                <nav class="navbar-light p-3" style="position: absolute;background-color: transparent;">
                    <a><img loading="lazy" src="assets/resources/images/TPSLogo.svg" class="d-inline-block align-top navbar-brand w-50 bg-light p-2 rounded-pill" style="box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);" alt=""></a>
                </nav>
            </div>
        </div> -->
        <div class="container-fluid">
            <div class="row no-gutter" id="questionastart">
                <!-- The image half -->
                <div class="col-md-6 d-flex align-items-center text-center">
                    <div class="container position-unset">
                        <div class="row" *ngIf="content">
                            <div class="col-lg-12 col-xl-12 p-5 mx-auto div-profiler" id="Div5" *ngIf="currentPage ==0  && !this.formSubmitSuccess && !this.skipOtpVerification && sampleid == null">
                                <h4 class="fs-1 fw-bold text-white" style="font-size: 60px;"><span id='welcomeMsg' [innerHtml]="content?.welcomeMsg?.content | safehtml">Welcome to your preliminary survey</span>, {{panelistInfo?.FirstName}} </h4>
                                <h4 class="text-white fs-5 mt-4"><span id="fillOutSurvey" [innerHtml]="content?.fillOutSurvey?.content | safehtml">Fill out this simple survey and earn</span> {{panelistInfo?.Points}}  {{panelistInfo?.PointText}}.</h4>
                                <h4 class="text-white fs-6 mt-2"> ({{panelistInfo?.PanelistMarketThreshold}} {{panelistInfo?.PointText}} = {{panelistInfo?.TrendingVoucherMinThreshold}} {{panelistInfo?.TrendingVoucher}})</h4> <img loading="lazy" src="assets/resources/images/profiler/banner.svg" class="w-30 mt-2" style="position: absolute;padding-right: 40px; bottom : 0;"> 
                            </div>
                            <div class="col-lg-12 col-xl-12 p-2 mx-auto div-profiler" id="Div4" *ngIf="this.formSubmitSuccess && !this.skipOtpVerification && !this.otpVerified && !this.mobileBonusPoints">
                                <div class="col-lg-12 col-md-12 rounded text-center mob-hide"> <img loading="lazy" src="assets/resources/images/profiler/banner-1.svg" class="w-50">
                                    <!-- <h1 class="fs-1 px-5 mt-md-0 mt-5 lh-sm text-white" id="kudosEarnedPoints" [innerHtml]="content?.kudosEarnedPoints?.content | safehtml"> Kudos! You have earned {{panelistInfo?.Points}}  {{panelistInfo?.PointText}} for taking the survey.</h1>  -->
                                    <h1 
                                        class="px-5 mt-md-0 mt-5 lh-sm text-white" 
                                        style="font-size: 30px;">
                                        <span 
                                            id="completingwelcomeMsg" 
                                            [innerHtml]="content?.completingwelcomeMsg?.content 
                                                        ? (content?.completingwelcomeMsg?.content | safehtml) 
                                                        : 'Kudos on completing the survey!'">
                                        </span>
                                        </h1> 
                                        <h1 
                                        class="px-5 mt-md-0 mt-5 lh-sm text-white" 
                                        style="font-size: 30px;">
                                        <span 
                                            id="moresurvey" 
                                            [innerHtml]="content?.moresurvey?.content 
                                                        ? (content?.moresurvey?.content | safehtml) 
                                                        : 'But wait, there’s more'">
                                        </span>
                                    </h1>
                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-12 p-2 mx-auto div-profiler" id="Div8" *ngIf="this.mobileBonusPoints && !this.otpVerified">
                                <div class="col-lg-12 col-md-12 rounded text-center mob-hide"> <img loading="lazy" src="assets/resources/images/profiler/banner-1.svg" class="w-50">
                                    <!-- <h1 class="fs-1 px-5 mt-md-0 mt-5 lh-sm text-white" id="kudosEarnedPoints" [innerHtml]="content?.kudosEarnedPoints?.content | safehtml"> Kudos! You have earned {{panelistInfo?.Points}}  {{panelistInfo?.PointText}} for taking the survey.</h1>  -->
                                    <h1 class="px-5 mt-md-0 mt-5 lh-sm text-white" style="font-size: 30px;"><span 
                                        id="completingwelcomeMsg" 
                                        [innerHtml]="content?.completingwelcomeMsg?.content 
                                                     ? (content?.completingwelcomeMsg?.content | safehtml) 
                                                     : 'Kudos on completing the survey!'">
                                      </span></h1> 

                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-12 p-2 mx-auto div-profiler" id="Div9" *ngIf="(this.formSubmitSuccess && this.otpVerified || this.formSubmitSuccess && this.skipOtpVerification && !this.otpVerified)">
                                <div class="col-lg-12 col-md-12 rounded text-center mob-hide"> <img loading="lazy" src="../../assets/resources/images/profiler/banner-2.png" class="img-fluid" 
                                    style="width: 75%;">
                                    <!-- <h1 class="fs-1 px-5 mt-md-0 mt-5 lh-sm text-white" id="kudosEarnedPoints" [innerHtml]="content?.kudosEarnedPoints?.content | safehtml"> Kudos! You have earned {{panelistInfo?.Points}}  {{panelistInfo?.PointText}} for taking the survey.</h1>  -->
                                    <!-- <h1 class="fs-1 px-5 mt-md-0 mt-5 lh-sm text-white" id="kudosEarnedPoints" [innerHtml]="content?.kudosEarnedPoints?.content | safehtml"> Kudos on completing the survey!</h1>  -->

                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-12 p-5 mx-auto div-profiler" id="Div7" *ngIf="(sampleid !== null) || (currentPage > 0  && !this.formSubmitSuccess)">
                                <h4 class="fs-1 fw-bold text-white lh-1" style="font-size: 60px;" id="hereYouGo" [innerHtml]="content?.hereYouGo?.content | safehtml">Here you go!</h4><img loading="lazy" src="assets/resources/images/profiler/banner.svg" class="w-30 mt-5 pt-5" style="position: absolute;padding-right: 40px; bottom : 0;"> </div>
                        </div>
                    </div>
                </div>

                <!-- PROFILER DIV -->
                <div class="col-md-6 bg-light p-0" id="Div2" *ngIf="!this.formSubmitSuccess && this.showProfilerDiv">
                    <div class="profilerStart login d-flex align-items-center" >
                        <!-- profiler start -->
                        <div class="container" [formGroup]="profilerQuestionsForm">
                            <div class="row">
                                <div class="col-lg-12 col-xl-12 mx-auto">
                                    <div class="row  p-0 px-md-5">
                                        <ng-container *ngIf="(profilerDetails?.response.Status == false) && this.mobileVerified">
                                            <div  [innerHtml]="profilerDetails?.response.Message | safehtml"></div>
                                        </ng-container>
                                        <div class="col-md-12 profilerQuestionList" *ngIf="profilerDetails?.response.Status == true">
                                            <!-- render the page start-->
                                            <ng-container *ngFor="let page of profilerQuestions;let i = index">
                                                <ng-container *ngIf="currentPage === (i)">
                                                <div class="step well" [formGroupName]="page.ID.toString()" data-id="{{page.ID}}">
                                                    <!-- Render the questions start -->
                                                    <ng-container *ngFor="let question of page.questions; let j=index;">
                                                        <div class="mb-4 formgroup">
                                                            <!-- <i class="bi bi-gender-ambiguous fs-1"></i> -->
                                                            <h5 class="mb-3">{{question.QuestionText}}</h5>
                                                            
                                                            <!-- 1. Drop Down -->
                                                            <ng-container *ngIf="question.QuestionType.includes('Drop Down')">
                                                            
                                                            <ngx-select-dropdown 
                                                                [config]="getConfig(question.ID)" 
                                                                [options]="question.AnswerChoice" 
                                                                (change)="onSelectionChange($event, question,page)">
                                                            </ngx-select-dropdown>
                                                                <input type="hidden" style="display: none;" type="text" [formControlName]="question.ID">
                                                            </ng-container>
                                                            <!-- 2.Date -->
                                                            <ng-container *ngIf="question.QuestionType.includes('Date')">
                                                                <div class="form-group">
                                                                    <input type="date" id="datepicker" class="form-control rounded w-75"
                                                                        placeholder="Tell us when you were born" style="padding: 3px 10px;height: 35px;" value="" [formControlName]="question.ID.toString()" max="2999-12-31">
                                                                </div>
                                                            </ng-container>
                                                            <!-- 3.Radio -->
                                                            <div *ngIf="question.QuestionType.includes('Radio Button')" class="mb-4">
                                                                
                                                                <div class="scroll-box">
                                                                    <ng-container
                                                                        *ngFor="let answers of question.AnswerChoice;let k = index">
                                                                        <div class="radiobtn">
                                                                            <input type="radio" (change)="otherOptionSelection(page,question,answers);" id="{{answers.ID}}" [formControlName]="question.ID.toString()" [value]="answers.ID" />
                                                                            <label for="{{answers.ID}}">{{answers.AnswerChoiceText}}</label>
                                                                        </div>
                                                                        <div class="is-other-textbox" *ngIf="answers.IsOthers && otherSelected[answers.ID]">
                                                                            <textarea 
                                                                                [formControlName]="'other-' + answers.ID" 
                                                                                placeholder="Type your answer here" 
                                                                                (input)="removeSymbol($event)">
                                                                            </textarea>
                                                                            <div class="text-danger mt-1" *ngIf="profilerQuestionsForm.get('other-' + answers.ID)?.invalid && profilerQuestionsForm.get('other-' + answers.ID)?.touched">
                                                                                This field cannot be empty.
                                                                              </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                
                                                            </div>
                                                            <!-- 4.Multiple selection -->
                                                            <ng-container *ngIf="question.QuestionType.includes('Multi Choice')">
                                                                <div [formArrayName]="question.ID.toString()" class="scroll-box">
                                                                <ng-container *ngFor="let answer of (question.AnswerChoice | filterOptions:searchTerms[question.ID].value); let k = index">
                                                                    <!-- <div class="mb-4"> -->
                                                                    <div class="radiobtn">
                                                                        <input type="checkbox" (change)="noOfTheAbove(page,question,answer);otherOptionSelection(page,question,answer);" [id]="answer.ID" [formControlName]="answer.ID" [value]="answer.ID" />
                                                                        <label [for]="answer.ID">{{answer.AnswerChoiceText}}</label>
                                                                    </div>
                                                                    <!-- </div> -->
                                                                    <div class="is-other-textbox" *ngIf="answer.IsOthers && otherSelected[answer.ID]">
                                                                        <textarea [formControlName]="'other-' + answer.ID" placeholder="Type your answer here" (input)="removeSymbol($event)"></textarea>
                                                                    </div>
                                                                </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <!-- Render the questions end -->
                                                </div>
                                                </ng-container>
                                            </ng-container>
                                            <!-- render the page end-->
                                            <div  class="alert alert-danger" role="alert" *ngIf="formGroupSubmit && !currentFormGroupValid">
                                                <!-- Above questions are mandatory, Please select answer -->
                                                {{(content?.['Above questions are mandatory, Please select answer'])? (content?.['Above questions are mandatory, Please select answer']):'Above questions are mandatory, Please select answer'}}
                                            </div>
                                            <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" (click)="ctaAction('next',profilerQuestions[currentPage+1]);" id="letstartbtn" *ngIf="currentPage == 0" id="letsstart" [innerHtml]="content?.letsstart?.content | safehtml">Let’s Start</button>
                                            <button class="btn btn-secondary rounded-pill action back mt-2" *ngIf="currentPage >1 && currentPage<profilerQuestions?.length" (click)="ctaAction('back',profilerQuestions[currentPage]);" id="back" [innerHtml]="content?.back?.content | safehtml">Back</button>
                                            <button class="btn btn-dark rounded-pill action next mt-2" id="Btnnext" (click)="ctaAction('next',profilerQuestions[currentPage+1]);" *ngIf="currentPage > 0 && currentPage<profilerQuestions?.length-1" id="next" [innerHtml]="content?.next?.content | safehtml">Next</button>
                                            <button class="btn action btn-dark rounded-pill submit btn btn-success mt-2" type="submit" value="Click" (click)="onSubmit();validateCurrentPageForm('next');ctaAction('Submit');"  *ngIf="currentPage == profilerQuestions?.length-1" id="submit" [innerHtml]="content?.submit?.content | safehtml">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>

            
                <!-- Mobile Verification Section -->
                <div class="otp-verification-container col-md-6 bg-light p-0 login d-flex align-items-center"  *ngIf="(this.formSubmitSuccess && !this.skipOtpVerification && !this.otpVerified) || this.showMobileVerification">
                    <div class="col-9 mx-auto text-center custom-p-style align-items-center" *ngIf="!this.mobileBonusPoints">
                        <h5 class="col-9 mx-auto text-center mb-3">
                            <span id="mobilepoints" 
                                  [innerHtml]="content?.mobilepoints?.content 
                                               ? (content?.mobilepoints?.content | safehtml) 
                                               : ('You can earn additional ' + (mobileInfo?.RewardValue || '') + ' ' + (mobileInfo?.RewardType || '') + ' by registering your mobile number with us.')">
                            </span>
                          </h5>
                          

                        <div class="input-group w-100 d-flex justify-content-center column gap-2 " style="padding-top : 25px; padding-bottom : 25px">

                            <button
                            class="confBtn"
                            (click)="skipOtp()"
                            [innerHtml]="content?.skipbonus?.content 
                                        ? (content?.skipbonus?.content | safehtml) 
                                        : 'No thanks, I\'ll skip the bonus.'">
                            </button>

                            <button
                            class="confBtn primaryBtn"
                            (click)="openOtp()"
                            [innerHtml]="content?.countbonus?.content 
                                        ? (content?.countbonus?.content | safehtml) 
                                        : 'Count me in for bonus points!'">
                            </button>

                        </div>
                        
                        <h5 
                        class="col-9 mx-auto text-center mt-3" 
                        id="surveypoints" 
                        [innerHtml]="content?.surveypoints?.content 
                                     ? (content?.surveypoints?.content | safehtml) 
                                     : ('You have earned ' + (panelistInfo?.Points || '0') + ' ' + (panelistInfo?.PointText || 'points') + ' on completing the preliminary survey.')">
                        </h5>
                    </div>

                    <div class="col-8 mx-auto custom-p-style align-items-center" *ngIf="this.mobileBonusPoints">
                        <h5 
                        class="mb-4 text-left" 
                        id="selectmode" 
                        [innerHtml]="content?.selectmode?.content 
                                     ? (content?.selectmode?.content | safehtml) 
                                     : 'Select your preferred mode to receive your OTP'">
                        </h5>
                      
                
                        <!-- OTP Mode Selection -->
                        <div class="d-flex justify-content-start">
                            <div *ngIf="showWhatsApp" class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="otpMode"
                                id="whatsapp"
                                value="whatsapp"
                                [(ngModel)]="selectedOtpModes"
                                [disabled]="isFormDisabled"
                            />
                            <label class="form-check-label" for="whatsapp" style="font-weight: normal;">WhatsApp</label>
                            </div>
                            <div *ngIf="showSms" class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="otpMode"
                                id="sms"
                                value="sms"
                                [(ngModel)]="selectedOtpModes"
                                [disabled]="isFormDisabled"
                            />
                            <label class="form-check-label" for="sms" style="font-weight: normal;">SMS</label>
                            </div>
                        </div>
                        
                        
                        <p *ngIf="getOtpClicked && !selectedOtpModes" class="text-danger text-left" style="font-size: 14px;">
                            Please select your preferred mode to receive the OTP.
                        </p>
                        
                        
                    
                    <!-- Mobile Number Input -->
                        <div class="input-group mt-3 mb-3 w-100">
                            <span class="input-group-text px-0 me-2 rounded">
                                <input
                                    type="text"
                                    class="form-control border-0 bg-transparent"
                                    [value]="'+' + (mobileInfo?.ISDCode || '')"
                                    readonly
                                    style="width: 55px; height: 20px; text-align : center;"
                                />
                            </span>
                            
                            <input
                                type="tel"
                                class="form-control rounded"
                                id="mobileNoInput"
                                [class.border-danger]="(isInvalid || !isConsentChecked) && getOtpClicked"
                                [(ngModel)]="mobileNumber"
                                (input)="onPhoneNumberInput()"
                                (keydown)="onKeyDown($event)"
                                [ngClass]="{'is-invalid': errorMessage || limitexpiredMessage}"
                                [placeholder]="(content?.['Enter Mobile Number']) ? (content?.['Enter Mobile Number']):'Enter Mobile Number'"
                                
                                aria-label="Mobile Number"
                                aria-describedby="basic-addon1"
                                [attr.minlength]="mobileInfo?.MobileNumberMinLength"
                                [attr.maxlength]="mobileInfo?.MobileNumberMaxLength"
                                pattern="[0-9]"
                                [disabled]="isFormDisabled">
                        </div>

                        <div 
                        *ngIf="errorMessage" 
                        class="text-danger my-2" 
                        style="font-size: 14px;" 
                        id="mobileErrorMessage" 
                        [innerHtml]="content?.mobileErrorMessage?.content 
                                    ? (content?.mobileErrorMessage?.content | safehtml) 
                                    : 'Mobile number is already registered'">
                        </div>
                        <!-- Error Message for Invalid Phone Number -->
                        <p 
                        *ngIf="showError" 
                        class="text-danger text-left my-2" 
                        style="font-size: 14px !important; line-height: 16px" 
                        id="providemobilenumber" 
                        [innerHtml]="content?.providemobilenumber?.content 
                                    ? (content?.providemobilenumber?.content | safehtml) 
                                    : 'Please enter a valid mobile number'">
                        </p>
                    
                        <!-- Agreement Checkbox -->
                        <div class="form-check mb-4 d-flex justify-content-start">
                            <input class="form-check-input me-2 mt-1" type="checkbox" value="" id="updatesAgreement" [(ngModel)]="isConsentChecked"  [disabled]="isFormDisabled">
                            <label 
                                class="form-check-label text-start m-0" 
                                for="updatesAgreement" 
                                style="font-weight: normal; line-height : 24px !important" 
                                [innerHtml]="content?.concernbox?.content 
                                            ? (content?.concernbox?.content | safehtml) 
                                            : 'I agree to receive updates through SMS, WhatsApp, and app notifications, with the option to change my preferences at any time.'">
                            </label>
                        </div>
                        
                        
                    
                        <!-- Error Message for Consent -->
                        <div *ngIf="getOtpClicked && !isConsentChecked" class="text-danger text-left" style="font-size: 14px;">
                        provide consent
                        </div>
                        
                        <!-- OTP Input Field (only shown when OTP is sent) -->
                        <div *ngIf="isOtpSent" class="input-group mb-1 w-100 mt-4 mb-3">
                            <input
                                type="text"
                                class="form-control rounded w-100"   
                                [placeholder]="(content?.['Enter OTP']) ? (content?.['Enter OTP']):'Enter OTP'"

                                [ngClass]="{
                                    'is-invalid': otperrorMessage || otpexpiredMessage|| (otp.length > 0 && otp.length <4)  ||limitexpiredMessage,
                                    'error-highlight': showErrorBorder
                                  }"
                                aria-label="OTP"
                                aria-describedby="basic-addon1"
                                [(ngModel)]="otp"
                                maxlength="4"
                                (input)="onOtpInput()"
                                (keydown)="onKeyDown($event)"
                                pattern="[0-9]{4}"
                                >
                        </div>
                        <p 
                        *ngIf="otperrorMessage" 
                        class="text-danger text-left mt-2" 
                        style="font-size: 14px !important; line-height: 16px" 
                        id="otperrorMessage" 
                        [innerHtml]="content?.otperrorMessage?.content 
                                    ? (content?.otperrorMessage?.content | safehtml) 
                                    : ' OTP is invalid'">
                        </p>
                        <p 
                        *ngIf="otpexpiredMessage" 
                        class="text-danger text-left mt-2" 
                        style="font-size: 14px !important; line-height: 16px" 
                        id="otpexpiredMessage" 
                        [innerHtml]="content?.otpexpiredMessage?.content 
                                    ? (content?.otpexpiredMessage?.content | safehtml) 
                                    : 'OTP Expired'">
                        </p>
                        <p 
                        *ngIf="limitexpiredMessage" 
                        class="text-danger text-left mt-2" 
                        style="font-size: 14px !important; line-height: 16px" 
                        id="limitexpiredMessage" 
                        [innerHtml]="content?.limitexpiredMessage?.content 
                                    ? (content?.limitexpiredMessage?.content | safehtml) 
                                    : 'OTP generation limit for the day has been exceeded'">
                        </p>
                        <p 
                        *ngIf="succesMessage" 
                        class="text-success text-left mt-2" 
                        style="font-size: 14px !important; line-height: 16px" 
                        id="succesMessage" 
                        [innerHtml]="content?.succesMessage?.content 
                                    ? (content?.succesMessage?.content | safehtml) 
                                    : 'OTP sent successfully'">
                        </p>

                        <!-- <p *ngIf="getOtpClicked && otp?.length !== 4" class="text-danger text-left" style="font-size: 14px !important;">
                            Please provide OTP.
                        </p> -->
                        <p 
                        *ngIf="otp.length > 0 && otp.length < 4" 
                        class="text-danger text-left mt-2" 
                        style="font-size: 14px !important;" 
                        id="provideotp" 
                        [innerHtml]="content?.provideotp?.content 
                                    ? (content?.provideotp?.content | safehtml) 
                                    : 'Please provide OTP.'">
                        </p>

                        <!-- OTP Button -->
                        <div class="flex-reverse d-flex justify-content-between text-end mt-2">
                        <!-- <button class="btn otp-button" (click)="validatePhoneNumber()" [disabled]="mobileNumber?.length !== 10 || isInvalid">
                            {{ isOtpSent ? 'Verify OTP' : 'Get OTP' }}
                        </button> -->

                        <!-- Resend OTP -->
                        <div 
                        *ngIf="isOtpSent && !resendOTPFlag" 
                        class="text-start d-flex align-items-center">
                        <div>
                            <span 
                            id="resendotp" 
                            [innerHtml]="content?.resendotp?.content 
                                        ? (content?.resendotp?.content | safehtml) 
                                        : 'Resend OTP'">
                            </span> in {{timeLeft}}s
                        </div>
                        </div>
            
                        <!-- Resend OTP Button -->
                        <div 
                        *ngIf="resendOTPFlag" 
                        class="text-start d-flex align-items-center">
                        <span 
                            style="cursor: pointer; color: #0194a8; text-decoration: underline;" 
                            (click)="resendOtp()" 
                            id="resendotp" 
                            [innerHtml]="content?.resendotp?.content 
                                        ? (content?.resendotp?.content | safehtml) 
                                        : 'Resend OTP'">
                        </span>
                        </div>
            

                        <button
                        *ngIf="!isOtpSent"
                        class="btn otp-button otpButton ms-auto"
                        (click)="validatePhoneNumber()"
                        [disabled]="!selectedOtpModes || mobileNumber.length > this.mobileInfo.MobileNumberMaxLength || mobileNumber.length < this.mobileInfo.MobileNumberMinLength || isInvalid || !isConsentChecked"
                        id="getotp"
                        [innerHtml]="content?.getotp?.content 
                                    ? (content?.getotp?.content | safehtml) 
                                    : 'Get OTP'">
                        </button>

                        <!-- Verify OTP Button -->
                        <button
                        *ngIf="isOtpSent"
                        class="btn otp-button otpButton ms-auto"
                        (click)="validateOtp()"
                        [disabled]="otp.length !== 4"
                        id="verifyotp"
                        [innerHtml]="content?.verifyotp?.content 
                                    ? (content?.verifyotp?.content | safehtml) 
                                    : 'Verify OTP'">
                        </button>

                        </div>
                
                    </div>
                    <div class="loader-overlay" *ngIf="isLoading">
                        <div class="loader"></div>
                    </div>
                    
                </div>
                <!-- </div> -->
                

                <!-- VOUCHER SELECTION DIV -->
                <div class="col-md-6 bg-light p-0" id="Div3" *ngIf="this.skipOtpVerification && !this.otpVerified">
                    <div style="height: 5px;"> </div>
                    <div class="login-2 d-flex align-items-center text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-11 col-xl-11 p-4 mx-auto">
                                    <div id="hideme" *ngIf="!claimYourVoucher">
                                        
                                        <p class="fw-normal mb-4" id="chooseVoucher" [innerHtml]="content?.chooseVoucher?.content | safehtml">Choose from the list below to redeem your voucher now!</p>
                                        <div class="row mt-3 mx-auto mb-4">
                                           
                                            <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                                                <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;display: ruby;" (click)="claimVoicher();claimYourVoucher=true;">
                                                    
                                                    <a class="test brand-img">
                                                        <div class="claim-voucher mb-2">
                                                            <img loading="lazy" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="image" class="image">
                                                            <div class="overlay">
                                                                <div class="text"> <span id="claimYour" [innerHtml]="content?.claimYour?.content | safehtml">Claim your</span></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-11 mx-auto" id="showme" *ngIf="claimYourVoucher"> <!--*ngIf="claimYourVoucher" -->
                                        <div class="card border-0 mb-3 glass">
                                            <div class="row d-flex align-items-center p-2">
                                                <div class="col-12 col-md-12 final text-start">
                                                    <div class="row d-flex align-items-center">
                                                        <div class="col-12 col-md-5">
                                                            
                                                            <div class="progress-wrapper" *ngIf="redeemPerc || redeemPerc == 0">
                                                                <div class="current progress-value" [ngStyle]="getOverlayStyle()">{{redeemPerc}}%</div>
                                                                <round-progress
                                                                [current]="redeemPerc"
                                                                [max]="100"
                                                                [color]="gradient ? 'url(#gradient)' : color"
                                                                [radius]="radius"
                                                                [stroke]="stroke"
                                                                [duration]="duration"
                                                                [animation]="animation"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 mt-3 mt-md-0">
                                                            <div class="boxes bg-light glass">
                                                                <input id="checkbox1" type="checkbox" checked (click)="$event.preventDefault()">
                                                                <label for="checkbox1" id="pointsEarned"> <span  [innerHtml]="content?.pointsEarned?.content | safehtml"  class="fw-normal"></span> {{panelistInfo?.Points}} {{panelistInfo?.PointText}}</label>
                                                                <input id="checkbox2" type="checkbox" (click)="$event.preventDefault()">
                                                                <label for="checkbox2" id="pointstobeEarned" [innerHtml]="content?.pointstobeEarned?.content | safehtml"  class="fw-normal">Earn #PointsRequired# more {{panelistInfo?.PointText}} to redeem your voucher</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <h4 class="fs-5 p-3 pb-0 mt-2" style="font-size: 16px; line-height: 23px;" id="redeem" [innerHtml]="content?.redeem?.content | safehtml">To redeem, you need #PointsRequired# {{panelistInfo?.PointText}} and must complete 1 consumer survey.</h4></div>
                                            </div>
                                        </div>
                                        <h1 class="mb-3 mt-4 mx-4 " style="font-size: 20px; line-height: 23px;" id="startTakingSurvey" [innerHtml]="content?.startTakingSurvey?.content | safehtml">Login in to your account and start completing surveys to earn even more points!</h1>
                                        <a [href]="loginLink.LoginLink">
                                            <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" id="loginNow" [innerHtml]="content?.loginNow?.content | safehtml">Login Now</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End -->
                 
                <!-- VOUCHER SELECTION DIV AFTER PHONE NUMBER VERIFICATION-->
                <div class="col-md-6 bg-light p-0" id="Div3" *ngIf="this.otpVerified">
                    <div style="height: 5px;"> </div>
                    <div class="login-2 d-flex align-items-center text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-11 col-xl-11 p-4 mx-auto">
                                    <div id="hideme" *ngIf="!claimYourVoucher">
                                       
                                        <p class="fw-normal mb-4" id="collectedpoints" [innerHtml]="content?.collectedpoints?.content | safehtml">Here's {{mobileInfo?.RewardValue}} {{mobileInfo?.RewardType}} for registering your mobile number</p>
                                        <p class="fw-normal mb-4" id="redeemvoucher" [innerHtml]="content?.redeemvoucher?.content | safehtml">Choose from the list below to redeem your voucher now!</p>
                                        <div class="row mt-3 mx-auto mb-4">
                                        
                                            <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                                                <div class="col-12 col-lg-4 col-md-4 col-xs-4" style="text-align: center; text-align: -webkit-center;display: ruby;" (click)="claimVoicher();claimYourVoucher=true;">
                                                    
                                                    <a class="test brand-img">
                                                        <div class="claim-voucher mb-2">
                                                            <img loading="lazy" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="image" class="image">
                                                            <div class="overlay">
                                                                <div class="text"> <span id="claimYour" [innerHtml]="content?.claimYour?.content | safehtml">Claim your</span></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-11 mx-auto" id="showme" *ngIf="claimYourVoucher"> <!--*ngIf="claimYourVoucher" -->
                                        <div class="card border-0 mb-3 glass">
                                            <div class="row d-flex align-items-center p-2">
                                                <div class="col-12 col-md-12 final text-start">
                                                    <div class="row d-flex align-items-center">
                                                        <!-- <div class="col-12 col-md-5"> -->
                                                            <!-- <div class="progress" [attr.data-percentage]="redeemPerc"> <span class="progress-left">
                        <span class="progress-bar"></span> </span> <span class="progress-right">
                        <span class="progress-bar"></span> </span>
                                                                <div class="progress-value">
                                                                    <div> {{redeemPerc}}% </div>
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="progress-wrapper" *ngIf="redeemPerc || redeemPerc == 0">
                                                                <div class="current progress-value" [ngStyle]="getOverlayStyle()">{{redeemPerc}}%</div>
                                                                <round-progress
                                                                [current]="redeemPerc"
                                                                [max]="100"
                                                                [color]="gradient ? 'url(#gradient)' : color"
                                                                [radius]="radius"
                                                                [stroke]="stroke"
                                                                [duration]="duration"
                                                                [animation]="animation"
                                                                />
                                                            </div> -->
                                                        <!-- </div> -->
                                                        <div class="col-12 col-md-12">
                                                            <div class="boxes bg-light glass">
                                                                <input id="checkbox1" type="checkbox" checked (click)="$event.preventDefault()">
                                                                <!-- <label for="checkbox1" id="pointsEarned"> <span  [innerHtml]="content?.pointsEarned?.content | safehtml"></span> {{panelistInfo?.Points}} {{panelistInfo?.PointText}}</label> -->
                                                                <label for="checkbox1" id="totalpointsEarned" [innerHtml]="content?.totalpointsEarned?.content | safehtml" class="fw-normal">So far you have earned <b>{{panelistInfo?.Points}} {{panelistInfo?.PointText}}</b> for completing the survey and <b>{{mobileInfo?.RewardValue}} {{mobileInfo?.RewardType}}</b> for registering the mobile number</label>
                                                                <input id="checkbox2" type="checkbox" (click)="$event.preventDefault()">
                                                                <label for="checkbox2" id="pointstobeEarned" [innerHtml]="content?.pointstobeEarned?.content | safehtml"  class="fw-normal">Earn #PointsRequired# more {{panelistInfo?.PointText}} to redeem your voucher</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <h1 class="fs-5 p-3 pb-0 mt-2" style="font-size: 16px;line-height: 23px;" id="redeem" [innerHtml]="content?.redeem?.content | safehtml">To redeem, you need #PointsRequired# {{panelistInfo?.PointText}} and must complete 1 consumer survey.</h1></div>
                                            </div>
                                        </div>
                                        <h1 class="mb-3 mt-4 mx-4 " style="font-size: 20px; line-height: 23px;" id="startTakingSurvey" [innerHtml]="content?.startTakingSurvey?.content | safehtml">Login in to your account and start completing surveys to earn even more points!</h1>
                                        <a [href]="loginLink.LoginLink">
                                            <button class="btn btn-dark rounded-pill mt-3" type="button" value="Click" id="loginNow" [innerHtml]="content?.loginNow?.content | safehtml">Login Now</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End -->
                
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="mymodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-center py-4">
                    <div class="modal-body">
                        <div class="col-lg-10 mt-3 mt-lg-0 mx-auto">
                            <label for="fname" class=""><b>Verify Your Mobile Number</b></label>
                            <h6 class="mb-3">Please enter the otp received via SMS</h6>
                            <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                            <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                            <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                            <input class="m-md-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" /> </div>
                    </div>
                    <div class="modal-footer border-0 d-block pt-0">
                        <button type="button" class="btn1 btn-outline-light rounded" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#mymodal4" style="padding: 3px 20px;margin-top: 7px;">Continue</button>
                        <p id="time">Time remaining <span id="timer" style="color: green;"></span></p>
                        <h6 id="resend">Didn't receive OTP?<a href="#" style="color: #0194a8;">Click here</a></h6> </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="mymodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-center py-4">
                    <div class="modal-body">
                        <div class="col-lg-12 text-center">
                            <div class="svg1">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle class="path1 circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline class="path1 check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " /> </svg>
                            </div>
                            <h4 class="pt-4 fs-4 text-center">Mobile Number Verified Successfully</h4> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>