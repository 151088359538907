import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

export const authGuard: CanActivateFn = (route, state) => {

  console.log(route.paramMap.get('market'))
  const authService = inject(AuthService);
  const appService  = inject(AppService);
  /*
  let market:any = appService.setdefaultmarket();
  if(route.paramMap.get('market')){
    market = route.paramMap.get('market');
  }
  //check localstoarge for market details
  let loadDetails = true;
  if(localStorage.getItem("marketInfo")){
    let info:any = localStorage.getItem("marketInfo");
    info = JSON.parse(info);
    if(info.iso2 == market){
      loadDetails = false;
    }
  }

  if(loadDetails == true){
    appService.getCountryDetails(market).subscribe( (res:any)=>{
        let  info =  Object.values(res.data)[0];
        localStorage.setItem("marketInfo", JSON.stringify(info) );
        // appService.marketDetailsLoaded(); //just emitting thevent
    });
  }
  */
  return true;
  if (authService.isValidToken()) {
    console.log("valid token")
    return true;
  } else {
    return new Promise(res => {
      appService.authenticateApp().subscribe ( (response:any)=>{
        authService.setToken(response.access_token);
        console.log("token loaded")
        res(true);
      }); 
    });
  }
  
  return true;
};
