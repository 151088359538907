<section class="bg-img_rewards"
    style="background-image: url('{{content?.fileDir}}/{{content?.rewardsBackground?.content}}');">
    <!-- style="background-image: url('{{content?.fileDir}}/{{content?.rewardsBackground?.content}}');" -->
    <div class="container  pt-3">
        <div class="row d-flex align-items-center div-height div-height1">
            <div class="col-12 text-center desktop-hide"><img loading="lazy" id="rewardsBackground"
                    src="assets/resources/images/rewards-bg-mob.webp" class="img-responsive"></div>
            <div class="col-lg-6 col-md-6 p-3 p-md-4 ps-md-5 direction-rtl">
                <h4 class="mt-0 text-left lh-sm" [innerHtml]="content?.gettingRewardsTitle?.content | safehtml"
                    id="gettingRewardsTitle">Getting rewards hasn’t been easier.</h4>
                <h5 class="fs-4 mt-3 mb-3" [innerHtml]="content?.gettingRewardsSubtitle?.content | safehtml"
                    id="gettingRewardsSubtitle">Speak your mind, have fun and get paid for it.</h5>
                <a [routerLink]="appSer.getLink('register',country)">
                    <button type="button" class="btn btn-outline-light mt-2 mb-4 rounded-pill"
                        [innerHtml]="content?.gettingRewardsButton?.content | safehtml" id="gettingRewardsButton" (click)="handleButtonClick('sign up')">Sign
                        Up For Free</button>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="bg-light">
    <div class="container p-lg-0 bg-primary-dark">
        <div class="row d-flex align-items-center p-0">
            <div class="col-md-6 p-0 text-center p-5 bg-light div-height earn-text">
                <h4 style="margin-top: 100px;" [innerHtml]="content?.youAddValueTitle?.content | safehtml"
                    id="youAddValueTitle">You add value with every survey you take.</h4>
                <h5 class="fs-4 mt-3 text-center" [innerHtml]="content?.youAddValueSubtitle?.content | safehtml"
                    id="youAddValueSubtitle"> In fact, you can begin your survey journey by answering these simple
                    questions.</h5>
            </div>

            <!-- Dynamic survey form start -->
            <div class="col-lg-6 col-md-6 py-3" *ngIf="content">
                <app-surveys [content]="content"></app-surveys>
            </div>
            <!-- Dynamic survey form end -->
            <!-- <div class="col-lg-6 col-md-6 text-center p-5" id="div7">
                <form class="appoinment-form mt-3 ps-lg-4 pe-lg-4 p-2 rounded">
                    <div class="row py-1">
                        <div class="col-lg-12 text-center mt-2">
                            <h4 class="text-white" id="we_need_opinion_title">We need your opinion.</h4>
                            <h5 class="fs-4 mt-3 px-3 text-center text-white" id="we_need_opinion_subtitle">  Answer these questions and add some fun to your day.</h5>
                            <button type="button" id="we_need_opinion_button" class="btn btn-outline-light rounded-pill mt-4 but">Get Started</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-6 py-3" id="div8">
                <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="climate_change_question">Do you think climate change affects your health?</h5>
                <form class="w-50 mt-4 ms-2 ms-md-5">
                    <div class="radiobtn">
                        <input type="radio" id="Yes" name="drone" value="Yes" />
                        <label for="Yes" id="climateQuestionYes">Yes</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="No" name="drone" value="No" />
                        <label for="No" id="climateQuestionNo">No</label>
                    </div>
                    <button type="button" id="button6" class="btn1 btn-outline-light rounded-pill mt-3 but">Ok</button>
                </form>
            </div>
            <div class="col-lg-6 py-3" id="div9">
                <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="climate_change_question2">Have you ever wondered how we know for sure that the climate is changing?</h5>
                <form class="w-50 mt-4 ms-2 ms-md-5">
                    <div class="radiobtn">
                        <input type="radio" id="Melt" name="drone" value="Melt" />
                        <label for="Melt" id="meltingGlaciers">Melting glaciers</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="More" name="drone" value="More" />
                        <label for="More" id="frequentIntenseHeat">More frequent &amp; intense heat waves</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="rise" name="drone" value="rise" />
                        <label for="rise" id="risingSeaLevels">Rising sea levels</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="all" name="drone" value="all" />
                        <label for="all" id="allOfTheAbove">All of the above</label>
                    </div>
                    <button type="button" id="button7" class="btn1 btn-outline-light rounded-pill mt-3 but">Ok</button>
                </form>
            </div>
            <div class="col-lg-6 py-3" id="div10">
                <h5 class="fs-3  ms-2 ms-md-5 w-75 text-white" id="carbon_footprint_question">What small changes you are willing to reduce your carbon footprint?</h5>
                <form class="w-50 mt-4 ms-2 ms-md-5">
                    <div class="radiobtn">
                        <input type="radio" id="Conserve" name="drone" value="Conserve" />
                        <label for="Conserve" id="conserveWater">Conserving water</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="transport" name="drone" value="transport" />
                        <label for="transport" id="publicTransport">Using public transportation</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="Plant" name="drone" value="Plant" />
                        <label for="Plant" id="plantingTrees">Planting more trees</label>
                    </div>
                    <button type="button" id="button8" class="btn1 btn-outline-light rounded-pill mt-3 but">Ok</button>
                </form>
            </div>
            <div class="col-lg-6 py-3" id="divTPS">
                <h5 class="fs-3  ms-2 ms-md-5 w-75 text-white" id="panelist_question">Are you a panelist on The Panel Station?</h5>
                <form class="w-50 mt-4 ms-2 ms-md-5">
                    <div class="radiobtn">
                        <input type="radio" id="option1" name="selection" value="option1" />
                        <label for="option1" id="panelistYes">Yes</label>
                    </div>
                    <div class="radiobtn">
                        <input type="radio" id="option2" name="selection" value="option2" />
                        <label for="option2" id="panelistNo">No</label>
                    </div>
                    <button type="button" onclick="showDiv()" class="btn1 btn-outline-light rounded-pill mt-3 but">Ok</button>
                </form>
            </div>
            <div id="result" class="col-lg-6 text-center p-4 hidden_div">
                <div id="option1Content">
                    <h4 class="p-4 fs-1 text-center text-white lh-sm" id="login_prompt">Login with your registered email id for more such engaging surveys.</h4>
                    <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but">Login</button>
                </div>
                <div id="option2Content">
                    <h4 class="p-4 fs-1 text-center text-white lh-sm" id="share_opinion_prompt">Your opinions hold immense worth. Share them with us!</h4>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#HelloModal" class="btn1 btn-outline-light rounded-pill mt-3 but">Register Now</button>
                </div>
            </div> -->
            <!-- End -->
        </div>
    </div>
</section>
<!-- <section class="section-sm wf-section ">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-5 col-lg-5 direction-rtl">
                <h5 class="fs-1 ms-lg-5" [innerHtml]="content?.redeemEarningsTitle?.content | safehtml"  id="redeemEarningsTitle">And you can redeem your earnings through</h5>
                <h5 class="fs-5 mt-2 ms-lg-5" [innerHtml]="content?.redeemEarningsSubtitle?.content | safehtml"  id="redeemEarningsSubtitle"> just by taking 1 - 2 surveys.<br>Patience is a virtue.<br> but you won’t need to exercise it here.</h5>
            </div>
            <div class="col-md-7 col-lg-7 text-center">
                <div class="row text-center parter-imgs">
                    
                    <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                        <div class="col-12 col-lg-4 col-md-4 col-xs-4 bg-light ms-4  mt-2 partner_logo">
                            <img id="rewardBrand{{i+1}}" class="mg-fluid img-fluid-mob w-100" src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="section-sm wf-section">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6">
                <h5 class="fs-1 ms-lg-5" [innerHtml]="content?.redeemEarningsTitle?.content | safehtml"
                    id="redeemEarningsTitle">
                    And you can redeem your earnings through
                </h5>
                <h5 class="fs-5 mt-2 ms-lg-5" [innerHtml]="content?.redeemEarningsSubtitle?.content | safehtml"
                    id="redeemEarningsSubtitle">
                    just by taking 1 - 2 surveys.<br>Patience is a virtue.<br> but you won’t need to exercise it here.
                </h5>
            </div>
            <div class="col-md-6 col-lg-6 text-center">
                <div class="row text-center parter-imgs">
                    <ng-container *ngFor="let reward of rewards?.rewards; let i = index;">
                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 p-1">
                            <img loading="lazy" id="rewardBrand{{i+1}}" class="img-fluid img-fluid-mob w-100"
                                src="{{rewards.storagePath}}/{{reward.imgPath}}" alt="{{reward.alt}}">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="wf-section">
    <div class="container bg-primary-dark p-lg-0 m-md-0 pb-1 pb-md-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6 p-md-5 p-4">
                <h5 class="fs-1 text-center text-white mb-4 earn-text1"
                    [innerHtml]="content?.funNonSurveyTitle?.content | safehtml" id="funNonSurveyTitle">Yes, we make
                    your non-survey hours fun too.</h5>
                <h5 class="fs-4 text-center text-white earn-text1"
                    [innerHtml]="content?.funNonSurveySubtitle?.content | safehtml" id="funNonSurveySubtitle">You can
                    earn assured rewards quickly not just through the best paid surveys but by participating in
                    auctions, contests, games and referral programs.</h5>
            </div>
            <div class="col-md-6 col-lg-6 bg-light div-height2 text-center p-md-5">
                <h5 class="fs-1 text-center mb-3 mt-3" [innerHtml]="content?.funSpinWinTitle?.content | safehtml"
                    id="funSpinWinTitle">Fun with Spin &amp; Win</h5>
                <section class="main1"> <span>
                        <ul class="wheel"></ul>
                    </span>
                    <button class="btn--wheel" [innerHtml]="content?.spinWheelButton?.content | safehtml"
                        id="spinWheelButton">Spin</button>
                </section>
                <section class="congrats justify-content-center col-md-12 col-sm-12">
                    <div class="msg">
                        <h4></h4>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
<section class="wf-section">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 order-md-2 col-lg-6 text-center p-lg-0"> <img loading="lazy" id="moreWayToEarnImg"
                    src="{{content?.fileDir}}/{{content?.moreWayToEarnImg?.content}}" class="img-fluid"> </div>
            <div class="col-md-6 order-md-1 col-lg-6 p-3 p-md-5 text-center">
                <h4 class="text-center mb-4" [innerHtml]="content?.moreWaysToEarnTitle?.content | safehtml"
                    id="moreWaysToEarnTitle">There’s more than just one way to earn.</h4>
                <h5 class="fs-4 text-center mt-2 earn-text1"
                    [innerHtml]="content?.moreWaysToEarnSubtitle1?.content | safehtml" id="moreWaysToEarnSubtitle1">Earn
                    The Panel Station coins as a joining bonus, from referrals or by converting your points to coins.
                </h5>
                <h5 class="fs-4 text-center mt-2 earn-text1"
                    [innerHtml]="content?.moreWaysToEarnSubtitle2?.content | safehtml" id="moreWaysToEarnSubtitle2">And
                    use the coins to take part in our <b>‘Bid-to-Win’</b> auction. Win big and get attractive vouchers.
                </h5>
                <button type="button" [routerLink]="appSer.getLink('register',country)" data-bs-toggle="modal"
                    data-bs-target="#exampleModal1" class="btn btn-outline-light mt-4 mb-4 rounded-pill"
                    [innerHtml]="content?.moreWaysWinButton?.content | safehtml" id="moreWaysWinButton" (click)="handleButtonClick('register now')">Register Now
                    &amp; Bid-to-Win</button>
            </div>
            <!-- removed class div-height -->
        </div>
    </div>
</section>
<!-- <section class="wf-section bg-light">
    <div class="container p-lg-0 m-md-0">
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-6 text-center p-lg-0"> <img loading="lazy" id="existingPanelistImg" src="{{content?.fileDir}}/{{content?.existingPanelistImg?.content}}" class="img-fluid w-100"> </div>
            <div class="col-md-6 col-lg-6 p-3 p-md-5 text-center">
                <h4 class="text-center mb-4" [innerHtml]="content?.existingPanelistTitle?.content | safehtml"  id="existingPanelistTitle">Are you an existing panelist?</h4>
                <h5 class="fs-4 text-center mt-2 earn-text1" [innerHtml]="content?.existingPanelistSubtitle1?.content | safehtml"  id="existingPanelistSubtitle1">We have got something fun for you too.</h5>
                <h5 class="fs-3 text-center mt-2 fw-bold earn-text1" [innerHtml]="content?.existingPanelistsubtitle2?.content | safehtml"  id="existingPanelistsubtitle2">Double the Fun. Double the Rewards.</h5>
                <h5 class="fs-4 text-center mt-2 pe-md-5 ps-md-5 earn-text1" [innerHtml]="content?.existingPanelistSubtitle3?.content | safehtml"  id="existingPanelistSubtitle3">Why have all the fun alone when you can double it with your friend along!</h5>
                <a [href]="environment.loginUrl">
                    <button type="button" class="btn btn-outline-light mt-4 mb-4 rounded-pill" [innerHtml]="content?.existingPanelistKnowMore?.content | safehtml"  id="existingPanelistKnowMore">Know More</button>
                </a>
            </div>
        </div>
    </div>
</section> -->
<section class="section-sm wf-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 text-center ps-md-5 pe-md-5">
                <h4 class="text-center lh-sm" [innerHtml]="content?.moreTitle?.content | safehtml" id="moreTitle">Wait,
                    there is more!</h4>
                <h5 class="fs-4 text-center mt-3" [innerHtml]="content?.moreSubtitle?.content | safehtml"
                    id="moreSubtitle">Want to be a part of our fun online community. Follow us on social media and get a
                    chance to be a part of exciting and engaging contests where you can win points, coins and gift
                    vouchers too.</h5>
            </div>
            <div class="col-md-12 col-lg-12 mt-4 text-center">
                <div class="social-media-icons col-xs-12">
                    <!-- <ul class="list-inline col-xs-12"> <a href="https://www.facebook.com/TPSIndiaOfficial" target="_blank"><i class="fa fa-facebook-square fa-4x"></i></a> <a href="https://twitter.com/StationPanel" target="_blank"><i class="fa fa-twitter-square fa-4x"></i></a> <a href="https://www.instagram.com/thepanelstation/" target="_blank"><i class="fa fa-instagram fa-4x"></i></a> <a href="https://www.youtube.com/channel/UCpuFdqaLo2f_3BVewT2rmmQ" target="_blank"><i class="fa fa-youtube-square fa-4x"></i></a> </ul> -->
                    <div class="footer-social-icon ms-1 mt-3">
                        <ng-container *ngFor="let item of socialLinkDetails?.sociallinks">
                            <img loading="lazy" class="social-links me-2 reward-social-links"
                                src="{{socialLinkDetails.storagePath}}/{{item.filePath}}" alt="{{item.alt}}"
                                (click)="appSer.openLink(item.link)">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-sm wf-section bg-primary-dark">
    <div class="container text-center">
        <div class="col-md-8 mx-auto col-lg-8 px-md-5">
            <h4 class="text-white lh-sm rewards-te4xt" style="font-size: 50px;"
                [innerHtml]="content?.earnRewardsTitle?.content | safehtml" id="earnRewardsTitle">Earn rewards for
                taking surveys and feel valued more often.</h4>
            <a [routerLink]="appSer.getLink('register',country)">
                <button type="button" class="btn btn-outline-light mt-3 rounded-pill"
                    [innerHtml]="content?.jtpsRewardsButton?.content | safehtml" id="jtpsRewardsButton" (click)="handleButtonClick('join the panel station')">Join The Panel
                    Station Today!</button>
            </a>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="HelloModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <!-- <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2 py-3">
            <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center px-4 py-0">
                <form action="#" method="" class="appoinment-form mt-1 p-2 rounded">
                    <div class="row py-1 text-center register-form">
                        <div class="col-12 col-md-12">
                            <h4 class="fs-2 mt-2 mb-md-3 mb-2 fw-bold">Hello!</h4>
                            <h4 class="fs-4 mt-2 mb-md-4 mb-2 lh-sm"> Become a part of our global community of 6.4 million survey takers! Share your opinions, influence brands and get rewarded. Let's make your voice count!</h4> </div>
                        <div class="col-md-12 mb-3 mx-auto">
                            <div class="form-group">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#getstartedModal" id="button4" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;margin-top: 7px;">Sign up with email</button>
                            </div>
                        </div>
                        <div class="col-md-12 mx-auto">
                            <p>OR</p>
                        </div>
                        <div class="col-md-12 mb-1 mx-auto">
                            <div class="form-group">
                                <button class="btn1 btn-outline-light rounded-pill w-100 badge" style="padding: 8px 0px;margin-top: 7px;">Sign up with Google</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> -->

</div>
<!-- <div class="modal fade" id="getstartedModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2 py-3">
            <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center py-0 px-4 ">
                <form action="#" method="POST" class="appoinment-form mt-3  p-0 rounded">
                    <div class="row py-1 ps-0 text-center register-form">
                        <div class="col-12 text-center">
                            <h4 class="fs-4 mt-2 mb-md-4 mb-3 fw-bold">Get started now!</h4></div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="text" class="form-control rounded" placeholder="First Name" style="padding: 7px 10px;height: 35px;" value="" required> </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="text" class="form-control rounded" placeholder="Last Name" style="padding: 7px 10px;height: 35px;" value="" required> </div>
                        </div>
                        <div class="col-md-12 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="email" class="form-control rounded" placeholder="Your Email" style="padding: 7px 10px;height: 35px;" value="" required> </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input type="password" name="password" class="form-control rounded" placeholder="Password" style="padding: 7px 10px;height: 35px;" value="" required/> </div>
                        </div>
                        <div class="col-md-6 mb-3  mx-auto">
                            <div class="form-group">
                                <input name="name" type="password" class="form-control rounded" placeholder="Confirm Password" style="padding: 7px 10px;height: 35px;" value="" required> </div>
                        </div>
                        <div class="col-lg-12 mx-auto">
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-12 text-start">
                                    <div class="form-group">
                                        <input type="checkbox" data-bs-toggle="modal" data-bs-target="#mymodal1" id="terms" name="terms" style="width: 12px;height: 12px;"> <span style="font-size: 13px;color: #000;margin-left: 5px;"> <a href="#" target="_blank" style="color: #0194a8;"> Terms and Conditions</a> and <a href="#" target="_blank" style="color: #0194a8;">Privacy Policy</a></span> </div>
                                </div>
                                <div class="col-md-12 mb-3 mx-auto">
                                    <div class="form-group">
                                        <button type="submit" id="button5" class="btn btn-outline-light rounded-pill w-100" style="padding: 7px 0px;margin-top: 7px;" data-bs-toggle="modal" data-bs-target="#exampleModal02">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="modal fade" id="exampleModal02" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2 py-3">
            <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center py-0 px-4 ">
                <div class="col-lg-12 text-center py-3" id="otp">
                    <h4 class="fs-2 pb-0 text-center p-md-0">Just one more step before we dive in!</h4>
                    <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center">We have sent an OTP to your email id at xxx.</h5>
                    <h5 class="p-4 pt-0 pb-0 fs-5 mt-4 mb-4 text-center">To finish creating The Panel Station membership, <br>enter your OTP below and activate your account</h5>
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <input class="m-2 text-center form-control rounded i-width inputs" type="text" maxlength="1" />
                    <br>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal03" class="btn btn-outline-light rounded-pill w-50 but mt-4">Submit &amp; Proceed</button>
                    <br> <a href="#" class="mt-5">Resend OTP</a> </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="modal fade" id="exampleModal03" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-2">
            <div class="modal-header border-0 p-0">
                <button type="button" class="btn-close pt-4 pe-4" style="font-size: 12px;font-weight: bold;" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
                <div class="col-lg-12 pb-3 text-center">
                    <div class="svg1">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path1 circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path1 check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " /> </svg>
                    </div>
                    <h4 class="p-4 fs-2 pb-0 text-center">Welcome to the Panel Station family.</h4>
                    <h5 class="p-4 pt-0 pb-0 fs-5 mt-3 text-center">You are all set to begin your rewarding journey. <br>Happy Surveying!</h5>
                    <h5 class="p-4 pt-0 pb-0 fs-5 mt-3 text-center"></h5>
                    <a href="profiler.html">
                        <button type="button" class="btn btn-outline-light rounded-pill w-50 but mt-2">Take Your First Survey</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div> -->