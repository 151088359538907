<section class="wf-section my-3">
    <div class="">
        <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-xs-4 rounded-pill mt-5">
                 <img loading="lazy" class="img-fluid img-fluid-mob w-100" src="assets/resources/images/subscribe/terminate.svg" alt="image"> </div>
        </div>
    </div>
    <div class="container all-img">
        <div class="row align-items-center w-100">
            <div class="col-md-6 col-lg-6">
                <h4 class="lh-base fs-2 ps-3"> </h4> </div>
            <div class="col-md-12 col-lg-12 ">
                <div class="row text-center">
                    <div class="col-lg-7 mx-auto text-center">
                        <h4 class="text-center lh-sm"  [innerHTML]="message | safehtml">Your answers are under review, we will get back to you</h4>
                    
                   
                    </div>


                    
                </div>
            </div>
        </div>
    </div>
</section>