import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.css']
})
export class TermsofserviceComponent {
  content: any;
  contentOrder: string[] = [
    'privacyNotice',
    'objectTermsandConditions',
    'rulesandElegibility',
    'winnerResponsibility',
    'redemption',
    'obligations',
    'obligationOfMembers',
    'termination',
    'legalAge',
    'intellectualRights',
    'limitationsonLiability',
    'disclaimerofWarranty',
    'dataProtection',
    'changesinTerms',
    'entireAgreement',
    'breachOrViolation',
    'forceMajeure',
    'jurisdictionsandLaw '
  ];
  private markeLoadedSubscription: any;
  constructor(private appSer: AppService, private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
      this.getPageContent();
    });
    
  }

  getPageContent() {
    let page = this.appSer.getlastRouteSegment(this._activatedRoute);
    this.appSer.getPageContent(page).subscribe((res: any) => {
      this.content = res.data;
      this.appSer.setTitle(res.data.title);
    });
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
}
