<div class="swiper mySwiper mySwipertestimonial">
  <div class="swiper-wrapper pb-3">
    <ng-container *ngFor="let testimonial of testimonailsData?.testmonials; let i = index;">
      <div class="swiper-slide">
        <div>
          <video class="homepagevideo" preload="metadata"
            [src]="testimonailsData.storagePath + '/' + testimonial.filePath+'#t=0.001'" width="auto" loop
            (mouseenter)="handleVideoControl($event)" (mouseleave)="handleVideoControl($event)" playsinline>
          </video>
          <div class="slide-content">
            <h3 [innerHtml]="testimonial?.name | safehtml"></h3>
            <hr>
            <p [innerHtml]="testimonial?.subtitle | safehtml"></p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
  <div class="swiper-scrollbar"></div>
</div>