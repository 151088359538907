import { Component , Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-verify-panelist',
  templateUrl: './verify-panelist.component.html',
  styleUrls: ['./verify-panelist.component.css']
})
export class VerifyPanelistComponent {
  public message = "verifying, please wait...";
  constructor(  
    public appSer: AppService, 
    private _activatedRoute: ActivatedRoute, 
    private router :Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._activatedRoute.paramMap.subscribe(({ params }: any) => {
        console.log(params.id);
        if (params.id) {
          this.appSer.verifyPanelist(params.id).subscribe((res: any) => {
            console.log(res);
            if (res.success) {
              if (typeof window !== 'undefined') {
                window.location.href = res.message;
              }
            } else {
              this.message = "Something went wrong";
            }
          });
        }
      });
    }
  }
}
