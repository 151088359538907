import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { Subject, take, takeUntil, tap, timer } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-paidsurveys',
  templateUrl: './paidsurveys.component.html',
  styleUrls: ['./paidsurveys.component.css']
})
export class PaidsurveysComponent {
  content: any;
  dynamicStr = "";
  i =1;
  subscription:any
  countUser: number = 3 ;
  closed$ = new Subject<any>();
  country = this.appSer.getLocalStorage('country');
  private markeLoadedSubscription: any;
  constructor(public appSer : AppService, private _activatedRoute: ActivatedRoute, private metaService:Meta, private renderer2:Renderer2){}

  ngOnInit(): void {
    this.markeLoadedSubscription = this.appSer.markeLoaded.subscribe((data:string) => {
      let page = this.appSer.getlastRouteSegment(this._activatedRoute);
      this.appSer.getPageContent(page).subscribe( (res:any)=>{
        this.content = res.data;
        this.appSer.setTitle(res.data.title);
        this.appSer.addMetaTagsFromString(res.data.metaData, this.metaService);
        this.displayText();
        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }
      });
    });
  }

  displayText() {
    if( this.appSer.isPlatformBrowser()){
    let dynamicTxt = this.content.dynamicContent.content
    
    if(dynamicTxt){
      dynamicTxt = dynamicTxt.split(',');
      this.countUser =  dynamicTxt.length;
      this.showMsg(dynamicTxt);
     
      setInterval(() => {
        this.countUser =  dynamicTxt.length; //just to reset the counter
        this.showMsg(dynamicTxt);
      }, (this.countUser*3000 )+2);

    }
    }
    // console.log(dynamicTxt);
  }

  setDynamicTxt(txt:any){
    this.dynamicStr = txt;
    // console.log(this.dynamicStr)
  }

  showMsg(dynamicTxt:any) {
    timer(0, 3000) // (1)
      .pipe(
        tap({ next: _ => this.countUser-- }), // (2)
        take(this.countUser), // (3)
        takeUntil(this.closed$) // (4)
      )
      .subscribe({
        next: count => {
          // console.log(count);
          this.setDynamicTxt(dynamicTxt[count])
        }
      });
  }

  ngOnDestroy() {
    this.closed$.next(0); // (5)
    if (this.markeLoadedSubscription) {
      this.markeLoadedSubscription.unsubscribe();
    }
  }
  handleButtonClick(buttonId: string): void {
    // Use ActivatedRoute to get the last part of the URL path
    const currentPage = this._activatedRoute.snapshot.url.map(segment => segment.path).join('/') || 'unknown';
    const message = `${currentPage}: user visited ${buttonId} button`;
    console.log('message',message);
    // Log user action with the constructed message
    this.appSer.loguser({ caid: null, langID: null }, message).subscribe({
      next: (data) => {
        console.log('User action logged successfully:', data);
      },
      error: (error) => {
        console.error('Error logging user action:', error);
      }
    });
  }
  
}
