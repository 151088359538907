import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent {
  constructor(  public appSer: AppService, private _activatedRoute: ActivatedRoute, private router :Router) { 
  }

  ngOnInit(){
    this.validateCountryList();
    // this.appSer.setmarket(this._activatedRoute);
  }
  
  // To check if the url country matches the database country codes. 
  // If url country code doesnt match, then shows the 404 Not found page
  validateCountryList() {
    // this.appSer.getCountryList().subscribe((res: any) => {
    //   let url = this.router.url.split('/'); //to check the url if it contains country code
    //   let langSet = url[1].split('?'); //to check if languege is selelcted
    //   let countryCode = (langSet.length > 1) ? langSet[0] : url[1];
    //   let countryList: any[] = Object.values(res.data);
    //   // if (!countryList.some(country => country.iso2 === countryCode)) {
    //   if (!countryList.some(country => country.iso2 === this.appSer.market)) {
    //     // this.router.navigate(['/not-found']);
    //   }
    // });

    this.appSer.isMarketExists(this.appSer.getLocalStorage('country')).subscribe((res: any) => {
      this._activatedRoute.paramMap.subscribe(({ params }: any) => {
        if (params['market']=='us') {
          this.router.navigateByUrl(this.router.url.replace('/us', ''));
        }
      })
      // console.log(res)
      if( res.success == false){
        this.router.navigate(['/not-found']);
      }
    });
  }
}
