import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { MarketComponent } from './market/market.component';
import { RewardzoneComponent } from './rewardzone/rewardzone.component';
import { PaidsurveysComponent } from './paidsurveys/paidsurveys.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { RegformComponent } from './regform/regform.component';
import { TerminateComponent } from './terminate/terminate.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { FaqsComponent } from './faqs/faqs.component';
import { CookiespolicyComponent } from './cookiespolicy/cookiespolicy.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { RegistrationComponent } from './registration/registration.component';
import { ProfilerComponent } from './profiler/profiler.component';
import { authGuard } from './auth.guard';
import { ProfilerterminateComponent } from './profilerterminate/profilerterminate.component';
import { VerifyPanelistComponent } from './verify-panelist/verify-panelist.component';
import { LoginredirectComponent } from './loginredirect/loginredirect.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';

const routes: Routes = [ 
  { path: '', component: HomeComponent, canActivate: [authGuard] },
  { path: 'reg', component: RegformComponent, canActivate: [authGuard] },
  { path: 'about-us', component: AboutComponent , canActivate: [authGuard]},
  // { path: 'home', component: HomeComponent, canActivate: [authGuard]},
  { path: 'paid-surveys', component: PaidsurveysComponent, canActivate: [authGuard] },
  { path: 'rewards', component: RewardzoneComponent, canActivate: [authGuard] },
  { path: 'terminate', component: TerminateComponent, canActivate: [authGuard] },
  { path: 'thankyou', component: ThankyouComponent, canActivate: [authGuard] },
  { path: 'how-it-works', component: HowitworksComponent, canActivate: [authGuard] },
  { path: 'not-found', component: NotfoundComponent, canActivate: [authGuard] },
  //{ path: 'faqs', component: FaqsComponent, canActivate: [authGuard] },
  { path: 'privacy-policy', component: PrivacypolicyComponent, canActivate: [authGuard]},
  { path: 'terms-and-conditions', component: TermsofserviceComponent, canActivate: [authGuard]},
  { path: 'cookie-policy', component: CookiespolicyComponent, canActivate: [authGuard]},
  { path: 'register', component: RegistrationComponent, canActivate: [authGuard]},
  { path: 'profiler/:id', component: ProfilerComponent, canActivate: [authGuard]},
  { path: 'profiler-terminate', component: ProfilerterminateComponent, canActivate: [authGuard]},
  { path: 'faqs', redirectTo: ''},
  { path: 'faq', redirectTo: ''},
  { path: 'home', redirectTo: ''},
  { path: 'aboutus', redirectTo: 'about-us'},
  { path: 'privacypolicy', redirectTo: 'privacy-policy'},
  { path: 'cookiespolicy', redirectTo: 'cookie-policy'},
  { path: 'termsandconditions', redirectTo: 'terms-and-conditions'},
  { path: 'contactus', redirectTo: ''},
  { path: 'crdct.aspx', component: LoginredirectComponent},
  { path: 'routing.aspx', component: LoginredirectComponent},
  { path: 'survey', component: LoginredirectComponent},
  { path: 'survey/:pathMatch(.*)*', component: LoginredirectComponent},
  { path: 'surveys', component: LoginredirectComponent},
  { path: 'surveys/:pathMatch(.*)*', component: LoginredirectComponent},
  { path: 'verify', component: VerifyPanelistComponent, canActivate: [authGuard]},
  // { path: 'blog', component: ComingsoonComponent,canActivate: [authGuard]},
  // { path: 'blog/:pathMatch(.*)*', component:ComingsoonComponent }, 
  { path: 'admin/api/verify/:id', component:VerifyPanelistComponent },
  { path: ':market',component: MarketComponent,
    children: [ { path: '', component: HomeComponent, canActivate: [authGuard] },
                // { path: 'home', component: HomeComponent, canActivate: [authGuard] },
                { path: 'about-us', component: AboutComponent, canActivate: [authGuard]},
                { path: 'paid-surveys', component: PaidsurveysComponent, canActivate: [authGuard] },
                { path: 'how-it-works', component: HowitworksComponent, canActivate: [authGuard] },
                { path: 'rewards', component: RewardzoneComponent, canActivate: [authGuard] },
                { path: 'terminate', component: TerminateComponent, canActivate: [authGuard] },
                { path: 'thankyou', component: ThankyouComponent, canActivate: [authGuard] },
                // { path: 'faqs', component: FaqsComponent, canActivate: [authGuard] },
                { path: 'privacy-policy', component: PrivacypolicyComponent, canActivate: [authGuard]},
                { path: 'terms-and-conditions', component: TermsofserviceComponent, canActivate: [authGuard]},
                { path: 'cookie-policy', component: CookiespolicyComponent, canActivate: [authGuard]},
                { path: 'register', component: RegistrationComponent, canActivate: [authGuard]},
                { path: 'profiler/:id', component: ProfilerComponent, canActivate: [authGuard]},
                { path: 'profiler-terminate', component: ProfilerterminateComponent, canActivate: [authGuard]},
                { path: 'faqs', redirectTo: ''},
                { path: 'faq', redirectTo: ''},
                { path: 'home', redirectTo: ''},
                { path: 'aboutus', redirectTo: 'about-us'},
                { path: 'privacypolicy', redirectTo: 'privacy-policy'},
                { path: 'cookiespolicy', redirectTo: 'cookie-policy'},
                { path: 'termsandconditions', redirectTo: 'terms-and-conditions'},
                { path: 'contactus', redirectTo: ''},
                { path: 'verify', component: VerifyPanelistComponent, canActivate: [authGuard]},
                // { path: 'blog', component: ComingsoonComponent,canActivate: [authGuard]},
  //               { path: 'crdct.aspx', component: LoginredirectComponent},
  //               { path: 'routing.aspx', component: LoginredirectComponent},
  //               { path: 'survey', component: LoginredirectComponent},
  //               { path: 'surveys', component: LoginredirectComponent, pathMatch:'prefix'},
              ]
  }
    ,
  
   { path:  '**', redirectTo:'not-found'}

];

const config: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
}; 

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
