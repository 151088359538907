import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOptions'
})
export class FilterOptionsPipe implements PipeTransform {
  transform(options: any[], searchTerm: string): any[] {
    if (!options || !searchTerm) {
      return options;
    }
    return options.filter(option =>
      option.AnswerChoiceText.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
