<form [formGroup]="questionsForm" (ngSubmit)="validateForm('isPanelist');" [ngClass]="(currentQuestion == 0) ? 'text-center p-5':'' ">

    <!-- *** Survey questions start here *** -->
    <ng-container *ngIf="currentQuestion === 0">
        <h4 class="text-white" id="we_need_opinion_title"  [innerHTML]="content?.we_need_opinion_title?.content  | safehtml">We need your opinion.</h4>
        <h5 class="fs-4 mt-3 px-3 text-center text-white" id="we_need_opinion_subtitle" [innerHTML]="content?.we_need_opinion_subtitle?.content  | safehtml">Answer these questions and add some
            fun to your day.</h5>
        <button type="button" id="we_need_opinion_button" [innerHTML]="content?.we_need_opinion_button?.content  | safehtml" class="btn btn-outline-light rounded-pill mt-4 but"
            (click)="nextQuestion('',currentQuestion)">Get Started</button>
    </ng-container>
    <!-- Looping sections starts here -->
    <ng-container *ngFor="let poll of pollData; let i = index">
      <ng-container *ngIf="currentQuestion === (i + 1)">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="climate_change_question">{{poll.QuestionText}}</h5>
        <div class="w-50 mt-4 ms-2 ms-md-5" [formGroupName]="poll.QuestionID.toString()">
          <div class="radiobtn" *ngFor="let choice of poll.OpinionPollsAnswerChoices">
            <input type="radio" [id]="choice.AnswerID" [formControlName]="poll.QuestionID" [value]="choice.AnswerID" />
            <label [for]="choice.AnswerID">{{choice.AnswerChoiceText}}</label>
          </div>
          <div  class="alert alert-danger" role="alert" *ngIf="!formGroupValid">
            Please select the answer
          </div>
          <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but" (click)="nextQuestion(poll.QuestionID,currentQuestion)">Ok</button>
          <!-- <button type="submit" class="btn1 btn-outline-light rounded-pill mt-3 but" *ngIf="currentQuestion == pollData.length">Submit</button> -->
        </div>
      </ng-container>
    </ng-container>
    <!-- Looping sections ends here -->
    <!-- *** Survey questions end here *** -->

    <!-- Final submit section starts here -->
    <ng-container *ngIf="currentQuestion === (pollData?.length + 1)">
      <ng-container *ngIf="!pollSurveyComplete">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="panelist_question" [innerHTML]="content?.panelist_question?.content  | safehtml">Are you a panelist on The Panel Station?</h5>
        <div class="w-50 mt-4 ms-2 ms-md-5">
          <div class="radiobtn">
            <input type="radio" id="existingUserYes" name="isPanelist" value="Yes" [formControlName]="'isPanelist'"/>
            <label for="existingUserYes">{{(content?.yes?.content) ? content?.yes?.content:'Yes'}}</label>
          </div>
          <div class="radiobtn">
            <input type="radio" id="existingUserNo" name="isPanelist" value="No" [formControlName]="'isPanelist'"/>
            <label for="existingUserNo">{{(content?.No?.content) ? content?.No?.content:'No'}}</label>
          </div>
          <div  class="alert alert-danger" role="alert" *ngIf="!formGroupValid">
            Please select the answer
          </div>
          <button type="submit" class="btn1 btn-outline-light rounded-pill mt-3 but">{{(content?.Ok?.content) ? content?.Ok?.content:'Ok'}}</button>
          <!-- <button type="submit" class="btn1 btn-outline-light rounded-pill mt-3 but" *ngIf="currentQuestion == pollData.length">Submit</button> -->
        </div>
      </ng-container>

      <!-- Final page bellow -->
      <div id="result" class="text-center p-4" *ngIf="pollSurveyComplete">
        <div *ngIf="questionsForm.value.isPanelist === 'Yes'">
          <h4 class="p-4 fs-1 text-center text-white lh-sm" id="login_prompt" [innerHtml]="content?.login_prompt?.content | safehtml"></h4>
          <a href="{{environment.loginUrl}}">
            <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but">{{(content?.Login) ? content?.Login:'Login'}}</button>
          </a>
        </div>
        <div *ngIf="questionsForm.value.isPanelist === 'No'">
          <h4 class="p-4 fs-1 text-center text-white lh-sm" id="share_opinion_prompt" [innerHtml]="content?.share_opinion_prompt?.content | safehtml"></h4>
          <button type="button" (click) = "openModalWithComponent()" class="btn1 btn-outline-light rounded-pill mt-3 but">{{(content?.RegisterNow?.content) ? content?.RegisterNow?.content:'Register Now'}}</button>
        </div>
        <!-- <button type="submit" class="btn1 btn-outline-light rounded-pill mt-3 but">Submit</button> -->
      </div>
    </ng-container>
    <!-- Final submit section ends here -->

    <!-- <div class="col-lg-6 col-md-6 text-center p-5" id="div7" >
        <h4 class="text-white" id="we_need_opinion_title">We need your opinion.</h4>
        <h5 class="fs-4 mt-3 px-3 text-center text-white" id="we_need_opinion_subtitle">
          Answer these questions and add some fun to your day.
        </h5>
        <button type="button" id="we_need_opinion_button" class="btn btn-outline-light rounded-pill mt-4 but" (click)="nextQuestion()">
          Get Started
        </button>
      </div> -->

    <!-- <div class="col-lg-6 py-3" id="div8" *ngIf="currentQuestion === 2">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="climate_change_question">Do you think climate change affects your health?</h5>
        <div class="radiobtn">
          <input type="radio" id="Yes" formControlName="climateAffectsHealth" value="Yes" />
          <label for="Yes" id="climateQuestionYes">Yes</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="No" formControlName="climateAffectsHealth" value="No" />
          <label for="No" id="climateQuestionNo">No</label>
        </div>
        <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but" (click)="nextQuestion()">Ok</button>
      </div>
  
      <div class="col-lg-6 py-3" id="div9" *ngIf="currentQuestion === 3">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="climate_change_question2">
          Have you ever wondered how we know for sure that the climate is changing?
        </h5>
        <div class="radiobtn">
          <input type="radio" id="Melt" formControlName="climateChangeProof" value="Melt" />
          <label for="Melt" id="meltingGlaciers">Melting glaciers</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="More" formControlName="climateChangeProof" value="More" />
          <label for="More" id="frequentIntenseHeat">More frequent & intense heat waves</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="rise" formControlName="climateChangeProof" value="rise" />
          <label for="rise" id="risingSeaLevels">Rising sea levels</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="all" formControlName="climateChangeProof" value="all" />
          <label for="all" id="allOfTheAbove">All of the above</label>
        </div>
        <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but" (click)="nextQuestion()">Ok</button>
      </div>
  
      <div class="col-lg-6 py-3" id="div10" *ngIf="currentQuestion === 4">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="carbon_footprint_question">
          What small changes you are willing to reduce your carbon footprint?
        </h5>
        <div class="radiobtn">
          <input type="radio" id="Conserve" formControlName="carbonFootprintReduction" value="Conserve" />
          <label for="Conserve" id="conserveWater">Conserving water</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="transport" formControlName="carbonFootprintReduction" value="transport" />
          <label for="transport" id="publicTransport">Using public transportation</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="Plant" formControlName="carbonFootprintReduction" value="Plant" />
          <label for="Plant" id="plantingTrees">Planting more trees</label>
        </div>
        <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but" (click)="nextQuestion()">Ok</button>
      </div>
  
      <div class="col-lg-6 py-3" id="divTPS" *ngIf="currentQuestion === 5">
        <h5 class="fs-3 ms-2 ms-md-5 w-75 text-white" id="panelist_question">Are you a panelist on The Panel Station?</h5>
        <div class="radiobtn">
          <input type="radio" id="option1" formControlName="isPanelist" value="Yes" />
          <label for="option1" id="panelistYes">Yes</label>
        </div>
        <div class="radiobtn">
          <input type="radio" id="option2" formControlName="isPanelist" value="No" />
          <label for="option2" id="panelistNo">No</label>
        </div>
        <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but" (click)="nextQuestion()">Ok</button>
      </div>
  
      <div id="result" class="col-lg-6 text-center p-4" *ngIf="currentQuestion === 6">
        <div *ngIf="questionsForm.value.isPanelist === 'Yes'">
          <h4 class="p-4 fs-1 text-center text-white lh-sm" id="login_prompt">
            Login with your registered email id for more such engaging surveys.
          </h4>
          <button type="button" class="btn1 btn-outline-light rounded-pill mt-3 but">Login</button>
        </div>
        <div *ngIf="questionsForm.value.isPanelist === 'No'">
          <h4 class="p-4 fs-1 text-center text-white lh-sm" id="share_opinion_prompt">
            Your opinions hold immense worth. Share them with us!
          </h4>
          <button type="button" data-bs-toggle="modal" data-bs-target="#HelloModal" class="btn1 btn-outline-light rounded-pill mt-3 but">Register Now</button>
        </div>
        <button type="submit" class="btn1 btn-outline-light rounded-pill mt-3 but">Submit</button>
      </div> -->
</form>