import { ElementRef, EventEmitter, Inject, Input, Output, QueryList, Renderer2, ViewChild, ViewChildren }  from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { NavigationExtras, Router, ActivatedRoute} from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { GoogleLoginProvider, SocialAuthService }  from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { passwordStrengthValidator,passwordMatch,firstNameValidator,firstNameLength,lastNameValidator, lastNameLength, emailPatternValidator } from '../customValidators/customValidators';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { log } from 'node:console';
import { __param } from 'tslib';
// import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

declare global {
  interface Window {
    google: any;
  }
}

@Component({
  selector: 'app-regform',
  templateUrl: './regform.component.html',
  styleUrls: ['./regform.component.css']
})

export class RegformComponent {

  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  formDIVFlag:boolean = true;
  questionsFlag:boolean = false;
  button5Flag:boolean = false;
  button6Flag:boolean = false;
  button7Flag:boolean = false;
  formFlag:boolean = false;
  form_1Flag:boolean = false;
  full_hFlag:boolean = false;
  show_hFlag:boolean = false;
  form_2Flag:boolean = false;
  form_3Flag:boolean = false;
  thankyouFlag:boolean = false;
  otpFlag:boolean = false;
  div0Flag:boolean = false;
  resendOTPFlag:boolean = false;

  profileForm =  this.fb.group({},{
    validators: passwordMatch.bind(this)
  });
  extraQues:any   = [];
  showForm:boolean = false;
  otpMsg:string = '';
  isSignupClicked:boolean = false;
  isvalid: Boolean = false;
  isFormSubmitted:boolean = false;
  timeLeft: number = 60;
  interval:any;
  defaultQues:any;
  @ViewChild('otpNew') otpNew!: ElementRef<HTMLInputElement>;
  @ViewChild('otp') otpInput!: ElementRef<HTMLInputElement>;
  @ViewChild('otp2') otp2Input!: ElementRef<HTMLInputElement>;
  @ViewChild('otp3') otp3Input!: ElementRef<HTMLInputElement>;
  @ViewChild('otp4') otp4Input!: ElementRef<HTMLInputElement>;
  @ViewChild('otp5') otp5Input!: ElementRef<HTMLInputElement>;
  @ViewChild('otp6') otp6Input!: ElementRef<HTMLInputElement>;
  @ViewChildren('otpInputs') otpInputs!: QueryList<ElementRef>;
  @Input() content:any;
  authSubscription!: Subscription;
  country = this.appSer.getLocalStorage('country');
  placeholderInfo :any = {};
  isClose:any=false;
  pwPrivacy: boolean = true;
  cpwPrivacy: boolean = true;
  public isVPN?:any = "";
  otpSuccess: any;
  isOtpInputFocused: boolean = false;
  registrationSubmit: boolean=false;
  passwordFocused: boolean = false;
  showTooltip = false;
  firstFocus = true;
  isConfirmPasswordFocused = false; // Tracks focus state of confirm password

  constructor(private fb: FormBuilder, public appSer:AppService, private router: Router, private el :ElementRef,private renderer: Renderer2, public options: ModalOptions, private socAuthService: SocialAuthService, private authSer:AuthService,private modalService:BsModalService, private cookieService:CookieService, @Inject(DOCUMENT) private document: Document, private renderer2:Renderer2,     private _activatedRoute: ActivatedRoute) {
    //  (['otp1','otp2','otp3','otp4','otp5','otp6']).forEach((v,k)=>{
    //   this.profileForm.addControl(v, new FormControl('',[Validators.required, Validators.minLength(1), Validators.maxLength(1)]));
    // })
    this.profileForm.addControl('otp', new FormControl('', [
      Validators.required, 
      Validators.minLength(4), // Expecting a 4-digit OTP
      Validators.maxLength(4)
    ]));
    this.profileForm.addControl('terms', new FormControl('',[Validators.required]));
    // console.log(this.options.initialState);
    if(this.options.initialState){ //if registartion opened from spin and wheel, get spinid selected here
      console.log(this.options.initialState);
      console.log('SpinID', this.options.initialState['SpinID']);
      this.isClose = (this.options.initialState['isClose']) ? this.options.initialState['isClose'] : false;
      this.profileForm.addControl('SpinID', new FormControl(this.options.initialState['SpinID']));
    }
   
  }

  signupWithEmail(){//button4
    // console.log(this.profileFormControls)
    // console.log(this.profileFormControls.terms.invalid  )
    // console.log(this.profileFormControls.terms.dirty  )
    this.isSignupClicked = true;
    if(this.isTermsAccepted()){
     
      this.formDIVFlag = false;
      this.formFlag = true;
      this.form_1Flag = false;
      this.thankyouFlag = false;
      this.otpFlag = false;
      this.show_hFlag = false;
      this.loguser('clicked on signup with email');
    }else{
      // this.appSer.showerror("Please check terms and conditions");
    }
    
  }
  onPasswordFocus() {
    this.showTooltip = true;
    this.passwordFocused = true;
    this.applyValidationClasses();
    
    if (this.firstFocus) {
      this.firstFocus = false;
      this.profileFormControls['camp_' + this.defaultQues['password']].markAsTouched();
    }
  }
  applyValidationClasses() {
    const control = this.profileFormControls['camp_' + this.defaultQues['password']];
    if (control && this.passwordFocused) {
      // If password is focused, reapply classes based on validity
      return {
        'is-invalid': control.invalid && (control.dirty || control.touched),
        'is-valid': control.valid
      };
    }
    return {};  // Return an empty object to remove classes if no focus
  }
  onConfirmPasswordFocus() {
    this.isConfirmPasswordFocused = true;
  }
  
  onConfirmPasswordBlur() {
    this.isConfirmPasswordFocused = false;
  }
  
  applyConfirmValidationClasses() {
    const confirmPasswordControl = this.profileFormControls['camp_' + this.defaultQues['confirm_password']];
    const passwordControl = this.profileFormControls['camp_' + this.defaultQues['password']];
  
    if (confirmPasswordControl && passwordControl) {
      const isMatch = passwordControl.value === confirmPasswordControl.value;
  
      if (this.isConfirmPasswordFocused) {
        return {
          'is-invalid': !isMatch || confirmPasswordControl.invalid,
          'is-valid': isMatch && confirmPasswordControl.valid,
        };
      }
    }
  
    // Remove all highlights if not focused
    return {};
  }
  
  
  
  onPasswordInput() {
    // Show tooltip when the user starts typing
    this.showTooltip = true;
  }

  onPasswordBlur() {
    this.showTooltip = false;
    this.passwordFocused = false;
  }
  onFieldClick(fieldName: string) {
    if (fieldName !== 'password') {
      this.passwordFocused = false;
      // Optionally, mark the password control as untouched
      const control = this.profileFormControls['camp_' + this.defaultQues['password']];
      if (control) {
        control.markAsUntouched();
      }
    }
  }
  
  showNextForm(){ //button5
    // console.log(this.profileForm.get('firstName')?.valid  )
    this.otpMsg = '';
    this.isFormSubmitted = true;
    // console.log(this.isFormValid())
    // return;
    if(this.isFormValid()){
      this.loguser('Form Submitted');
      this.button5Flag = true;
      this.appSer.checkmail(this.profileForm.value).subscribe( (response:any)=>{

        console.log(response);
        if(response.success == true){ //open otp section
          // this.appSer.sendOTP(this.profileForm.value).subscribe((otpResponse:any)=>{
          //     if( otpResponse.success == true){
          //       this.formDIVFlag = false;
          //       this.formFlag = false;
          //       this.form_1Flag = false;
          //       this.thankyouFlag = false;
          //       this.otpFlag = true;
          //       this.show_hFlag = false;
          //     }
          //     setTimeout(() => {
          //       this.otpInputs.first.nativeElement.focus();
          //     }, 1000);
          // });
          this.appSer.addToLocalStorage("profilerDetails", JSON.stringify(this.profileForm.value));
          // this.profileForm.addControl('unsubscribeUser', new FormControl(response.data.unsubscribeUser));
          this.appSer.saveRegDetails(this.profileForm.value ).subscribe((regResponse:any)=>{
              if( regResponse.success == true){
                this.formDIVFlag = false;
                this.formFlag = true;
                this.form_1Flag = false;
                this.thankyouFlag = false;
                this.otpFlag = true;
                this.show_hFlag = false;
                // this.otpSuccess = 'OTP sent to the above email id';
                this.otpSuccess = (this.content?.['OTP sent to the above email id'])?(this.content?.['OTP sent to the above email id']):'OTP sent to the above email id';
                this.OTPAction();
                // const otp1Input = document.getElementById('otp1');
                // if (otp1Input) {
                //     otp1Input.focus();
                // }

                //add PanelistSVId, PanelistId to form group
                this.profileForm.addControl('PanelistSVId', new FormControl(regResponse.message.PanelistSVId));
                this.profileForm.addControl('PanelistId', new FormControl(regResponse.message.PanelistId));
                
                setTimeout(() => {
                  this.otpNew.nativeElement.focus();
                }, 1000);
                setTimeout(() => {
                  this.otpSuccess = '';
                }, 10000);
              }else if(regResponse.success == false && regResponse.message.redirect != ''){
                //redirect to terminate
                
                this.router.navigate(['/'+regResponse.message.redirect],  {state: {message: regResponse.message.message}});
              }
              
              //resend OTP TImer
              this.startTimer();
            
          });

        }else if(response.success == false && response.message.redirect != ''){
          //redirect to terminate
          let sryText = (this.content['wearesorry'] )? this.content['wearesorry'] : "We are Sorry";
          if(response.message.PanalistStatus == 'Lead'){
            sryText = "";
          }
         
          this.router.navigate(['/'+response.message.redirect],  {state: {message: response.message.message,wearesorry:sryText,PanalistStatus:response.message.PanalistStatus , session: this.profileForm.get('session')?.value || null }});
        }
      });

    }else{
    this.isFormSubmitted = false;
    }
    
  }
  OTPAction() {
    if (this.otpFlag) {
      this.profileForm.get('camp_'+this.defaultQues['fname'])?.disable();
      this.profileForm.get('camp_'+this.defaultQues['lname'])?.disable();
      this.profileForm.get('camp_'+this.defaultQues['email'])?.disable();
      this.profileForm.get('camp_'+this.defaultQues['password'])?.disable();
      this.profileForm.get('camp_'+this.defaultQues['confirm_password'])?.disable();
    } else {
      this.profileForm.get('camp_'+this.defaultQues['fname'])?.enable();
      this.profileForm.get('camp_'+this.defaultQues['lname'])?.enable();
      this.profileForm.get('camp_'+this.defaultQues['email'])?.enable();
      this.profileForm.get('camp_'+this.defaultQues['password'])?.enable();
      this.profileForm.get('camp_'+this.defaultQues['confirm_password'])?.enable();
    }
  }

  submitProceed(){//button7
    this.registrationSubmit=true;
    this.otpMsg = '';
    // let OTP = this.profileForm.get('otp1')?.value.toString() + this.profileForm.get('otp2') +
    // if( !(this.profileForm.get('otp1')?.valid && this.profileForm.get('otp2')?.valid && this.profileForm.get('otp3')?.valid 
    //   && this.profileForm.get('otp4')?.valid && this.profileForm.get('otp5')?.valid && this.profileForm.get('otp6')?.valid )
    // ){
    //   this.otpMsg = (this.content['Enter OTP'] ) ? this.content['Enter OTP']: 'Please enter OTP';
    //   return
    // }
    // let OTP = "";
    // (['otp1','otp2','otp3','otp4','otp5','otp6']).forEach((v,k)=>{
    //   OTP += (this.profileForm.get(v)?.value) ? this.profileForm.get(v)?.value : "";
    // })
    let OTP = this.profileForm.get('otp')?.value || "";

    console.log(OTP);
   
    if(OTP.length < 4){
      this.otpSuccess="";
      this.otpMsg = (this.content['Enter OTP'] ) ? this.content['Enter OTP']: 'Please enter OTP';
      this.registrationSubmit = false;
      return
    }

    this.appSer.verifyOTP(this.profileForm.value).subscribe( (response:any)=>{
      this.registrationSubmit = false;
      if( response.success == true){
        this.loguser('Verifying OTP');
        // if(response.message.profilerInfo.Rejoin){ //if it is true means it is a unsubscribe user and need to redirect dashboard.
        //   window.open(response.message.profilerInfo.RejoinRedirection, "_self"); //redirect to profiler dashboard if user is unsubscribe.
        // }else{
        //   this.router.navigate(['/'+response.message.redirect],  {state: {message: response.message.message, profilerInfo: response.message.profilerInfo}});
        // }
        this.router.navigate(['/'+response.message.redirect],  {state: {message: response.message.message, profilerInfo: response.message.profilerInfo}});
       
        this.formDIVFlag = false;
        this.formFlag = false;
        this.form_1Flag = false;
        this.thankyouFlag = true;
        this.otpFlag = false;
        this.show_hFlag = false;
        this.loguser('OTP Verified');
        if( this.appSer.isPlatformBrowser()){//On success remove the old profile details 
          localStorage.removeItem("profilerDetails");
        }
        // this.appSer.register(this.profileForm.value).subscribe( (regResponse:any)=>{
        //     if( regResponse.success == true ){
        //       console.log(regResponse);
        //       this.router.navigate(['/'+regResponse.message.redirect],  {state: {message: regResponse.message.message, profilerInfo: regResponse.message.profilerInfo}});
        //       this.formDIVFlag = false;
        //       this.formFlag = false;
        //       this.form_1Flag = false;
        //       this.thankyouFlag = true;
        //       this.otpFlag = false;
        //       this.show_hFlag = false;
        //     }else if( regResponse.success == false ){
        //       this.router.navigate(['/'+regResponse.message.redirect],  {state: {message: regResponse.message.message}});
        //     }
        // });
       
      }else{
        this.otpMsg =  response.message;
      }
    });
   

  }

  isTermsAccepted(){
    return this.profileForm.get('terms')?.value;
  }

  Message: string | null = null;
  
  getCountryCampaignDetails(){
    // VPN Validation
    console.log("VPN");
    // check VPN is connected or not // Sudip

    this.appSer.VPNValidation().subscribe( (result:any) =>{
      if(result.success){
        if (result.data.vpnConnected != false) {
          this.isVPN = result.data.vpnConnected;
        }else{
          console.log("inside after vpn");
          
          let langID = this.appSer.getLanguageID();
          this.appSer.getCountryCampaignDetails(langID).subscribe( (res:any)=>{
            
            console.log(res.data);
            // this.content = res.data;
            // console.log(this.content.answerWithEase.content)
      
            // Geo-location Validation  
            if(result.data.geoLock != 0){
              // let urlCountyCode = this.router.url.split('/')[1];
              // let urlCountyCode = this.router.routerState.snapshot.root.firstChild?.url[0].path;
              let urlCountyCode = this._activatedRoute.snapshot.root.children[0].params['market'];
              //alert(urlCountyCode);
              if(!urlCountyCode){
                urlCountyCode = this.appSer.setdefaultmarket();
              }
              // let urlCountyCode = this.appSer.getLocalStorage("geo");
              console.log("URL code" ,urlCountyCode);
              if(urlCountyCode?.toLowerCase() !== res.data.api.response.CountryCode.toLowerCase()){
                let navigateTo = (res.data.api.response.CountryCode.toLowerCase() != 'us') ?
                res.data.api.response.CountryCode.toLowerCase() : "";
                this.router.navigateByUrl('/'+navigateTo+"/register");
              }else{
                console.log('matchUrlCode', urlCountyCode)
              }
        
            }
            this.defaultQues = JSON.parse(res.data.DEFAULT_QIDS);
            console.log("DEFAULT_QIDS", this.defaultQues)
            this.Message = res.data.api?.response?.Message || null;
            if(res.data.api){
              // console.log(res.data.api.response.xcampaign.pages[0]);
              (res.data.api.response.xcampaign.pages[0]['questions']).forEach((qs:any,k:any)=>{
                  // console.log(Object.values(defaultQues));
                  
                    console.log('qs',qs);
                    let type = 'input';
                    if( qs['QuestionType'] == "Single Choice - Drop Down" || qs['QuestionType'] == 'Single Choice - Radio Button' || qs['QuestionType'] == 'Multi Choice'){
                      type = 'select';
                    }
                                     
                    if(qs.Mandatory){
                      this.profileForm.addControl('camp_'+qs['ID'], new FormControl('',Validators.required));
                    }else{
                      this.profileForm.addControl('camp_'+qs['ID'], new FormControl(''));
                    }
                    if(qs['ID'] == this.defaultQues['email']){//email
                      this.profileForm.get('camp_'+this.defaultQues['email'])?.addValidators(emailPatternValidator());               
                    }else if(qs['ID'] == this.defaultQues['password']){
                      this.profileForm.get('camp_'+this.defaultQues['password'])?.addValidators(passwordStrengthValidator());    
                    }else if(qs['ID'] == this.defaultQues['fname']){
                      this.profileForm.get('camp_'+this.defaultQues['fname'])?.addValidators([firstNameValidator()]);    //, firstNameLength()
                    }else if(qs['ID'] == this.defaultQues['lname']){
                      this.profileForm.get('camp_'+this.defaultQues['lname'])?.addValidators([lastNameValidator()]);    
                    }
      
                    if( !Object.values(this.defaultQues).includes(qs['ID']) ){
                      this.extraQues.push({'type':type, 'placeholder': qs.QuestionText, 'name':"camp_"+qs['ID'] ,'options' : qs.AnswerChoice});
                    }
      
                    this.showForm = true;
                    this.placeholderInfo[qs['ID']] = qs.QuestionText;
                    if (this.appSer.isPlatformBrowser()) {//To set the previous profiler details entered post OTP generation
                      let profilerDetails = localStorage.getItem("profilerDetails");
                      if (profilerDetails) {
                        this.profileForm.get('terms')?.patchValue(true);
                        this.signupWithEmail();
                        this.showOldProfilerDetails(profilerDetails);
                      }
                    }
              });
             console.log('placeholderInfo', this.placeholderInfo)
            }
            //adding caid, langID to form control
            let session:any = (this.authSer.getTokenId())? this.authSer.getTokenId()?.toString() : new Date().getTime();
            this.profileForm.addControl('caid', new FormControl(res.data.caid));
            this.profileForm.addControl('langID', new FormControl(res.data.langID));
            this.profileForm.addControl('terminatedText', new FormControl(res.data.api.response.xcampaign.pages[2]['questions'][0]['QuestionText']));
            this.profileForm.addControl('thankText', new FormControl(res.data.api.response.xcampaign.pages[1]['questions'][0]['QuestionText']));
            this.profileForm.addControl('CountryCode', new FormControl(res.data.api.response.CountryCode));
            this.profileForm.addControl('session', new FormControl(session));
            // let session:any = this.authSer.getTokenId()?.toString();
            if( this.appSer.isPlatformBrowser()){
              localStorage.setItem("session", session);
            }
            this.loguser('visited the site');
            this.getRDScore(session,res.data.caid );
          });
        }
      }
    });
  }

  // Function to set the privious user filled data
  showOldProfilerDetails(profilerDetails: any) {
    if (profilerDetails) {
      let detailsObj = JSON.parse(profilerDetails);
      // Dynamically set the form values based on defaultQues
      let formFields = [
        { key: 'fname', formControl: this.profileForm.get('camp_' + this.defaultQues['fname']) },
        { key: 'lname', formControl: this.profileForm.get('camp_' + this.defaultQues['lname']) },
        { key: 'email', formControl: this.profileForm.get('camp_' + this.defaultQues['email']) },
        { key: 'password', formControl: this.profileForm.get('camp_' + this.defaultQues['password']) },
        { key: 'confirm_password', formControl: this.profileForm.get('camp_' + this.defaultQues['confirm_password']) }
      ];

      // Iterate through form fields and set values from detailsObj
      formFields.forEach(field => {
        if (field.formControl && detailsObj['camp_' + this.defaultQues[field.key]]) {
          field.formControl.setValue(detailsObj['camp_' + this.defaultQues[field.key]]);
        }
      });
    }
  }

  ngOnInit(){
    
    //check for profiler , if found redirect to profiler
    // console.log('cookie', this.cookieService.get('isProfiler'))
    if(this.cookieService.check('isProfiler')) {
      // this.router.navigateByUrl(this.cookieService.get('isProfiler'));
    }
   
    if(this.isClose == true){ //f opening from popup
      this.getCountryCampaignDetails();
    }else{//wait for market details to load
      this.appSer.markeLoaded.subscribe((data: string) => {
        console.log("market ........." ,this.appSer.market);
        
        this.getCountryCampaignDetails();
      });
    }
    // this.router.navigate(['/thankyou'],  {state: {message: '', "name": "Jogi"}});

    this.authSubscription = this.socAuthService.authState.subscribe((user) => {
      console.log('user', user);
      this.signinWithGoogle(user);
    });

    if( typeof this.content == 'undefined'){ //if this coponent is opened from popup
      this.appSer.getPageContent('register').subscribe( (res:any)=>{
        this.content = res.data;
        if(this.content.schema_script){
          this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
        }
      });
    }else{
      if(this.content.schema_script){
        this.appSer.addSchema( this.renderer2, this.content.schema_script.content)
      }
    }

  }

  isFormValid(): any {
    
    if( this.profileForm.errors?.['passwordNotMatch']){
      return false;
    }
    this.isvalid = true;
    Object.keys(this.profileForm.controls).forEach(key => {
      const control:any = this.profileForm.get(key);
      if (key.includes("camp_") && !control.valid) { //questions from api
        console.log(control.value);
        this.isvalid = false;
        return;
      }
    });
    return this.isvalid;
    
  }

  get profileFormControls(): any {
    return this.profileForm['controls'];
  }

  onKeyUp(event: KeyboardEvent, input: HTMLInputElement, index: number) {
    if (event.key === 'Shift' || event.key === 'Tab' || event.key === 'Meta' || event.key === 'Alt' || event.key === 'Control') {
      return;
    }

    if ((event.key === 'Backspace' || event.key === 'ArrowLeft') && index > 0) {
      this.otpInputs.toArray()[index - 1].nativeElement.focus();
    } else if (event.key !== 'Backspace' && index < this.otpInputs.length - 1) {
      this.otpInputs.toArray()[index + 1].nativeElement.focus();
    }

    if (input.value.length > 1) {
      this.splitNumber(event);
    }
  }

  onFocus(event: FocusEvent, input: HTMLInputElement, index: number) {
    if (index === 0) return;

    const firstInput = this.otpInputs.first.nativeElement;
    if (firstInput.value === '') {
      firstInput.focus();
    }

    const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
    if (prevInput.value === '') {
      prevInput.focus();
    }
  }

  splitNumber(event: Event) {
    let data = (event as InputEvent).data || (event.target as HTMLInputElement).value;
    if (!data) return;
    if (data.length === 1) return;

    this.populateNext(event.target as HTMLInputElement, data);
  }

  populateNext(input: HTMLInputElement, data: string) {
    input.value = data[0];
    data = data.substring(1);
    if (input.nextElementSibling && data.length) {
      this.populateNext(input.nextElementSibling as HTMLInputElement, data);
    }
  }
  ngAfterViewInit() {
    // if (this.otpInputs) {
    //   this.otpInputs.forEach((input, index) => {
    //     input.nativeElement.addEventListener('keyup', (e: KeyboardEvent) => this.onKeyUp(e, input.nativeElement, index));
    //     input.nativeElement.addEventListener('focus', (e: FocusEvent) => this.onFocus(e, input.nativeElement, index));
    //   });
    //   const firstInput = this.otpInputs.first.nativeElement;
    //   firstInput.addEventListener('input', (e: Event) => this.splitNumber(e));
    // } else {
    //   console.error('otpInputs is not defined');
    // }
  }

  createFakeGoogleWrapper = () => {
    const googleLoginWrapper = this.document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    this.document.body.appendChild(googleLoginWrapper);
    if( this.appSer.isPlatformBrowser()){
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200',
    });
    }
  
    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      'div[role=button]'
    ) as HTMLElement;
  
    return {
      click: () => {
        googleLoginWrapperButton?.click();
      },
    };
  };

  handleGoogleLogin() {
    
    this.isSignupClicked = true;
    if(this.isTermsAccepted()){
      this.createFakeGoogleWrapper().click();
      this.loguser('clicked on signup with gmail');
    }
    // googleWrapper.click();
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    // .then(user => {
    //   // this.user = user;
    //   console.log(user);
    // })
    // .catch(err => {
    //   console.error('Error signing in with Google: ', err);
    // });
    // return
    //this.loginWithGoogle.emit(this.createFakeGoogleWrapper());
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

  signinWithGoogle(user:any){
    // console.log('email', user.email)
    this.loguser('Form Submitted');
    let emailControlName = "camp_"+this.defaultQues.email.toString();
    this.profileForm.controls[emailControlName].setValue(user.email);
    this.appSer.checkmail(this.profileForm.value).subscribe( (response:any)=>{
      if(response.success == true){
        // this.profileForm.addControl('unsubscribeUser', new FormControl(response.data.unsubscribeUser));
        this.appSer.signinWithGoogle(user, this.profileForm.value).subscribe( (res:any)=>{
          console.log(res);
          if(res.success == true){ //thnaku page
            // if(res.message.profilerInfo.Rejoin){ //if it is true means it is a unsubscribe user and need to redirect dashboard.
            //   window.open(res.message.profilerInfo.RejoinRedirection, "_self"); //redirect to profiler dashboard if user is unsubscribe.
            // }else{
            //   this.router.navigate(['/'+res.message.redirect],  {state: {message: res.message.message, profilerInfo: res.message.profilerInfo}});
            // }
            this.router.navigate(['/'+res.message.redirect],  {state: {message: res.message.message, profilerInfo: res.message.profilerInfo}});
          }else{ //terminate
            this.router.navigate(['/'+res.message.redirect],  {state: {message: res.message.message}});
          }
        });
      }else if(response.success == false && response.message.redirect != ''){
        //redirect to terminate
        let sryText = (this.content['wearesorry'] )? this.content['wearesorry'] : "We are Sorry";
        if(response.message.PanalistStatus == 'Lead'){
          sryText = "";
        }
        this.router.navigate(['/'+response.message.redirect],  {state: {message: response.message.message,wearesorry:sryText,PanalistStatus:response.message.PanalistStatus,session: this.profileForm.get('session')?.value || null }});
      }
    });
   
  }

  loguser(action:string){
    this.appSer.loguser(this.profileForm.value,action).subscribe( (data)=>{});
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        //this.timeLeft = 60;
        
      }

      if( this.timeLeft == 0){
        this.resendOTPFlag = true;
        clearInterval(this.interval);
      }
    },1000)
  }

  resendOTP(){
    this.timeLeft = 60;
    this.startTimer();
    this.resendOTPFlag = false;
    this.loguser('clicked on Resend OTP');
    this.appSer.resendOTP(this.profileForm.value).subscribe( (data)=>{

    });
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const newValue = input.value;
    // input.select(); // Select the content of the focused input

    // Ensure only numeric values are accepted
    if (/^\d$/.test(newValue)) {
      // Only update if the new value is valid
      this.profileForm.get(`otp${index + 1}`)?.setValue(newValue);
      const nextInput = this.otpInputs.toArray()[index + 1];
      if (nextInput) {
        nextInput.nativeElement.focus();
      }
    } else {
      // Clear the input if the value is not numeric
      input.value = '';
      this.profileForm.get(`otp${index + 1}`)?.setValue('');
    }
  }


  onKeyDown(event: KeyboardEvent, index: number): void {
    let input = event.target as HTMLInputElement;
    // input.select(); // Select the content of the focused input
    let controlsArray = this.otpInputs.toArray();

    // Handle numerical key inputs
    if (/^\d$/.test(event.key)) {
      // Check if the focused input is filled and should be updated
      if (input.value.length === 1) {
        // Update the value of the focused input
        input.value = event.key;
        this.profileForm.get(`otp${index + 1}`)?.setValue(event.key);

        // Move focus to the next input field
        let nextInput = controlsArray[index + 1];
        if (nextInput) {
          nextInput.nativeElement.focus();
        }
      } else {
        // Move focus to the next empty input field if available
        let nextEmptyIndex = this.findFirstEmptyInput();
        if (nextEmptyIndex !== null && nextEmptyIndex < controlsArray.length) {
          let nextEmptyInput = controlsArray[nextEmptyIndex];
          nextEmptyInput.nativeElement.value = event.key;
          this.profileForm.get(`otp${nextEmptyIndex + 1}`)?.setValue(event.key);

          // Move focus to the next empty input field if there is room
          if (nextEmptyIndex < controlsArray.length - 1) {
            controlsArray[nextEmptyIndex + 1].nativeElement.focus();
          }
        }
      }
      event.preventDefault(); // Prevent default behavior for numerical input
    }

    // Handle backspace
    if (event.key === 'Backspace') {
      // Find the last filled input field
      let filledInputs = controlsArray.filter(el => el.nativeElement.value !== '');
      let lastFilledInput = filledInputs[filledInputs.length - 1];

      if (lastFilledInput) {
        let inputIndex = controlsArray.findIndex(el => el.nativeElement === lastFilledInput);
      
        // Check if the currently focused input is empty
        if (input.value === '') {
          lastFilledInput.nativeElement.focus();
        } else {
          // If the current input is not the last filled value and not empty
          if (input !== lastFilledInput.nativeElement) {
            // Move the focus to the last filled input field
            lastFilledInput.nativeElement.focus();
          } else {
            // Clear the value of the last filled input field
            lastFilledInput.nativeElement.value = '';
            this.profileForm.get(`otp${inputIndex + 1}`)?.setValue('');
      
            // Move focus to the last input field that still has a value
            let newFilledInputs = controlsArray.filter(el => el.nativeElement.value !== '');
            let newLastFilledInput = newFilledInputs[newFilledInputs.length - 1];
      
            if (newLastFilledInput) {
              newLastFilledInput.nativeElement.focus();
            } else {
              // If no filled inputs are left, move focus to the first input field
              controlsArray[0]?.nativeElement.focus();
            }
          }
        }
      }
      
      event.preventDefault(); // Prevent default behavior for backspace
    }

    // if (event.key === 'Backspace') {
    //   // If the current input is empty, move to the last filled input
    //   if (!input.value) {
    //     let filledInputs = controlsArray.filter(el => el.nativeElement.value !== '');
    //     let lastFilledInput = filledInputs[filledInputs.length - 1];
  
    //     if (lastFilledInput) {
    //       lastFilledInput.nativeElement.focus();
    //     } else {
    //       // If no filled inputs are left, focus the first input field
    //       controlsArray[0]?.nativeElement.focus();
    //     }
    //   } else {
    //     // If the current input has a value, clear it and focus the previous input
    //     input.value = '';
    //     this.profileForm.get(`otp${index + 1}`)?.setValue('');
  
    //     let prevInput = controlsArray[index - 1];
    //     if (prevInput) {
    //       prevInput.nativeElement.focus();
    //     }
    //   }
    //   event.preventDefault(); // Prevent default behavior for backspace
    // }
  
    // if (event.key === 'Backspace') {
    //   if (input.value === '') {
    //     // Condition 1: If focused input is empty, move focus to the last filled input
    //     let filledInputs = controlsArray.filter(el => el.nativeElement.value !== '');
    //     let lastFilledInput = filledInputs[filledInputs.length - 1];
  
    //     if (lastFilledInput) {
    //       lastFilledInput.nativeElement.focus();
    //     } else {
    //       controlsArray[0]?.nativeElement.focus();
    //     }
    //   } else {
    //     // Condition 2: If focused input has a value, clear it and move focus to the previous input
    //     input.value = '';
    //     this.profileForm.get(`otp${index + 1}`)?.setValue('');
  
    //     if (index > 0) {
    //       controlsArray[index - 1].nativeElement.focus();
    //     }
    //   }
  
    //   event.preventDefault();
    // }
  
  
  
    //Handle cursor movement with arrow
    if (event.code === 'ArrowLeft') {
      const prevInput = (<HTMLInputElement>event.target).previousElementSibling;
      if (prevInput && prevInput.classList.contains('otp-input')) {
        (<HTMLInputElement>prevInput).select();
        (<HTMLInputElement>prevInput).focus();
      }
    } else if (event.code === 'ArrowRight') {
      const nextInput = (<HTMLInputElement>event.target).nextElementSibling;
      if (nextInput && nextInput.classList.contains('otp-input')) {
        (<HTMLInputElement>nextInput).select();
        (<HTMLInputElement>nextInput).focus();
      }
    }
  }
  findFirstEmptyInput(): number | null {
    let controls = this.otpInputs.toArray();
    for (let i = 0; i < controls.length; i++) {
      if (controls[i].nativeElement.value === '') {
        return i;
      }
    }
    return null;
  }
  focus(event:any){
    setTimeout(() => {
      let input = event.target as HTMLInputElement;
      input.select();
    }, 10);
    
  }

  
closeModal(){
  console.log(this.modalService.getModalsCount());
  for (let i = 1; i <= 10; i++) {
    this.modalService.hide(i);
  }
}

checkSpace(controlName:any){
  // console.log( this.profileForm.get(controlName)?.value );
  if(this.profileForm.get(controlName)?.value){
    let trimedStr =  this.profileForm.get(controlName)?.value.trimStart()
    this.profileForm.patchValue({[controlName]: trimedStr})
  }
}

preventEmoji(event: Event): void {
  const input = event.target as HTMLInputElement;

  const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F1E6}-\u{1F1FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F018}-\u{1F270}]|[\u{238C}-\u{2454}]/gu;

  if (emojiRegex.test(input.value)) {
    input.value = input.value.replace(emojiRegex, '');
  }
  input.value = input.value.replace(/[^\x00-\x7F]/g, '');
}

sanitizeInput(controlName: string, event: Event, allowSpace: boolean = false): void {
  const input = event.target as HTMLInputElement;

  // Regex to allow only A-Z, a-z, and optionally spaces
  const regex = allowSpace ? /[^a-zA-Z\s]/g : /[^a-zA-Z]/g;

  // Current value of the input field
  const currentValue = input.value;

  // Remove invalid characters based on the regex
  const sanitizedValue = currentValue.replace(regex, '');

  // Update the input field directly
  if (sanitizedValue !== currentValue) {
    input.value = sanitizedValue; // Update displayed value
    this.profileForm.get(controlName)?.setValue(sanitizedValue, { emitEvent: false }); // Update form control without emitting an event
  }
}



// To show and hide the password and current password
passwordPrivacy(type:any,event:Event) {
  (type=='password')?(this.pwPrivacy = !this.pwPrivacy):(this.cpwPrivacy = !this.cpwPrivacy);
}


getRDScore(session:any,caid:any){
  this.appSer.getRDScore(session,caid).subscribe( (j_obj:any)=>{
    console.log(j_obj)
    // var j_obj = this.toObject(response);
    let hdnRdParams = "&RDFlag=" + j_obj["Surveys"][0]["flag"] + "&DupeUniqueId=" + j_obj[
        "Surveys"][0]["duplicate_initial_ud"] + "&RespondentRisk=" + j_obj["Respondent"][
        "respondent_risk"
    ] + "&ThreatPotential=" + j_obj["Respondent"]["threat_potential"] +
    "&CountryMismatch=" + j_obj["Surveys"][0]["country_mismatch"] + "&FailureReason=" +
    j_obj["Surveys"][0]["failure_reason"] + "&DupeScore=" + j_obj["Surveys"][0][
        "duplicate_score"
    ] + "&DupePotential=" + j_obj["Surveys"][0]["duplicate_potential"] + "&WeatherCode=" +
    j_obj["Respondent"]["weather_code"] + "&TimeZone=" + j_obj["Respondent"]["time_zone"] +
    "&CountryCode=" + j_obj["Respondent"]["country_code"] + "&IsVPN=" + j_obj["Respondent"][
        "vpt"
    ] + "&Country=" + j_obj["Respondent"]["country"] + "&IPAddress=&Latitude=" + j_obj[
        "Respondent"]["latitude"] + "&Longitude=" + j_obj["Respondent"]["longitude"] +
    "&RDScore=" + j_obj["Respondent"]["threat_potential_score"];
    this.profileForm.addControl('hdnRdParams', new FormControl(hdnRdParams));
  });
}


onInputNew(event: Event): void {
  const input = event.target as HTMLInputElement;
  const newValue = input.value;

  // Ensure the OTP consists of only numeric values
  if (/^\d{0,4}$/.test(newValue)) {
    // Set the value in the form control
    this.profileForm.get('otp')?.setValue(newValue);
  } else {
    // Clear the input if the value is not numeric
    input.value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    this.profileForm.get('otp')?.setValue(input.value);
  }
}
restrictSpaces(event: KeyboardEvent): void {
  const key = event.key;

  // Block space and any non-ASCII keys except for backspace, delete, and tab
  if ((key === ' ' || key === 'Spacebar') || 
      (!/^[\x00-\x7F]$/.test(key) && 
       key !== "Backspace" && 
       key !== "Delete" && 
       key !== "Tab")) {
    event.preventDefault();
  }
}



// onKeyDownNew(event: KeyboardEvent): void {
//   const input = event.target as HTMLInputElement;

//   // Handle backspace
//   if (event.key === 'Backspace' && input.value.length === 0) {
//     event.preventDefault(); // Prevent backspace when input is empty
//   }

//   // Handle cursor movement with arrow keys if necessary (optional)
//   if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
//     event.preventDefault(); // Prevent default behavior for moving within the single input field
//   }
// }


}
