<!-- <div *ngIf="isLoading | async" class="overlay"> -->
<div *ngIf="loading$ | async" class="overlay">

    <div id="overlay">
        <div class="cv-spinner">
          <span class="spinner"></span>
          <!-- <img _ngcontent-ng-c2006045979="" src="assets/resources/images/TPSLogo.svg" alt="logo" class="img-fluid" style="width:100px"> -->
        </div>
      </div>
  </div>
  <!-- <div id="overlay">
    <div class="cv-spinner">
      <span class="spinner"></span> 
      <img _ngcontent-ng-c2006045979="" src="assets/resources/images/TPSLogo.svg" alt="logo" class="img-fluid">
    </div>
  </div> -->