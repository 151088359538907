<section>
    <div class="pageBanner">
        <img loading="lazy" src="{{content?.fileDir}}/{{content?.cookiespolicyImg?.content}}" class="w-100" />
        <div class="pageBannertext">
            <h3> OUR COOKIE POLICY</h3>
        </div>
    </div>
</section>
<!-- <div [innerHtml]="content?.CookiePolicy?.content | safehtml"></div> -->

<div name="termly-embed" data-id="e4788244-1d42-4c56-a962-8721a1b41f82"></div>