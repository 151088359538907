import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { atLeastOneRadioSelectedValidator } from '../customValidators/customValidators';
import { CookieService } from 'ngx-cookie-service';
import { NgxDropdownConfig } from 'ngx-select-dropdown';
import { DOCUMENT, formatDate } from '@angular/common';

@Component({
  selector: 'app-profiler',
  templateUrl: './profiler.component.html',
  styleUrls: ['./profiler.component.css']
})
export class ProfilerComponent {
  timerOn: boolean = false;
  currentStep = 1;
  widgetElements: any[] = [];
  profilerQuestionsForm!: FormGroup;
  typeOfQuestion:any;
  profilerDetails:any;
  presentPage:any;
  totalPage: any;
  profilerQuestions:  any = [];
  currentPage: number = 0;
  questionGroup!: FormGroup<{}>;
  formGroupSubmit: boolean = false;
  currentFormGroupValid: boolean = false;
  formSubmitSuccess: boolean=false; 
  claimYourVoucher: boolean =false;
  panelistInfo:any;
  rewards:any;
  terminatedTxt:any;
  profilerCompleted: boolean = false;
  content:any;
  loginLink:any;
  lastAnsweredPage: any=[];
  redeemPerc:any ;
  DOB: any;
  current = 0;
  max = 100;
  stroke = 15;
  radius = 125;
  color = '#0194a8';
  duration = 800;
  animation = 'easeOutCubic';
  gradient = false;
  realCurrent = 0;
  onRefresh: boolean = false;
  searchTerms: { [key: string]: FormControl } = {};
  dropdownSelected: { [key: string]: FormControl } = {};
  searchControl = new FormControl('');
  selectedOption: any;
  filteredOptions: any = [];
  isOpen: boolean=false;
  selectedValue:any;
  selectedDropdown: any = {};
  // caid:string = '';
  panelistId:any;
  CampaignID:any;
  panelistEmail:any;
  storedPixelCodeScreened: string | null = null;
  otherSelected: { [key: string]: boolean } = {};
  questionControl: any;
  constructor(private fb: FormBuilder,private appSer:AppService, private route:ActivatedRoute, private router:Router, private cookieService:CookieService, @Inject(DOCUMENT) private document: Document){}
  ngOnInit(): void {
    this.profilerQuestionsForm = new FormGroup({});
    // this.document?.addEventListener("DOMContentLoaded", function () { // voucher selection
    //   const testElement = document.querySelector(".test");
    //   const showElement = document.getElementById("showme");
    //   const hideElement = document.getElementById("hideme");

    //   testElement?.addEventListener("click", function () {
    //     (<HTMLElement>showElement).style.display = "block"; // Show the element
    //     (<HTMLElement>hideElement).style.display = "none"; // Hide the element
    //   });
    // });

    // this.appSer.markeLoaded.subscribe((data:string) => {
      // this.getPageContent();
    // });
    
    //  Form validations section start
    this.getProfilerQuestions();
    //  Form validations section end

    this.getProfilerInfo();

    this.getLoginLink();

    this.cookieService.set('isProfiler', this.router.url , { expires: 1, sameSite: 'Lax' });
  }
  voicherSelection(){

  }
  onSelectionChange(event: any,question:any,page:any) {
    this.searchTerms[question.ID]?.setValue("");
    let pageGroup = this.profilerQuestionsForm.get(page.ID.toString());
    pageGroup?.get(question.ID.toString())?.patchValue(event.value.ID);
    this.selectedDropdown[question.ID] = event.value.AnswerChoiceText;
  }
  getProfilerQuestions() {
    this.appSer.getProfilerQuestions(this.id).subscribe( (res:any)=>{
      // res.data.response.xcampaign.pages?.sort((a:any, b:any) => a.ID - b.ID);
      this.profilerDetails = res.data;
      if( res.data.response.Status == false ){ //profiler complted

      }else{
        // res.data.response.xcampaign.pages?.sort((a:any, b:any) => a.ID - b.ID);
        this.getLastAnsweredPage(res.data.response.xcampaign.pages);
        // this.profilerQuestions = this.profilerDetails.response.xcampaign.pages;
        (this.profilerDetails.response.xcampaign.pages).forEach( (v:any,i:any)=>{
          console.log(v)
          if( v.Name != 'Thank You' && v.Name != 'Terminated' ){
            this.profilerQuestions.push(v);
          }

          // if( v.Name == 'Terminated'){
            // this.terminatedTxt = (v.questions[0].QuestionText)?v.questions[0].QuestionText:"Your answers are under review, we will get back to you";
          // }
        });

        this.terminatedTxt = res.data.response.TerminatedMessage;
        // console.log("terminatedTxt", this.terminatedTxt)
        if (this.lastAnsweredPage.length > 0) {// on refresh of the profiler, show the last visited page i.e. next page of last answered page
          this.profilerQuestions.some((page: any) => {
            if (page.Name == "Default") {
              this.ctaAtion('initial',this.profilerQuestions[this.currentPage]);
            }
            if (page.Name != "Default") {
              setTimeout(() => {
                this.ctaAtion('next',this.profilerQuestions[this.currentPage+1],'onRefresh');
              }, 100);
            }
          });
        }else this.ctaAtion('initial',this.profilerQuestions[this.currentPage]);
      }
    });
  }
  // To check which of the page is last visited
  // Logic :- to keep the track of last answered page
  getLastAnsweredPage(pages: any): any {
    pages.forEach((page: any) => {
      let hasActiveAnswer = false;
      if (page.Name != 'Default' && page.Name != 'Thank You' && page.Name != 'Terminated') {
        page.questions.forEach((question: any) => {
          let isActive = question.AnswerChoice.some((choice: any) => choice.IsActive);
          if (isActive) {
            hasActiveAnswer = true;
            let latest = page;
            this.lastAnsweredPage=[latest];
          }
        });
      }
    });
    this.onRefresh=true;
  }

  // button1() {
  //   if (this.document.getElementById('Div1')) {
  //     if (this.document.getElementById('Div1')?.style.display == 'none') {
  //       (<HTMLElement>this.document.getElementById('Div1')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div2')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div3')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div7')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div5')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById("DivStart")).style.display = "none";

  //     }
  //     else {
  //       (<HTMLElement>this.document.getElementById('Div1')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div2')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div3')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div7')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div5')).style.display = 'none';
  //     }
  //   }

  // }

  // button2() {
  //   if (this.document.getElementById('Div1')) {
  //     if (this.document.getElementById('Div1')?.style.display == 'none') {
  //       (<HTMLElement>this.document.getElementById('Div1')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div2')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div3')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div4')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div5')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div7')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById("DivStart")).style.display = "none";
  //     }
  //     else {
  //       (<HTMLElement>this.document.getElementById('Div1')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div2')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div3')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div4')).style.display = 'none';
  //       (<HTMLElement>this.document.getElementById('Div5')).style.display = 'block';
  //       (<HTMLElement>this.document.getElementById('Div7')).style.display = 'none';
  //     }
  //   }
  // }

  // profilerFlowInitial() {
  //     let currentStep = 1;
  //     let widgetElements = this.document.querySelectorAll(".step");
  //     let btnNext = <HTMLElement>this.document.querySelector(".next");
  //     let btnBack = <HTMLElement>this.document.querySelector(".back");
  //     let btnSubmit = <HTMLElement>this.document.querySelector(".submit");

  //     this.presentPage = currentStep;
  //     this.totalPage = widgetElements.length;

  //     // Hide all widgets except the first one
  //     widgetElements.forEach((element, index) => {
  //       if (index !== 0) {
  //         (<HTMLElement>element).style.display = "none";
  //       }
  //     });

  //     btnNext?.addEventListener("click", function () {
  //       if (currentStep < widgetElements.length) {
  //         widgetElements.forEach(element => (<HTMLElement>element).style.display = "none"); // Show all widgets temporarily
  //         (<HTMLElement>widgetElements[currentStep]).style.display = "block"; // Hide the current step
  //         currentStep++;
  //       }
  //     });

  //     btnBack?.addEventListener("click", function () {
  //       if (currentStep > 1) {
  //         currentStep -= 2; // Decrement by 2 to go back one step
  //         btnNext.click(); // Trigger the next button click to handle step transition
  //       }
  //     });
  //   this.presentPage = currentStep;

  // }

  //whatsapp Logo
  show1() {
    let x: any = this.document.getElementsByClassName('cart');
    x[0].style.display = 'none';
  }

  show2() {
    let x: any = this.document.getElementsByClassName('cart')
    x[0].style.display = 'block';
  }



  // To switch between the pages i.e. to show profiler questions page one by one
  ctaAtion(action?: any, page?: any,onRefresh?:any) {
    console.log("");
    
    this.formGroupSubmit = true;
    
    if (action == 'initial') {
      this.addFormGroup(page);
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
    }else if (action == 'next' || action =="Submit") {
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
      if (this.currentFormGroupValid) {
        //save answered ques
       
        if(action =="Submit"){
          this.profilerCompleted = true;
          this.saveAnswers( this.currentPage);//calling after page submit
        }else if (action == 'next') {
          if (!onRefresh) {
            this.saveAnswers( this.currentPage,action,page);//calling before page increment
          }else{// Only on refresh scenario 
            this.currentPage++;
            this.completionPercentage(this.currentPage);
            this.addFormGroup(page);
            this.formGroupSubmit = false;
          }
        }
       

        
      }
    } else {
      this.formGroupSubmit = false;
      this.currentPage--;
      this.currentFormGroupValid = this.validateCurrentPageForm(action);
      this.completionPercentage(this.currentPage);
    }

  }

  // To show the completion percentage of the profiler
  // completionPercentage(step: any) {
  //   let steps: any = (100 / (this.profilerQuestions.length-1)) * (step);
  //   let percent = parseInt(steps);
  //   (<HTMLElement>this.document.getElementById("percentValue")).textContent = `${percent}%`;
  //   (<HTMLElement>this.document.querySelector(".progress-bar")).style.width = `${percent}%`;
  // }
  completionPercentage(step: any) {
    let steps: any = (100 / (this.profilerQuestions.length - 1)) * step;
    let percent = parseInt(steps);
  
    const percentValueElement = this.document.getElementById("percentValue");
    const progressBarElement = this.document.querySelector(".progress-bar") as HTMLElement;
  
    if (percentValueElement) {
      (<HTMLElement>percentValueElement).textContent = `${percent}%`;
    }
  
    if (progressBarElement) {
      progressBarElement.style.width = `${percent}%`;
    }
  }
  

  // To add the form group dynamicaly depending on the active page
  addFormGroup(page?: any) {
    let selectionDetails:any= [];
    this.questionGroup = this.fb.group({});
    page.questions.forEach((question: any) => {
      this.searchTerms[question.ID] = new FormControl('');
      let activeChoice = question.AnswerChoice?.find((choice:any) => choice.IsActive);
      let selectedAns;
      if (question.QuestionType === 'Date') {
        // selectedAns = new Date(question?.Answer);
        selectedAns =(question?.Answer)?(formatDate(question?.Answer, 'yyyy-MM-dd', 'en')):'';
      } else {
        selectedAns = activeChoice ? activeChoice.ID : '';
      }
      
      // let selectedAns = (question.QuestionType =='Date')?question.Answer:(activeChoice ? activeChoice.ID : '');
      if (!question?.QuestionType.includes('Multi Choice')) {
        this.questionGroup.addControl(
          question?.ID.toString(), 
          (question.Mandatory)
            ? this.fb.control(selectedAns, Validators.required)
            : this.fb.control(selectedAns)
        );
        question.AnswerChoice.forEach((choice: any) => {
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        question.AnswerChoice.forEach((choice: any) => {
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        if (activeChoice) {
          this.selectedDropdown[question.ID] =activeChoice.AnswerChoiceText;
        }
      } else {
        this.createFormArrays(page);
      }
    });
    this.profilerQuestionsForm.addControl(page?.ID.toString(), this.questionGroup);
    if (selectionDetails) {
      selectionDetails.forEach((selection:any) => {
        this.otherOptionSelection(selection.page, selection.question, selection.choice);
      });
    }
  }


  // To create an form arrays
  createFormArrays(page?: any) {
    const pageGroup = this.fb.group({});
    let selectionDetails:any= [];
    page.questions.forEach((question: any) => {
      if (question.QuestionType.includes('Multi Choice')) { // FormGroup creation for multi-selection questions
        const formGroup = this.fb.group({});
        question.AnswerChoice.forEach((choice: any) => {
          const slection = choice.IsActive ? choice.ID : false;
          formGroup.addControl(choice.ID.toString(), this.fb.control(slection));
          if (choice.IsActive && choice.IsOthers) {
            selectionDetails.push({ page, question, choice });            
          }
        });
        if (question.Mandatory) formGroup.setValidators(atLeastOneRadioSelectedValidator());
        pageGroup.addControl(question.ID.toString(), formGroup);
      } else {
        let activeChoice = question.AnswerChoice?.find((choice:any) => choice.IsActive);
        const slection = (activeChoice?.IsActive) ? activeChoice.ID : '';
        pageGroup.addControl(question.ID.toString(), this.fb.control(slection,question.Mandatory ? Validators.required : []));
      }
    });
    this.profilerQuestionsForm.addControl(page.ID.toString(), pageGroup);
    if (selectionDetails) {
      selectionDetails.forEach((selection:any) => {
        this.otherOptionSelection(selection.page, selection.question, selection.choice);
      });
    }
  }

  getFormArray(pageId: number, questionId: number): FormArray {
    return this.profilerQuestionsForm.get([pageId.toString(), questionId.toString()]) as FormArray;
  }
  // To fetch the values of the selected anwers of multiple selection choice questions
  getSelectedValues(pageId: number, questionId: number): string[] {
    let selectedValues: string[] = [];
    let pageGroup = this.profilerQuestionsForm.get(pageId.toString()) as FormGroup;
    let questionControl = pageGroup.get(questionId.toString());
    if (questionControl) {
      let question = this.profilerQuestions.find((page: any) => page.ID === pageId)?.questions.find((q: any) => q.ID === questionId);
      if (question) {
        if (question.QuestionType.includes('Multi Choice')) {
          // Handle Multi Choice questions
          let questionGroup = questionControl as FormGroup;
          question.AnswerChoice.forEach((answer: any) => {
            let control = questionGroup.get(answer.ID.toString());
            if (control && control.value) {
              selectedValues.push(answer.ID);
            }
          });
        } else {
          // Handle Single Choice questions (radio buttons)
          if (questionControl.value) {
            selectedValues.push(questionControl.value);
          }
        }
      }
    }
    return selectedValues;
  }
  validateCurrentPageForm(action?:any,id?:any):any {
    if (action=='initial') {
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((this.currentPage+1).toString())?.valid)?true:false;
    } else if(action=='next') {
      let id:any = this.document.querySelector('.step')?.id;
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((id).toString())?.valid)?true:false;
    }else{
      let id:any = this.document.querySelector('.step')?.id;
      return this.currentFormGroupValid = (this.profilerQuestionsForm.get((id).toString())?.valid)?true:false;
    }
  }
  // Profiler form submission
  onSubmit() {
    // console.log(this.profilerQuestionsForm.value)
    this.getRewards();
    // this.getLoginLink();
    // this.saveAnswers(this.currentPage);
    let formValue = {...this.profilerQuestionsForm.value};
    this.profilerQuestions.forEach((page: any) => {
      page.questions.forEach((question: any) => {
        if (question.QuestionType.includes('Multi Choice')) {
          formValue[page.ID.toString()][question.ID.toString()] = this.getSelectedValues(page.ID, question.ID);
        }
      });
    });
    if (this.profilerQuestionsForm.valid) {
      //this.formSubmitSuccess = true;
    }
  }
  claimVoicher(){
    this.claimYourVoucher=true;
  }

  saveAnswers(pageNum:any,action?:any,page?:any){
    // console.log("pageNum", pageNum)
    console.log(this.profilerQuestionsForm.value)
    if(this.profilerQuestions[pageNum]){
      let ansPage = this.profilerQuestions[pageNum];
      // console.log(ansPage);
      let formValue:any = [];
      /*
      this.profilerQuestions.forEach((page: any) => {
        // console.log(page);
        if( page.ID == ansPage.ID ){
          page.questions.forEach((question: any) => {
            if (question.QuestionType.includes('Multi Choice')) {
              // formValue[page.ID.toString()][question.ID.toString()] = this.getSelectedValues(page.ID, question.ID);
              formValue.push ({'pageID' : page.ID.toString(), 'questionID' :question.ID.toString() ,'selectedvalues' : this.getSelectedValues(page.ID, question.ID)});
            }
          });
          console.log("Answered values "+ page.ID, formValue)
          return
        }
        
      });
      */
    //  console.log("Parent FormGroup", this.profilerQuestionsForm.value);
      ansPage.questions.forEach((question: any) => {
        // let selectedAns ;
        // if (question.QuestionType.includes('Multi Choice')) {
        //   // formValue[page.ID.toString()][question.ID.toString()] = this.getSelectedValues(page.ID, question.ID);
        //   selectedAns = this.getSelectedValues(ansPage.ID, question.ID);
        // }else{
        //   selectedAns =  '';
        // }
        console.log(typeof question)
        formValue.push (
          {
            'pageID' : ansPage.ID.toString(), 
            'questionID' :question.ID.toString(),
            'selectedvalues' : this.getSelectedValues(ansPage.ID, question.ID),
            'Comment':this.getComment(ansPage.ID,question.ID),
            'qInfo': {QuestionType: question.QuestionType, MaxRange:question.MaxRange, MinRange:question.MinRange}
          });
          if (question.QuestionType ==="Date") {
            this.getDob(formValue[0]);
          }
          // Push the Date of birth object on submit
          if (this.profilerCompleted) {
            // formValue.push(this.DOB);
            if (this.DOB && !formValue.some((element: any) => element.questionID === this.DOB.questionID)) {
              formValue.push(this.DOB);
            }
          }
      });
    
      if(formValue){
        console.log(formValue);
        this.appSer.saveProfiler(formValue, this.id, this.profilerCompleted).subscribe( (response:any)=>{
          console.log("Answered Response", response)
          console.log("isSubmmitted", this.profilerCompleted)

          if( response.success == true && this.profilerCompleted == true){
            this.getLoginLink();
            this.formSubmitSuccess = true;
            this.cookieService.delete('isProfiler');

            //Added Script to Body
            if (this.storedPixelCodeScreened) {
              this.appendPixelCodeToBody(this.storedPixelCodeScreened);
            } else {
              console.log('PixelCodeScreened is not available.');
            }
            this.injectPixelScripts(this.panelistEmail, this.panelistId, 'hashed_email_value', 'user_ip_address');
             //UPDATE URL PARAM to THANKYOU
             const currentUrl = new URL(window.location.href);
             const params = new URLSearchParams(currentUrl.search);
             params.set('s', 'Screened-thankyou');
             const newUrl = `${currentUrl.pathname}?${params.toString()}`;
             window.history.pushState({ path: newUrl }, '', newUrl);
 
          }else if( response.success == false ){
            this.router.navigate(['/profiler-terminate'],  {state: {message: this.terminatedTxt}});
          }
          if (response.success == true) {// Go to next page only when there is response from API
            this.currentPage++;
            this.completionPercentage(this.currentPage);
            this.addFormGroup(page);
            this.formGroupSubmit = false;
          }
        });
      }
     
    }
    
  }

  get id(){
    return this.route.snapshot.paramMap.get('id');
  }

  getProfilerInfo(){
    this.appSer.getProfilerInfo(this.id).subscribe( (response:any)=>{
      this.panelistInfo = response.response;
      this.panelistId = response.PID;
      // this.CampaignID = response.response.CampaignID;
      this.CampaignID = response.response.RegistrationCampaignID;
      this.panelistEmail= response.response.EmailID;
      console.log("PANELIST ID : ", response.PID);
      console.log("CAMPAIGN ID: ", this.CampaignID);
      console.log("PANELIST EMAIL : ", this.panelistEmail);
      console.log("PROFILER INFO : ", this.panelistInfo);
      this.getPixelcodes(this.CampaignID);
      this.appSer.getCountryLangIDFromlangCulture(this.panelistInfo.LanguageCulture).subscribe( (countryLangInfo:any)=>{
        let funParam = {data:countryLangInfo.message};
        this.appSer.marketStyles(funParam); //applying market styles
        this.getPageContent(countryLangInfo.message.id);
      });
      // console.log('panelistInfo', this.panelistInfo);
     
    })
  }

  getPixelcodes(caid: number) {
    this.appSer.getPixelsCode(caid).subscribe(
      (data:any) => {
        if (data && data.response) {
          console.log("CID FOR GET PIXEL  ", caid);
          console.log("GET PIXEL RESPONSE", data);
          const pixelCodeScreened = data.response.PixelCodeScreened;
          this.storedPixelCodeScreened = pixelCodeScreened;
        }
      },
      error => {
        console.log('Error fetching pixel codes', error);
      }
    );
  }
  // appendPixelCodeToBody(pixelCode: string) {
  //   if (pixelCode) {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const subId:any = urlParams.get('subId');
  //     const transId = this.panelistId;
  //     console.log("SUB ID FROM URL" + subId);
  //     console.log("TRANS ID" + subId);
  //     console.log("TYPE OF SUBID URL " + typeof(subId));
  //     console.log("TYPE OF TRANS ID " + typeof(transId));
  //     // const subId = this.CampaignID;
  //     // console.log("PANELIST ID : ", this.panelistId);
  //     // console.log("PANELIST CID : ", this.caid);
  //     const updatedPixelCode = pixelCode
  //       .replace('[UNIQUE_TRANSACTION_ID]', transId)
  //       .replace('[UNIQUE_SUB_ID]', subId);
  //     const divContainer = document.createElement('div');
  //     divContainer.innerHTML = updatedPixelCode;
  //     //console.log("ADDING SCRIPT TO BODY:", divContainer);
  //     document.body.prepend(divContainer);
  //     console.log("SCRIPT ADDED TO BODY",divContainer);
  //   } else {
  //     console.log('No PixelCodeScreened script available to append.');
  //   }
  // }

  appendPixelCodeToBody(pixelCode: string) {
    if (pixelCode) {
      const urlParams = new URLSearchParams(window.location.search);
      const subId: any = urlParams.get('subid') || ""; 
      const transId:any = this.panelistId || "";

      console.log("SUB ID FROM URL : ", subId);
      console.log("TRANS ID : ", transId);
      console.log("TYPE OF SUBID URL : ", typeof subId);
      console.log("TYPE OF TRANS ID : ", typeof transId);
  
      const updatedPixelCode = pixelCode
        .replace('[UNIQUE_TRANSACTION_ID]', transId)
        .replace('[UNIQUE_SUB_ID]', subId);
  
      const divContainer = document.createElement('div');
      divContainer.innerHTML = updatedPixelCode;
      document.body.prepend(divContainer);
      console.log("SCRIPT ADDED TO BODY:", divContainer);
    } else {
      console.log('No PixelCodeScreened script available to append.');
    }
  }

  
  injectPixelScripts(email: string, panelistId: string, sha256Email: string, ipAddress: string) {
    // Tiktok Pixel Script
    const tiktokScript = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
        ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
        ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
        n=document.createElement("script");
        n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;
        e=document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n,e)};
        ttq.load('CNTE8CBC77UBM1BSGU70');
        ttq.page();
      }(window, document, 'ttq');
  
      ttq.identify({
        email: '${email}',
        'external_id': '${panelistId}'
      });
  
      ttq.track('CompleteRegistration');
      console.log("TIKTOK SCRIPT WORKING");
    `;
  
    // Meta Pixel Script
    const metaScript = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '3241517045981800', {
        em: '${email}',
      });
  
      fbq('track', 'PageView');
      fbq('track', 'CompleteRegistration');
      console.log("META SCRIPT WORKING");
    `;
  
    // Snapchat Pixel Script
    const snapchatScript = `
      (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
      {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
  
      snaptr('init', 'd83cb686-a1f2-4fde-8885-0dd7def53fb6', {
      user_hashed_phone_number: '${sha256Email}',
      ip_address: '${ipAddress}'
      });
  
      snaptr('track', 'PURCHASE');
      console.log("SNAPCHAT SCRIPT WORKING");
    `;
  
    // Inject the scripts into the DOM
    //console.log("Executing Pixel Completes Scripts.");
    const tiktokScriptElement = document.createElement('script');
    tiktokScriptElement.innerHTML = tiktokScript;
    document.body.appendChild(tiktokScriptElement);
    //console.log("Added Tiktok Script : ", tiktokScriptElement );
    
    const metaScriptElement = document.createElement('script');
    metaScriptElement.innerHTML = metaScript;
    document.body.appendChild(metaScriptElement);
    //console.log("Added Meta Script : ", metaScriptElement );
    
    const snapchatScriptElement = document.createElement('script');
    snapchatScriptElement.innerHTML = snapchatScript;
    document.body.appendChild(snapchatScriptElement);
    //console.log("Added Snapchat Script : ", snapchatScriptElement);
  
    //console.log("Pixel scripts injected successfully.");
  }

  getRewards(){
    this.appSer.getAwardsDetails().subscribe((res:any) => { // To get the awards details 
      this.rewards = res.data;
    });
  }

  getPageContent(countryLangID:any){
    this.appSer.getPageContent('Profiler',countryLangID).subscribe( (res:any)=>{
      this.content = res.data;
      (this.content.kudosEarnedPoints.content) ? this.content.kudosEarnedPoints.content = this.content.kudosEarnedPoints.content.replace("#EarnedPoints#", this.panelistInfo.Points) : "";
      // this.selectedOption=this.content?.selectfromdropdown?.content

      if(this.content.selectfromdropdown){
        this.content.selectfromdropdown.content = this.content.selectfromdropdown.content.replace(/<[^>]*>/g, '');
      }
      

    });
  }

  getLoginLink(){
    this.appSer.getLoginLink(this.id).subscribe( (response:any)=>{
      // this.loginLinkInfo = response.response;
      // console.log(response)
      this.loginLink = response.data.response;
      this.redeemPerc = Math.floor((Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100);
      if( this.redeemPerc > 100){
        this.redeemPerc = 100;
      }
      // this.redeemPerc =Math.ceil((Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100 / 10) * 10;
      // let percentage = (Number(this.panelistInfo.Points) / Number(this.loginLink?.PointRequired)) * 100;
      // this.redeemPerc = Math.min(Math.ceil(percentage / 10) * 10, 100);
      console.log('redeemPerc', this.redeemPerc);
      let PointTobeRequired = Number(this.loginLink?.PointRequired) - Number(this.panelistInfo.Points);
      if(PointTobeRequired < 0){
        PointTobeRequired = 0;
      }
      (this.content.pointstobeEarned) ? this.content.pointstobeEarned.content = this.content.pointstobeEarned.content.replace("#PointsRequired#", PointTobeRequired) : "";
      (this.content.redeem) ? this.content.redeem.content = this.content.redeem.content.replace("#PointsRequired#", this.loginLink?.PointRequired) : "";
    })
  }
  // To get the Date of birth 
  getDob(form: any) {
    let dob =(this.profilerQuestionsForm.get((form.pageID).toString())?.get((form.questionID).toString()))?.value;
    this.DOB={
      'pageID' : (form?.pageID.toString()) ? form?.pageID.toString():'', 
      'questionID' :(form?.questionID.toString()) ? (form?.questionID.toString()):'',
      'selectedvalues' : (form?.selectedvalues) ? (form?.selectedvalues):'',
      'qInfo': form?.qInfo,
      'dob':dob,
      'min':form?.qInfo.MinRange.toString(),
      'max':form?.qInfo.MaxRange.toString()
    }
  }
// TO set the percentage of profiler completion
  getOverlayStyle() {
    const transform = ('translateY(-50%) ') + 'translateX(-50%)';

    return {
      top:  '50%',
      bottom: 'auto',
      left: '50%',
      transform,
      fontSize: this.radius / 3.5 + 'px',
    };
  }
  // Handle option selection
  onSelect(answer: any, question: any,page:any) {
    this.searchTerms[question.ID]?.setValue("");
    let pageGroup = this.profilerQuestionsForm.get(page.ID.toString());
    pageGroup?.get(question.ID.toString())?.patchValue(answer.ID);
    // this.searchTerms[question.ID]?.setValue(answer.AnswerChoiceText);
    this.dropdownSelected[question.ID] = answer.AnswerChoiceText;
  }
  getConfig(questionID: string) {
    let placeholderVal= "Select From dropdown";
    if(this.selectedDropdown[questionID]){
      placeholderVal = this.selectedDropdown[questionID];
    }else if( typeof this.content != 'undefined' && typeof this.content.selectfromdropdown !== 'undefined'){
      placeholderVal = this.content.selectfromdropdown.content;
    }else{
      placeholderVal = (this.selectedDropdown[questionID]) ? this.selectedDropdown[questionID] : "Select From dropdown";
    }
    return {
      displayFn: (item: any) => item.AnswerChoiceText,
      displayKey: 'AnswerChoiceText',
      // valueKey: 'ID',
      search: true,
      height: '210px',
      placeholder: placeholderVal,
      //customComparator: this.customComparator, // Ensure this returns a number
      // limitTo: 10,
      // moreText: 'More',
      noResultsFound: 'No results found',
      searchPlaceholder: 'Search',
      searchOnKey: 'AnswerChoiceText'
    };
  }
  noOfTheAbove(page: any, question: any, selection: any) {
    let pageControl = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl = pageControl?.get(question.ID.toString());
    if (selection.IsNoneoftheAbove) {
      // If "None of the Above" is selected, uncheck all other checkboxes
      question.AnswerChoice.forEach((answer: any) => {
        let otherControl = questionControl?.get(answer.ID.toString());
        if (answer.ID !== selection.ID) {
          otherControl?.setValue(false); // Uncheck all other checkboxes
        }
      });
    } else {
      // If any other checkbox is selected, uncheck "None of the Above"
      question.AnswerChoice.forEach((answer: any) => {
        if (answer.IsNoneoftheAbove) {
          let noneOfTheAboveControl = questionControl?.get(answer.ID.toString());
          noneOfTheAboveControl?.setValue(false); // Uncheck "None of the Above"
        }
      });
    }
  }
  // To select/unselect options when "Other" option selected 
  otherOptionSelection(page: any, question: any, selection: any) {
    let pageControl = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl = pageControl?.get(question.ID.toString());
    if (question.QuestionType.includes('Multi Choice')) {
      if (selection.IsOthers) { // If "Other option" is selected, uncheck all other checkboxes
        question.AnswerChoice.forEach((answer: any) => {
          let answerControl = questionControl?.get(answer.ID.toString());
          if (answer.ID !== selection.ID) {
            answerControl?.setValue(false); // Uncheck all other checkboxes
          }
        });
        this.showOtherOption(page, question, selection); // Show the other option textarea
      } else { // If any other checkbox is selected, uncheck "Other option"
        question.AnswerChoice.forEach((answer: any) => {
          if (answer.IsOthers) {
            let otherOptionControl = questionControl?.get(answer.ID.toString());
            otherOptionControl?.setValue(false); // Uncheck "Other option"
            this.hideOtherOption(page, question, answer); // Hide the other option textarea
          }
        });
      }
    } else {
      if (selection.IsOthers) {
        this.showOtherOption(page, question, selection); // Show the other option textarea
      } else {
        question.AnswerChoice.forEach((answer: any) => {
          if (answer.IsOthers) {
            let otherOptionControl = questionControl?.get(answer.ID.toString());
            otherOptionControl?.setValue(false); // Uncheck "Other option"
            this.hideOtherOption(page, question, answer); // Hide the other option textarea
          }
        });
      }
    }
  }
  // To show the Textarea when other option is selected 
  showOtherOption(page: any, question: any, answer: any) {
    let pageControl: any = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl: any = pageControl?.get(question.ID.toString());
    let otherOptionControlName = 'other-' + answer.ID;
    if (question.QuestionType.includes('Multi Choice')) { //Multi Choice buttons 
      if (questionControl.get(answer.ID.toString())?.value) { // If "Other" option is selected
        this.otherSelected[answer.ID] = true;
        if (!questionControl.get(otherOptionControlName)) {
          questionControl.addControl(otherOptionControlName, new FormControl((questionControl.get(otherOptionControlName)?.value) ? questionControl.get(otherOptionControlName)?.value : answer?.Comment, Validators.required));
        }
      } else { // If "Other" option is deselected
        this.otherSelected[answer.ID] = false;
        questionControl.removeControl(otherOptionControlName);
      }
    } else { //Radio buttons 
      if (pageControl.get(question.ID.toString())?.value) { // If "Other" option is selected
        this.otherSelected[answer.ID] = true;
        if (!pageControl.get(otherOptionControlName)) {
          pageControl.addControl(otherOptionControlName, new FormControl((pageControl.get(otherOptionControlName)?.value) ? pageControl.get(otherOptionControlName)?.value : answer?.Comment, Validators.required));
        }
      } else { // If "Other" option is deselected
        this.otherSelected[answer.ID] = false;
        pageControl.removeControl(otherOptionControlName);
      }
    }
  }
  // To hide the Textarea when other option is un-selected 
  hideOtherOption(page: any, question: any, answer: any) {
    let pageControl: any = this.profilerQuestionsForm?.get(page.ID.toString());
    let questionControl: any = pageControl?.get(question.ID.toString());
    const otherOptionControlName = 'other-' + answer.ID;
    if (question.QuestionType.includes('Multi Choice')) { //Multi Choice buttons
      this.otherSelected[answer.ID] = false;
      questionControl.removeControl(otherOptionControlName);
    } else {
      this.otherSelected[answer.ID] = false;
      pageControl.removeControl(otherOptionControlName);
    }
  }
  // To get the other option content (Comment)
  getComment(pageId: number, questionId: number): string[] {
    let comment;
    let pageGroup = this.profilerQuestionsForm.get(pageId.toString()) as FormGroup;
    let questionControl = pageGroup.get(questionId.toString());
    if (questionControl) {
      let presentQuestion = this.profilerQuestions.find((page: any) => page.ID === pageId)?.questions.find((q: any) => q.ID === questionId);
      if (presentQuestion) {
        let selectedAnswer = presentQuestion.AnswerChoice.find((answer: any) => answer.IsOthers && answer.IsActive);
        if (presentQuestion.QuestionType.includes('Multi Choice')) {
          if (selectedAnswer) {
            comment = questionControl.get('other-' + selectedAnswer.ID)?.value;
          } else {
            comment = "Priority profiler";
          }
        } else {
          if (selectedAnswer) {
            comment = pageGroup.get('other-' + selectedAnswer.ID)?.value;
          } else {
            comment = "Priority profiler";
          }
        }
      }
    }
    return comment;
  }
}
